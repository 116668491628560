import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Styles from "../Review/Review.module.css";
import Table from "react-bootstrap/Table";
import { Dropdown } from "react-bootstrap";
import { SlOptions } from "react-icons/sl";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import { Rating } from "@mui/material";
import ReviewPopup from "./ReviewPopup";
import SwiftVanApi from "../../helpers/Api";

import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Loader from "../../Validation/LoadingSpinner";

const Review = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);

  //  --review
  const [reviewPopupShow, SetReviewPopupShow] = useState(false);
  const ReviewPopupHandleClose = () => SetReviewPopupShow(false);
  const ReviewPopupHandleShow = () => SetReviewPopupShow(true);

  const [reviewData, setReviewData] = useState("");
  const [reviewUpdateData, setReviewUpdate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const ReviewDataList = () => {
    setIsLoading(true);
    let res = SwiftVanApi.getReviewData(authToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setReviewData(data.data);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    ReviewDataList();
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              {/*---search-section---*/}

              <div className={Styles.couponHeading}>
                <h1>{t("REVIEW_LIST")}</h1>
              </div>

              {/* table */}
              <div className="tableMainSection">
                <div className="tableMainInner">
                  <div className="tableBootomScroll">
                    <div className="UserTableInner">
                      <Table striped responsive="lg">
                        <thead>
                          <tr className="align-middle">
                            <th className="tableThBg tablethleft leftsidepadding ">
                              {t("Title")}
                            </th>
                            <th className="tableThBg">{t("DESCRIPTION")}</th>
                            <th className="tableThBg">{t("RATING")}</th>
                            <th className="tableThBg tablethRight text-center ">
                              {t("ACTION")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reviewData && reviewData.length > 0 ? (
                            reviewData.map((item, index) => {
                              return (
                                <tr key={index} className="align-middle">
                                  <td
                                    className="tableTdText  leftsidepadding"
                                    style={{ minWidth: "300px" }}
                                  >
                                    {item && item.review_title ? (
                                      <>
                                        {capitalizeFirstLetter(
                                          item && item.review_title
                                        )}
                                      </>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                  <td
                                    className="tableTdText "
                                    style={{ minWidth: "400px" }}
                                  >
                                    <div className="tableTextThreeLine">
                                      {item && item.review_desc
                                        ? item.review_desc
                                        : "NA"}
                                    </div>
                                  </td>

                                  <td
                                    className="tableTdText"
                                    style={{ minWidth: "200px" }}
                                  >
                                    {/* rating */}
                                    <Rating
                                      name="read-only"
                                      value={item && item.rating}
                                      precision={1}
                                      readOnly
                                    />
                                  </td>

                                  <td className="text-center">
                                    <div className="TableDropdown">
                                      <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                          <div className="optionList">
                                            <SlOptions />
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="dropDownOnlyOneList">
                                          <Dropdown.Item
                                            onClick={() => {
                                              ReviewPopupHandleShow("");
                                              setReviewUpdate(item);
                                            }}
                                          >
                                            {t("EDIT_DROPDOWN")}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="7" className="centeredCell">
                                <div className="dataRecord">
                                  {t("NO_REC_FOUND")}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <ReviewPopup
        reviewPopupShow={reviewPopupShow}
        SetReviewPopupShow={SetReviewPopupShow}
        ReviewPopupHandleClose={ReviewPopupHandleClose}
        ReviewDataList={ReviewDataList}
        reviewUpdateData={reviewUpdateData}
      />
    </>
  );
};

export default Review;
