// Function to calculate the total amount with discount applied
export const calculateTotalAmountWithDiscount = (job, type) => {
  let amount = 0;
  let afterDiscount = 0;
  let driverEarning = 0;
  let discount = 0;
  let adminCommissionRs = 0;

  if (
    job.payment_summary &&
    job.payment_summary[0] &&
    job.payment_summary[0].amount
  ) {
    // Get the amount and coupon code value
    if (job.payment_summary[0].admin_amount) {
      amount = parseFloat(job.payment_summary[0].admin_amount);
    } else {
      amount = parseFloat(job.payment_summary[0].amount);
    }
    const couponCodeValue = job.payment_summary[0].coupon_code_value;

    discount = amount * (couponCodeValue / 100);

    // Calculate the discounted total amount
    var adminComPer;
    if (job?.payment_summary[0]?.new_admin_commission) {
      adminComPer = parseFloat(job.payment_summary[0].new_admin_commission);
    } else {
      adminComPer = parseFloat(job.payment_summary[0].admin_commission);
    }
    afterDiscount = amount - discount;

    adminCommissionRs = amount * (adminComPer / 100);

    if (type == "commission") {
      return adminCommissionRs.toFixed(2);
    } else if (type == "discount") {
      return discount.toFixed(2);
    } else if (type == "driver_earning") {
      driverEarning = amount - adminCommissionRs;
      return driverEarning.toFixed(2);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};
