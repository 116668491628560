import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import First from "./Tabs/First";
import { useSelector } from "react-redux";
import Second from "./Tabs/Second";
import Third from "./Tabs/Third";
import Fourth from "./Tabs/Fourth";
import Fifth from "./Tabs/Fifth";

const CreateJob = () => {
  const activeTab = useSelector((state) => state.createJob.activeTab);

  const renderTab = (tabName) => {
    switch (tabName) {
      case "first":
        return <First />;
      case "second":
        return <Second />;
      case "third":
        return <Third />;
      case "fourth":
        return <Fourth />;
      case "fifth":
        return <Fifth />;
      //   case "b2bEnquiry":
      //     return <B2bEnquiry />;
      default:
        return null; // You can handle other cases or unknown tabs here
    }
  };

  return (
    <>
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">{renderTab(activeTab)}</div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateJob;
