import styles from "./LoadingSpinner.module.css";
import loadingSpinner from "../Assests/Images/loadingSpinner.gif";

const Loader = () => (
  <div className={styles.loaderOverlay}>
    <div className={styles.loaderContainer}>
      <img
        src={loadingSpinner}
        alt="Loading Spinner"
        className="customLoader"
      />
    </div>
  </div>
);

export default Loader;