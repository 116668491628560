import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "./metadata.module.css";
import FileImg from "../../Assests/Images/FileInput.png";
import CameraImg from "../../Assests/Images/uploadCamera.png";
import { useTranslation } from "react-i18next";
import { Validation } from "../../Validation/Validation";
import { Controller, useForm } from "react-hook-form";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";

function MetadataPopup({
  show,
  handleClose,
  setShow,
  vehicleTypeList,
  vanUpdateId,
  isUpdate,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timeInput, setTimeInput] = useState("");
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [vanType, setVanType] = useState({
    id: "",
    label: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState,
    reset,
    control,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  //===== van type option list=====
  const vanOptions = [
    {
      id: 1,
      label: "Small",
    },
    {
      id: 2,
      label: "Medium",
    },
    {
      id: 3,
      label: "Large",
    },
    {
      id: 4,
      label: "Extra Large",
    },
    {
      id: 5,
      label: "Super Large",
    },
  ];

  // function for select van type
  const vanSelect = (value) => {
    const selectedOption = vanOptions.find((option) => option.label === value);
    if (selectedOption) {
      setVanType(selectedOption);
    }
  };

  useEffect(() => {
    if (isUpdate === false) {
      reset();
      clearErrors();
      setPreviewImage("");
      setShowPreview("");
    }
  }, [show]);

  useEffect(() => {
    reset();
    if (Object.keys(vanUpdateId).length > 0) {
      setPreviewImage(vanUpdateId.vehicle_image);
      setValue("vehicleName", vanUpdateId.vehicle_name);
      const vanSize = vanOptions.find((val) => val.label == vanUpdateId.size);
      setVanType(vanSize);
      setValue("vehicleDescription", vanUpdateId.vehicle_description);
      setValue("minimumTime", vanUpdateId.time);
      setTimeInput(vanUpdateId.time);
    } else {
      setPreviewImage("");
      setShowPreview("");
      setValue("vehicleName", "");
      setValue("vehicleDescription", "");
      setValue("minimumTime", "");
      setTimeInput("");
    }
  }, [vanUpdateId, show]);

  useEffect(() => {
    reset();
  }, []);

  const [previewImage, setPreviewImage] = useState(null);
  const [showPreview, setShowPreview] = useState("");

  // function for apply validation in image field
  const validateImage = () => {
    if (!previewImage) {
      return `${t("IMA_IS_REQUIRED")}`;
    }
    return true;
  };

  //----- image upload-----
  function handleFileSelection(e) {
    const selectedFile = e.target.files[0];
    setShowPreview(selectedFile);

    if (e.target.files.length !== 0) {
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
      setShowPreview(e.target.files[0]);
    }
  }

  const VanhHandleClose = () => {
    setPreviewImage("");
    setShowPreview("");
  };

  const clearData = () => {
    setPreviewImage("");
    setValue("vehicleName", "");
    setValue("vehicleDescription", "");
    setTimeInput("");
  };

  const onSubmit = (data) => {
    {
      isUpdate === true ? updateApi(data) : createApi(data);
    }
  };

  const updateApi = (data) => {
    const trimmedVehicleName =
      data && data.vehicleName && data.vehicleName.trim();
    const trimmedVehicleDescription =
      data && data.vehicleDescription && data.vehicleDescription.trim();

    var PostData = new FormData();
    PostData.append("vehicle_id", vanUpdateId.id ? vanUpdateId.id : "");
    PostData.append("vehicle_name", trimmedVehicleName);
    PostData.append("size", vanType.id);
    PostData.append("vehicle_description", trimmedVehicleDescription);
    PostData.append("vehicle_image", showPreview);
    PostData.append("time", Number(timeInput));
    setIsLoading(true);
    let res = SwiftVanApi.postVehicleTypeLists(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("UPDATE_VEHICALE_SUCCESSFULLY"), "success");
        setShow(false);
        vehicleTypeList();
        reset();
        clearData();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const createApi = (data) => {
    const trimmedVehicleName =
      data && data.vehicleName && data.vehicleName.trim();
    const trimmedVehicleDescription =
      data && data.vehicleDescription && data.vehicleDescription.trim();

    var PostData = new FormData();
    PostData.append("vehicle_id", vanUpdateId.id ? vanUpdateId.id : "");
    PostData.append("vehicle_name", trimmedVehicleName);
    PostData.append("size", vanType.id);
    PostData.append("vehicle_description", trimmedVehicleDescription);
    PostData.append("vehicle_image", showPreview);
    PostData.append("time", Number(timeInput));
    setIsLoading(true);
    let res = SwiftVanApi.postVehicleTypeLists(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("CREATE_VEHICALE_SUCCESSFULLY"), "success");
        setIsLoading(false);
        setShow(false);
        vehicleTypeList();
        reset();
        clearData();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={show} onHide={handleClose}>
        <div className="addVanPopupMain">
          <Modal.Header>
            {vanUpdateId && vanUpdateId.id ? (
              <Modal.Title>{t("EDIT_VAN")}</Modal.Title>
            ) : (
              <Modal.Title>{t("ADD_VAN")}</Modal.Title>
            )}

            <MdCancel onClick={handleClose} />
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={Styles.addVanUploadBtn}>
                <h1>{t("IMAGE")}</h1>

                <div className={Styles.vanUploadBtnInner}>
                  {previewImage && (
                    <div className={Styles.addVanPreviewImg}>
                      <span
                        className={Styles.previewImageCrossIcon}
                        onClick={VanhHandleClose}
                      >
                        <MdCancel />
                      </span>
                    </div>
                  )}

                  <div className={Styles.vanUploadInnerText}>
                    {previewImage ? (
                      <div className={Styles.vanUploadImg}>
                        <img src={previewImage} />
                      </div>
                    ) : (
                      <>
                        <div className={Styles.vanUploadImgIcon}>
                          <img
                            src={FileImg}
                            width="50px"
                            height="50px"
                            alt="File Icon"
                          />
                        </div>
                        <p>{t("UPLOAD_VAN_IMAGE")}</p>

                        <div>
                          <Controller
                            control={control}
                            name="vehicleImage"
                            render={({ field }) => (
                              <input
                                id="file"
                                type="file"
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={(e) => {
                                  handleFileSelection(e);
                                }}
                              />
                            )}
                            rules={{ validate: validateImage }}
                          />
                          <div className={Styles.uploadBtnMain}>
                            <button type="button">
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="file"
                              >
                                <img src={CameraImg} alt="Camera" />
                                {t("UPLOAD")}
                              </label>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {!previewImage && formState.errors.vehicleImage && (
                  <p style={{ color: "red" }}>
                    {t(formState.errors.vehicleImage.message)}
                  </p>
                )}
              </div>

              <Row>
                <Col lg={12}>
                  <Form.Group className="popupInput">
                    <Form.Label>{t("VAN_NAME")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="vehicleName"
                      {...register("vehicleName", Validation.vehicleName)}
                      onChange={(e) => {
                        setValue("vehicleName", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      placeholder={`${t("ENTER_VEHICLE_NAME")}`}
                      autoComplete="off"
                    />
                    {formState.errors.vehicleName && (
                      <p style={{ color: "red" }}>
                        {t(formState.errors.vehicleName.message)}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="CreateInput">
                    <Form.Label>{t("MINIMUM_TIME")}</Form.Label>
                    <div style={{ display: "flex", position: "relative" }}>
                      <div style={{ width: "100%" }}>
                        <Form.Control
                          type="text"
                          name="minimumTime"
                          placeholder={`${t("MINIMUM_TIME_INPUT")}`}
                          value={timeInput}
                          {...register("minimumTime", Validation.minimumTime)}
                          onChange={(e) => {
                            setValue("minimumTime", e.target.value, {
                              shouldValidate: true,
                            });

                            const inputValue = e.target.value;
                            if (
                              inputValue === "" ||
                              (/^\d+$/.test(inputValue) &&
                                inputValue >= 1 &&
                                inputValue <= 600)
                            ) {
                              setTimeInput(inputValue);
                            }
                          }}
                          min={1}
                          max={600}
                        />
                        {formState.errors.minimumTime && (
                          <p style={{ color: "red" }}>
                            {t(formState.errors.minimumTime.message)}
                          </p>
                        )}
                      </div>

                      <div className={Styles.minTimeInput}>
                        <div className={Styles.CategoryDevider}></div>
                        <div className={Styles.SelectDuration}>
                          <div className={Styles.SelectDurationInner}>
                            {t("MIN")}
                            <p></p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Label>{t("VAN_SIZE")}</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="vanDrop"
                    value={vanType.label}
                    onChange={(e) => vanSelect(e.target.value)}
                  >
                    {vanOptions.map((item, index) => {
                      return (
                        <option key={index + 1} value={item.user_role}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col lg={12}>
                  <Form.Group className="popupInput">
                    <Form.Label>{t("DESCRIPTION")}</Form.Label>

                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="vehicleDescription"
                      placeholder={`${t("ENTER_DESCRIPTION")}`}
                      autoComplete="off"
                      {...register(
                        "vehicleDescription",
                        Validation.vehicleDescription
                      )}
                      onChange={(e) => {
                        setValue("vehicleDescription", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {formState.errors.vehicleDescription && (
                      <p style={{ color: "red" }}>
                        {t(formState.errors.vehicleDescription.message)}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className={Styles.AddVanBtn}>
                {vanUpdateId && vanUpdateId.id ? (
                  <button type="submit">{t("EDIT_VAN")}</button>
                ) : (
                  <button type="submit">{t("ADD_VAN")}</button>
                )}
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default MetadataPopup;
