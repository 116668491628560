import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "./metadata.module.css";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Validation } from "../../Validation/Validation";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";

const ColorPopup = ({
  colorshow,
  colorClosePopup,
  colorDataList,
  colorNameUpdate,
  isUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };

  const { register, handleSubmit, setValue, formState, reset, clearErrors } =
    useForm({
      mode: "onChange",
    });

  useEffect(() => {
    if (isUpdate === false) {
      reset();
      clearErrors();
      setSelectedColor("");
    }
  }, [colorshow]);

  useEffect(() => {
    reset();
    if (Object.keys(colorNameUpdate).length > 0) {
      setValue("ColourName", colorNameUpdate.value_name);
      setValue("ColourCode", colorNameUpdate.value_code);
      setSelectedColor(colorNameUpdate.value_code);
    } else {
      setValue("ColourName", "");
      setValue("ColourCode", "");
      setSelectedColor("");
    }
  }, [colorNameUpdate, colorshow]);

  const onSubmit = (data) => {
    isUpdate === true ? updateApi(data) : createApi(data);
  };

  const createApi = (data) => {
    if (selectedColor === "") {
      Toster(t("PLEASE_SELECT_CO_CODE"), "error");
    } else {
      const trimmedColourCodeName =
        data && data.ColourName && data.ColourName.trim();

      var PostData = new FormData();
      PostData.append(
        "master_id",
        colorNameUpdate.id ? colorNameUpdate.id : ""
      );
      PostData.append("name", trimmedColourCodeName);
      PostData.append("keyName", "colours");
      PostData.append("value_code", selectedColor);
      setIsLoading(true);
      let res = SwiftVanApi.postMasterDataList(authToken, PostData);
      res.then((data) => {
        const msg = data && data.data && data.data.message;
        const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
        if (data && data.code === STATUS_CODES.SUCCESS) {
          setIsLoading(false);
          Toster(t("CREATE_COLOR_SUCCESSFULLY"), "success");
          colorDataList();
          colorClosePopup();
          reset();
        } else if (
          (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
          (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          if (
            data &&
            data.data &&
            data.data.code === STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  };
  const updateApi = (data) => {
    const trimmedColourName = data && data.ColourName && data.ColourName.trim();

    var PostData = new FormData();
    PostData.append("master_id", colorNameUpdate.id ? colorNameUpdate.id : "");
    PostData.append("name", trimmedColourName);
    PostData.append("keyName", "colours");
    PostData.append("value_code", selectedColor);
    setIsLoading(true);
    let res = SwiftVanApi.UpdateMasterDataList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("UPDATE_COLOR_SUCCESSFULLY"), "success");
        colorClosePopup();
        colorDataList();
        reset();
        clearData();
      } else if (
        data.code === STATUS_CODES.INVALID_TOKEN ||
        data.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const clearData = () => {
    setValue("ColourName", "");
    setValue("ColourCode", "");
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={colorshow} onHide={colorClosePopup}>
        <div className="colorPopupMain">
          <div className="addVanPopupMain">
            <Modal.Header>
              {colorNameUpdate && colorNameUpdate.id ? (
                <Modal.Title>{t("UPDATE_NEW_COLOR")}</Modal.Title>
              ) : (
                <Modal.Title>{t("ADD_NEW_COLOR")}</Modal.Title>
              )}
              <MdCancel onClick={colorClosePopup} />
            </Modal.Header>

            <Modal.Body>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={Styles.colorPopupMian}>
                  <div className={Styles.colorPopupInner}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("COLOR_NAME")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="ColourName"
                        {...register("ColourName", Validation.ColourName)}
                        onChange={(e) => {
                          setValue("ColourName", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        placeholder={t("ENTER_COLOUR_NAME_INPUT")}
                        autoComplete="off"
                      />
                      {formState.errors.ColourName && (
                        <p style={{ color: "red", mb: 1 }}>
                          {t(formState.errors.ColourName.message)}
                        </p>
                      )}
                    </Form.Group>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("COLOR_CODE_NAME")}</Form.Label>
                      <div
                        className={Styles.SelectedColorPreview}
                        style={{ backgroundColor: selectedColor }}
                      >
                        {selectedColor}
                      </div>
                      <div className={Styles.selectedColorBox}>
                        <SketchPicker
                          className={Styles.SketchPicker}
                          color={selectedColor}
                          onChange={handleColorChange}
                        />
                      </div>
                    </Form.Group>

                    <div className={Styles.AddVanBtn}>
                      <button type="submit">{t("SUBMIT")}</button>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ColorPopup;
