import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import styles from "./modal.module.css";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function SelectModal({
  showSelectSofas,
  setShowSelectSofas,
  selectedOptions,
  setSelectedOptions,
}) {
  const { t } = useTranslation();
  const [options, setOptions] = useState();
  const [filteredOptions, setFilteredOptions] = useState();

  useEffect(() => {
    const getQuantity = (items, showSelectSofas, option) => {
      // Initialize total quantity
      let totalQuantity = 0;

      // showSelectSofas.subItems.map((val) => {
      items.map((el) => {
        if (option.id == el.id) {
          return (totalQuantity = el.quantity);
        }
      });
      return totalQuantity;
    };

    const calculateQuantity = (option, selectedOptions) => {
      // Find the selected option with the same ID as the current option
      const selectedOption = selectedOptions.find(
        (selectedOption) => selectedOption.id === showSelectSofas.id
      );
      // If a matching selected option is found, return the total quantity, otherwise return 0
      return selectedOption
        ? getQuantity(selectedOption.items, showSelectSofas, option)
        : 0;
    };

    const updatedOptions = showSelectSofas.subItems?.map((option) => ({
      ...option,
      quantity: calculateQuantity(option, selectedOptions),
      is_custom_item: 0,
      width: "",
      height: "",
      depth: "",
      unit: { val: "", id: "" },
      weight: "",
      weightUnit: { val: "", id: "" },
    }));

    // Set the updated options with pre-filled quantities
    setOptions(updatedOptions);
    setFilteredOptions(updatedOptions);
  }, [showSelectSofas.subItems, selectedOptions, showSelectSofas.show]);

  const handleQuantityChange = (id, value) => {
    setFilteredOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) => {
        if (option.id === id) {
          return {
            ...option,
            quantity: option.quantity + value,
            is_custom_item: 0,
          };
        }
        return option;
      });
      return updatedOptions;
    });
  };

  const handleClose = () => {
    setShowSelectSofas((pre) => ({ ...pre, show: false }));
  };

  const handleSubmit = () => {
    if (options == undefined) {
      handleClose();
      return;
    }
    setSelectedOptions((prevOptions) => {
      // Check if an option with the same id already exists in selectedOptions
      const existingOptionIndex = prevOptions.findIndex(
        (option) => option.id === showSelectSofas.id
      );

      // Filter out options with quantity greater than 0
      const filteredOptions2 = filteredOptions.filter(
        (option) => option.quantity > 0
      );

      // Create a new array with the updated options
      const updatedOptions = prevOptions.map((option) => {
        if (option.id === showSelectSofas.id) {
          // If the option matches the id, update its items property with the filtered options
          return { ...option, items: filteredOptions2 };
        }
        return option;
      });

      // If the option with the same id doesn't exist and there are options with quantity greater than 0,
      // add a new entry to selectedOptions
      if (existingOptionIndex === -1 && filteredOptions2.length > 0) {
        updatedOptions.push({
          id: showSelectSofas.id,
          title: showSelectSofas.title,
          items: filteredOptions2,
        });
      }

      return updatedOptions.filter((option) => option.items.length > 0);
    });

    handleClose();
  };

  const handleSearchSubCategory = (value) => {
    const query = value.toLowerCase().trim();
    const filteredSubCategories = options?.filter((subItem) => {
      return subItem.name.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredOptions(filteredSubCategories);
  };

  return (
    <>
      <Modal show={showSelectSofas.show} onHide={() => handleClose()}>
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{showSelectSofas.title}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                handleClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <InputGroup className="mb-3" style={{ height: "45px" }}>
                  <InputGroup.Text
                    id="basic-addon1"
                    // style={{
                    //   borderRight: "none",
                    //   backgroundColor: "white",
                    // }}
                  >
                    <Icon
                      icon="iconamoon:search"
                      color="#4770EB"
                      width="22"
                      height="22"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="search"
                    onChange={(e) => handleSearchSubCategory(e.target.value)}
                    // style={{ borderLeft: "none" }}
                    placeholder={t("ENTER_ITEMS")}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              {filteredOptions?.map((option) => (
                <Col lg={6} className="mb-3">
                  <div className={styles.optionContainer}>
                    <div className={styles.optionName}>{option.name}</div>
                    {/* <div className={styles.optionCounts}>
                      <div>
                        {option.quantity > 0 ? (
                          <Icon
                            icon="zondicons:minus-solid"
                            color="0F7DFF"
                            width="19"
                            height="19"
                            onClick={() => handleQuantityChange(option.id, -1)}
                          />
                        ) : (
                          <Icon
                            icon="zondicons:minus-solid"
                            color="rgba(130, 130, 130, 0.8509803921568627)"
                            width="19"
                            height="19"
                          />
                        )}
                      </div>
                      <div className={styles.optionQuantity}>
                        {option.quantity}
                      </div>
                      <div>
                        {option.quantity >= 20 ? (
                          <Icon
                            icon="bi:plus-circle-fill"
                            color="rgba(130, 130, 130, 0.8509803921568627)"
                            width="19"
                            height="19"
                          />
                        ) : (
                          <Icon
                            icon="bi:plus-circle-fill"
                            color="#0F7DFF"
                            width="19"
                            height="19"
                            onClick={() => handleQuantityChange(option.id, +1)}
                          />
                        )}
                      </div>
                    </div> */}
                    <div className={styles.optionCounting}>
                      <div className={styles.optionCounts}>
                        {option.quantity > 0 ? (
                          <Icon
                            icon="ic:round-minus"
                            color="#202020"
                            width="19"
                            height="19"
                            onClick={() => handleQuantityChange(option.id, -1)}
                          />
                        ) : (
                          <Icon
                            icon="ic:round-minus"
                            color="#828282"
                            width="19"
                            height="19"
                          />
                        )}
                        <div className={styles.optionQuantity}>
                          {option.quantity}
                        </div>
                        <div>
                          {option.quantity >= 20 ? (
                            <Icon
                              icon="ic:round-plus"
                              color="rgba(130, 130, 130, 0.8509803921568627)"
                              width="20"
                              height="20"
                            />
                          ) : (
                            <Icon
                              icon="ic:round-plus"
                              color="#202020"
                              width="20"
                              height="20"
                              onClick={() =>
                                handleQuantityChange(option.id, +1)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              {options?.length == 0 ||
                (!options && <div>{t("SUBCATEGORY_NOT_AVAILABLE")}</div>)}
              {filteredOptions?.length == 0 && (
                <div>{t("NOT_FOUND_RESULTS")}</div>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              {t("DONE")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default SelectModal;
