export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber == null) {
    return;
  }

  // Remove any non-digit characters
  phoneNumber = phoneNumber?.replace(/\D/g, "");

  let formattedPhoneNumber;
  if (phoneNumber.length < 5) {
    formattedPhoneNumber = phoneNumber;
  } else if (phoneNumber.length === 5) {
    formattedPhoneNumber = phoneNumber.replace(/(\d{5})/, "$1");
  } else if (phoneNumber.length <= 7) {
    formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{0,2})/, "$1 $2");
  } else if (phoneNumber.length <= 10) {
    formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{0,4})/,
      "$1 $2 $3"
    );
  } else {
    formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{0,4})/,
      "$1 $2 $3"
    );
  }

  return formattedPhoneNumber;
}
