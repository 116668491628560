import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Loader from "../../Validation/LoadingSpinner";
import Styles from "./Deleted.module.css";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useTranslation } from "react-i18next";
import Toster from "../../Utils/Toster";
import { useNavigate } from "react-router-dom";
import { Dropdown, Table } from "react-bootstrap";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdRemoveRedEye,
} from "react-icons/md";
import { SlOptions } from "react-icons/sl";
import { formatPhoneNumber } from "../../Utils/FormateMobileNumber";
import { PAGE } from "../../Utils/Constant";
import ReactPaginate from "react-paginate";
import { STATUS_MSG } from "../../Utils/StatusMsg";

export default function DeletedAccount() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const itemsPerPage = PAGE.PAGE_COUNT;
  const [isLoading, setIsLoading] = useState(false);
  const [tabOption, setTabOption] = useState([
    {
      title: "Customer",
      active: true,
    },
    {
      title: "|",
      active: false,
    },
    {
      title: "Driver",
      active: false,
    },
  ]);

  const [activeSection, setActiveSection] = useState("Customer");
  const [userList, setUserList] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState();

  const handleTabClick = (title) => {
    setActiveSection(title);
    setTabOption((prevState) =>
      prevState.map((tab) => ({
        ...tab,
        active: tab.title === title,
      }))
    );
  };

  // function for get user list api calling
  async function getUserList(pageNumber) {
    setIsLoading(true);
    const details = await SwiftVanApi.getUserList(
      userToken,
      pageNumber,
      "",
      "deleted_account"
    );
    setIsLoading(false);
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setUserList(details && details.data && details.data.customerList);
      setTotalRecords(details && details.data && details.data.customerCount);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    }
  }

  // function for get driver list api calling
  async function getDriverList() {
    setIsLoading(true);
    const details = await SwiftVanApi.getDriverList(
      userToken,
      pageNumber,
      "",
      "",
      "",
      "deleted_account"
    );
    setIsLoading(false);
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setUserList(details && details.data && details.data.driverList);
      setTotalRecords(details && details.data && details.data.driverCount);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    }
  }

  useEffect(() => {
    activeSection == "Driver" && getDriverList();
    activeSection == "Customer" && getUserList(pageNumber);
  }, [activeSection, pageNumber]);

  // function for handle pagination
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  async function changeUserStatus(id) {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("user_id", id);
    const details = await SwiftVanApi.changeUserStatus(requestData, userToken);
    setIsLoading(false);
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      activeSection == "Driver" && getDriverList();
      activeSection == "Customer" && getUserList(pageNumber);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    } else {
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  const handleDetails = (id) => {
    activeSection == "Driver" && navigate(`/driver/driver-details/${id}`);
    activeSection == "Customer" && navigate(`/user/user-details/${id}`);
  };

  return (
    <div className="adminPageWrapper">
      <div className="adminPageInner">
        <div className="adminPageInner_sidebar">
          <Sidebar />
        </div>
        <div className="adminPageInner_rightside">
          <div className="headersection">
            <Header />
          </div>
          {isLoading ? <Loader /> : ""}
          <div className="adminPageInner_heading">
            <div className={Styles.deletedHeading}>
              {tabOption.map((val) => {
                return (
                  <>
                    <div
                      className={Styles.headTitle}
                      style={{ color: val.active ? "#0094f6" : "" }}
                      onClick={() => handleTabClick(val.title)}
                    >
                      {val.title}
                    </div>
                  </>
                );
              })}
            </div>

            {/* table */}
            <div className="tableMainSection">
              <div className="tableMainInner">
                <div className="tableBootomScroll">
                  <div className="UserTableInner">
                    <Table striped responsive="lg">
                      <thead>
                        <tr className="align-middle">
                          <th className="tableThBg tablethleft text-center">
                            {t("IMG")}
                          </th>
                          <th className="tableThBg">{t("NAME")}</th>
                          <th className="tableThBg">{t("EMAIL")}</th>
                          <th className="tableThBg">{t("CONTACT_NUMBER")}</th>
                          <th className="tableThBg">{t("JOBS")}</th>
                          <th className="tableThBg text-center">{t("VIEW")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList && userList.length > 0 ? (
                          userList.map((item, index) => {
                            return (
                              <tr key={index} className="align-middle">
                                <td className="tableTdText text-center tableImgMain ">
                                  <span>
                                    <img
                                      src={item && item.profile_image}
                                      alt="img"
                                    />
                                  </span>
                                </td>
                                <td
                                  className="tableTdText textUpperLatter"
                                  style={{ minWidth: "180px" }}
                                >
                                  {item && item.first_name ? (
                                    <>
                                      {item && item.first_name}{" "}
                                      {item && item.last_name}
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </td>
                                <td
                                  className="tableTdText"
                                  style={{ minWidth: "200px" }}
                                >
                                  {item && item.email ? item.email : "NA"}
                                </td>
                                <td
                                  className="tableTdText"
                                  style={{ minWidth: "180px" }}
                                >
                                  {item && item.phone_number ? (
                                    <>
                                      {item &&
                                      item.dial_code &&
                                      !item.dial_code.includes("+")
                                        ? `+${item && item.dial_code}`
                                        : `${item && item.dial_code}`}{" "}
                                      {formatPhoneNumber(
                                        item && item.phone_number
                                      )}
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </td>
                                <td
                                  className="tableTdText"
                                  style={{ minWidth: "80px" }}
                                >
                                  {item && item.jobs_count}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ minWidth: "120px" }}
                                >
                                  <MdRemoveRedEye
                                    className="eyeIcon"
                                    onClick={() => {
                                      handleDetails(item.id);
                                      // navigate(`/user/user-details/${item.id}`);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" className="centeredCell">
                              <div className="dataRecord">
                                {t("NO_REC_FOUND")}
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>

                {/* pagination */}
                <div className="tablePaginationDash">
                  {totalRecords > itemsPerPage && (
                    <ReactPaginate
                      previousLabel={<MdKeyboardArrowLeft />}
                      nextLabel={<MdKeyboardArrowRight />}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      pageCount={Math.ceil(totalRecords / itemsPerPage)}
                      breakLabel={<span className="page-link">...</span>}
                      marginPagesDisplayed={0}
                      pageRangeDisplayed={window.innerWidth > 768 ? 10 : 2}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
