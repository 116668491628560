import React, { useRef, useState } from "react";
import HeaderLogo from "../../Assests/Images/Header_logo.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import BellIconMessage from "./BellIconMessage";
import Styles from "../Header/header.module.css";
import LogoutPopup from "../../authComponent/LogoutPopup";
import NotificationPage from "../Notification/NotificationPage";

const Header = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [logoutPopupShow, SetLogoutPopupShow] = useState(false);
  const LogoutPopupHandleClose = () => SetLogoutPopupShow(false);
  const LogoutPopupHandleShow = () => {
    SetLogoutPopupShow(true);
  };

  return (
    <div className="headersection">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Navbar.Brand href="#home">
          <img src={HeaderLogo} alt="" />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            {/* <Nav.Link ref={target} onClick={() => setShow(!show)}>
              <span className={Styles.headerMessageicon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1585_860)">
                    <path
                      d="M36.1222 30.9227C35.0508 29.9675 34.1128 28.8725 33.3334 27.6671C32.4825 26.0033 31.9725 24.1862 31.8334 22.3227V16.8338C31.8407 13.9067 30.7789 11.0776 28.8475 8.87818C26.9161 6.67873 24.248 5.26028 21.3445 4.88932V3.45599C21.3445 3.06258 21.1882 2.68529 20.91 2.40711C20.6318 2.12894 20.2545 1.97266 19.8611 1.97266C19.4677 1.97266 19.0904 2.12894 18.8123 2.40711C18.5341 2.68529 18.3778 3.06258 18.3778 3.45599V4.91155C15.5003 5.30924 12.8644 6.73627 10.9584 8.92833C9.05235 11.1204 8.00528 13.9289 8.01114 16.8338V22.3227C7.87198 24.1862 7.362 26.0033 6.51114 27.6671C5.74545 28.8697 4.82247 29.9646 3.76669 30.9227C3.64817 31.0268 3.55318 31.1549 3.48805 31.2986C3.42291 31.4423 3.38912 31.5982 3.38892 31.756V33.2671C3.38892 33.5618 3.50598 33.8444 3.71435 34.0528C3.92273 34.2611 4.20534 34.3782 4.50003 34.3782H35.3889C35.6836 34.3782 35.9662 34.2611 36.1746 34.0528C36.383 33.8444 36.5 33.5618 36.5 33.2671V31.756C36.4998 31.5982 36.466 31.4423 36.4009 31.2986C36.3358 31.1549 36.2408 31.0268 36.1222 30.9227ZM5.70003 32.156C6.73381 31.1573 7.64403 30.0382 8.41114 28.8227C9.48293 26.8132 10.1083 24.596 10.2445 22.3227V16.8338C10.2004 15.5316 10.4188 14.2339 10.8867 13.0178C11.3547 11.8018 12.0625 10.6924 12.9681 9.75566C13.8737 8.8189 14.9585 8.07395 16.158 7.56518C17.3575 7.05641 18.6471 6.79422 19.95 6.79422C21.253 6.79422 22.5426 7.05641 23.7421 7.56518C24.9415 8.07395 26.0264 8.8189 26.932 9.75566C27.8376 10.6924 28.5454 11.8018 29.0133 13.0178C29.4812 14.2339 29.6997 15.5316 29.6556 16.8338V22.3227C29.7918 24.596 30.4171 26.8132 31.4889 28.8227C32.256 30.0382 33.1662 31.1573 34.2 32.156H5.70003Z"
                      fill="#0094F6"
                      stroke="#0094F6"
                    />
                    <path
                      d="M20 38.089C20.6999 38.0729 21.3716 37.8097 21.8962 37.3461C22.4208 36.8825 22.7646 36.2483 22.8667 35.5557H17.0222C17.1272 36.2671 17.487 36.9162 18.0348 37.3822C18.5825 37.8482 19.2809 38.0994 20 38.089Z"
                      fill="#0094F6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1585_860">
                      <rect width="40" height="40" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </Nav.Link> */}
            <Nav.Link>
              <NotificationPage />
            </Nav.Link>

            <Nav.Link onClick={() => LogoutPopupHandleShow()}>
              <span className={Styles.headerMessageicon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M33.9063 28.5938H31.1602C30.9727 28.5938 30.7969 28.6758 30.6797 28.8204C30.4063 29.1524 30.1133 29.4727 29.8047 29.7774C28.5426 31.0408 27.0476 32.0476 25.4024 32.7422C23.6979 33.4622 21.8659 33.8315 20.0156 33.8282C18.1445 33.8282 16.332 33.461 14.6289 32.7422C12.9837 32.0476 11.4887 31.0408 10.2266 29.7774C8.96218 28.5183 7.95399 27.0259 7.25783 25.3829C6.53517 23.6797 6.17189 21.8712 6.17189 20.0001C6.17189 18.129 6.53908 16.3204 7.25783 14.6172C7.95314 12.9727 8.95314 11.4922 10.2266 10.2227C11.5 8.95318 12.9805 7.95318 14.6289 7.25787C16.332 6.53912 18.1445 6.17193 20.0156 6.17193C21.8867 6.17193 23.6992 6.53522 25.4024 7.25787C27.0508 7.95318 28.5313 8.95318 29.8047 10.2227C30.1133 10.5313 30.4024 10.8516 30.6797 11.1797C30.7969 11.3243 30.9766 11.4063 31.1602 11.4063H33.9063C34.1524 11.4063 34.3047 11.1329 34.168 10.9258C31.1719 6.26959 25.9297 3.18756 19.9727 3.20318C10.6133 3.22662 3.10939 10.8243 3.20314 20.1719C3.29689 29.3712 10.7891 36.7969 20.0156 36.7969C25.957 36.7969 31.1758 33.7188 34.168 29.0743C34.3008 28.8672 34.1524 28.5938 33.9063 28.5938ZM37.3789 19.754L31.836 15.379C31.6289 15.2149 31.3281 15.3633 31.3281 15.6251V18.5938H19.0625C18.8906 18.5938 18.75 18.7344 18.75 18.9063V21.0938C18.75 21.2657 18.8906 21.4063 19.0625 21.4063H31.3281V24.3751C31.3281 24.6368 31.6328 24.7852 31.836 24.6212L37.3789 20.2462C37.4163 20.2169 37.4465 20.1796 37.4673 20.1369C37.488 20.0943 37.4988 20.0475 37.4988 20.0001C37.4988 19.9526 37.488 19.9058 37.4673 19.8632C37.4465 19.8205 37.4163 19.7832 37.3789 19.754Z"
                    fill="#EC3B1E"
                  />
                </svg>
              </span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <LogoutPopup
        logoutPopupShow={logoutPopupShow}
        LogoutPopupHandleClose={LogoutPopupHandleClose}
      />

      <BellIconMessage target={target} show={show} />
    </div>
  );
};

export default Header;
