import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./Customers.module.css";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Loader from "../../Validation/LoadingSpinner";
import { updateSelectedCustomerId } from "../../Redux/Slices/createJob";
import AddGuestCustomer from "./Modals/addGuestCustomer";

export default function Customers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userToken } = useSelector((state) => state.user);
  const storedCustomerId = useSelector(
    (state) => state.createJob.selectedCustomerId
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showGuestCustomer, setShowGuestCustomer] = useState(false);

  const onBack = () => {
    navigate("/job-summary");
  };

  useEffect(() => {
    setIsLoading(true);
    async function getCustomersLists(id = "", level = "") {
      const response = await SwiftVanApi.getCustomersLists(
        userToken,
        pageNumber,
        searchKey
      );
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        if (pageNumber > 1) {
          setCustomerList((prev) => [...prev, ...response.data.customerList]);
        } else {
          setCustomerList(response && response.data.customerList);
        }
        setIsLoadMore(response.data.loadMore);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      }
    }
    getCustomersLists();
  }, [pageNumber]);

  useEffect(() => {
    const getFilterdData = setTimeout(() => {
      setIsLoading(true);
      let res = SwiftVanApi.getCustomersLists(userToken, pageNumber, searchKey);
      res.then((response) => {
        const responseMsg = response && response.data && response.data.message;
        const responseCode =
          STATUS_MSG[response && response.data && response.data.code];
        setIsLoading(false);
        if (response.code === STATUS_CODES.SUCCESS) {
          if (pageNumber > 1) {
            setCustomerList((prev) => [...prev, ...response.data.customerList]);
          } else {
            setCustomerList(response && response.data.customerList);
          }
          setIsLoadMore(response.data.loadMore);
        } else {
          if (
            response.code == STATUS_CODES.SERVER_VALIDATION ||
            response.data.code == STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(responseMsg, "error");
          }
          if (
            response.code == STATUS_CODES.INVALID_TOKEN ||
            response.data.code == STATUS_CODES.INVALID_TOKEN
          ) {
            Toster(t(responseCode), "error");
            dispatch(userLogoutClear());
          } else {
            Toster(t(responseCode), "error");
          }
        }
      });
      res.catch(() => {
        setIsLoading(false);
      });
    }, 1000);

    return () => clearTimeout(getFilterdData);
  }, [searchKey]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchKey(value);
    setPageNumber(1);
  };

  const handleGuestCustomer = () => {
    setShowGuestCustomer(true);
  };

  const handelSelectCustomer = (cusId) => {
    dispatch(updateSelectedCustomerId(cusId));
    navigate("/hire-drivers");
  };

  const handleLoadMore = () => {
    setPageNumber((pre) => pre + 1);
  };

  return (
    <>
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <Row className={styles.topHead}>
                <Col lg={5} md={5} sm={5} xs={3}>
                  <div className="backarrow">
                    <FiArrowLeft onClick={() => onBack()} />
                  </div>
                </Col>
                <Col lg={7} md={7} sm={7} xs={9}>
                  <div>
                    <span className={styles.headTitle}>{t("CUSTOMERS")}</span>
                  </div>
                </Col>
              </Row>
              <Row className={styles.searchInput}>
                <Col lg={9} md={8} sm={9}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="search"
                      className={styles.searchInput}
                      placeholder={t("Search")}
                      value={searchKey}
                      onChange={(e) => handleSearchChange(e)}
                    />
                  </Form.Group>
                </Col>
                {/* <Col lg={3} md={4} sm={3}>
                  <Button className={styles.guestAddBtn}>
                    {t("ADD_GUEST_CUSTOMER")}
                  </Button>
                </Col> */}
              </Row>
              <Row>
                {isLoading && <Loader />}
                {customerList &&
                  customerList.map((customerInfo) => {
                    return (
                      <Col lg={3} md={4} sm={6} className="mb-3">
                        <div
                          className={styles.topDriverCard}
                          style={{
                            background:
                              storedCustomerId == customerInfo.id ? "" : "",
                            color:
                              storedCustomerId == customerInfo.id ? "" : "",
                          }}
                        >
                          <div className={styles.driverRating}>
                            <div className={styles.driverBooked}>
                              <div className={styles.driverReviewRating}>
                                {customerInfo.jobs_booked}
                              </div>
                              <div className={styles.driverReviewCount}></div>
                            </div>
                            <div className={styles.driverReviewContainer}>
                              <div className={styles.driverReviewRating}>
                                <div></div>
                                <div></div>
                              </div>
                              <div className={styles.driverReviewCount}></div>
                            </div>
                          </div>
                          <div
                            className={styles.driverCard}
                            // onClick={() => viewDriverDetails(customerInfo.id)}
                          >
                            <div>
                              <img
                                src={customerInfo.profile_image}
                                className={styles.driverImage}
                                alt="driver-image"
                              />
                            </div>
                            <div className={styles.customerName}>
                              {customerInfo.first_name} {customerInfo.last_name}
                            </div>
                            <div className={styles.customerEmail}>
                              {customerInfo.email}
                            </div>
                          </div>
                          <div className={styles.driverAccept}>
                            <Button
                              onClick={() =>
                                handelSelectCustomer(customerInfo.id)
                              }
                            >
                              {storedCustomerId !== customerInfo.id
                                ? t("SELECT")
                                : t("SELECT")}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
              {!isLoading && customerList.length == 0 && (
                <Row>
                  <div className={styles.notFoundContainer}>
                    <div className={styles.notFoundIcon}>
                      <Icon
                        icon="lucide:search-x"
                        color="grey"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div className={styles.notFoundMessage}>
                      {t("CUSTOMER_NOT_FOUND")}
                    </div>
                  </div>
                </Row>
              )}
              {isLoadMore && (
                <div className={styles.loadMoreBtn}>
                  <Button onClick={() => handleLoadMore()}>
                    {t("LOAD_MORE")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <AddGuestCustomer
        showGuestCustomer={showGuestCustomer}
        setShowGuestCustomer={setShowGuestCustomer}
      />
    </>
  );
}
