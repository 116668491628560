import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "./metadata.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Validation } from "../../Validation/Validation";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import SwiftVanApi from "../../helpers/Api";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";

const FloorPopup = ({
  floorshow,
  floorClosePopup,
  floorUpdate,
  setShowFloor,
  floorDataList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onChange",
  });

  const [timeInput, setTimeInput] = useState("");

  // mange time validation
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue == "" ||
      (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 600)
    ) {
      setValue("minimumTime", inputValue, { shouldValidate: true });
      setTimeInput(inputValue);
    }
  };

  useEffect(() => {
    reset();
    if (Object.keys(floorUpdate).length > 0) {
      setValue("floorName", floorUpdate.value_name);
      setValue("minimumTime", floorUpdate.time);
      setTimeInput(floorUpdate.time);
    } else {
      setValue("floorName", "");
      setValue("minimumTime", "");
      setTimeInput("");
    }
  }, [floorUpdate]);

  const onSubmit = (data) => {
    updateApi(data);
  };

  const updateApi = (data) => {
    const trimmedFloorName = data && data.floorName && data.floorName.trim();

    var PostData = new FormData();
    PostData.append("master_id", floorUpdate.id ? floorUpdate.id : "");
    PostData.append("name", trimmedFloorName);
    PostData.append("keyName", "floors");
    PostData.append("time", Number(timeInput));
    setIsLoading(true);
    let res = SwiftVanApi.postMasterDataList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("UPDATE_FLOOR_SUCCESSFULLY"), "success");
        setShowFloor(false);
        floorDataList();
        reset();
        clearData();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const clearData = () => {
    setValue("floorName", "");
    setTimeInput("");
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={floorshow} onHide={floorClosePopup}>
        <div className="floorPopupMain">
          <div className="helperPopupMain">
            <div className="addVanPopupMain">
              <Modal.Header>
                <Modal.Title>{t("ADD_FlOOR_BTN")}</Modal.Title>

                <MdCancel onClick={floorClosePopup} />
              </Modal.Header>

              <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="popupInput">
                        <Form.Label>{t("FLOOR_NAME")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`${t("ENTER_FLOOR_NAME")}`}
                          autoComplete="off"
                          name="floorName"
                          disabled
                          {...register("floorName", Validation.floorName)}
                          onChange={(e) => {
                            setValue("floorName", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="CreateInput">
                        <Form.Label>{t("MINIMUM_TIME")}</Form.Label>
                        <div style={{ display: "flex", position: "relative" }}>
                          <div style={{ width: "100%" }}>
                            <Form.Control
                              type="text"
                              name="minimumTime"
                              value={timeInput}
                              placeholder={`${t("MINIMUM_TIME_INPUT")}`}
                              {...register("minimumTime", {
                                required: {
                                  value: true,
                                  message: `${t("ENTER_MINIMUM_TIME")}`,
                                },
                              })}
                              onChange={handleInputChange}
                              min={1}
                              max={600}
                            />

                            {formState.errors.minimumTime && (
                              <p style={{ color: "red" }}>
                                {t(formState.errors.minimumTime.message)}
                              </p>
                            )}
                          </div>

                          <div className={Styles.minTimeInput}>
                            <div className={Styles.CategoryDevider}></div>
                            <div className={Styles.SelectDuration}>
                              <div className={Styles.SelectDurationInner}>
                                {t("MIN")}
                                <p></p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className={Styles.AddVanBtn}>
                    <button type="submit">{t("ADD_FlOOR_BTN")}</button>
                  </div>
                </form>
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FloorPopup;
