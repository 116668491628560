import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";

const UserShowMessage = ({ target, show }) => {
  const navigate = useNavigate();

  return (
    <>
      <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            <div>
              <svg
                onClick={() => {
                  navigate("/user/user-details");
                }}
                style={{ cursor: "pointer" }}
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={{ fill: "white" }}
                  d="M9.99992 4.5C9.33688 4.5 8.70099 4.76339 8.23215 5.23223C7.76331 5.70107 7.49992 6.33696 7.49992 7C7.49992 7.66304 7.76331 8.29893 8.23215 8.76777C8.70099 9.23661 9.33688 9.5 9.99992 9.5C10.663 9.5 11.2988 9.23661 11.7677 8.76777C12.2365 8.29893 12.4999 7.66304 12.4999 7C12.4999 6.33696 12.2365 5.70107 11.7677 5.23223C11.2988 4.76339 10.663 4.5 9.99992 4.5ZM9.99992 11.1667C8.89485 11.1667 7.83504 10.7277 7.05364 9.94628C6.27224 9.16488 5.83325 8.10507 5.83325 7C5.83325 5.89493 6.27224 4.83512 7.05364 4.05372C7.83504 3.27232 8.89485 2.83333 9.99992 2.83333C11.105 2.83333 12.1648 3.27232 12.9462 4.05372C13.7276 4.83512 14.1666 5.89493 14.1666 7C14.1666 8.10507 13.7276 9.16488 12.9462 9.94628C12.1648 10.7277 11.105 11.1667 9.99992 11.1667ZM9.99992 0.75C5.83325 0.75 2.27492 3.34167 0.833252 7C2.27492 10.6583 5.83325 13.25 9.99992 13.25C14.1666 13.25 17.7249 10.6583 19.1666 7C17.7249 3.34167 14.1666 0.75 9.99992 0.75Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default UserShowMessage;
