import Modal from "react-bootstrap/Modal";
import styles from "../../Validation/Alert.module.css";
import { useTranslation } from "react-i18next";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";

function DriverStatusModal({
  showAlertModal,
  setShowAlertModal,
  getDriverDetails,
  documentId,
  documentStatus,
  title,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);

  // function for change driver status api calling
  async function changeDriverStatus(status, id) {
    let requestData = new FormData();
    requestData.append("doc_id", id);
    requestData.append("status", status);
    setLoader(true);
    const details = await SwiftVanApi.driverDocumentStatus(
      requestData,
      userToken
    );
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      Toster(t("DRIVER_STATUS"), "success");
      setShowAlertModal(false);
      getDriverDetails();
      setLoader(false);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
      setLoader(false);
    } else {
      setLoader(false);
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  return (
    <>
      {loader && <Loader />}
      <Modal
        show={showAlertModal}
        onHide={() => {
          setShowAlertModal(false);
        }}
      >
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("ALERT")}</p>
            <span className={styles.alertCross}>
              <MdCancel
                onClick={() => {
                  setShowAlertModal(false);
                }}
              />
            </span>
          </div>

          <div className={styles.devider}></div>
          <div className={styles.alertBodyInner}>
            <div className={styles.alertLabel}>
              <p>Are you sure you want to {title} this document?</p>
            </div>

            <div className={styles.buttonContainer}>
              <button
                className={styles.buttonNo}
                type="button"
                onClick={() => {
                  setShowAlertModal(false);
                }}
              >
                {t("NO")}
              </button>
              <button
                className={styles.buttonYes}
                type="submit"
                onClick={() => changeDriverStatus(documentStatus, documentId)}
              >
                {t("YES")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DriverStatusModal;
