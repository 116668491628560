import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button } from "react-bootstrap";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function SelectUnits({ showUnitSelector, setShowUnitSelector, setProducts }) {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState({ val: "", id: "" });
  const { unitTypesList } = useSelector((state) => state.createJob);

  useEffect(() => {
    if (showUnitSelector.show) {
      if (showUnitSelector.type == "dimension_units") {
        setCurrentValue((prev) => ({
          ...prev,
          val: showUnitSelector.pro.unit.val,
        }));
      } else {
        setCurrentValue(showUnitSelector.pro.weightUnit);
        setCurrentValue((prev) => ({
          ...prev,
          val: showUnitSelector.pro.weightUnit.val,
        }));
      }
    }
  }, [showUnitSelector]);

  const handleCheckboxChange = (code, value) => {
    setCurrentValue({ val: value, id: code });
  };

  const handleSubmit = () => {
    if (showUnitSelector.type == "dimension_units") {
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === showUnitSelector.pro.id
            ? { ...product, unit: currentValue }
            : product
        )
      );
      setShowUnitSelector((prevState) => ({ ...prevState, show: false }));
    } else {
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === showUnitSelector.pro.id
            ? { ...product, weightUnit: currentValue }
            : product
        )
      );
      setShowUnitSelector((prevState) => ({ ...prevState, show: false }));
    }
  };

  const onClose = () => {
    setShowUnitSelector((pre) => ({ ...pre, show: false }));
  };

  return (
    <>
      <Modal
        show={showUnitSelector.show}
        onHide={() => {
          onClose();
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("SELECT")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                onClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {unitTypesList[showUnitSelector.type]?.map((checkbox) => (
              <div
                key={checkbox.id}
                className="floorCheckboxes"
                onClick={(e) => {
                  handleCheckboxChange(checkbox.id, checkbox.value_name);
                }}
              >
                <label
                  className={
                    currentValue.val === checkbox.value_name
                      ? styles.activeFloor
                      : ""
                  }
                >
                  {checkbox.value_name}
                </label>
                {/* <Button
                  variant={
                    currentValue?.val === checkbox.value_name
                      ? "primary"
                      : "secondary"
                  }
                  className={`${styles.selectedBtn}`}
                  onClick={(e) => {
                    handleCheckboxChange(
                      checkbox.id,
                      checkbox.value_name,
                      checkbox.time
                    );
                  }}
                >
                  {currentValue?.val === checkbox.value_name
                    ? t("SELECTED")
                    : t("SELECT")}
                </Button> */}
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              {t("DONE")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default SelectUnits;
