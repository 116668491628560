import Modal from "react-bootstrap/Modal";
import styles from "../../Validation/Alert.module.css";
import { MdCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useState } from "react";
import Loader from "../../Validation/LoadingSpinner";

function VehicleStatusPopup(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);

  // --vehicle status change
  const changeStatus = (id, status) => {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("id", id);
    requestData.append("status", status);
    let res = SwiftVanApi.driverVehicleStatus(requestData, userToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_STATUS_SUCCESSFULLY"), "success");
        props.SetVehicleShow(false);
        setIsLoading(false);
        props.getDriverDetails();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={props.vehicleShow} onHide={props.VehicleHandleClose}>
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("DELETE_TITLE")}</p>
            <span className={styles.alertCross}>
              <MdCancel onClick={props.VehicleHandleClose} />
            </span>
          </div>

          <div className={styles.devider}></div>
          <div className={styles.alertBodyInner}>
            <div className={styles.alertLabel}>
              <p>{t("ARE_YOU_SURE_APPROVE_VEHICLE")}</p>
            </div>

            <div className={styles.buttonContainer}>
              <button
                className={styles.buttonNo}
                onClick={() => {
                  changeStatus(props.vehicleId, "rejected");
                }}
              >
                {t("REJECT_BTN")}
              </button>
              <button
                className={styles.buttonYes}
                onClick={() => {
                  changeStatus(props.vehicleId, "approved");
                }}
              >
                {t("APPROVE_BTN")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default VehicleStatusPopup;
