import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "../Category/category.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import SwiftVanApi from "../../helpers/Api";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useForm } from "react-hook-form";
import { sanitizeHtmlTags } from "../../Utils/sanitizeHtmlTags";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";

const AddItemPopup = ({
  addItemshow,
  addItemhandleClose,
  subEventKeyValue,
  getCategoryList,
  isUpdate,
  itemList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const [timeInput, setTimeInput] = useState("");

  useEffect(() => {
    if (isUpdate === false) {
      reset();
      clearErrors();
      setTimeInput("");
    }
  }, [addItemshow]);

  useEffect(() => {
    reset();
    if (Object.keys(itemList).length > 0) {
      setValue("itemName", itemList.name);
      setTimeInput(itemList.time);
    } else {
      reset();
      clearErrors();
      setTimeInput("");
    }
  }, [itemList, addItemshow]);

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // mange time validation
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue == "" ||
      (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 600)
    ) {
      setValue("minimumTime", inputValue, { shouldValidate: true });
      setTimeInput(inputValue);
    }
  };

  const onSubmit = (formdata) => {
    {
      isUpdate === true ? updateApi(formdata) : createApi(formdata);
    }
  };

  //----- function for edit subcategory items-----
  const updateApi = async (formdata) => {
    let requestData = new FormData();
    requestData.append("category_id", itemList ? itemList.id : "");
    requestData.append(
      "category_name",
      formdata ? formdata.itemName && formdata.itemName.trim() : ""
    );
    requestData.append("parent_id", subEventKeyValue);
    requestData.append("time", Number(timeInput));
    setLoader(true);
    await SwiftVanApi.addCategory(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      const SuccessCode = STATUS_MSG[response && response.code];
      if (response && response.code === STATUS_CODES.CATEGORY_UPDATE) {
        setLoader(false);
        addItemhandleClose();
        Toster(t(SuccessCode), "success");
        getCategoryList(3, subEventKeyValue, "");
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        setLoader(false);
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- function for add subcategory items-----
  const createApi = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "category_name",
      formdata ? formdata.itemName && formdata.itemName.trim() : ""
    );
    requestData.append("parent_id", subEventKeyValue);
    requestData.append("time", Number(timeInput));
    setLoader(true);
    await SwiftVanApi.addCategory(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      const SuccessCode = STATUS_MSG[response && response.code];
      if (response && response.code === STATUS_CODES.CATEGORY_ADD) {
        setLoader(false);
        addItemhandleClose();
        Toster(t(SuccessCode), "success");
        getCategoryList(3, subEventKeyValue, "");
        setValue("itemName", "");
        setValue("minimumTime", "");
        setTimeInput("");
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        setLoader(false);
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {loader && <Loader />}
      <Modal show={addItemshow} onHide={addItemhandleClose}>
        <div className="helperPopupMain">
          <div className="addVanPopupMain">
            <Modal.Header>
              {itemList && itemList.id ? (
                <Modal.Title>{t("Update Item")} </Modal.Title>
              ) : (
                <Modal.Title>{t("ADD_ITEM")} </Modal.Title>
              )}
              <MdCancel onClick={addItemhandleClose} />
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="popupInput mb-3">
                      <Form.Label>{t("ITEM_NAME")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`${t("ENTER_NAME")}`}
                        autoComplete="off"
                        {...register("itemName", {
                          onChange: (e) => handleKeyPress(e),
                          required: {
                            value: true,
                            message: `${t("ITEM_NAME_ENTER")}`,
                          },
                          minLength: {
                            value: 2,
                            message: `${t("ITEM_MINLENGTH")}`,
                          },
                          maxLength: {
                            value: 50,
                            message: `${t("ITEM_MAXLENGTH")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      {errors.itemName && (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {t(errors.itemName.message)}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="CreateInput">
                      <Form.Label>{t("MINIMUM_TIME")}</Form.Label>
                      <div style={{ display: "flex", position: "relative" }}>
                        <div style={{ width: "100%" }}>
                          <Form.Control
                            type="text"
                            name="minimumTime"
                            value={timeInput}
                            placeholder={`${t("MINIMUM_TIME_INPUT")}`}
                            {...register("minimumTime", {
                              required: {
                                value: true,
                                message: `${t("ENTER_MINIMUM_TIME")}`,
                              },
                            })}
                            onChange={handleInputChange}
                            min={1}
                            max={600}
                          />
                          {errors.minimumTime && (
                            <p style={{ color: "red" }}>
                              {t(errors.minimumTime.message)}
                            </p>
                          )}
                        </div>
                        <div className={Styles.minTimeInput}>
                          <div className={Styles.CategoryDevider}></div>
                          <div className={Styles.SelectDuration}>
                            <div className={Styles.SelectDurationInner}>
                              {t("MIN")}
                              <p></p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <div className={Styles.AddVanBtn}>
                  {itemList && itemList.id ? (
                    <button type="submit">{t("Update Item")}</button>
                  ) : (
                    <button type="submit">{t("ADD_ITEM")}</button>
                  )}
                </div>
              </Form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddItemPopup;
