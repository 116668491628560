import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/Assests/style/common.css"
import Router from "./routes/Router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  return (
    <>

     <Router/>
     <ToastContainer />

    </>
  );
};

export default App;
