import style from "./NotFound.module.css";

function NotFound() {
  return (
    <>
      <div className={style.notFoundWrapper}>
        <h1>404 - Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        <a href="/">Go to Home</a>
      </div>
    </>
  );
}

export default NotFound;