import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_ID_KEY,
    uint32.toString(32)
  );
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_TOKEN_KEY,
    uint32.toString(32)
  );
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class SwiftVanApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    accept: "application/json",
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(
      process.env.REACT_APP_DEVICE_TOKEN_KEY
    ),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${REACT_PROXYURL}${BASE_URL}${endpoint}`;
    const headers = { ...SwiftVanApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /* ------LOGIN API -----*/
  static async login(data) {
    let res = await this.request(`/login`, data, "post");
    return res;
  }

  /* ------FORGOT PASSWORD API -----*/
  static async forgotPassword(data) {
    let res = await this.request(`/forgot-password`, data, "post");
    return res;
  }

  /* ------RESET PASSWORD API -----*/
  static async resetPassword(data) {
    let res = await this.request(`/reset-password`, data, "put");
    return res;
  }

  /* -----GET DRIVER LIST -----*/
  static async getDriverList(
    authToken,
    page_no,
    search,
    filter_by_doc,
    rating_from,
    list_type
  ) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-list?page_no=${page_no}&search=${search}&filter_by_doc=${filter_by_doc}&rating_from=${rating_from}&list_type=${list_type}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----GET USER LIST -----*/
  static async getUserList(authToken, page_no, search, list_type) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-list?page_no=${page_no}&search=${search}&list_type=${list_type}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------VEHICLE-TYPE-----*/
  static async vehicleTypeLists(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/vehicle-type`, "", "get", header);
    return res;
  }

  /* --------------GET-VEHICLE-TYPE-----------*/
  static async vehicleTypeLists(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/vehicle-type`, "", "get", header);
    return res;
  }

  // ------------POST-VEHICLE-TYPE------------
  static async postVehicleTypeLists(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/vehicle-type`, data, "post", header);
    return res;
  }

  // ------------DELETE--VEHICLE-TYPE-----------
  static async deleteVehicleTypeLists(authToken, vehicle_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/vehicle-type/${vehicle_id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  // ------------UPDATE--VEHICLE-TYPE-----------
  static async updateVhicleTypeLists(authToken, vehicle_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/vehicle-type/${vehicle_id}`,
      undefined,
      "put",
      header
    );
    return res;
  }

  /* ---------GET-MASTER--DATA-------------*/
  static async masterDataLists(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/master-data-list?ref_key=${id}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* --------POST-MASTER--DATA--------------*/
  static async postMasterDataList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/save-master-data`, data, "post", header);
    return res;
  }

  /* ------update-master-data-----*/
  static async updateMasterData(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/save-master-data`, {}, "post", header);
    return res;
  }

  /* -----GET CATEGORY LIST -----*/
  static async getCategoryList(authToken, id, is_level) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/category-list?id=${id}&is_level=${is_level}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----DRIVER DETAILS -----*/
  static async driverDetail(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/driver-details`, data, "post", header);
    return res;
  }

  /* -----CHANGE DRIVER DOCUMENT STATUS -----*/
  static async driverDocumentStatus(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/change-driver-document-status`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* -----USER DETAILS -----*/
  static async userDetails(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/customer-details`, data, "post", header);
    return res;
  }

  /* -----GET B2B Enquiry LIST -----*/
  static async getB2BEnquiry(authToken, page_no, search) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/b2bEnquiry-list?page_no=${page_no}&search=${search}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----ADD CATEGORY-----*/
  static async addCategory(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/add-category`, data, "post", header);
    return res;
  }

  /*  ------------DELETE CATEGORY-----------*/
  static async deleteCategory(authToken, category_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/delete-category/${category_id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  // ------------UPDATE--MASTER--DATA-----------
  static async UpdateMasterDataList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/save-master-data`, data, "post", header);
    return res;
  }

  // ------------DELETE--MASTER--DATA-----------
  static async deleteMasterDataList(authToken, master_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/master-data/${master_id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  // ------------STATUS-CHANGE-VHICLE--TYPE-----------
  static async statusVhicleTypeLists(vehicle_id, authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/vehicle-type/${vehicle_id}/changeStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  // ------------STATUS-CHANGE-MASTER--DATA-----------
  static async statusChangeMasterData(master_id, authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/master-data/${master_id}/changeStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  // ------------CHANGE USER STATUS-----------
  static async changeUserStatus(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/change-user-status`, data, "patch", header);
    return res;
  }

  /* -----GET DASHBOARD DATA -----*/
  static async getDashboardData(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/dashboard-data`, "", "get", header);
    return res;
  }

  // ----------GET_JOB_LIST-------------------
  static async getJobList(
    authToken,
    page_no,
    search,
    list_for,
    driver_id,
    customer_id,
    filter_by_start_date,
    filter_by_end_date,
    filter_by_category,
    filter_by_job_status,
    filter_by_track_job
  ) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/job-list?page_no=${page_no}&search=${search}&list_for=${list_for}&driver_id=${driver_id}&customer_id=${customer_id}&filter_by_start_date=${filter_by_start_date}&filter_by_end_date=${filter_by_end_date}&filter_by_category=${filter_by_category}&filter_by_job_status=${filter_by_job_status}&filter_by_track_job=${filter_by_track_job}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----JOB DETAILS -----*/
  static async jobDetails(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/job-detail`, data, "post", header);
    return res;
  }
  // ----------DELETE_B2BNQUIRY_LIST-------------------
  static async deleteB2bEnquiry(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/delete-b2bEnquiry/${id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  /* -----GET COUPON LIST -----*/
  static async getCouponData(authToken, page_no) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/coupon-list?page_no=${page_no}`,
      "",
      "get",
      header
    );
    return res;
  }

  // ------------CHANGE COUPON STATUS-----------
  static async changeCouponStatus(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/change-coupon-status`,
      data,
      "patch",
      header
    );
    return res;
  }

  // ------------POST-COUPON-------------
  static async postCoupon(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/add-coupon`, data, "post", header);
    return res;
  }

  // ------------DELETE--COUPON------------
  static async deleteCoupon(authToken, coupon_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/delete-coupon?coupon_id=${coupon_id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  // -------------GET--REVIEW--LIST---------
  static async getReviewData(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/rating-list`, "", "get", header);
    return res;
  }

  // ------------POST-COUPON-------------
  static async postReviewData(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/update-rating`, data, "post", header);
    return res;
  }

  /* ---------GET-SETTING-OPTION-DATA-(Commission)------------*/
  static async settingOptionList(authToken, type, option_name) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/setting-options?type=${type}&option_name=${option_name}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* --------POST-SETTING-OPTION-DATA-(Commission)-------------*/
  static async postSettingOptionList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/update-setting-option`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* -----CHANGE DRIVER VEHICLE STATUS -----*/
  static async driverVehicleStatus(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/change-driver-vehicle-status`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* -----CHANGE DRIVER Service STATUS -----*/
  static async driverServiceStatus(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/change-driver-service-status`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* -----GET driver-review-list -----*/
  static async getDriverReviewList(authToken, page_no, driver_id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-review-list?page_no=${page_no}&driver_id=${driver_id}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ----payment-release-----*/
  static async paymentRelease(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/payment-release`, data, "patch", header);
    return res;
  }

  /* ---------service-charge-data------------*/
  static async serviceChargeData(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/service-charge-data`, "", "get", header);
    return res;
  }

  /* --------post-service-charge-data-------------*/
  static async postServiceChargeData(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/update-service-charge-data`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* ------assign-driver-list-------*/
  static async assignDriverList(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/assign-driver-list`, data, "post", header);
    return res;
  }

  /* ------update-job-amount-------*/
  static async updateJobAmount(job_id, data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/update-job-amount/${job_id}`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* ------assign-job-------*/
  static async assignJob(job_id, data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/assign-job/${job_id}`, data, "put", header);
    return res;
  }

  /* ------delete--remove--assigned--user-------*/
  static async removeAssignedUser(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/remove-assigned-user/${id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  /* ---------get support------------*/
  static async supportList(authToken, list_type, page_no) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/support-list?list_type=${list_type}&page_no=${page_no}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------delete-support-list------*/
  static async deteteSupportUser(authToken, support_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/delete-support-data?support_id=${support_id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  /* ---------get-fetch-tutorial------------*/
  static async getTutorial(authToken, category_type) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/fetch-tutorial?category_type=${category_type}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* ---------post-fetch-tutorial-----------*/
  static async postTutorialData(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/update-tutorial`, data, "post", header);
    return res;
  }

  /* ------cancel--job-------*/
  static async cancelJob(authToken, job_id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/cancel-job/${job_id}`, "", "patch", header);
    return res;
  }

  // Api's for create job section

  /* -----GET CATEGORY LIST -----*/
  static async fetchCategoryList(authToken, id, is_level) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/category-list?id=${id}&is_level=${true}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----FETCH MASTER DATA LIST -----*/
  static async fetchMasterDataList(authToken, refKey) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/master-data-list?ref_key=${refKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----FETCH FLOOR OPTIONS -----*/
  static async fetchFloorOptions(authToken) {
    let header = { "access-token": `${authToken}` };

    let res = await this.request(
      `/master-data-list?ref_key=floors`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----FETCH VEHICLE INFO LIST -----*/
  static async fetchVehicleInfoList(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/vehicle-type`, {}, "get", header);
    return res;
  }

  /* -----FETCH UNIT TYPE LIST -----*/
  static async fetchUnitTypes(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/master-data-list?ref_key=weight_units,dimension_units`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----FETCH HELPERS LIST -----*/
  static async fetchHelpersList(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/master-data-list?ref_key=helpers`,
      {},
      "get",
      header
    );
    return res;
  }

  /* ----- FETCH SETTINGS-OPTIONS LIST -----*/
  static async fetchSettingsOptionsList(authToken, type) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/setting-options?type=${type}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* ----- GET-CUSTOMERS-LISTS -----*/
  static async getCustomersLists(authToken, page_no, key) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-list?page_no=${page_no}&search=${key}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----GET DRIVERS LIST -----*/
  static async getDriversList(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-quotation-list`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ----- CREATE-JOB-ADMIN -----*/
  static async createJobAdmin(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/create-job`, data, "post", header);
    return res;
  }

  /* ----- Broadcast user list -----*/
  static async getBroadcastUsersList(authToken, userType, searchKey, page_no) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/broadcast-user-list?search=${searchKey}&user_type=${userType}&page_no=${page_no}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* ----- Send Broadcast Notification -----*/
  static async sendBroadCastNotification(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/send-broadcast-notification`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ----- Fetch FAQ Lists -----*/
  static async fetchAdminFaqList(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/faq-list`, {}, "get", header);
    return res;
  }

  /* ----- Fetch FAQ Lists -----*/
  static async deleteAdminFaq(authToken, id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/delete-faq?question_id=${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  /* ----- Fetch FAQ Lists -----*/
  static async addAdminFaq(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/add-faq`, data, "put", header);
    return res;
  }

  /* ----- REPLY B2B ENQUIRY -----*/
  static async replyB2bEnquiry(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/reply-enquiry`, data, "post", header);
    return res;
  }

  /* ----- REPLY MAIL TO SUPPORT -----*/
  static async replyToSupport(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/reply-support`, data, "post", header);
    return res;
  }

  /* ----- UN ASSIGN JOB -----*/
  static async unAssignJob(authToken, job_id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/unassign-job/${job_id}`,
      {},
      "patch",
      header
    );
    return res;
  }

  /* ----- REFUND JOB AMOUNT TO CUSTOMER -----*/
  static async refundAmount(authToken, job_id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/refund-job-amount/${job_id}`,
      {},
      "patch",
      header
    );
    return res;
  }

  /* ----- RE-SCHEDULE JOB  -----*/
  static async reScheduleJob(authToken, job_id, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/reschedule-job/${job_id}`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* ------Get Admin Notification API -----*/
  static async getAdminNotification(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/notification-list?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------ Admin Read Notification Api-----*/
  static async adminReadNotification(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/read-notification?notification_id=${id}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------ Admin Clear Notification Api -----*/
  static async clearNotificationAdmin(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/clear-all-notification`,
      "",
      "delete",
      header
    );
    return res;
  }

  /* ------ Admin Broadcast NotificationList Api -----*/
  static async broadcastNotificationList(authToken, page_no) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/broadcast-notification-list?page_no=${page_no}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------ Admin Delete Broadcast Notification Api -----*/
  static async broadcastNotificationDelete(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/broadcast-notification/${id}`,
      "",
      "delete",
      header
    );
    return res;
  }

  /* ------ Admin Driver Shuffling List Api -----*/
  static async getDriverShufflingList(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/driver-shuffling-list/${id}`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ------ Admin Driver Shuffling Process -----*/
  static async driverShufflingProcess(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/driver-shuffling-process/${id}`,
      data,
      "post",
      header
    );
    return res;
  }
}

export default SwiftVanApi;
