import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "./metadata.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Validation } from "../../Validation/Validation";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";

const PropertyPopup = ({
  propertyshow,
  propertyClosePopup,
  setShowProperty,
  propertyDataList,
  propertyUpdate,
  isUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue, formState, reset, clearErrors } =
    useForm({
      mode: "onChange",
    });

  const [timeInput, setTimeInput] = useState("");

  // mange time validation
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue == "" ||
      (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 600)
    ) {
      setValue("minimumTime", inputValue, { shouldValidate: true });
      setTimeInput(inputValue);
    }
  };

  const clearData = () => {
    setValue("PropertyTypes", "");
  };

  useEffect(() => {
    if (isUpdate === false) {
      reset();
      clearErrors();
    }
  }, [propertyshow]);

  useEffect(() => {
    reset();
    if (Object.keys(propertyUpdate).length > 0) {
      setValue("PropertyTypes", propertyUpdate.value_name);
      setValue("TimeType", propertyUpdate.time);
      setTimeInput(propertyUpdate.time);
    } else {
      setValue("PropertyTypes", "");
      setValue("TimeType", "");
      setTimeInput("");
    }
  }, [propertyUpdate, propertyshow]);

  const onSubmit = (data) => {
    isUpdate === true ? updateApi(data) : createApi(data);
  };

  const createApi = (data) => {
    const trimmedPropertyName =
      data && data.PropertyTypes && data.PropertyTypes.trim();

    var PostData = new FormData();
    PostData.append("master_id", propertyUpdate.id ? propertyUpdate.id : "");
    PostData.append("name", trimmedPropertyName);
    PostData.append("keyName", "property_types");
    PostData.append("time", Number(timeInput));

    setIsLoading(true);
    let res = SwiftVanApi.postMasterDataList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("CREATE_PROPERTY_SUCCESSFULLY"), "success");
        setShowProperty(false);
        propertyDataList();
        reset();
        clearData();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const updateApi = (data) => {
    const trimmedPropertyName =
      data && data.PropertyTypes && data.PropertyTypes.trim();

    var PostData = new FormData();
    PostData.append("master_id", propertyUpdate.id ? propertyUpdate.id : "");
    if (propertyUpdate && propertyUpdate.value_code !== "static") {
      PostData.append("name", trimmedPropertyName);
    } else {
      PostData.append("name", propertyUpdate.value_name);
    }
    // PostData.append("name", trimmedPropertyName);
    PostData.append("keyName", "property_types");
    PostData.append("time", Number(timeInput));
    setIsLoading(true);
    let res = SwiftVanApi.UpdateMasterDataList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("UPDATE_PROPERTY_SUCCESSFULLY"), "success");
        setShowProperty(false);
        propertyDataList();
        reset();
        clearData();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    reset();
  }, []);

  function isPropertyTypeDisabled() {
    if (propertyUpdate && propertyUpdate.value_code === "static") {
      return true;
    }
    return false;
  }

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={propertyshow} onHide={propertyClosePopup}>
        <div className="helperPopupMain">
          <div className="addVanPopupMain">
            <Modal.Header>
              {propertyUpdate && propertyUpdate.id ? (
                <Modal.Title>{t("UPDATE_PROPERTY_TYPE")} </Modal.Title>
              ) : (
                <Modal.Title>{t("ADD_PROPERTY_TYPE")} </Modal.Title>
              )}
              <MdCancel onClick={propertyClosePopup} />
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={12}>
                    <Form.Group
                      className="popupInput"
                      style={{ marginBottom: "30px" }}
                    >
                      <Form.Label>{t("PROPERTY_TYPE")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_PROPERTY_TYPE")}
                        autoComplete="off"
                        name="PropertyTypes"
                        disabled={isPropertyTypeDisabled()}
                        {...register("PropertyTypes", Validation.PropertyTypes)}
                        onChange={(e) => {
                          setValue("PropertyTypes", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                      />
                      {formState.errors.PropertyTypes && (
                        <p style={{ color: "red", mb: 1 }}>
                          {t(formState.errors.PropertyTypes.message)}
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={12} style={{ marginBottom: "20px" }}>
                    <Form.Group className="CreateInput">
                      <Form.Label>{t("MINIMUM_TIME")}</Form.Label>
                      <div style={{ display: "flex", position: "relative" }}>
                        <div style={{ width: "100%" }}>
                          <Form.Control
                            type="text"
                            name="minimumTime"
                            value={timeInput}
                            placeholder={`${t("MINIMUM_TIME_INPUT")}`}
                            {...register("minimumTime", {
                              required: {
                                value: true,
                                message: `${t("ENTER_MINIMUM_TIME")}`,
                              },
                            })}
                            onChange={handleInputChange}
                            min={1}
                            max={600}
                          />

                          {formState.errors.minimumTime && (
                            <p style={{ color: "red" }}>
                              {t(formState.errors.minimumTime.message)}
                            </p>
                          )}
                        </div>

                        <div className={Styles.minTimeInput}>
                          <div className={Styles.CategoryDevider}></div>
                          <div className={Styles.SelectDuration}>
                            <div className={Styles.SelectDurationInner}>
                              {t("MIN")}
                              <p></p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <div className={Styles.AddVanBtn}>
                  {propertyUpdate && propertyUpdate.id ? (
                    <button type="submit">{t("UPDATE_PROPERTY_BTN")}</button>
                  ) : (
                    <button type="submit">{t("ADD_PROPERTY_BTN")}</button>
                  )}
                </div>
              </Form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PropertyPopup;
