import { Button, Col, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import styles from "../CreateJob.module.css";
import tabStyles from "./Tabs.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab, updateJobData } from "../../../Redux/Slices/createJob";
import { getActiveTab } from "../helper";
import { useEffect, useState } from "react";
import { vehicleDescription, vehicleOptions } from "../../../../src/mockData";
import Confirm from "../Modals/confirm";
import Toster from "../../../Utils/Toster";

function Fourth() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.createJob);

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [openViewGuide, setOpenViewGuide] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (
      jobData.createJobData &&
      jobData.createJobData.fourth &&
      jobData.createJobData.fourth.isSubmit
    ) {
      setSelectedVehicle(jobData.createJobData.fourth.selectedVehicle);
    }
  }, []);

  const onBack = () => {
    if (openViewGuide) {
      setOpenViewGuide(false);
      return;
    }
    if (jobData.createJobData.second.isCustomItems) {
      dispatch(setActiveTab("third"));
    } else {
      dispatch(setActiveTab("second"));
    }
  };

  const goToNext = () => {
    if (selectedVehicle) {
      setShowConfirm(true);
    } else {
      Toster(t("SELECT_VEHICLE_TYPE"), "error");
    }
  };

  const handleSelectVehicle = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  const viewVanSizeGuide = () => {
    setOpenViewGuide(true);
  };

  const handleConfirm = () => {
    dispatch(
      updateJobData({
        step: "fourth",
        data: { selectedVehicle, isSubmit: true },
      })
    );
    dispatch(setActiveTab("fifth"));
    setShowConfirm(false);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.topHead}>
            <div className="backarrow">
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            {openViewGuide && (
              <div className="backarrow">
                <span className={tabStyles.viewGuideHead}>
                  {t("VAN_GUIDE")}
                </span>
              </div>
            )}
            <div className="backarrow">
              <span className={styles.tabCount}>
                <strong>{getActiveTab(jobData.activeTab) - 1}</strong>
                <span>/4</span>
              </span>
            </div>
          </div>
          {!openViewGuide && (
            <div className={styles.topSubContainer}>
              <div className={styles.topSubHead}>
                <div className={styles.topHeading}>
                  <div className={styles.backarrow}>
                    <FiArrowLeft onClick={() => onBack()} />
                  </div>
                  <div
                    className={styles.topSubHeadTitle}
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("SELECT_VEHICLE_TYPE")}
                  </div>
                  <div className={styles.tabCounting}>
                    <strong>{getActiveTab(jobData.activeTab) - 1}</strong>
                    <span>/4</span>
                  </div>
                </div>
                {/* <div className={styles.topSubHeadDesc}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type.
                </div> */}
                <div>
                  <div className={tabStyles.vehicleSectionWrapper}>
                    <div className={tabStyles.vanSizeGuide}>
                      <span onClick={() => viewVanSizeGuide()}>
                        {t("VIEW_VAN_GUIDE")}
                      </span>
                    </div>
                    <Row style={{ justifyContent: "left" }}>
                      {jobData.vehicleInfoList.map((vehicle) => {
                        return (
                          <Col lg={3} md={4} sm={6} className="mb-3">
                            <div
                              onClick={() => handleSelectVehicle(vehicle)}
                              style={{
                                backgroundColor:
                                  selectedVehicle?.id === vehicle.id
                                    ? "#0A3CD1"
                                    : "white",
                                color:
                                  selectedVehicle?.id === vehicle.id
                                    ? "white"
                                    : "",
                              }}
                              className={tabStyles.vehicleSection}
                            >
                              <div>
                                <img
                                  src={vehicle.vehicle_image}
                                  alt="lutonVan"
                                  className={tabStyles.vehicleImage}
                                />
                              </div>
                              <div className={tabStyles.vehicleName}>
                                {vehicle.vehicle_name}
                              </div>
                              <div
                                className={tabStyles.vehicleTitle}
                                title={
                                  vehicle.vehicle_description.length > 40
                                    ? vehicle.vehicle_description
                                    : null
                                }
                              >
                                {vehicle.vehicle_description}
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                    <div className={styles.nextBtnFooter}>
                      <button
                        className={styles.nextBtn}
                        onClick={() => goToNext()}
                      >
                        <span>{t("NEXT")}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {openViewGuide && (
            <div className={styles.topSubContainer}>
              <div className={styles.topSubHead}>
                <div className={tabStyles.topHeadingVan}>
                  <div className={styles.backarrow}>
                    <FiArrowLeft onClick={() => onBack()} />
                  </div>
                  <div
                    className={styles.topSubHeadTitle}
                    style={{ textTransform: "uppercase" }}
                  >
                    {t("VAN_GUIDE")}
                  </div>
                  <div className={styles.tabCounting}>
                    <strong>{getActiveTab(jobData.activeTab) - 1}</strong>
                    <span>/4</span>
                  </div>
                </div>
                <div className={styles.topSubHeadDesc}>
                  {t("JOB_VAN_GUIDE")}
                </div>
                <div>
                  <div className={tabStyles.vehicleSectionWrapper}>
                    <Row
                      style={{
                        justifyContent: "left",
                      }}
                    >
                      {vehicleDescription.map((vehicle, ind) => {
                        return (
                          <>
                            {ind % 2 == 0 ? (
                              <Row className={tabStyles.oddVehicleRow}>
                                <Col className="mb-3">
                                  <div className={tabStyles.vehicleTextView}>
                                    <div className={tabStyles.vehicleViewTitle}>
                                      {t(vehicle.title)}
                                    </div>
                                    <div className={tabStyles.vehicleViewDesc}>
                                      {t(vehicle.description)}
                                    </div>
                                    <div className={tabStyles.vehicleViewInfo}>
                                      {Object.entries(vehicle.dimention).map(
                                        ([key, value]) => (
                                          <div key={key}>{t(value)}</div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={3} md={4} sm={6} className="mb-3">
                                  <div>
                                    <img
                                      src={vehicle.image}
                                      alt="lutonVan"
                                      className={tabStyles.vehicleImage}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <Row className={tabStyles.evenVehicleRow}>
                                <Col lg={3} md={4} sm={6} className="mb-3">
                                  <div>
                                    <img
                                      src={vehicle.image}
                                      alt="lutonVan"
                                      className={tabStyles.vehicleImage}
                                    />
                                  </div>
                                </Col>
                                <Col className="mb-3">
                                  <div
                                    className={tabStyles.vehicleTextView}
                                    style={{ textAlign: "right" }}
                                  >
                                    <div className={tabStyles.vehicleViewTitle}>
                                      {t(vehicle.title)}
                                    </div>
                                    <div className={tabStyles.vehicleViewDesc}>
                                      {t(vehicle.description)}
                                    </div>
                                    <div className={tabStyles.vehicleViewInfo}>
                                      {Object.entries(vehicle.dimention).map(
                                        ([key, value]) => (
                                          <div key={key}>{t(value)}</div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            )}

                            {ind + 1 < vehicleDescription.length && <hr />}
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Confirm
        show={showConfirm}
        subTitle={t("RIGHT_VEHICLE_SELECT_WARN")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  );
}
export default Fourth;
