import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { Col, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";
import Styles from "../Tutorial/Tutorial.module.css";
import Repleaceicon from "../../Assests/Images/ReplaceIconSVG.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import TutorialPopup from "./TutorialPopup";
import ReactPlayer from "react-player";
import TutorialDeletePopup from "./TutorialDeletePopup";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../../Validation/LoadingSpinner";
import ReplacePopup from "./ReplacePopup";

const Tutorial = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  const [tutorialPopupShow, SetTutorialPopupShow] = useState({
    show: false,
    id: "",
  });

  const [ReplaceVideoPopupShow, SetReplaceVideoPopupShow] = useState({
    show: false,
    id: "",
  });

  const tutorialPopupHandleClose = () =>
    SetTutorialPopupShow({ show: false, id: "" });
  const tutorialPopupHandleShow = (id) => {
    SetTutorialPopupShow({ show: true, id });
  };

  const replaceVideoHandleClose = () =>
    SetReplaceVideoPopupShow({ show: false, id: "" });
  const replaceVideoHandleShow = (id) => {
    SetReplaceVideoPopupShow({ show: true, id });
  };

  const [tutorialDeletePopupShow, SetTutorialDeletePopupShow] = useState(false);
  const tutorialDeletePopupHandleClose = () =>
    SetTutorialDeletePopupShow(false);
  const tutorialDeletePopupHandleShow = () => SetTutorialDeletePopupShow(true);

  const [category, setCategory] = useState("");
  const [tutorialId, setTutorialId] = useState("");
  const [videoId, setVideoId] = useState("");
  const [eventKeyValue, setEventKeyValue] = useState(
    category && category.length > 0 ? category[0].id : null
  );
  const [seletedTutorialData, setSeletedTutorialData] = useState([]);

  const UpdateTutorialPopup = (data) => {
    tutorialPopupHandleShow(data);
  };

  useEffect(() => {
    setEventKeyValue(category && category.length > 0 ? category[0].id : null);
  }, [category]);

  // get category function
  const getCategoryData = () => {
    setIsLoading(true);
    let res = SwiftVanApi.getCategoryList(authToken);
    res.then((data) => {
      setIsLoading(false);
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setCategory(data && data.data);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  // get Tutorial function
  const TutorialList = () => {
    setIsLoading(true);
    let res = SwiftVanApi.getTutorial(authToken, eventKeyValue);
    res.then((data) => {
      setIsLoading(false);
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        const selected = data.data.tutorialList.filter(
          (val) => val.category_id == eventKeyValue
        );
        setSeletedTutorialData(selected);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    TutorialList();
  }, [eventKeyValue]);

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>
            <div className="adminPageInner_heading">
              <div className="categoryMinSection">
                <div className={Styles.couponHeading}>
                  <h1>{t("TUTORIALS")}</h1>
                </div>
                {eventKeyValue && category ? (
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={eventKeyValue}
                  >
                    <Row>
                      <Col lg={4} md={12} sm={12}>
                        <div className="categoryTapLeft">
                          <div className="metadataTapLeft">
                            <Nav
                              variant="pills"
                              className="flex-column"
                              onSelect={(value) => {
                                setEventKeyValue(value);
                                TutorialList(eventKeyValue);
                              }}
                            >
                              <div className="metaDataTitle">
                                <h1>{t("CATEGORIES")}</h1>
                              </div>
                              <Row>
                                {category &&
                                  category.map((item, index) => (
                                    <Col
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      key={index}
                                    >
                                      <Nav.Item>
                                        <Nav.Link eventKey={item.id}>
                                          <div
                                            style={{
                                              width: "40px",
                                              borderRadius: "50%",
                                              background: "#0094f6",
                                              marginRight: "12px",
                                            }}
                                          >
                                            <img
                                              src={item.icon}
                                              alt={item.name}
                                            />
                                          </div>
                                          <p>{item.name}</p>
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Col>
                                  ))}
                              </Row>
                            </Nav>
                          </div>
                        </div>
                      </Col>

                      <Col lg={8} md={12} sm={12}>
                        <Tab.Content>
                          <Tab.Pane
                            eventKey={eventKeyValue ? eventKeyValue : ""}
                          >
                            {seletedTutorialData &&
                            seletedTutorialData.length > 0 ? (
                              <>
                                <div className="categoryTapLeft">
                                  <div className="metadataTapLeft">
                                    {seletedTutorialData &&
                                      seletedTutorialData &&
                                      seletedTutorialData.map((item, idx) => (
                                        <Col lg={12} md={12} sm={12} key={idx}>
                                          <div
                                            className={
                                              Styles.TutorialVideoComponent
                                            }
                                          >
                                            {item &&
                                              item.videos.map((data, index) => {
                                                return (
                                                  <div
                                                    className={
                                                      Styles.TutorialVideoMain
                                                    }
                                                    key={index}
                                                  >
                                                    <div
                                                      className={
                                                        Styles.TutorialVideoleft
                                                      }
                                                    >
                                                      <ReactPlayer
                                                        url={data.name}
                                                        controls
                                                        width="100%"
                                                        height="100%"
                                                      />
                                                    </div>

                                                    <div
                                                      className={
                                                        Styles.TutorialVideoRight
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          Styles.videoRightBtnTop
                                                        }
                                                      >
                                                        <img
                                                          src={Repleaceicon}
                                                          alt="Replace"
                                                          onClick={() => {
                                                            replaceVideoHandleShow();
                                                            setTutorialId(
                                                              item.id
                                                            );
                                                            setVideoId(data.id);
                                                          }}
                                                        />
                                                        <p>{t("REPLACE")}</p>
                                                      </span>
                                                      <span
                                                        className={
                                                          Styles.videoRightBtnBottom
                                                        }
                                                      >
                                                        <RiDeleteBin6Line
                                                          onClick={() => {
                                                            tutorialDeletePopupHandleShow();
                                                            setTutorialId(
                                                              item.id
                                                            );
                                                            setVideoId(data.id);
                                                          }}
                                                        />
                                                        <p>
                                                          {t("DELETE_DROPDOWN")}
                                                        </p>
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </Col>
                                      ))}
                                    {seletedTutorialData &&
                                      seletedTutorialData &&
                                      seletedTutorialData.map((item, idx) => (
                                        <div
                                          className={Styles.descVideoTutorial}
                                          key={idx}
                                        >
                                          <div
                                            className={
                                              Styles.descVideoTutorialLeft
                                            }
                                          >
                                            {item.description}
                                          </div>
                                          <div
                                            className={
                                              Styles.descVideoTutorialRight
                                            }
                                          >
                                            <span
                                              onClick={() => {
                                                UpdateTutorialPopup(item.id);
                                              }}
                                            >
                                              <FaRegEdit />
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className={Styles.videoAddbtn}>
                                <button
                                  className={Styles.btnAddNew}
                                  onClick={() => {
                                    UpdateTutorialPopup(
                                      seletedTutorialData.category_id
                                    );
                                  }}
                                >
                                  {t("ADD_NEW")}
                                </button>
                              </div>
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <TutorialPopup
        tutorialPopupShow={tutorialPopupShow}
        tutorialPopupHandleClose={tutorialPopupHandleClose}
        seletedTutorialData={seletedTutorialData}
        TutorialList={TutorialList}
        eventKeyValue={eventKeyValue}
      />
      <TutorialDeletePopup
        tutorialDeletePopupShow={tutorialDeletePopupShow}
        tutorialDeletePopupHandleClose={tutorialDeletePopupHandleClose}
        tutorialId={tutorialId}
        videoId={videoId}
        TutorialList={TutorialList}
      />

      <ReplacePopup
        videoId={videoId}
        tutorialId={tutorialId}
        seletedTutorialData={seletedTutorialData}
        replaceVideoHandleClose={replaceVideoHandleClose}
        ReplaceVideoPopupShow={ReplaceVideoPopupShow}
        TutorialList={TutorialList}
      />
    </>
  );
};

export default Tutorial;
