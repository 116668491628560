import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Form, Row } from "react-bootstrap";
import Styles from "../Commission/Commission.module.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useForm } from "react-hook-form";
import Loader from "../../Validation/LoadingSpinner";
import { sanitizeHtmlTags } from "../../Utils/sanitizeHtmlTags";
import { AiOutlinePercentage } from "react-icons/ai";
import { handleInputValue } from "../../Utils/FormateNumber";

const ServiceCharge = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [flateValue, setFlateValue] = useState("");
  const [percentValue, setPercentValue] = useState("");
  const [isActive, setIsActive] = useState();
  const [id, setId] = useState("");

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const handleSwitchChange = () => {
    setIsActive(!Number(isActive));
  };

  const handleInputFlateValue = (e) => {
    const inputValue = e.target.value;
    handleInputValue(inputValue, 1, 999, setValue, setFlateValue);
  };

  const handleInputPercentValue = (e) => {
    const inputValue = e.target.value;
    handleInputValue(inputValue, 1, 99, setValue, setPercentValue);
  };

  const serviceChargeList = () => {
    setIsLoading(true);
    let res = SwiftVanApi.serviceChargeData(authToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setFlateValue(data.data[0].flat_value);
        setPercentValue(data.data[0].percent_value);
        setIsActive(data.data[0].is_active);
        setId(data.data[0].id);
        setValue("FlateValue", data.data[0].flat_value);
        setValue("PercentValue", data.data[0].percent_value);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    serviceChargeList();
  }, []);

  const onSubmit = (data) => {
    updateApi(data);
  };

  const updateApi = () => {
    var PostData = new FormData();
    PostData.append("id", id);
    PostData.append("status", Number(isActive));
    PostData.append("flat_value", Number(flateValue));
    PostData.append("percent_value", Number(percentValue));
    setIsLoading(true);
    let res = SwiftVanApi.postServiceChargeData(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_PAYMENT_SERVICE_SUCCESSFULLY"), "success");
        serviceChargeList();
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="commissionSection">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ maxWidth: "500px" }}
                >
                  <div className={Styles.commissionMainSection}>
                    <h5>{t("PAYMENT_SERVICE_CHARGE")}</h5>

                    <Row>
                      <Col lg={12}>
                        <Form.Group className="popupInput commissionPersentSign">
                          <Form.Label>{t("FLATE_VALUE")}</Form.Label>
                          <div
                            style={{ display: "flex", position: "relative" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Form.Control
                                type="text"
                                name="FlateValue"
                                {...register("FlateValue", {
                                  required: {
                                    value: true,
                                    message: `${t("FLATE_VALUE_INPUT")}`,
                                  },
                                  ...sanitizeHtmlTags(),
                                })}
                                value={flateValue}
                                onChange={handleInputFlateValue}
                                placeholder={`${t("FLATE_VALUE")}`}
                                min={0}
                                max={999}
                              />

                              {formState.errors.FlateValue && (
                                <p
                                  style={{
                                    color: "red",
                                    mb: "1",
                                  }}
                                >
                                  {t(formState.errors.FlateValue.message)}
                                </p>
                              )}
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="popupInput commissionPersentSign">
                          <Form.Label>{t("PERCENT_VALUE")}</Form.Label>
                          <div
                            style={{ display: "flex", position: "relative" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Form.Control
                                type="text"
                                name="PercentValue"
                                {...register("PercentValue", {
                                  required: {
                                    value: true,
                                    message: `${t("PERCENT_VALUE_INPUT")}`,
                                  },
                                  ...sanitizeHtmlTags(),
                                })}
                                value={percentValue}
                                onChange={handleInputPercentValue}
                                placeholder={`${t("COMMISSION_5%")}`}
                                min={1}
                                max={99}
                              />

                              {formState.errors.PercentValue && (
                                <p
                                  style={{
                                    color: "red",
                                    mb: "1",
                                  }}
                                >
                                  {t(formState.errors.PercentValue.message)}
                                </p>
                              )}
                            </div>

                            <div className={Styles.minTimeInput}>
                              <div className={Styles.CategoryDevider}></div>
                              <div className={Styles.SelectDuration}>
                                <div className={Styles.SelectDurationInner}>
                                  <AiOutlinePercentage />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form.Group>
                      </Col>

                      {/* <Col>
                        <Form>
                          <Form.Check
                            className={Styles.checkBoxSwitch}
                            type="switch"
                            id="custom-switch"
                            checked={isActive}
                            onChange={handleSwitchChange}
                          />
                        </Form>
                      </Col> */}
                    </Row>

                    <div className={Styles.serviceChargeBtn}>
                      <button type="submit">{t("UPDATE")}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ServiceCharge;
