import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

function MapView({ jobData }) {
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const locations = [];

  useEffect(() => {
    jobData && calculateRoute();
  }, [jobData]);

  locations.push({
    id: "source",
    name: jobData.source_location,
    coordinates: [jobData.source_latitude, jobData.source_longitude],
  });

  // Add stops if available
  if (jobData.stops && jobData.stops.length > 0) {
    jobData.stops.forEach((stop, index) => {
      locations.push({
        id: `stop_${index + 1}`,
        name: stop.location,
        coordinates: [stop.latitude, stop.longitude],
      });
    });
  }

  // Add destination location to the locations array
  locations.push({
    id: "destination",
    name: jobData.destination_location,
    coordinates: [jobData.destination_latitude, jobData.destination_longitude],
  });
  // const position = [first.source_latitude, first.source_longitude];
  const center = {
    lat: jobData.source_latitude,
    lng: jobData.source_longitude,
  };

  async function calculateRoute() {
    const directionsService = new window.google.maps.DirectionsService();
    const stopss =
      jobData &&
      jobData.stops &&
      jobData.stops.length > 0 &&
      jobData.stops.map((stop) => ({
        location: { lat: stop.latitude, lng: stop.longitude },
        stopover: true, // Make sure to specify stopover as true for each stop
      }));
    const results = await directionsService.route({
      destination: {
        lat: jobData.destination_latitude,
        lng: jobData.destination_longitude,
      },
      origin: {
        lat: jobData.source_latitude,
        lng: jobData.source_longitude,
      },
      waypoints: stopss, // Add waypoints here
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results && results);

    const distance = results.routes[0].legs.reduce(
      (total, leg) => total + leg.distance.value,
      0
    );
    const duration = results.routes[0].legs.reduce(
      (total, leg) => total + leg.duration.value,
      0
    );
  }

  const renderGoogleMap = () => {
    return (
      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{ height: "250px", width: "100%" }}
      >
        <DirectionsRenderer
          directions={directionsResponse && directionsResponse}
        />
      </GoogleMap>
    );
  };

  return <>{renderGoogleMap()}</>;
}

export default MapView;
