import React, { useState } from "react";
import Styles from "./Faq.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { MdCancel } from "react-icons/md";
import { sanitizeHtmlTags } from "../../Utils/sanitizeHtmlTags";
import SwiftVanApi from "../../helpers/Api";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../../Validation/LoadingSpinner";

export default function AddFaq({ showFaq, setShowFaq, fetchFaqList }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);

  const Validation = {
    question: {
      required: {
        value: true,
        message: `${t("PLS_ENTER_QUESTION")}`,
      },
      minLength: {
        value: 3,
        message: `${t("MIN_3_CHAR_REQUIRED")}`,
      },
      maxLength: {
        value: 150,
        message: `${t("MAX_150_CHAR_ALLOEWD")}`,
      },
      ...sanitizeHtmlTags(),
    },

    answer: {
      required: {
        value: true,
        message: `${t("PLS_ENTER_ANSWER")}`,
      },
      minLength: {
        value: 2,
        message: `${t("MIN_2_CHAR_REQUIRED")}`,
      },
      maxLength: {
        value: 1000,
        message: `${t("MAX_1000_CHAR_ALLOWED")}`,
      },
      ...sanitizeHtmlTags(),
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onClose = () => {
    setShowFaq(false);
    reset();
  };

  const onSubmit = (data) => {
    const postData = new FormData();
    postData.append("question", data.question);
    postData.append("answer", data.answer);
    setIsLoading(true);
    let res = SwiftVanApi.addAdminFaq(authToken, postData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("FAQ_ADDED_SUCCESS"), "success");
        setIsLoading(false);
        onClose();
        fetchFaqList();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      <Modal show={showFaq} onHide={onClose}>
        <div className="CouponPopupMain">
          <div className="addVanPopupMain">
            <Modal.Header>
              <Modal.Title>{t("ADD_FAQ")} </Modal.Title>
              <MdCancel onClick={onClose} />
            </Modal.Header>
            <Modal.Body>
              {isLoading && <Loader />}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("QUESTION")}</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("question", Validation.question)}
                        onChange={(e) => {
                          setValue("question", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        placeholder={t("ENTER_QUESTION")}
                        autoComplete="off"
                        isInvalid={!!errors.question}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(errors?.question?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("ANSWER")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        type="text"
                        {...register("answer", Validation.answer)}
                        onChange={(e) => {
                          setValue("answer", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        placeholder={t("ENTER_ANSWER")}
                        autoComplete="off"
                        isInvalid={!!errors.answer}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(errors?.answer?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <div className={Styles.AddFAQBtn}>
                      <button type="submit">{t("SUBMIT")}</button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
}
