export const formatNumber = (num) => {
  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num.toFixed(2);
  }
};

export const formatNumberOneDigit = (num) => {
  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num.toFixed(1);
  }
};

export const handleInputValue = (
  inputValue,
  min,
  max,
  setValueFunc,
  stateUpdateFunc
) => {
  if (
    inputValue === "" ||
    (/^\d+$/.test(inputValue) && inputValue >= min && inputValue <= max)
  ) {
    setValueFunc(inputValue);
    stateUpdateFunc(inputValue);
  }
};

export const handleInputChange = (e, field, setValueFunc, stateUpdateFunc) => {
  const inputValue = e.target.value;
  if (
    inputValue === "" ||
    (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 600)
  ) {
    setValueFunc(field, inputValue, { shouldValidate: true });
    stateUpdateFunc(inputValue);
  }
};

//----------------//progressbar--jobTrackList----------------------------------
export function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);
  const formattedDate = `${date.toLocaleDateString(undefined, {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  })}, ${date
    .toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .toUpperCase()}`;
  return formattedDate;
}

//--------------hours-Format--------------------------------
export const convertToHourMinuteFormat = (estimatedTime) => {
  const hours = Math.floor(estimatedTime / 60);
  const minutes = estimatedTime % 60;
  const formattedHours = hours < 10 ? `${hours}` : hours;
  const formattedMinutes =
    minutes === 0 ? "00" : minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes}`;
};
