import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { MdCancel } from "react-icons/md";
import SwiftVanApi from "../../helpers/Api";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../../Validation/LoadingSpinner";
import Styles from "../../Admin/Metadata/metadata.module.css";
import FileImg from "../../Assests/Images/FileInput.png";
import CameraImg from "../../Assests/Images/uploadCamera.png";
import { TUTORIAL_TYPE } from "../../Utils/Constant";

function ReplacePopup({
  ReplaceVideoPopupShow,
  replaceVideoHandleClose,
  tutorialId,
  videoId,
  TutorialList,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, reset, control, formState } = useForm({
    mode: "onChange",
  });

  const [previewVideos, setPreviewVideos] = useState([null]);

  useEffect(() => {
    if (ReplaceVideoPopupShow.show) {
      reset();
      setPreviewVideos([null]);
    }
  }, [ReplaceVideoPopupShow.show, reset]);

  const handleFileSelection = (index) => (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const updatedPreviews = [...previewVideos];
      updatedPreviews[index] = selectedFile;
      setPreviewVideos(updatedPreviews);
    }
  };

  const onSubmit = (data) => {
    ReplaceApi(data);
  };

  const ReplaceApi = () => {
    var PostData = new FormData();
    const videosItems = previewVideos.filter((item) => item !== null);
    PostData.append("type", TUTORIAL_TYPE.UPDATE);
    PostData.append("tutorial_id", tutorialId);
    PostData.append("vid_id", videoId);
    PostData.append("description", "opo");
    if (videosItems.length > 0) {
      PostData.append("videos", videosItems[0]);
    }
    setIsLoading(true);
    let res = SwiftVanApi.postTutorialData(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("REPLACE_TUTORIAL_SUCCESSFULLY"), "success");
        setIsLoading(false);
        replaceVideoHandleClose();
        TutorialList();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else if (
          data &&
          data.data &&
          data.data.code ===
            STATUS_CODES.TUTORIAL_FOR_THIS_CATEGORY_ALREADY_EXISTS
        ) {
          Toster(t("TUTORIAL_VIDEO_ADD_ERROR_CODE"), "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const onClose = () => {
    replaceVideoHandleClose();
    reset();
  };

  const handleReplaceIconClick = (index) => () => {
    document.getElementById(`file${index}`).click();
  };

  const validateVideo = (index) => () => {
    if (!previewVideos[index]) {
      return `${t("VIDEO_IS_REQUIRED")}`;
    }
    return true;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal show={ReplaceVideoPopupShow.show} onHide={() => onClose()}>
        <div className="addVanPopupMain">
          <Modal.Header>
            <Modal.Title>{t("REPLACE_TUTORIAL")}</Modal.Title>
            <MdCancel onClick={onClose} />
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={Styles.tutorialPopupMain}>
                <div className={Styles.addVanUploadBtn}>
                  <h1>{t("TUTORIAL_VIDEO")}</h1>

                  {previewVideos.map((previewVideo, index) => (
                    <>
                      <div className={Styles.vanUploadBtnInner} key={index}>
                        {previewVideo && (
                          <div className={Styles.addVanPreviewImg}>
                            <span
                              className={Styles.previewImageCrossIcon}
                              onClick={() => {
                                const updatedPreviews = [...previewVideos];
                                updatedPreviews[index] = null;
                                setPreviewVideos(updatedPreviews);
                              }}
                            >
                              <MdCancel />
                            </span>
                          </div>
                        )}

                        <div className={Styles.vanUploadInnerText}>
                          {previewVideo ? (
                            <div className={Styles.vanUploadImg}>
                              <video
                                src={URL.createObjectURL(previewVideo)}
                                controls
                                width="265px"
                                height="150px"
                              />
                            </div>
                          ) : (
                            <>
                              <div className={Styles.vanUploadImgIcon}>
                                <img
                                  src={FileImg}
                                  width="50px"
                                  height="50px"
                                  alt="File Icon"
                                />
                              </div>

                              <p>{t("UPLOAD_TUTORIAL_VIDEO")}</p>

                              <div>
                                <Controller
                                  control={control}
                                  name={`tutorialVideo${index + 1}`}
                                  rules={{ validate: validateVideo(index) }}
                                  render={({ field }) => (
                                    <input
                                      id={`file${index}`}
                                      type="file"
                                      style={{ display: "none" }}
                                      accept="video/*"
                                      onChange={handleFileSelection(index)}
                                    />
                                  )}
                                />
                                <div className={Styles.uploadBtnMain}>
                                  <button
                                    type="button"
                                    onClick={handleReplaceIconClick(index)}
                                  >
                                    <img src={CameraImg} alt="Camera" />
                                    {t("UPLOAD")}
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {!previewVideo &&
                        formState.errors[`tutorialVideo${index + 1}`] && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {t(
                              formState.errors[`tutorialVideo${index + 1}`]
                                .message
                            )}
                          </p>
                        )}
                    </>
                  ))}
                </div>
              </div>

              <div className={Styles.AddVanBtn}>
                <button type="submit">{t("SAVE")}</button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default ReplacePopup;
