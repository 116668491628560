import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import styles from "./modal.module.css";
import { IoMdCloseCircle } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { sanitizeHtmlTags } from "../../../Utils/sanitizeHtmlTags";
import PhoneInput from "react-phone-input-2";
import { removeExtraPlusSigns } from "../../../Utils/removeExtraPlus";

function AddGuestCustomer({ showGuestCustomer, setShowGuestCustomer }) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const onClose = () => {
    setShowGuestCustomer(false);
  };

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // function for apply validation in phoneNumber field
  const validateNumber = () => {
    if (!phoneNo) {
      return `${t("CONTACT_REQUIRED")}`;
    }
    return true;
  };

  // --------function for submit guest form--------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "first_name",
      formdata ? formdata.firstName && formdata.firstName.trim() : ""
    );
    requestData.append(
      "last_name",
      formdata ? formdata.lastName && formdata.lastName.trim() : ""
    );
    requestData.append(
      "email",
      formdata ? formdata.email && formdata.email.trim() : ""
    );

    requestData.append(
      "dial_code",
      dialCode ? removeExtraPlusSigns(dialCode) : ""
    );
    requestData.append("phone_country_code", countryCode ? countryCode : "");
    requestData.append("phone_num", phoneNo ? phoneNo : "");
  };

  return (
    <>
      <Modal show={showGuestCustomer} onHide={() => onClose()}>
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>Guest Customer</Modal.Title>
            <IoMdCloseCircle onClick={() => onClose()} />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
              <Form.Group className="mb-3">
                <Form.Label>{t("FIRST_NAME")}</Form.Label>
                <Form.Control
                  placeholder={`${t("FIRSTNAME_TEXT")}`}
                  type="text"
                  {...register("firstName", {
                    onChange: (e) => handleKeyPress(e),
                    required: {
                      value: true,
                      message: `${t("FIRSTNAME_REQUIRED")}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("FIRSTNAME_MINLENGTH")}`,
                    },
                    maxLength: {
                      value: 50,
                      message: `${t("FIRSTNAME_MAXLENGTH")}`,
                    },
                    ...sanitizeHtmlTags(),
                  })}
                />
                {errors.firstName && (
                  <span className="errorMsg">{errors.firstName.message}</span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("LAST_NAME")}</Form.Label>
                <Form.Control
                  placeholder={`${t("LASTNAME_TEXT")}`}
                  type="text"
                  {...register("lastName", {
                    onChange: (e) => handleKeyPress(e),
                    required: {
                      value: true,
                      message: `${t("LASTNAME_REQUIRED")}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("LASTNAME_MINLENGTH")}`,
                    },
                    maxLength: {
                      value: 50,
                      message: `${t("LASTNAME_MAXLENGTH")}`,
                    },
                    ...sanitizeHtmlTags(),
                  })}
                />
                {errors.lastName && (
                  <span className="errorMsg">{errors.lastName.message}</span>
                )}
              </Form.Group>
              <Form.Group className="mb-3 phoneInputSet">
                <Form.Label>{t("CONTACT")}</Form.Label>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <PhoneInput
                      placeholder={`${t("CONTACT_TEXT")}`}
                      country={"gb"}
                      countryCodeEditable={false}
                      value={dialCode.toString() + phoneNo.toString()}
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setCountryCode(country.countryCode);
                        setDialCode(dialCode);
                        setPhoneNo(phone);
                        field.onChange(value); // Update the form value
                        clearErrors("phoneNumber");
                      }}
                    />
                  )}
                  rules={{ validate: validateNumber }}
                />
                {errors.phoneNumber && (
                  <span className="errorMsg">{errors.phoneNumber.message}</span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("EMAIL")}</Form.Label>
                <Form.Control
                  placeholder={`${t("EMAIL_TEXT")}`}
                  type="text"
                  {...register("email", {
                    onChange: (e) => onInputChange(e),
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: `${t("EMAIL_INVALID")}`,
                    },
                    minLength: {
                      value: 5,
                      message: `${t("EMAIL_MINLENGTH")}`,
                    },
                    maxLength: {
                      value: 100,
                      message: `${t("EMAIL_MAXLENGTH")}`,
                    },
                    ...sanitizeHtmlTags(),
                    required: {
                      value: true,
                      message: `${t("EMAIL_REQUIRED")}`,
                    },
                  })}
                />
                {errors.email && (
                  <span className="errorMsg">{errors.email.message}</span>
                )}
              </Form.Group>

              <div className={styles.loginBtn}>
                <button type="submit" onClick={() => navigate("/login")}>
                  {t("SUBMIT")}
                </button>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => onClose()}
              //   onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
export default AddGuestCustomer;
