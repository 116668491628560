import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Dropdown, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Styles from "../Broadcasting/Broadcasting.module.css";
import InputSearch from "../../Assests/Images/uil_search.png";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { useForm } from "react-hook-form";
import Loader from "../../Validation/LoadingSpinner";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { PAGE } from "../../Utils/Constant";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import moment from "moment";
import { SlOptions } from "react-icons/sl";
import Confirm from "../CreateJob/Modals/confirm";

const Broadcasting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const itemsPerPage = PAGE.PAGE_COUNT;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [broadCastList, setBroadCastList] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [modifiedBroadCastList, setModifiedBroadCastList] = useState([]);
  const [userType, setUserType] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [activeSection, setActiveSection] = useState("Send Broadcast");
  const [totalRecords, setTotalRecords] = useState();
  const [listPageNumber, setListPageNumber] = useState(1);
  const [notificationList, setNotificationList] = useState([]);
  const [showConfirm, setShowConfirm] = useState({ id: "", show: false });

  const [tabOption, setTabOption] = useState([
    {
      title: "Send Broadcast",
      active: true,
    },
    {
      title: "|",
      active: false,
    },
    {
      title: "Broadcast List",
      active: false,
    },
  ]);

  // function for handle pagination
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setListPageNumber(newPageNumber);
  };

  async function fetchBroadCastNotificationList() {
    setIsLoading(true);
    const details = await SwiftVanApi.broadcastNotificationList(
      authToken,
      listPageNumber
    );
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setNotificationList(
        details && details.data && details.data.broadcastList
      );
      setTotalRecords(
        details && details.data && details.data.broadcastListCount
      );
      setIsLoading(false);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  async function handleDeleteNotification(id) {
    setIsLoading(true);
    const details = await SwiftVanApi.broadcastNotificationDelete(
      authToken,
      id
    );
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setShowConfirm(() => ({
        id: "",
        show: false,
      }));
      Toster(t("BROADCAST_DELETE_SUCCESS"), "success");
      setIsLoading(false);
      fetchBroadCastNotificationList();
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  useEffect(() => {
    activeSection == "Broadcast List" && fetchBroadCastNotificationList();
  }, [activeSection, listPageNumber]);

  useEffect(() => {
    setListPageNumber(1);
  }, [activeSection]);

  useEffect(() => {
    var modifiedArray;

    if (isAllChecked) {
      modifiedArray = broadCastList.map((user) => ({
        ...user,
        isChecked: true,
      }));
      setModifiedBroadCastList(modifiedArray);
    } else {
      modifiedArray = broadCastList.map((user) => ({
        ...user,
        isChecked: false,
      }));
    }
    setModifiedBroadCastList(modifiedArray);
  }, [broadCastList]);

  useEffect(() => {
    const selectedIds = modifiedBroadCastList
      .filter((val) => val.isChecked) // Filter items that are checked
      .map((val) => val.id); // Extract the ids of checked items
    setSelectedIds(selectedIds);
  }, [modifiedBroadCastList]);

  const userOptions = [
    {
      user_role: "customer",
      label: "Customer",
    },
    {
      user_role: "driver",
      label: "Driver",
    },
    {
      user_role: "",
      label: "All",
    },
  ];

  const fetchBroadCastUserList = (isLoadMore = false) => {
    let pageNo = pageNumber;
    if (isLoadMore === true) {
      setPageNumber((preState) => preState + 1);
      pageNo = pageNo + 1;
    }
    !searchKey && setIsLoading(true);
    let res = SwiftVanApi.getBroadcastUsersList(
      authToken,
      userType,
      searchKey,
      pageNo
    );
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setTotalDataCount(data.data.fetchUserListCount);
        setBroadCastList(data.data.fetchUserList);
        setLoadMore(data.data.loadMore);
        if (isLoadMore == true) {
          setBroadCastList([...broadCastList, ...data.data.fetchUserList]);
        } else {
          setBroadCastList(data.data.fetchUserList);
        }
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    fetchBroadCastUserList();
  }, [userType, searchKey]);

  const onUserSelect = (value) => {
    setPageNumber(1);
    setSearchKey("");
    setIsAllChecked(false);
    setUserType(value);
  };

  const handleAllChecked = (checked) => {
    const updatedList = modifiedBroadCastList.map((user) => ({
      ...user,
      isChecked: checked,
    }));
    setModifiedBroadCastList(updatedList);
    setIsAllChecked(checked);
  };

  const handleSingleChecked = (checked, id) => {
    const updatedList = modifiedBroadCastList.map((user) =>
      user.id === id ? { ...user, isChecked: checked } : user
    );

    setModifiedBroadCastList(updatedList);

    // Update the state of "Select All" checkbox
    const allChecked = updatedList.every((user) => user.isChecked);
    setIsAllChecked(allChecked);
  };

  const handleLoadMore = () => {
    // setPageNumber((preState) => preState + 1);
    fetchBroadCastUserList(true);
  };

  const handleSearchChange = (key) => {
    setPageNumber(1);
    setSearchKey(key);
  };

  const handleSendBroadCastNotification = (formData) => {
    setIsLoading(true);
    var postData = new FormData();
    postData.append("title", formData.subject);
    postData.append("description", formData.description);
    if (isAllChecked && searchKey == "") {
      postData.append("user_id", "");
      postData.append("user_type", userType);
    } else {
      postData.append("user_id", selectedIds.join(","));
      postData.append("user_type", userType);
    }
    let res = SwiftVanApi.sendBroadCastNotification(authToken, postData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        reset();
        setIsAllChecked(false);
        setSearchKey("");
        var modifiedArray = broadCastList.map((user) => ({
          ...user,
          isChecked: false,
        }));
        setModifiedBroadCastList(modifiedArray);
        Toster(t("NOTIFICATION_SEND_SUCCESS"), "success");
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const onSubmit = (formData) => {
    const isSingleChecked = modifiedBroadCastList.some((val) => val.isChecked);
    if (!isSingleChecked) {
      Toster(t("SELECT_ATLEAST_ONE_USER"), "error");
      return;
    }
    handleSendBroadCastNotification(formData);
  };

  const handleTabClick = (title) => {
    setActiveSection(title);
    setTabOption((prevState) =>
      prevState.map((tab) => ({
        ...tab,
        active: tab.title === title,
      }))
    );
  };

  const handleConfirm = () => {
    handleDeleteNotification(showConfirm.id);
  };

  const handleCancel = () => {
    setShowConfirm((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  const handleShowConfirm = (id) => {
    setShowConfirm(() => ({
      id,
      show: true,
    }));
  };

  return (
    <div className="adminPageWrapper">
      <div className="adminPageInner">
        <div className="adminPageInner_sidebar">
          <Sidebar />
        </div>
        <div className="adminPageInner_rightside">
          <div className="headersection">
            <Header />
          </div>
          {isLoading ? <Loader /> : ""}
          <div className="adminPageInner_heading">
            <div className={Styles.broadcastHeading}>
              {tabOption.map((val) => {
                return (
                  <>
                    <div
                      className={Styles.headTitle}
                      style={{ color: val.active ? "#0094f6" : "" }}
                      onClick={() => handleTabClick(val.title)}
                    >
                      {val.title}
                    </div>
                  </>
                );
              })}
            </div>
            {activeSection == "Send Broadcast" ? (
              <div className="broadcasting">
                <Row>
                  <Col lg={4} md={6}>
                    <div className={Styles.broadcastingMainLeft}>
                      <div className={Styles.broadcastingMainLeftTop}>
                        {/*---search-section---*/}
                        <div className="topSearchSection">
                          <div className="topSearchSectionInner">
                            <div className="topSearchMain">
                              <img src={InputSearch} />
                              <Form.Control
                                placeholder={`${t(
                                  "SEARCH_BY_NAME_EMAIL_RATING"
                                )}`}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={searchKey}
                                onChange={(e) =>
                                  handleSearchChange(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className={Styles.broadCastUserType}>
                          <Form.Select
                            aria-label="Default select example"
                            className="inputDrop"
                            value={userType}
                            onChange={(e) => onUserSelect(e.target.value)}
                          >
                            {userOptions.map((item, index) => {
                              return (
                                <option key={index + 1} value={item.user_role}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>

                        {/* ----Select All---- */}
                        <div className={Styles.broadcastingSelect}>
                          <h5>{t("SELECT_ALL")}</h5>
                          <Form.Check
                            aria-label="option 1"
                            checked={isAllChecked}
                            onChange={(e) => handleAllChecked(e.target.checked)}
                          />
                        </div>
                      </div>
                      <hr className={Styles.broadcastingSelectLine} />
                      {modifiedBroadCastList.map((user, index) => {
                        return (
                          <div
                            className="broadcastingCard"
                            key={index}
                            onScroll={(e) => {
                              const element = e.target;

                              if (
                                Math.abs(
                                  element.scrollHeight -
                                    element.scrollTop -
                                    element.clientHeight
                                ) <= 1 &&
                                broadCastList.length > 0 &&
                                loadMore == true
                              ) {
                                fetchBroadCastUserList(true);
                              }
                            }}
                          >
                            <div className={Styles.broadcastingLeft}>
                              <div className={Styles.broadLeftInner}>
                                <img src={user.profile_image} />
                                <div className={Styles.broadLeftTitle}>
                                  <h3>
                                    {user.first_name} {user.last_name}{" "}
                                  </h3>
                                  <p>{user.email}</p>
                                </div>
                              </div>
                            </div>
                            <div className={Styles.broadcastingRight}>
                              <Form.Check
                                aria-label="option 1"
                                checked={user.isChecked}
                                onChange={(e) =>
                                  handleSingleChecked(e.target.checked, user.id)
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                      {loadMore && (
                        <>
                          <hr className={Styles.broadcastingSelectLine} />
                          <div className={Styles.loadMore}>
                            <span onClick={() => handleLoadMore()}>
                              {t("LOAD_MORE")}
                            </span>
                          </div>
                        </>
                      )}
                      {searchKey && broadCastList.length == 0 && (
                        <>
                          <div className={Styles.loadMore}>
                            <span>{t("USER_NOT_FOUND")}</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>

                  <Col lg={5} md={6}>
                    <div className="commissionSectionnew">
                      <div className={Styles.commissionMainSection}>
                        <h5>{t("BROADCASTING")}</h5>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="popupInput">
                                <Form.Label>{t("SUBJECT")}</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={`${t("ENTER_SUBJECT")}`}
                                  autoComplete="off"
                                  {...register("subject", {
                                    required: {
                                      value: true,
                                      message: `${t("PLS_ENTER_SUBJECT")}`,
                                    },
                                    minLength: {
                                      value: 2,
                                      message: `${t("MIN_2_CHAR_REQUIRED")}`,
                                    },
                                    maxLength: {
                                      value: 150,
                                      message: `${t("MAX_150_CHAR_ALLOEWD")}`,
                                    },
                                  })}
                                  isInvalid={!!errors.subject}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t(errors?.subject?.message)}
                                </Form.Control.Feedback>{" "}
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="popupInput">
                                <Form.Label>{t("MESSAGE")}</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  placeholder={`${t("ENTER_MESSAGE")}`}
                                  autoComplete="off"
                                  {...register("description", {
                                    required: {
                                      value: true,
                                      message: `${t("PLS_ENTER_DESC")}`,
                                    },
                                    minLength: {
                                      value: 2,
                                      message: `${t("MIN_2_CHAR_REQUIRED")}`,
                                    },
                                  })}
                                  isInvalid={!!errors.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t(errors?.description?.message)}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className={Styles.AddVanBtn}>
                            <button type="submit">{t("SEND")}</button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <div className="tableMainSection">
                  <div className="tableMainInner">
                    <div className="tableBootomScroll">
                      <div className="SupporTableInner">
                        <div className="UserTableInner">
                          <Table striped responsive="lg">
                            <thead>
                              <tr className="align-middle">
                                <th className="tableThBg">
                                  {t("RECIEVER_NAME")}
                                </th>
                                <th className="tableThBg">{t("TITLE")}</th>
                                <th className="tableThBg">
                                  {t("DESCRIPTIONS")}
                                </th>
                                <th className="tableThBg">{t("DATE")}</th>
                                <th className="tableThBg tablethRight text-center">
                                  {t("ACTION")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {notificationList &&
                              notificationList.length > 0 ? (
                                notificationList.map((item, index) => {
                                  return (
                                    <tr key={index} className="align-middle">
                                      <td
                                        className="tableTdText textUpperLatter "
                                        style={{ minWidth: "140px" }}
                                      >
                                        {item && item.receiver_name ? (
                                          <>{item && item.receiver_name}</>
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                      <td
                                        className="tableTdText"
                                        style={{ minWidth: "165px" }}
                                      >
                                        {item.title}
                                      </td>
                                      <td
                                        className="tableTdText"
                                        style={{ minWidth: "200px" }}
                                      >
                                        <div className="tableTextThreeLine">
                                          {item && item.description ? (
                                            <>
                                              {capitalizeFirstLetter(
                                                item && item.description
                                              )}
                                            </>
                                          ) : (
                                            "NA"
                                          )}
                                        </div>
                                      </td>
                                      <td
                                        className="tableTdText"
                                        style={{ minWidth: "120px" }}
                                      >
                                        <div className="tableTextThreeLine">
                                          {item && item.created_at ? (
                                            <>
                                              {moment(
                                                item && item.created_at
                                              ).format("DD/MM/YYYY")}
                                            </>
                                          ) : (
                                            "NA"
                                          )}
                                        </div>
                                      </td>

                                      <td className="text-center">
                                        <div className="TableDropdown">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <div className="optionList">
                                                <SlOptions />
                                              </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="dropDownOnlyOneList">
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleShowConfirm(item.id)
                                                }
                                              >
                                                {t("DELETE_DROPDOWN")}
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="7" className="centeredCell">
                                    <div className="dataRecord">
                                      {t("NO_REC_FOUND")}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {/* pagination */}
                      <div className="tablePaginationDash">
                        {totalRecords > itemsPerPage && (
                          <ReactPaginate
                            previousLabel={<MdKeyboardArrowLeft />}
                            nextLabel={<MdKeyboardArrowRight />}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            pageCount={Math.ceil(totalRecords / itemsPerPage)}
                            breakLabel={<span className="page-link">...</span>}
                            marginPagesDisplayed={0}
                            pageRangeDisplayed={
                              window.innerWidth > 768 ? 10 : 2
                            }
                            onPageChange={handlePageChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Confirm
                  show={showConfirm.show}
                  subTitle={t("SURE_DELETE_BROADCAST")}
                  handleConfirm={handleConfirm}
                  handleCancel={handleCancel}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Broadcasting;
