import Modal from "react-bootstrap/Modal";
import styles from "../../src/Validation/Alert.module.css";
import { MdCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Toster from "../Utils/Toster";
import { userLogoutClear } from "../Redux/Slices/userSlice";

function LogoutPopup(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handelLogout = () => {
    dispatch(userLogoutClear());
    // Toster(t("LOGOUT_SUCCESSFULLY"), "success");
    navigate("/");
  };

  return (
    <>
      <Modal show={props.logoutPopupShow} onHide={props.LogoutPopupHandleClose}>
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("DELETE_TITLE")}</p>
            <span className={styles.alertCross}>
              <MdCancel onClick={props.LogoutPopupHandleClose} />
            </span>
          </div>

          <div className={styles.devider}></div>
          <div className={styles.alertBodyInner}>
            <div className={styles.alertLabel}>
              <p>{t("ARE_YOU_SURE_LOGOUT")}</p>
            </div>

            <div className={styles.buttonContainer}>
              <button
                className={styles.buttonNo}
                onClick={props.LogoutPopupHandleClose}
              >
                {t("NO")}
              </button>
              <button
                className={styles.buttonYes}
                onClick={() => handelLogout()}
              >
                {t("YES")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LogoutPopup;
