import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button } from "react-bootstrap";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function SelectFloor({
  showSelectFloor,
  setShowSelectFloor,
  setSourceFloor,
  setDestinationFloor,
}) {
  const { t } = useTranslation();
  const jobData = useSelector((state) => state.createJob);
  const [currentValue, setCurrentValue] = useState({
    val: "",
    id: "",
    time: "",
  });

  useEffect(() => {
    setCurrentValue(showSelectFloor.value);
  }, [showSelectFloor]);

  const handleCheckboxChange = (id, value, time) => {
    // setCurrentValue({ val: value, id: id });
    if (showSelectFloor.type == "source") {
      setSourceFloor({ val: value, id: id, time });
      setShowSelectFloor((prevState) => ({ ...prevState, show: false }));
    } else {
      setDestinationFloor({ val: value, id: id, time });
      setShowSelectFloor((prevState) => ({ ...prevState, show: false }));
    }
  };

  // const handleSubmit = () => {
  //   if (showSelectFloor.type == "source") {
  //     setSourceFloor(currentValue);
  //     setShowSelectFloor((prevState) => ({ ...prevState, show: false }));
  //   } else {
  //     setDestinationFloor(currentValue);
  //     setShowSelectFloor((prevState) => ({ ...prevState, show: false }));
  //   }
  // };
  return (
    <>
      <Modal
        show={showSelectFloor.show}
        onHide={() => {
          setShowSelectFloor(false);
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("SELECT_FLOOR")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                setShowSelectFloor(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {jobData.floorOptions?.map((checkbox) => (
              <div
                key={checkbox.value_code}
                className="floorCheckboxes"
                onClick={(e) => {
                  handleCheckboxChange(
                    checkbox.id,
                    checkbox.value_name,
                    checkbox.time
                  );
                }}
              >
                <label
                  className={
                    currentValue?.val === checkbox.value_name
                      ? styles.activeFloor
                      : ""
                  }
                >
                  {checkbox.value_name}
                </label>
                {/* <input
                  type="radio"
                  checked={currentValue?.val === checkbox.value_name}
                /> */}
                {/* <Button
                  variant={
                    currentValue?.val === checkbox.value_name
                      ? "primary"
                      : "secondary"
                  }
                  className={`${styles.selectedBtn}`}
                  onClick={(e) => {
                    handleCheckboxChange(
                      checkbox.id,
                      checkbox.value_name,
                      checkbox.time
                    );
                  }}
                >
                  {currentValue?.val === checkbox.value_name
                    ? t("SELECTED")
                    : t("SELECT")}
                </Button> */}
              </div>
            ))}
          </Modal.Body>
          {/* <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              Done
            </Button>
          </Modal.Footer> */}
        </div>
      </Modal>
    </>
  );
}

export default SelectFloor;
