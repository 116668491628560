import UserTable1 from "./Assests/Images/UserTable1.png";
import UserTable2 from "./Assests/Images/UserTable2.png";
import UserTable3 from "./Assests/Images/UserTable3.png";
import UserTable4 from "./Assests/Images/UserTable4.png";
import UserTable5 from "./Assests/Images/UserTable5.png";

import ReviewImg1 from "./Assests/Images/Review1.png";
import ReviewImg2 from "./Assests/Images/Review2.png";
import ReviewImg3 from "./Assests/Images/Review3.png";

import liveChatImg1 from "./Assests/Images/liveChatImg1.png";
import liveChatImg2 from "./Assests/Images/liveChatImg2.png";
import liveChatImg3 from "./Assests/Images/liveChatImg3.png";
import liveChatImg4 from "./Assests/Images/liveChatImg4.png";
import liveChatImg5 from "./Assests/Images/liveChatImg5.png";
import StatusImg from "./Assests/Images/Livechatpin.png";
import lutonVan from "./Assests/Images/Luton_Van.jpg";
import extraLongWheel from "./Assests/Images/Extra_Long_Wheel_Base.jpg";
import longWheel from "./Assests/Images/Large_Long_Wheel_Base_Van.jpg";
import mediumVan from "./Assests/Images/Medium_Removals_Van.jpg";
import smallVan from "./Assests/Images/Small_Moving_Van.jpg";

export const ReviewTap = [
  {
    Img: ReviewImg1,
    Name: "Adam Smith",
    Email: "adamsmith@gmail.com",
    Date: "25/04/2022",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    Img: ReviewImg2,
    Name: "Micky Hamshworth",
    Email: "micky.hamsworth@gmail.com",
    Date: "25/04/2022",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry..",
  },
  {
    Img: ReviewImg3,
    Name: "Michel Marsh",
    Email: "michel.marsh@gmail.com",
    Date: "25/04/2022",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry..",
  },
];

export const SupportTable = [
  {
    Img: UserTable1,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "Home removal",
    Status_type: "Its been  completed",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
  {
    Img: UserTable2,
    Name: "Toby Maghwair",
    Email: "natasha.romanoff@gmail.com",
    Category: "About a booking",
    Booking_type: "Furniture & other items",
    Status_type: "Its been  completed",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
  {
    Img: UserTable3,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "About pay",
    Status_type: "Its happening now",
    Issue_type: "I cant Complete the booking",
    Action: "...",
  },
  {
    Img: UserTable4,
    Name: "Toby Maghwair",
    Email: "natasha.romanoff@gmail.com",
    Category: "About a booking",
    Booking_type: "Furniture & other items",
    Status_type: "Its happening now",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
  {
    Img: UserTable2,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "Furniture & other items",
    Status_type: "Its been  completed",
    Issue_type: "Other",
    Action: "...",
  },
  {
    Img: UserTable1,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "About pay",
    Status_type: "Its happening now",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
  {
    Img: UserTable4,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "Furniture & other items",
    Status_type: "Its happening now",
    Issue_type: "Customer wants to reschedule",
    Action: "...",
  },
  {
    Img: UserTable2,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "Furniture & other items",
    Status_type: "Its happening now",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
  {
    Img: UserTable1,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "Home removal",
    Status_type: "Its been  completed",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
  {
    Img: UserTable1,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "Home removal",
    Status_type: "Its been  completed",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
  {
    Img: UserTable1,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "Home removal",
    Status_type: "Its been  completed",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
  {
    Img: UserTable1,
    Name: "Toby Maghwair",
    Email: "toby.maghwair@gmail.com",
    Category: "About a booking",
    Booking_type: "Home removal",
    Status_type: "Its been  completed",
    Issue_type: "Pay doesn’t look right",
    Action: "...",
  },
];

export const BroadcastingCard = [
  {
    Img: UserTable1,
    Name: "Jeremy Renner",
    Desc: "Lorem Ipsum is simply.",
    Rating: 4.6,
  },
  {
    Img: UserTable2,
    Name: "Adam Worlok",
    Desc: "Lorem Ipsum is simply.",
    Rating: 3.4,
  },
  {
    Img: UserTable3,
    Name: "Vicktor Timlly",
    Desc: "Lorem Ipsum is simply.",
    Rating: 3,
  },
  {
    Img: UserTable4,
    Name: "Oroborsh Marsh",
    Desc: "Lorem Ipsum is simply.",
    Rating: 4.4,
  },
  {
    Img: UserTable2,
    Name: "Toby Maghwair",
    Desc: "Lorem Ipsum is simply.",
    Rating: 4.3,
  },
  {
    Img: UserTable4,
    Name: "Adam Worlok",
    Desc: "Lorem Ipsum is simply.",
    Rating: 4.1,
  },
  {
    Img: UserTable4,
    Name: "Oroborsh Marsh",
    Desc: "Lorem Ipsum is simply.",
    Rating: 4.4,
  },
  {
    Img: UserTable2,
    Name: "Toby Maghwair",
    Desc: "Lorem Ipsum is simply.",
    Rating: 4.3,
  },
  {
    Img: UserTable4,
    Name: "Adam Worlok",
    Desc: "Lorem Ipsum is simply.",
    Rating: 4.1,
  },
];

export const Livechatcard = [
  {
    Img: liveChatImg1,
    Name: "Jeremy Renner",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 0,
    StatusImg: StatusImg,
  },
  {
    Img: liveChatImg2,
    Name: "Adam Worlok",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 0,
  },
  {
    Img: liveChatImg3,
    Name: "Vicktor Timlly",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 2,
    StatusImg: StatusImg,
  },
  {
    Img: liveChatImg4,
    Name: "Oroborsh Marsh",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 0,
  },
  {
    Img: liveChatImg5,
    Name: "Toby Maghwair",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 2,
    StatusImg: StatusImg,
  },
  {
    Img: liveChatImg1,
    Name: "Adam Worlok",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 0,
  },
  {
    Img: liveChatImg2,
    Name: "Oroborsh Marsh",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 2,
  },
  {
    Img: liveChatImg3,
    Name: "Toby Maghwair",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 0,
  },
  {
    Img: liveChatImg4,
    Name: "Adam Worlok",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 0,
    StatusImg: StatusImg,
  },
  {
    Img: liveChatImg5,
    Name: "Toby Maghwair",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 0,
  },
  {
    Img: liveChatImg1,
    Name: "Adam Worlok",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 2,
    Status: 0,
  },
  {
    Img: liveChatImg2,
    Name: "Toby Maghwair",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 0,
    Status: 0,
  },
  {
    Img: liveChatImg3,
    Name: "Adam Worlok",
    Desc: "Lorem Ipsum is simply.",
    time: "5:40 pm",
    unread_msg: 2,
    Status: 1,
  },
];

export const BellIconMes = [
  {
    Img: liveChatImg1,
    time: "25 min ago",
    Sent_amount: "has Sent amount",
    For: "for",
    Customer_Name: "Nick Jonas",
    Amount: "£252.00",
    Driver_Name: "David Thomson",
    color: "#FFFFFF",
  },
  {
    Img: liveChatImg2,
    time: "25 min ago",
    Order_Accept: "Has Accept ",
    Order_KM: "Job request  for 20KM Amount",
    Amount: "£252.00",
    color: "#FFFFFF",
  },
  {
    Img: liveChatImg3,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_Successfully_Delivered: "has successfully Delivered",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg4,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_On_the_way: "is On the way with ",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg5,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_On_the_way: "is On the way with ",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg1,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_Pickup: "is Pickup the",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg1,
    time: "25 min ago",
    Sent_amount: "has Sent amount",
    For: "for",
    Customer_Name: "Nick Jonas",
    Amount: "£252.00",
    Driver_Name: "David Thomson",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg2,
    time: "25 min ago",
    Order_Accept: "Has Accept ",
    Order_KM: "Job request  for 20KM Amount",
    Amount: "£252.00",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg3,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_Successfully_Delivered: "has successfully Delivered",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg4,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_On_the_way: "is On the way with ",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg5,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_On_the_way: "is On the way with ",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg1,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_Pickup: "is Pickup the",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },

  {
    Img: liveChatImg1,
    time: "25 min ago",
    Sent_amount: "has Sent amount",
    For: "for",
    Customer_Name: "Nick Jonas",
    Amount: "£252.00",
    Driver_Name: "David Thomson",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg2,
    time: "25 min ago",
    Order_Accept: "Has Accept ",
    Order_KM: "Job request  for 20KM Amount",
    Amount: "£252.00",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg3,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_Successfully_Delivered: "has successfully Delivered",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg4,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_On_the_way: "is On the way with ",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg5,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_On_the_way: "is On the way with ",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
  {
    Img: liveChatImg1,
    time: "25 min ago",
    Driver_Name: "Victor Doom",
    Order_Pickup: "is Pickup the",
    Customer_Name: "Mitchel Marsh",
    Order: "order",
    Color: "#EDEDED",
  },
];

export const driverStatus = [
  {
    id: "1",
    text: "Approved",
    value: "approved",
  },
  {
    id: "2",
    text: "Pending",
    value: "pending",
  },
  {
    id: "3",
    text: "Rejected",
    value: "rejected",
  },
];

export const ratingStatus = [
  {
    id: "1",
    text: `1★ & above`,
    value: 1,
  },
  {
    id: "2",
    text: `2★ & above`,
    value: 2,
  },
  {
    id: "3",
    text: "3★ & above",
    value: 3,
  },
  {
    id: "4",
    text: "4★ & above",
    value: 4,
  },
];

export const jobStatus = [
  {
    id: "1",
    text: "Pending",
    value: "pending",
  },
  {
    id: "2",
    text: "Inprogress",
    value: "inprogress",
  },
  {
    id: "3",
    text: "Complete",
    value: "complete",
  },
  {
    id: "4",
    text: "Cancel By Driver",
    value: "cancel_driver",
  },
  {
    id: "5",
    text: "Cancel By Customer",
    value: "cancel_customer",
  },
  {
    id: "4",
    text: "Cancel By Admin",
    value: "cancel_by_admin",
  },
];

export const jobTrackingStatus = [
  {
    id: "1",
    text: "Start",
    value: "start",
  },
  {
    id: "2",
    text: "Pickup",
    value: "pickup",
  },
  {
    id: "3",
    text: "On the Way",
    value: "on-the-way",
  },
  {
    id: "4",
    text: "Reached",
    value: "reached",
  },
  {
    id: "5",
    text: "Delivered",
    value: "delivered",
  },
];

export const CouponCard = [
  {
    Title: "What is Lorem Ipsum?",
    Description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    Rating: "★★★★★",
  },
  {
    Title: "What is Lorem Ipsum?",
    Description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    Rating: "★★★★★",
  },
  {
    Title: "What is Lorem Ipsum?",
    Description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    Rating: "★★★★★",
  },
  {
    Title: "What is Lorem Ipsum?",
    Description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    Rating: "★★★★★",
  },
  {
    Title: "What is Lorem Ipsum?",
    Description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    Rating: "★★★★★",
  },
];

export const vehicleOptions = [
  {
    id: 1,
    name: "LUTON VAN",
    title: "Lorem Ipsum is simply dummy text",
    image: lutonVan,
  },
  {
    id: 2,
    name: "LARGE VAN",
    title: "Lorem Ipsum is simply dummy text",
    image: lutonVan,
  },
  {
    id: 3,
    name: "MEDIUM VAN",
    title: "Lorem Ipsum is simply dummy text",
    image: lutonVan,
  },
  {
    id: 4,
    name: "SMALL VAN",
    title: "Lorem Ipsum is simply dummy text",
    image: lutonVan,
  },
];

export const vehicleDescription = [
  {
    id: 1,
    title: `${"VAN_TITLE"}`,
    description: `${"VAN_DESCRIPTION"}`,
    dimention: {
      length: `${"INFO_LENGTH1"}`,
      width: `${"INFO_LENGTH2"}`,
      height: `${"INFO_LENGTH3"}`,
      payload: `${"INFO_LENGTH4"}`,
      seats: `${"INFO_LENGTH5"}`,
    },
    image: lutonVan,
  },
  {
    id: 5,
    title: `${"XTRA_LARGE_VAN_TITLE"}`,
    description: `${"XTRA_LARGE_VAN_DESCRIPTION"}`,
    dimention: {
      length: `${"XTRA_LARGE_VAN_LENGTH1"}`,
      width: `${"XTRA_LARGE_VAN_LENGTH2"}`,
      height: `${"XTRA_LARGE_VAN_LENGTH3"}`,
      payload: `${"XTRA_LARGE_VAN_LENGTH4"}`,
      seats: `${"XTRA_LARGE_VAN_LENGTH5"}`,
    },
    image: extraLongWheel,
  },
  {
    id: 2,
    title: `${"LARGE_VAN_TITLE"}`,
    description: `${"LARGE_VAN_DESCRIPTION"}`,
    dimention: {
      length: `${"LARGE_VAN_LENGTH1"}`,
      width: `${"LARGE_VAN_LENGTH2"}`,
      height: `${"LARGE_VAN_LENGTH3"}`,
      payload: `${"LARGE_VAN_LENGTH4"}`,
      seats: `${"LARGE_VAN_LENGTH5"}`,
    },
    image: longWheel,
  },
  {
    id: 3,
    title: `${"MEDIUM_VAN_TITLE"}`,
    description: `${"MEDIUM_VAN_DESCRIPTION"}`,
    dimention: {
      length: `${"MEDIUM_LENGTH1"}`,
      width: `${"MEDIUM_LENGTH2"}`,
      height: `${"MEDIUM_LENGTH3"}`,
      payload: `${"MEDIUM_LENGTH4"}`,
      seats: `${"MEDIUM_LENGTH5"}`,
    },
    image: mediumVan,
  },
  {
    id: 4,
    title: `${"SMALL_VAN_TITLE"}`,
    description: `${"SMALL_VAN_DESCRIPTION"}`,
    dimention: {
      length: `${"SMALL_LENGTH1"}`,
      width: `${"SMALL_LENGTH2"}`,
      height: `${"SMALL_LENGTH3"}`,
      payload: `${"SMALL_LENGTH4"}`,
      seats: `${"SMALL_LENGTH5"}`,
    },
    image: smallVan,
  },
];
