import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Dropdown } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { PAGE, SUPPORT_TYPE } from "../../Utils/Constant";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Loader from "../../Validation/LoadingSpinner";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import DeleteSupportPopup from "./DeleteSupportPopup";
import { SlOptions } from "react-icons/sl";
import ReplyMail from "../B2Benquiry/ReplyMail";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import moment from "moment";

const Support = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const itemsPerPage = PAGE.PAGE_COUNT;
  const [isLoading, setIsLoading] = useState(false);
  const [supportData, setSupportData] = useState("");
  const [supportType, setSupportType] = useState(SUPPORT_TYPE.DRIVER);
  const [supportId, setSupportId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [supportDelete, setSupportDetete] = useState(false);
  const [showReply, setShowReply] = useState({ show: false, id: "" });
  const SupportDeteteClosePopup = () => setSupportDetete(false);
  const SupportDeleteShowPopup = () => setSupportDetete(true);

  // function for handle pagination
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const handleTabChange = (selectedTab) => {
    const supportType =
      selectedTab === "Driver_Support"
        ? SUPPORT_TYPE.DRIVER
        : SUPPORT_TYPE.CUSTOMER;
    setPageNumber(1);
    setSupportType(supportType);
    SupportDataList(supportType);
  };

  useEffect(() => {
    SupportDataList(supportType);
  }, [pageNumber, supportType]);

  // function for get user details api calling
  async function SupportDataList(supportType) {
    setIsLoading(true);
    const details = await SwiftVanApi.supportList(
      userToken,
      supportType,
      pageNumber
    );
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setSupportData(details && details.data && details.data.supportList);
      setTotalRecords(details && details.data && details.data.supportListCount);
      setIsLoading(false);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  const handleReplyToMail = (email, id) => {
    setShowReply({ show: true, email, id });
  };

  const onSubmitSuceess = () => {
    SupportDataList(supportType);
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="BenquiryMainSection">
                {/*---search-section---*/}
                <div className="topSearchSection">
                  <div className="topSearchSectionInner">
                    <h1>{t("SUPPORT")}</h1>
                  </div>
                </div>

                {/* ---- */}
                <div className="supportTabsMain">
                  <div className="userDetailsTabsMain">
                    <Tabs
                      id="justify-tab-example"
                      className="mb-3 borderSet"
                      onSelect={handleTabChange}
                      justify
                    >
                      {/* ---Driver_Support--- */}
                      <Tab
                        eventKey="Driver_Support"
                        title="Driver Support"
                        className="borderSet"
                      >
                        <div className="tableMainSection">
                          <div className="tableMainInner">
                            <div className="tableBootomScroll">
                              <div className="SupporTableInner">
                                <div className="UserTableInner">
                                  <Table striped responsive="lg">
                                    <thead>
                                      <tr className="align-middle">
                                        <th className="tableThBg tablethleft text-center">
                                          {t("IMG")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("NAME")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("EMAIL")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("CATEGORY")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("TITLE")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("DESCRIPTIONS")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("DATE")}
                                        </th>
                                        <th className="tableThBg tablethRight text-center">
                                          {t("ACTION")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {supportData && supportData.length > 0 ? (
                                        supportData.map((item, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="align-middle"
                                            >
                                              <td className="tableTdText text-center tableImgMain ">
                                                <span>
                                                  <img
                                                    src={
                                                      item && item.profile_image
                                                    }
                                                    alt="img"
                                                  />
                                                </span>
                                              </td>
                                              <td
                                                className="tableTdText "
                                                style={{ minWidth: "140px" }}
                                              >
                                                {item && item.first_name ? (
                                                  <>
                                                    {item && item.first_name}
                                                    &nbsp;
                                                    {item && item.last_name}
                                                  </>
                                                ) : (
                                                  "NA"
                                                )}
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "150px" }}
                                              >
                                                {item && item.email}
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "120px" }}
                                              >
                                                {item && item.category}
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "165px" }}
                                              >
                                                {item && item.title}
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "200px" }}
                                              >
                                                <div className="">
                                                  {item && item.description ? (
                                                    <>
                                                      {capitalizeFirstLetter(
                                                        item && item.description
                                                      )}
                                                    </>
                                                  ) : (
                                                    "NA"
                                                  )}
                                                </div>
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "120px" }}
                                              >
                                                <div className="tableTextThreeLine">
                                                  {item && item.date ? (
                                                    <>
                                                      {moment(
                                                        item && item.date
                                                      ).format("DD/MM/YYYY")}
                                                    </>
                                                  ) : (
                                                    "NA"
                                                  )}
                                                </div>
                                              </td>
                                              <td className="text-center">
                                                <div className="TableDropdown">
                                                  <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                      <div className="optionList">
                                                        <SlOptions />
                                                      </div>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="dropDownOnlyOneList">
                                                      <Dropdown.Item
                                                        onClick={() => {
                                                          handleReplyToMail(
                                                            item.email,
                                                            item.id
                                                          );
                                                        }}
                                                        disabled={item.is_reply}
                                                      >
                                                        {!item.is_reply
                                                          ? t("REPLY")
                                                          : t("REPLIED")}
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() => {
                                                          SupportDeleteShowPopup();
                                                          setSupportId(item.id);
                                                        }}
                                                      >
                                                        {t("DELETE_DROPDOWN")}
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="7"
                                            className="centeredCell"
                                          >
                                            <div className="dataRecord">
                                              {t("NO_REC_FOUND")}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                              {/* pagination */}
                              <div className="tablePaginationDash">
                                {totalRecords > itemsPerPage && (
                                  <ReactPaginate
                                    previousLabel={<MdKeyboardArrowLeft />}
                                    nextLabel={<MdKeyboardArrowRight />}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    pageCount={Math.ceil(
                                      totalRecords / itemsPerPage
                                    )}
                                    breakLabel={
                                      <span className="page-link">...</span>
                                    }
                                    marginPagesDisplayed={0}
                                    pageRangeDisplayed={
                                      window.innerWidth > 768 ? 10 : 2
                                    }
                                    onPageChange={handlePageChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <span>|</span>
                      {/* ---Customer Support--- */}
                      <Tab eventKey="Customer_Support" title="Customer Support">
                        <div className="tableMainSection">
                          <div className="tableMainInner">
                            <div className="tableBootomScroll">
                              <div className="SupporTableInner">
                                <div className="UserTableInner">
                                  <Table striped responsive="lg">
                                    <thead>
                                      <tr className="align-middle">
                                        <th className="tableThBg tablethleft text-center">
                                          {t("IMG")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("NAME")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("EMAIL")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("CATEGORY")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("TITLE")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("DESCRIPTIONS")}
                                        </th>
                                        <th className="tableThBg">
                                          {t("DATE")}
                                        </th>
                                        <th className="tableThBg tablethRight text-center">
                                          {t("ACTION")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {supportData && supportData.length > 0 ? (
                                        supportData.map((item, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="align-middle"
                                            >
                                              <td className="tableTdText text-center tableImgMain ">
                                                <span>
                                                  <img
                                                    src={
                                                      item && item.profile_image
                                                    }
                                                    alt="img"
                                                  />
                                                </span>
                                              </td>
                                              <td
                                                className="tableTdText textUpperLatter "
                                                style={{ minWidth: "140px" }}
                                              >
                                                {item && item.first_name ? (
                                                  <>
                                                    {item && item.first_name}
                                                    &nbsp;
                                                    {item && item.last_name}
                                                  </>
                                                ) : (
                                                  "NA"
                                                )}
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "150px" }}
                                              >
                                                {item && item.email}
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "120px" }}
                                              >
                                                {item && item.category}
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "165px" }}
                                              >
                                                {item.title}
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "200px" }}
                                              >
                                                <div className="">
                                                  {item && item.description ? (
                                                    <>
                                                      {capitalizeFirstLetter(
                                                        item && item.description
                                                      )}
                                                    </>
                                                  ) : (
                                                    "NA"
                                                  )}
                                                </div>
                                              </td>
                                              <td
                                                className="tableTdText"
                                                style={{ minWidth: "120px" }}
                                              >
                                                <div className="tableTextThreeLine">
                                                  {item && item.date ? (
                                                    <>
                                                      {moment(
                                                        item && item.date
                                                      ).format("DD/MM/YYYY")}
                                                    </>
                                                  ) : (
                                                    "NA"
                                                  )}
                                                </div>
                                              </td>

                                              <td className="text-center">
                                                <div className="TableDropdown">
                                                  <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                      <div className="optionList">
                                                        <SlOptions />
                                                      </div>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="dropDownOnlyOneList">
                                                      <Dropdown.Item
                                                        onClick={() => {
                                                          handleReplyToMail(
                                                            item.email,
                                                            item.id
                                                          );
                                                        }}
                                                        disabled={item.is_reply}
                                                      >
                                                        {!item.is_reply
                                                          ? t("REPLY")
                                                          : t("REPLIED")}
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() => {
                                                          SupportDeleteShowPopup();
                                                          setSupportId(item.id);
                                                        }}
                                                      >
                                                        {t("DELETE_DROPDOWN")}
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="7"
                                            className="centeredCell"
                                          >
                                            <div className="dataRecord">
                                              {t("NO_REC_FOUND")}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                              {/* pagination */}
                              <div className="tablePaginationDash">
                                {totalRecords > itemsPerPage && (
                                  <ReactPaginate
                                    previousLabel={<MdKeyboardArrowLeft />}
                                    nextLabel={<MdKeyboardArrowRight />}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    pageCount={Math.ceil(
                                      totalRecords / itemsPerPage
                                    )}
                                    breakLabel={
                                      <span className="page-link">...</span>
                                    }
                                    marginPagesDisplayed={0}
                                    pageRangeDisplayed={
                                      window.innerWidth > 768 ? 10 : 2
                                    }
                                    onPageChange={handlePageChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <DeleteSupportPopup
        supportId={supportId}
        supportDelete={supportDelete}
        SupportDeteteClosePopup={SupportDeteteClosePopup}
        SupportDataList={SupportDataList}
        supportType={supportType}
      />
      <ReplyMail
        showReply={showReply}
        setShowReply={setShowReply}
        type={"support"}
        onSubmitSuceess={onSubmitSuceess}
      />
    </>
  );
};

export default Support;
