import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Form, Row } from "react-bootstrap";
import Styles from "../AboutUs/AboutUs.module.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useForm } from "react-hook-form";
import {
  COMMISSION_TYPE,
  OPTION_NAME,
  messageValidation,
} from "../../Utils/Constant";
import Loader from "../../Validation/LoadingSpinner";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [privacyPolicyData, setPrivacyPolicyData] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { handleSubmit } = useForm({
    mode: "onChange",
  });

  //   text editer function
  const handleEditorChange = (value) => {
    setPrivacyPolicyData(value);

    const trimmedValue = value.replace(/(<([^>]+)>)/gi, "").trim();
    if (trimmedValue.trim().length === 0) {
      setIsError(true);
      setErrorMessage(t("TEXT_IS_REQUIRED"));
    } else if (trimmedValue.length < messageValidation.Min) {
      setIsError(true);
      setErrorMessage(t("TEXT_MUST_BE_AT_LEAST_3_CHARACTERS"));
    } else if (trimmedValue.length > messageValidation.Max) {
      setIsError(true);
      setErrorMessage(t("TEXT_MUST_MUST_20000_CHARACTERS"));
    } else {
      setIsError(false);
      setErrorMessage("");
    }
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ color: [] }, { background: [] }],
      ["link"],
      ["clean"],
    ],
  };

  //---get-api-function
  const SettingOption = () => {
    setIsLoading(true);
    let res = SwiftVanApi.settingOptionList(
      authToken,
      COMMISSION_TYPE.PAGE,
      OPTION_NAME.PRIVACY_POLICY
    );
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        if (data.data.length > 0) {
          const aboutUs = data.data.filter((obj) => {
            return obj.option_name === "privacy_policy";
          });
          setPrivacyPolicyData(aboutUs[0].option_value);
        }

        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    SettingOption();
  }, []);

  const onSubmit = (data) => {
    const trimmedValue = privacyPolicyData.replace(/(<([^>]+)>)/gi, "").trim();
    if (trimmedValue.length === 0) {
      setIsError(true);
      return;
    }
    updateApi(data);
  };

  const updateApi = () => {
    var PostData = new FormData();
    PostData.append("option_name", OPTION_NAME.PRIVACY_POLICY);
    PostData.append("option_value", privacyPolicyData);
    PostData.append("option_type", COMMISSION_TYPE.PAGE);

    setIsLoading(true);
    let res = SwiftVanApi.postSettingOptionList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_PRIVACY_POLICY_SUCCESSFULLY"), "success");
        SettingOption();
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="commissionSection">
                <div className={Styles.commissionMainSection}>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <h5>{t("PRIVACY_POLICY_SIDEBAR")}</h5>

                    <Row>
                      <Col lg={12}>
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={privacyPolicyData}
                            onChange={handleEditorChange}
                            modules={modules}
                            formats={formats}
                            placeholder={t("PLEASE_WRITE_SOMETHING")}
                          />
                          {isError && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <div className={Styles.AddVanBtn}>
                      <button type="submit">{t("SAVE")}</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
