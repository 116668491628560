import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";
import Styles from "../Category/category.module.css";
import FileImg from "../../Assests/Images/FileInput.png";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { CiCamera } from "react-icons/ci";
import { MdCancel } from "react-icons/md";
import { sanitizeHtmlTags } from "../../Utils/sanitizeHtmlTags";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import Loader from "../../Validation/LoadingSpinner";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AddSubCategoryPopup = ({
  show,
  handleClose,
  eventKeyValue,
  getCategoryList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState("");
  const [showImage, setShowImage] = useState("");
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  // function for apply validation in image field
  const validateImage = () => {
    if (!previewImage) {
      return `${t("IMA_IS_REQUIRED")}`;
    }
    return true;
  };

  useEffect(() => {
    reset();
    clearErrors();
    setValue("categoryName", "");
    setPreviewImage("");
    setShowImage("");
  }, [show]);

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //----- image upload-----
  function onImageUpload(e) {
    if (e.target.files.length !== 0) {
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
      setShowImage(e.target.files[0]);
    }
  }

  //----- image remove-----
  const onImageRemove = () => {
    setPreviewImage("");
    setShowImage("");
  };

  //----- function for add subcategory-----
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "category_name",
      formdata ? formdata.categoryName && formdata.categoryName.trim() : ""
    );
    requestData.append("parent_id", eventKeyValue);
    requestData.append("category_icon", showImage);
    setLoader(true);
    await SwiftVanApi.addCategory(requestData, userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      const SuccessCode = STATUS_MSG[response && response.code];
      if (response && response.code === STATUS_CODES.CATEGORY_ADD) {
        setLoader(false);
        handleClose();
        Toster(t(SuccessCode), "success");
        getCategoryList(2, eventKeyValue, "");
        setValue("categoryName", "");
        setPreviewImage("");
        setShowImage("");
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        setLoader(false);
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        }else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {loader && <Loader />}
      <Modal show={show} onHide={handleClose}>
        <div className="addVanPopupMain">
          <Modal.Header>
            <Modal.Title>{t("ADD_SUB_CATEGORY")}</Modal.Title>
            <MdCancel onClick={handleClose} />
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={Styles.addVanUploadBtn}>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("NAME")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`${t("ENTER_NAME")}`}
                        autoComplete="off"
                        {...register("categoryName", {
                          onChange: (e) => handleKeyPress(e),
                          required: {
                            value: true,
                            message: `${t("CATEGORY_NAME_REQUIRED")}`,
                          },
                          minLength: {
                            value: 2,
                            message: `${t("CATEGORY_MINLENGTH")}`,
                          },
                          maxLength: {
                            value: 50,
                            message: `${t("CATEGORY_MAXLENGTH")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      {errors.categoryName && (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {t(errors.categoryName.message)}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className={Styles.AddSubUploadDiv}>
                  <h1>{t("UPLOAD_ICON")} </h1>
                  <div className={Styles.uploadImage}>
                    <Controller
                      control={control}
                      name="categoryImage"
                      render={({ field }) => (
                        <input
                          id="uploadImage"
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={(e) => {
                            onImageUpload(e);
                            field.onChange(e);
                          }}
                        />
                      )}
                      rules={{ validate: () => validateImage() }}
                    />
                    <div className={Styles.cancelIcon}>
                      {previewImage && (
                        <MdCancel
                          onClick={(e) => {
                            onImageRemove(e);
                          }}
                        />
                      )}
                    </div>
                    {previewImage ? (
                      <div className={Styles.uploadInputImg}>
                        <img src={previewImage} />
                      </div>
                    ) : (
                      <div className={Styles.uploadInput}>
                        <img src={FileImg} alt="upload-image" />
                        <p>{t("UPLOAD_ICON_PNG")}</p>
                        <div className={Styles.uploadIcon}>
                      <label
                        htmlFor="uploadImage"
                        className={Styles.uploadbutton}
                      >
                        <CiCamera /> {t("UPLOAD")}
                      </label>
                    </div>
                      </div>
                    )}
                    
                  </div>
                  {!previewImage && errors.categoryImage && (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      {t(errors.categoryImage.message)}
                    </p>
                  )}
                </div>
              </div>

              <div className={Styles.AddVanBtn}>
                <button type="submit">{t("ADD_SUBCATEGORY")}</button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AddSubCategoryPopup;
