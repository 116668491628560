import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "../Coupon/Coupon.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Validation } from "../../Validation/Validation";
import Loader from "../../Validation/LoadingSpinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdCancel, MdOutlineDateRange } from "react-icons/md";
import { TfiMinus } from "react-icons/tfi";

const DateRagePopup = ({ dateRangeShow, DateRangeHandleClose }) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState();
  const [formatedDate, setFormatedDate] = useState("");

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {};

  // --date
  const handleDateChange = (date) => {
    const ddate = new Date(date);
    const year = ddate.getFullYear();
    const month = ddate.getMonth() + 1;
    const day = ddate.getDate();
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;
    const newDate = year + "-" + formattedMonth + "-" + formattedDay;
    setFormatedDate(newDate);
  };
  const minDate = new Date();

  return (
    <>
      <Modal show={dateRangeShow} onHide={DateRangeHandleClose}>
        <div className="CouponPopupMain">
          <div className="addVanPopupMain">
            <Modal.Header>
              <Modal.Title>{t("DATE_RANGE")} </Modal.Title>

              <MdCancel onClick={DateRangeHandleClose} />
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col style={{ marginBottom: "12px" }} lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("START_DATE")}</Form.Label>
                      <div className="dateMinDiv">
                        <DatePicker
                          selected={startDate}
                          {...register(
                            "DateRangeStartDate",
                            Validation.DateRangeStartDate
                          )}
                          onChange={(date) => {
                            handleDateChange(date);
                            setStartDate(date);
                            setValue("DateRangeStartDate", date, {
                              shouldValidate: true,
                            });
                          }}
                          minDate={minDate}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          showMonthDropdown
                          changeMonth
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          placeholderText={t("SELECT_START_DATE")}
                        />
                        {formState.errors.DateRangeStartDate && (
                          <p style={{ color: "red", marginBottom: 1 }}>
                            {t(formState.errors.DateRangeStartDate.message)}
                          </p>
                        )}
                        <MdOutlineDateRange
                          className={Styles.dateRageInputSvg}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <div className={Styles.dateRageTO}>
                    <span>
                      <TfiMinus />
                      <p>TO</p>
                      <TfiMinus />
                    </span>
                  </div>

                  <Col style={{ marginBottom: "12px" }} lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("END_DATE")}</Form.Label>
                      <div className="dateMinDiv">
                        <DatePicker
                          selected={startDate}
                          {...register(
                            "DateRangeEndDate",
                            Validation.DateRangeEndDate
                          )}
                          onChange={(date) => {
                            handleDateChange(date);
                            setStartDate(date);
                            setValue("DateRangeEndDate", date, {
                              shouldValidate: true,
                            });
                          }}
                          minDate={minDate}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          showMonthDropdown
                          changeMonth
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          placeholderText={t("SELECT_END_DATE")}
                        />
                        {formState.errors.DateRangeEndDate && (
                          <p style={{ color: "red", marginBottom: 1 }}>
                            {t(formState.errors.DateRangeEndDate.message)}
                          </p>
                        )}
                        <MdOutlineDateRange
                          className={Styles.dateRageInputSvg}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <div className={Styles.AddVanBtn}>
                  <button type="submit">{t("SUBMIT")}</button>
                </div>
              </Form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DateRagePopup;
