import styles from "../ResetPassword/ResetPassword.module.css";
import { useTranslation } from "react-i18next";
import { Container, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { useRef, useState } from "react";
import OtpInput from "react-otp-input";
import lock from "../../Assests/Images/key.png";
import Loader from "../../Validation/LoadingSpinner";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import SwiftVanApi from "../../helpers/Api";
import Toster from "../../Utils/Toster";
import { Validation } from "../../Validation/Validation";

import { useLocation, useNavigate } from "react-router-dom";

//---------function for reset password----------
function ResetPassword() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, watch, control, formState } =
    useForm();

  const userEmail = location.state;

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassShow, setConfirmPassShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("email", userEmail.email);
    requestData.append(
      "password",
      formdata.password && formdata.password.trim()
    );
    requestData.append(
      "cnf_password",
      formdata.confirmPassword && formdata.confirmPassword.trim()
    );
    requestData.append("code", formdata.otp);
    setIsLoading(true);
    await SwiftVanApi.resetPassword(requestData).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("RESET_PASSWORD_SUCCESFULLY"), "success");
        navigate("/");
      } else {
        setIsLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div className="main">
      {isLoading && <Loader />}
      <div className={styles.loginMain}>
        <Container>
          <div className={styles.formBlock}>
            <div className={styles.resetImage}>
              <img src={lock} alt="logo-image" />
            </div>
            <div className={styles.resetText}>
              <h3>{t("NEW_PASSWORD")}</h3>
            </div>

            <Form
              onSubmit={handleSubmit(onSubmit)}
              className="formSection formTopSpace"
            >
              <div className="otpInput">
                <Controller
                  name="otp"
                  type="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: `${t("OTP_REQUIRED")}`,
                    minLength: {
                      value: 4,
                      message: `${t("DIGIT_REQUIRED")}`,
                    },
                  }}
                  render={({ field }) => (
                    <OtpInput
                      inputStyle="inputStyle"
                      {...field}
                      numInputs={4}
                      isInputNum
                      shouldAutoFocus={field.value.length < 4} // Only autofocus if less than 4 digits
                      renderInput={(props, index) => (
                        <input
                          {...props}
                          // onKeyDown={(e) => {
                          //     // Allow only digits (0 to 9) and the backspace key
                          //     if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                          //         e.preventDefault();
                          //     }
                          // }}
                        />
                      )}
                      onChange={(value) => {
                        let numbers = /^[0-9]+$/;
                        if (value.match(numbers) || value === "") {
                          field.onChange(value);
                        } else {
                          return false;
                        }
                      }}
                    />
                  )}
                />
              </div>

              <div className="otpError">
                {formState.errors.otp && (
                  <span className="errorMsg">
                    {formState.errors.otp.message}
                  </span>
                )}
              </div>

              <Form.Group className="mb-3 setIconPassword">
                <Form.Label>{t("PASSWORD")}</Form.Label>
                <Form.Control
                  placeholder={`${t("PASSWORD_TEXT")}`}
                  type={passwordShow ? "text" : "password"}
                  autoComplete="new-password"
                  {...register("password", Validation.password)}
                  onChange={(e) => {
                    setValue("password", e.target.value.replace(/\s/g, ""), {
                      shouldValidate: true,
                    });
                  }}
                />

                {formState.errors.password && (
                  <p style={{ color: "red" }}>
                    {t(formState.errors.password.message)}
                  </p>
                )}

                <div className="passwordicon">
                  {passwordShow ? (
                    <FaRegEye
                      className="icon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    />
                  ) : (
                    <FaRegEyeSlash
                      className="icon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    />
                  )}
                </div>
              </Form.Group>
              <Form.Group className="mb-3 setIconPassword">
                <Form.Label>{t("CONFIRM_PASSWORD")}</Form.Label>
                <Form.Control
                  placeholder={`${t("CONFIRM_PASS_TEXT")}`}
                  type={confirmPassShow ? "text" : "password"}
                  {...register("confirmPassword", Validation.confirmPassword)}
                  onChange={(e) => {
                    setValue(
                      "confirmPassword",
                      e.target.value.replace(/\s/g, ""),
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                />

                {formState.errors.confirmPassword && (
                  <p style={{ color: "red" }}>
                    {t(formState.errors.confirmPassword.message)}
                  </p>
                )}
                <div className="passwordicon">
                  {confirmPassShow ? (
                    <FaRegEye
                      className="icon"
                      onClick={() => setConfirmPassShow(!confirmPassShow)}
                    />
                  ) : (
                    <FaRegEyeSlash
                      className="icon"
                      onClick={() => setConfirmPassShow(!confirmPassShow)}
                    />
                  )}
                </div>
              </Form.Group>
              <div className={styles.loginBtn}>
                <button type="submit">{t("SUBMIT")}</button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </div>
  );
}
export default ResetPassword;
