import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Styles from "../Category/category.module.css";
import AddSubCategoryPopup from "./AddSubCategoryPopup";
import AddItemPopup from "./AddItemPopup";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { MdCancel } from "react-icons/md";
import CategoryDeletePopup from "./categoryDeletePopup";
import "../../Assests/style/common.css";
import { FaEdit } from "react-icons/fa";
import Loader from "../../Validation/LoadingSpinner";

//---------function for category list--------
const Category = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [addItemshow, setAddItemshow] = useState(false);
  const addItemhandleClose = () => setAddItemshow(false);
  const addItemhandleShow = () => setAddItemshow(true);
  const [category, setCategory] = useState("");
  const { userToken } = useSelector((state) => state.user);
  const [eventKeyValue, setEventKeyValue] = useState(
    category && category.length > 0 ? category[0].id : null
  );
  const [subCategory, setSubCategory] = useState("");
  const [categoryItems, setCategoryItems] = useState("");
  const [name, setName] = useState();
  const [level, setLevel] = useState();
  const [categoryModal, setCategoryModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const [subEventKeyValue, setSubEventKeyValue] = useState(
    subCategory && subCategory.length > 0 ? subCategory[0].id : null
  );
  const [itemList, setItemList] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setEventKeyValue(category && category.length > 0 ? category[0].id : null);
  }, [category]);

  useEffect(() => {
    setSubEventKeyValue(
      subCategory && subCategory.length > 0 ? subCategory[0].id : null
    );
  }, [subCategory]);

  const addItemhandlePopup = (item) => {
    setItemList(item);
    addItemhandleShow();
  };

  // function for get category list api calling
  async function getCategoryList(category = 1, id = "", level = "") {
    setLoader(true);
    const details = await SwiftVanApi.getCategoryList(userToken, id, level);
    if (details && details.code === STATUS_CODES.SUCCESS) {
      if (category == 1) {
        setCategory(details && details.data);
        if (details.data.length > 0) {
          getCategoryList(2, details.data[0].id, "");
        }
      } else if (category == 2) {
        setSubCategory(details && details.data);
        if (details.data.length > 0) {
          getCategoryList(3, details.data[0].id, "");
          setName(details.data[0].name);
        }
      } else {
        setCategoryItems(details && details.data);
      }
      setLoader(false);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
      setLoader(false);
    }
  }

  // function for get category list api calling
  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <>
      {loader && <Loader />}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>
            {eventKeyValue && category ? (
              <div className="adminPageInner_heading">
                <div className="categoryMinSection">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={eventKeyValue}
                  >
                    <Row>
                      <Col lg={12} md={12} sm={12}>
                        <div className="categoryTapLeft">
                          <div className="metadataTapLeft">
                            <Nav
                              variant="pills"
                              className="flex-column"
                              onSelect={(value) => {
                                setEventKeyValue(value);
                                setCategoryItems("");
                                setName("");
                              }}
                            >
                              <div className="metaDataTitle">
                                <h1>{t("CATEGORY")}</h1>
                              </div>
                              <Row>
                                {category &&
                                  category.length > 0 &&
                                  category.map((item, index) => {
                                    return (
                                      <Col
                                        xl={4}
                                        lg={6}
                                        md={12}
                                        sm={12}
                                        key={index}
                                      >
                                        <Nav.Item
                                          key={index}
                                          onClick={() => {
                                            getCategoryList(2, item.id, "");
                                          }}
                                        >
                                          <Nav.Link eventKey={item.id}>
                                            <div
                                              style={{
                                                width: "40px",
                                                borderRadius: "50%",
                                                background: "#0094f6",
                                                marginRight: "12px",
                                              }}
                                            >
                                              <img src={item.icon} />
                                            </div>
                                            <p>{item.name}</p>
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            </Nav>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12}>
                        <Tab.Content
                          style={{ flexWrap: "wrap", overflow: "hidden" }}
                        >
                          <Tab.Pane
                            eventKey={eventKeyValue ? eventKeyValue : ""}
                          >
                            <div className={Styles.furnitureMainSection}>
                              <div className={Styles.furnitureMainTop}>
                                <h1>{t("SUB_CATEGORIES")}</h1>
                                <button
                                  onClick={() => {
                                    handleShow();
                                  }}
                                >
                                  {t("ADD_MORE")}
                                </button>
                              </div>

                              <div
                                className={`${Styles.itemScroll} ${Styles.itemScrollSpace}`}
                              >
                                <Row>
                                  {subEventKeyValue &&
                                    subCategory &&
                                    subCategory.length > 0 &&
                                    subCategory.map((item1, index) => {
                                      return (
                                        <Col
                                          xl={3}
                                          lg={4}
                                          md={6}
                                          sm={6}
                                          key={index}
                                        >
                                          <div
                                            className={`${
                                              Styles.furnitureDiv
                                            } ${
                                              item1.id === subEventKeyValue
                                                ? "activeCls"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              getCategoryList(3, item1.id, "");
                                              setName(item1.name);
                                              setSubEventKeyValue(item1.id);
                                            }}
                                          >
                                            <img src={item1.icon} />
                                            <p>
                                              {subEventKeyValue && item1.name}
                                            </p>

                                            <MdCancel
                                              className="svgCls"
                                              onClick={() => {
                                                setCategoryModal(true);
                                                setDeleteCategoryId(item1.id);
                                                setLevel(2);
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      );
                                    })}
                                </Row>
                              </div>
                            </div>

                            <div className={Styles.furnitureMainBottom}>
                              <div className={Styles.furnitureMainTop}>
                                <h1>{name}</h1>
                                <button
                                  onClick={() => {
                                    addItemhandlePopup("");
                                    setIsUpdate(false);
                                  }}
                                >
                                  {t("ADD_MORE")}
                                </button>
                              </div>
                              <div
                                className={`${Styles.itemScroll} ${Styles.itemScrollSpace}`}
                              >
                                <Row>
                                  {subEventKeyValue &&
                                    categoryItems &&
                                    categoryItems.length > 0 &&
                                    categoryItems.map((item1, index) => {
                                      return (
                                        <Col
                                          lg={3}
                                          md={6}
                                          sm={6}
                                          xs={12}
                                          key={index}
                                        >
                                          <div
                                            className={Styles.furnitureBottom}
                                          >
                                            <MdCancel
                                              onClick={() => {
                                                setCategoryModal(true);
                                                setDeleteCategoryId(item1.id);
                                                setLevel(3);
                                              }}
                                            />
                                            <div>
                                              <p>{item1.name}</p>
                                              <p>
                                                {item1.time} {t("MINS")}
                                              </p>
                                            </div>
                                            <span>
                                              <FaEdit
                                                onClick={() => {
                                                  addItemhandlePopup(item1);
                                                  setIsUpdate(true);
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </Col>
                                      );
                                    })}
                                </Row>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <AddSubCategoryPopup
                    show={show}
                    handleClose={handleClose}
                    eventKeyValue={eventKeyValue}
                    getCategoryList={getCategoryList}
                  />
                  <AddItemPopup
                    addItemshow={addItemshow}
                    addItemhandleClose={addItemhandleClose}
                    subEventKeyValue={subEventKeyValue}
                    getCategoryList={getCategoryList}
                    isUpdate={isUpdate}
                    itemList={itemList}
                  />
                  <CategoryDeletePopup
                    categoryModal={categoryModal}
                    setCategoryModal={setCategoryModal}
                    deleteCategoryId={deleteCategoryId}
                    getCategoryList={getCategoryList}
                    level={level}
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                    categoryItems={categoryItems}
                    setCategoryItems={setCategoryItems}
                    setName={setName}
                    subEventKeyValue={subEventKeyValue}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Category;
