import React, { useEffect, useState } from "react";
import styles from "./Drivers.module.css";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  acceptJob,
  fetchMasterDataList,
  getDriversLists,
  resetAllJobData,
  setActiveCategory,
  setActiveTab,
  updateIsRedirect,
} from "../../../Redux/Slices/createJob";
import { Icon } from "@iconify/react";
import { userLogoutClear } from "../../../Redux/Slices/userSlice";
import Loader from "../../../Validation/LoadingSpinner";
import Toster from "../../../Utils/Toster";
import { STATUS_CODES } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import SwiftVanApi from "../../../helpers/Api";

export default function HireDrivers() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const jobData = useSelector((state) => state.createJob);

  const { first, second, third, fourth, fifth } = useSelector(
    (state) => state.createJob.createJobData
  );
  const storedCoupon = useSelector((state) => state.createJob.selectedCoupon);
  const selectedCustomerId = useSelector(
    (state) => state.createJob.selectedCustomerId
  );
  const storedJobType = useSelector((state) => state.createJob.jobType);
  const storedCustomeTypes = useSelector(
    (state) => state.createJob.customeType
  );

  const { userToken } = useSelector((state) => state.user);
  const [driversList, setDriversList] = useState([]);
  const [filterDriversList, setFilterDriversList] = useState([]);
  const [propertTypesHide, setPropertTypesHide] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    getDriversList();
  }, []);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchKey(value);
    if (value) {
      const filterList = filterDriversList.filter(
        (val) =>
          val.first_name.toLowerCase().includes(value.toLowerCase()) ||
          val.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setDriversList(filterList);
    } else {
      setDriversList(filterDriversList);
    }
  };

  const isSendFloorType = (type) => {
    if (jobData.activeCategory == "mbk5ez") {
      if (type == "source") {
        if (
          first.propertTypesHide.includes(first.propertyType.sourceProperty.val)
        ) {
          return false;
        } else {
          return true;
        }
      }
      if (type == "destination") {
        if (
          first.propertTypesHide.includes(
            first.propertyType.destinationProperty.val
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  const getDriversList = () => {
    var mergedAllItems;

    if (third.isSubmit && second.isSubmit) {
      mergedAllItems = [...second.allItems, ...third?.customItems];
    } else if (third.isSubmit) {
      mergedAllItems = third?.customItems;
    } else if (second.isSubmit) {
      mergedAllItems = second.allItems;
    }

    const transformedData = mergedAllItems.map((obj) => ({
      category_id: obj.categoryId,
      item_id: obj.id,
      item_name: obj.name,
      quantity: obj.quantity,
      width: obj.width,
      height: obj.height,
      depth: obj.depth,
      unit_id: obj.unit.id,
      estimated_weight: obj.weight,
      estimated_weight_unit_id: obj.weightUnit.id,
      is_custom_item: obj.is_custom_item,
    }));

    const transformedStops = first.stops.map((obj) => ({
      location: obj.location,
      latitude: obj.lat,
      longitude: obj.lng,
    }));

    let requestData = new FormData();

    requestData.append("category_type_id", jobData.activeCategory);
    requestData.append("source_location", first.sourceLocation);
    requestData.append("source_latitude", first.source_latitude.toFixed(6));
    requestData.append("source_longitude", first.source_longitude.toFixed(6));
    {
      isSendFloorType("source")
        ? requestData.append("source_floor_id", first.sourceFloor.id)
        : requestData.append("source_floor_id", "");
    }
    requestData.append("is_source_lift", first.hasSourceLift);
    requestData.append("destination_location", first.destinationLocation);
    requestData.append(
      "destination_latitude",
      first.destination_latitude.toFixed(6)
    );
    requestData.append(
      "destination_longitude",
      first.destination_longitude.toFixed(6)
    );
    {
      isSendFloorType("destination")
        ? requestData.append("destination_floor_id", first.destinationFloor.id)
        : requestData.append("destination_floor_id", "");
    }
    requestData.append("is_destination_lift", first.hasDestinationLift);
    requestData.append("category_json", JSON.stringify(transformedData));
    requestData.append("add_stop", JSON.stringify(transformedStops));
    requestData.append("vehicle_type_id", fourth.selectedVehicle.id);
    requestData.append("helper_id", fifth.helper.id);
    requestData.append(
      "availability_date",
      dayjs(fifth.date).format("YYYY-MM-DD")
    );
    requestData.append(
      "availability_time",
      dayjs(fifth.time).format("HH:mm:ss")
    );
    requestData.append("is_packing", fifth.isPacking);
    requestData.append("estimated_time", fifth.estimatedJobTimeMinutes);
    requestData.append("total_distance", fifth.totalDistance);
    if (storedJobType == "customJob") {
      requestData.append("custom_job", true);
      requestData.append("amount", storedCustomeTypes.price);
      requestData.append("commission", storedCustomeTypes.commission);
    }
    storedCoupon && requestData.append("coupon_code", storedCoupon.coupon_code);
    requestData.append(
      "source_property_type_id",
      first.propertyType.sourceProperty.id
    );
    requestData.append(
      "destination_property_type_id",
      first.propertyType.destinationProperty.id
    );
    const data = { userToken, requestData };
    dispatch(getDriversLists(data)).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setDriversList(response.data);
        setFilterDriversList(response.data);
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  };

  const onBack = () => {
    navigate("/customer-list");
  };

  const viewDriverDetails = (id) => {
    // navigate(`/driver-details/${id}`);
  };

  const createJobAdmin = async (postData, token) => {
    setIsLoading(true);

    let res = SwiftVanApi.createJobAdmin(token, postData);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        navigate("/jobs");
        Toster(t("JOB_CREATE_SUCCESS"), "success");
        dispatch(resetAllJobData());
        dispatch(setActiveCategory("lejRej"));
        dispatch(setActiveTab("first"));
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  const handelAcceptJob = (amount) => {
    var mergedAllItems;

    if (third.isSubmit && second.isSubmit) {
      mergedAllItems = [...second.allItems, ...third?.customItems];
    } else if (third.isSubmit) {
      mergedAllItems = third?.customItems;
    } else if (second.isSubmit) {
      mergedAllItems = second.allItems;
    }

    const transformedData = mergedAllItems.map((obj) => ({
      category_id: obj.is_custom_item ? "" : obj.categoryId,
      item_id: obj.is_custom_item ? "" : obj.id,
      item_name: obj.name,
      quantity: obj.quantity,
      width: obj.width,
      height: obj.height,
      depth: obj.depth,
      unit_id: obj.unit.id,
      estimated_weight: obj.weight,
      estimated_weight_unit_id: obj.weightUnit.id,
      is_custom_item: obj.is_custom_item,
    }));

    const transformedStops = first.stops.map((obj) => ({
      location: obj.location,
      latitude: obj.lat,
      longitude: obj.lng,
    }));

    let requestData = new FormData();

    requestData.append("category_type_id", jobData.activeCategory);
    requestData.append("source_location", first.sourceLocation);
    requestData.append("source_latitude", first.source_latitude.toFixed(6));
    requestData.append("source_longitude", first.source_longitude.toFixed(6));
    requestData.append("source_city", first.source_city);
    requestData.append("source_postal_code", first.source_postalcode);
    {
      isSendFloorType("source")
        ? requestData.append("source_floor_id", first.sourceFloor.id)
        : requestData.append("source_floor_id", "");
    }
    requestData.append("is_source_lift", first.hasSourceLift);
    requestData.append("destination_location", first.destinationLocation);
    requestData.append(
      "destination_latitude",
      first.destination_latitude.toFixed(6)
    );
    requestData.append(
      "destination_longitude",
      first.destination_longitude.toFixed(6)
    );
    requestData.append("destination_city", first.destination_city);
    requestData.append("destination_postal_code", first.destination_postalcode);
    {
      isSendFloorType("destination")
        ? requestData.append("destination_floor_id", first.destinationFloor.id)
        : requestData.append("destination_floor_id", "");
    }
    requestData.append("is_destination_lift", first.hasDestinationLift);
    requestData.append("add_stop", JSON.stringify(transformedStops));
    requestData.append("category_json", JSON.stringify(transformedData));
    requestData.append("vehicle_type_id", fourth.selectedVehicle.id);
    requestData.append("helper_id", fifth.helper.id);
    requestData.append(
      "availability_date",
      dayjs(fifth.date).format("YYYY-MM-DD")
    );
    requestData.append(
      "availability_time",
      dayjs(fifth.time).format("HH:mm:ss")
    );
    requestData.append("estimated_time", fifth.estimatedJobTimeMinutes);
    requestData.append("is_packing", fifth.isPacking);
    requestData.append("amount", amount);
    requestData.append("customer_id", selectedCustomerId);
    requestData.append("total_distance", fifth.totalDistance);
    storedCoupon && requestData.append("coupon_code", storedCoupon.coupon_code);
    requestData.append(
      "source_property_type_id",
      first.propertyType.sourceProperty.id
    );
    requestData.append(
      "destination_property_type_id",
      first.propertyType.destinationProperty.id
    );
    requestData.append(
      "custom_job",
      storedJobType == "systemJob" ? false : true
    );
    {
      storedJobType == "customJob" &&
        requestData.append("commission", storedCustomeTypes.commission);
    }
    {
      storedJobType == "systemJob" &&
        requestData.append("driver_id", selectedIds.join(","));
    }
    {
      storedJobType == "customJob" &&
        requestData.append("multiple_driver_id", selectedIds.join(","));
    }
    createJobAdmin(requestData, userToken);
  };

  const handleDriverSelect = (id) => {
    if (storedJobType == "systemJob") {
      setSelectedIds((pre) => [id]);
      return;
    }
    if (storedJobType == "customJob") {
      const isIdExist = selectedIds.includes(id);
      if (isIdExist) {
        const removeExist = selectedIds.filter((val) => val !== id);
        setSelectedIds(removeExist);
      } else {
        setSelectedIds((pre) => [...pre, id]);
      }
    }
  };

  const handleAllClick = () => {
    // const allDriversId = driversList.map((val) => val.id);
    // setSelectedIds(allDriversId);

    // Check if all ids are currently selected
    if (selectedIds.length === driversList.length) {
      // If all are selected, clear the selection
      setSelectedIds([]);
    } else {
      // If not all are selected, select all ids
      const allDriversId = driversList.map((val) => val.id);
      setSelectedIds(allDriversId);
    }
  };

  const handleDoneClick = () => {
    if (selectedIds.length == 0) {
      Toster(t("Please select driver."), "error");
      return;
    }
    if (storedJobType == "systemJob") {
      const getDriversAmount = driversList.find(
        (val) => val.id == selectedIds.join(",")
      );
      const selectDriverAmount = getDriversAmount.amount;
      handelAcceptJob(selectDriverAmount);
    }
    if (storedJobType == "customJob") {
      const customAmount = storedCustomeTypes.price;
      handelAcceptJob(customAmount);
    }
  };

  return (
    <>
      {(jobData.isLoading || isLoading) && <Loader />}

      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="main">
                <div className="spaceTopManage">
                  <Row className={styles.topHead}>
                    <Col lg={5} md={5} sm={5} xs={3}>
                      <div className="backarrow">
                        <FiArrowLeft onClick={() => onBack()} />
                      </div>
                    </Col>
                    <Col lg={7} md={7} sm={7} xs={9}>
                      <div>
                        <span className={styles.headTitle}>
                          {t("HIRE_YOUR_DRIVER")}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {!jobData.isLoading && (
                    <div className={styles.topContainer}>
                      <div className={styles.subContainer}>
                        <div className={styles.topSubHeadDesc}>
                          {driversList.length !== 0 && t("SELECT_DRIVER_TEXT")}
                        </div>
                        <Row>
                          <Col lg={6} md={6} sm={6} xs={8}>
                            <Form.Group className="mb-3">
                              <Form.Control
                                type="search"
                                placeholder={t("Search")}
                                value={searchKey}
                                onChange={(e) => handleSearchChange(e)}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={6} xs={4}>
                            <div className={styles.searchSection}>
                              {!jobData.isLoading &&
                                driversList.length !== 0 &&
                                storedJobType == "customJob" && (
                                  <Button onClick={() => handleAllClick()}>
                                    Select All
                                  </Button>
                                )}
                              {!jobData.isLoading &&
                                driversList.length !== 0 && (
                                  <Button onClick={() => handleDoneClick()}>
                                    Done
                                  </Button>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {driversList &&
                            driversList.map((driverInfo) => {
                              return (
                                <Col lg={3} md={4} sm={6} className="mb-3">
                                  <div
                                    className={styles.topDriverCard}
                                    onClick={() =>
                                      handleDriverSelect(driverInfo.id)
                                    }
                                    style={{
                                      border: selectedIds.includes(
                                        driverInfo.id
                                      )
                                        ? "2px solid #0094F6"
                                        : "",
                                    }}
                                  >
                                    <div className={styles.driverRating}>
                                      <div className={styles.driverBooked}>
                                        <div
                                          className={styles.driverReviewRating}
                                        >
                                          {driverInfo.jobs_booked}
                                        </div>
                                        <div
                                          className={styles.driverReviewCount}
                                        >
                                          {driverInfo.jobs_booked > 1
                                            ? t("JOBS_BOOKED")
                                            : t("JOB_BOOKED")}
                                        </div>
                                      </div>
                                      <div
                                        className={styles.driverReviewContainer}
                                      >
                                        <div
                                          className={styles.driverReviewRating}
                                        >
                                          <div>
                                            {driverInfo.rating.average_rating?.toFixed(
                                              1
                                            )}
                                          </div>
                                          <div>
                                            <Icon
                                              icon="material-symbols:star"
                                              color="#0F7DFF"
                                              width="22"
                                              height="22"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className={styles.driverReviewCount}
                                        >
                                          {driverInfo.rating.rating_count}{" "}
                                          {driverInfo.rating.rating_count > 1
                                            ? t("REVIEWS")
                                            : t("REVIEW")}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={styles.driverCard}
                                      onClick={() =>
                                        viewDriverDetails(driverInfo.id)
                                      }
                                    >
                                      <div>
                                        <img
                                          src={driverInfo.image}
                                          className={styles.driverImage}
                                          alt="driver-image"
                                        />
                                      </div>
                                      <div className={styles.driverName}>
                                        {driverInfo.first_name}{" "}
                                        {driverInfo.last_name}
                                      </div>
                                      <div className={styles.vanType}>
                                        {driverInfo.vehicle_name}
                                      </div>
                                      {driverInfo.discount !== 0 && (
                                        <div
                                          className={styles.driverPriceCancel}
                                        >
                                          {driverInfo.currency_symbol}{" "}
                                          <strike>{driverInfo.amount}</strike>
                                        </div>
                                      )}
                                      <div className={styles.driverPrice}>
                                        {driverInfo.currency_symbol}{" "}
                                        {/* {storedJobType == "systemJob"
                                          ? driverInfo.total_amount.toFixed(2)
                                          : Number(
                                              storedCustomeTypes.price
                                            ).toFixed(2)} */}
                                        {driverInfo.total_amount.toFixed(2)}
                                      </div>
                                      <div className={styles.driverExtraHours}>
                                        For the first{" "}
                                        <strong>
                                          {fifth.estimatedJobTime.hour}:
                                          {Number(
                                            fifth.estimatedJobTime.min
                                          ).toFixed()}{" "}
                                          hours
                                        </strong>{" "}
                                        and then{" "}
                                        <strong>
                                          {driverInfo.currency_symbol}
                                          {driverInfo.extra_per_hours_price}
                                        </strong>{" "}
                                        per hour
                                      </div>
                                    </div>
                                    {/* <div className={styles.driverAccept}>
                                      <Button
                                        onClick={() =>
                                          handelAcceptJob(
                                            driverInfo.id,
                                            driverInfo.amount
                                          )
                                        }
                                      >
                                        {t("ACCEPT")}
                                      </Button>
                                    </div> */}
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                        {!jobData.isLoading && driversList.length == 0 && (
                          <Row>
                            <div className={styles.notFoundContainer}>
                              <div className={styles.notFoundIcon}>
                                <Icon
                                  icon="lucide:search-x"
                                  color="grey"
                                  width="100"
                                  height="100"
                                />
                              </div>
                              <div className={styles.notFoundMessage}>
                                {t("DRIVERS_NOT_FOUND")}
                              </div>
                            </div>
                          </Row>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
