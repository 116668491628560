import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import styles from "./jobSummary.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MapView from "./MapView";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FcNext, FcPrevious } from "react-icons/fc";
import {
  setActiveTab,
  updateIsBackToFifth,
  updateIsRedirect,
  updateSelectedCoupon,
  updateJobType,
  updateCustomeTypes,
} from "../../Redux/Slices/createJob";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import Toster from "../../Utils/Toster";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
// import AddCoupon from "./Modals/addCoupon";

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <>
      <FcPrevious
        className={`${styles.PrevSlickArrow} slick-arrow`}
        onClick={onClick}
        style={{
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <>
      <FcNext
        className={`${styles.NextSlickArrow} slick-arrow`}
        onClick={onClick}
        style={{
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}
function PrevArrowCustom(props) {
  const { onClick } = props;
  return (
    <>
      <FcPrevious
        className={`${styles.PrevSlickArrowCustom} slick-arrow`}
        onClick={onClick}
        style={{
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}

function NextArrowCustom(props) {
  const { onClick } = props;
  return (
    <>
      <FcNext
        className={`${styles.NextSlickArrowCustom} slick-arrow`}
        onClick={onClick}
        style={{
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}

function JobSummary() {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrowCustom />,
    prevArrow: <PrevArrowCustom />,
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { first, second, third, fourth, fifth } = useSelector(
    (state) => state.createJob.createJobData
  );
  const { activeCategory, categoryList } = useSelector(
    (state) => state.createJob
  );

  const storedCoupon = useSelector((state) => state.createJob.selectedCoupon);
  const storedJobType = useSelector((state) => state.createJob.jobType);
  const storedCustomTypes = useSelector((state) => state.createJob.customeType);
  const { userToken } = useSelector((state) => state.user);
  const [jobType, setJobType] = useState("systemJob");
  const [price, setPrice] = useState();
  const [commission, setCommission] = useState();
  const [driverEarning, setDriverEarning] = useState(0);

  useEffect(() => {
    if (storedCoupon) {
      setSelectedCoupon(storedCoupon);
    }
    if (storedJobType) {
      setJobType(storedJobType);
    }
    if (storedJobType == "customJob") {
      setPrice(storedCustomTypes.price);
      setCommission(storedCustomTypes.commission);
    }
  }, []);

  useEffect(() => {
    if (price != null && commission != null) {
      if (price == "" || commission == "") {
        setDriverEarning(0);
        return;
      }
      const calculatedEarning = price - price * (commission / 100);
      setDriverEarning(calculatedEarning);
    }
  }, [price, commission]);

  const [showCoupon, setShowCoupon] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState();

  const onBack = () => {
    dispatch(updateIsBackToFifth(true));
    navigate("/create-job", { state: { sureToClear: false } });
  };

  const getCustomersList = () => {
    if (!jobType) {
      Toster("Please select job type.", "error");
      return;
    }
    if (jobType == "customJob") {
      if (!price || !commission) {
        Toster("Please enter price & commission.", "error");
        return;
      }
    }
    dispatch(updateJobType(jobType));
    dispatch(
      updateCustomeTypes({
        price,
        commission,
      })
    );
    navigate("/customer-list");
  };

  const handleEditJob = () => {
    dispatch(setActiveTab("first"));
    dispatch(updateIsBackToFifth(true));
    navigate("/create-job", { state: { sureToClear: false } });
  };

  const handleShowCoupon = () => {
    setShowCoupon(true);
  };

  const handleRemoveCoupon = () => {
    setSelectedCoupon();
  };

  const getCategoryName = () => {
    const categoryName = categoryList?.find(
      (val) => val?.id === activeCategory
    );
    return categoryName?.name;
  };

  const handleJobTypeChange = (event) => {
    setJobType(event.target.value);
  };

  const handleChangeCustom = (event, type) => {
    const value = event.target.value;
    if (
      type == "price" &&
      (value.startsWith(0) || value.startsWith(" ") || isNaN(value))
    ) {
      setPrice("");
      return;
    }
    if (
      type == "commission" &&
      (value.startsWith(0) || value.startsWith(" ") || isNaN(value))
    ) {
      setCommission("");
      return;
    }

    if (!isNaN(value)) {
      if (type == "price") {
        const numericValue = Number(value);
        if (value.includes(".")) {
          const decimalPlaces = value.split(".")[1].length;
          if (decimalPlaces > 2) {
            return;
          }
        }
        if (
          (numericValue >= 1 && numericValue <= 10000000) ||
          numericValue == ""
        ) {
          setPrice(value);
        }
      }
      if (type == "commission") {
        const numericValue = Number(value);
        if (value.includes(".")) {
          const decimalPlaces = value.split(".")[1].length;
          if (decimalPlaces > 2) {
            return;
          }
        }
        if ((numericValue >= 1 && numericValue <= 99) || numericValue == "") {
          setCommission(value);
        }
      }
    }
  };

  function formatJobTime(hours, minutes) {
    // Ensure hours and minutes are integers
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes == "" ? 0 : minutes, 10);

    // Calculate total minutes
    const totalMinutes = hours * 60 + minutes;

    // Calculate new hours and minutes
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;

    // Build the result string
    let result = "";
    if (newHours > 0) {
      result += newHours + (newHours > 1 ? " hours " : " hour ");
    }
    if (newMinutes > 0 || newHours === 0) {
      result += newMinutes + " min";
    }

    // Handle edge case for zero minutes
    if (newHours === 0 && newMinutes === 0) {
      result = "0 min";
    }

    return result;
  }

  return (
    <>
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="spaceTopManage">
                <div className={styles.topHeadSummmary}>
                  <div className={styles.topHeadBackBtn}>
                    <FiArrowLeft onClick={() => onBack()} />
                  </div>
                  <div>
                    <span className={styles.topTitle}>{t("JOB_SUMMARY")}</span>
                  </div>
                  <div></div>
                </div>
                <div className={styles.mainContainerWrapper}>
                  <div className={styles.mainContainer}>
                    <Row>
                      <Col lg={5}>
                        <div className={styles.subContainer}>
                          <div>
                            <MapView />
                          </div>
                          <div className={styles.titleName}>{t("ADDRESS")}</div>
                          <div className={styles.addressRouteWrapper}>
                            <div className={styles.routeLinePath}>
                              <div>
                                <Icon
                                  icon="fluent:location-arrow-up-16-regular"
                                  height="22"
                                  color="#828282"
                                />
                              </div>
                              <div className={styles.leftRouteLine}></div>
                              <div>
                                <Icon
                                  icon="fluent:location-arrow-up-16-regular"
                                  vFlip
                                  height="22"
                                  color="#828282"
                                />
                              </div>
                            </div>
                            <div className={styles.subLocationContainer}>
                              <div className={styles.subTitleName}>
                                {t("COLLECT_ADDRESS")}
                              </div>
                              <div className={styles.titleDescription}>
                                {first.sourceLocation}
                              </div>
                              <div className={styles.floorSection}>
                                {activeCategory == "mbk5ez" && (
                                  <div>
                                    <span className={styles.floorSectionTitle}>
                                      {t("Property")} :{" "}
                                    </span>
                                    <span className={styles.floorValue}>
                                      {first.propertyType.sourceProperty.val}
                                    </span>{" "}
                                  </div>
                                )}
                                {activeCategory !== "mbk5ez" && (
                                  <div>
                                    <span className={styles.floorSectionTitle}>
                                      {t("FLOOR")} :{" "}
                                    </span>
                                    <span className={styles.floorValue}>
                                      {first.sourceFloor?.val}
                                    </span>
                                  </div>
                                )}
                                {activeCategory == "mbk5ez" &&
                                  !first.propertTypesHide.includes(
                                    first.propertyType.sourceProperty.val
                                  ) && (
                                    <div>
                                      <span
                                        className={styles.floorSectionTitle}
                                      >
                                        {t("FLOOR")} :{" "}
                                      </span>
                                      <span className={styles.floorValue}>
                                        {first.sourceFloor?.val}
                                      </span>
                                    </div>
                                  )}
                                {!first.propertTypesHide.includes(
                                  first.propertyType.sourceProperty.val
                                ) && (
                                  <div>
                                    <span className={styles.floorSectionTitle}>
                                      {t("LIFT_AVAILABLE")} :
                                    </span>
                                    {first.hasSourceLift ? (
                                      <span className={styles.liftCheckYes}>
                                        {" "}
                                        {t("YES")}
                                      </span>
                                    ) : (
                                      <span className={styles.liftCheckNo}>
                                        {" "}
                                        {t("NO")}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className={styles.hrLineDraw}></div>
                              {first.stops.length !== 0 &&
                                first.stops.map((stop, ind) => {
                                  return (
                                    <>
                                      <div className={styles.stopTitle}>
                                        {t("VIA")}
                                        {first.stops.length > 1 ? ind + 1 : ""}
                                      </div>
                                      <div className={styles.titleDescription}>
                                        {stop.location}
                                      </div>
                                      <div className={styles.hrLineStops}></div>
                                    </>
                                  );
                                })}
                              <div className={styles.subTitleName}>
                                {t("DELIVER_ADDRESS")}
                              </div>
                              <div className={styles.titleDescription}>
                                {first.destinationLocation}
                              </div>
                              {/* <div className={styles.floorSection}>
                                <div>
                                  <span className={styles.floorSectionTitle}>
                                    {t("FLOOR")} :{" "}
                                  </span>
                                  <span className={styles.floorValue}>
                                    {first.destinationFloor.val}
                                  </span>
                                </div>
                                <div>
                                  <span className={styles.floorSectionTitle}>
                                    {t("LIFT_AVAILABLE")} :
                                  </span>
                                  <span className={styles.liftCheck}>
                                    {" "}
                                    {first.hasDestinationLift ? "Yes" : "No"}
                                  </span>
                                </div>
                              </div> */}
                              <div className={styles.floorSection}>
                                {activeCategory == "mbk5ez" && (
                                  <div>
                                    <span className={styles.floorSectionTitle}>
                                      {t("Property")} :{" "}
                                    </span>
                                    <span className={styles.floorValue}>
                                      {
                                        first.propertyType.destinationProperty
                                          .val
                                      }
                                    </span>{" "}
                                  </div>
                                )}
                                {activeCategory !== "mbk5ez" && (
                                  <div>
                                    <span className={styles.floorSectionTitle}>
                                      {t("FLOOR")} :{" "}
                                    </span>
                                    <span className={styles.floorValue}>
                                      {first.destinationFloor?.val}
                                    </span>
                                  </div>
                                )}
                                {activeCategory == "mbk5ez" &&
                                  !first.propertTypesHide.includes(
                                    first.propertyType.destinationProperty.val
                                  ) && (
                                    <div>
                                      <span
                                        className={styles.floorSectionTitle}
                                      >
                                        {t("FLOOR")} :{" "}
                                      </span>
                                      <span className={styles.floorValue}>
                                        {first.destinationFloor?.val}
                                      </span>
                                    </div>
                                  )}
                                {!first.propertTypesHide.includes(
                                  first.propertyType.destinationProperty.val
                                ) && (
                                  <div>
                                    <span className={styles.floorSectionTitle}>
                                      {t("LIFT_AVAILABLE")} :
                                    </span>
                                    {first.hasDestinationLift ? (
                                      <span className={styles.liftCheckYes}>
                                        {" "}
                                        {t("YES")}
                                      </span>
                                    ) : (
                                      <span className={styles.liftCheckNo}>
                                        {" "}
                                        {t("NO")}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={styles.hrLineDraw}></div>
                          <div className={styles.totalMileage}>
                            {t("TOTAL_MILEAGE")} :&nbsp;
                            <span>{`${Number(fifth.totalDistance).toFixed(
                              2
                            )} miles`}</span>
                          </div>
                        </div>
                      </Col>
                      <Col lg={7}>
                        {second?.allItems?.length > 1 && (
                          <Slider {...settings}>
                            {second.allItems.map((items, ind, arr) => {
                              return (
                                <div
                                  className={styles.furnitureContainerSlider}
                                >
                                  <div className={styles.itemsSection}>
                                    <div className={styles.titleName}>
                                      {t("FURNITURE_AND_OTHER")}
                                    </div>
                                    <div>
                                      <span>{ind + 1}</span>
                                      <span className={styles.tabCount}>
                                        /{arr.length}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles.itemsSection}>
                                    <div className={styles.furnitureName}>
                                      {items.title}
                                    </div>
                                    <div className={styles.rightQuantity}>
                                      <span className={styles.tabCount}>
                                        Quantity :{" "}
                                      </span>
                                      {items.quantity}
                                    </div>
                                  </div>
                                  <div className={styles.subTitleName}>
                                    {items.name}
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        )}
                        {second?.allItems?.length == 1 &&
                          second.allItems.map((items, ind, arr) => {
                            return (
                              <div
                                className={
                                  styles.singleFurnitureContainerSlider
                                }
                              >
                                <div className={styles.itemsSection}>
                                  <div className={styles.titleName}>
                                    {t("FURNITURE_AND_OTHER")}
                                  </div>
                                  <div>
                                    <span>{ind + 1}</span>
                                    <span className={styles.tabCount}>
                                      /{arr.length}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.itemsSection}>
                                  <div className={styles.furnitureName}>
                                    {items.title}
                                  </div>
                                  <div>
                                    <span className={styles.tabCount}>
                                      Quantity :{" "}
                                    </span>
                                    {items.quantity}
                                  </div>
                                </div>
                                <div className={styles.subTitleName}>
                                  {items.name}
                                </div>
                              </div>
                            );
                          })}
                        {third?.customItems?.length !== 0 &&
                          third?.customItems && (
                            <div className={styles.ownTitleHeading}>
                              Own Item
                            </div>
                          )}
                        {third?.customItems?.length == 1 &&
                          third.customItems.map((items, ind, arr) => {
                            return (
                              <>
                                <div
                                  className={
                                    styles.singleFurnitureContainerSlider
                                  }
                                >
                                  <div className={styles.itemsSection}>
                                    <div className={styles.titleName}>
                                      {items.name}
                                    </div>
                                    <div>
                                      <span>{ind + 1}</span>
                                      <span className={styles.tabCount}>
                                        /{arr.length}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles.itemsSection}>
                                    <div className={styles.furnitureName}>
                                      {items.title}
                                    </div>
                                  </div>
                                  <div className={styles.ownQuantity}>
                                    <span className={styles.tabCount}>
                                      Quantity :{" "}
                                    </span>
                                    {items.quantity}
                                  </div>
                                  <hr />
                                  <div className={styles.titleNameDimension}>
                                    {t("ESTIMATED_DIMENSIONS")}
                                  </div>
                                  {/* <div className={styles.itemsSection}>
                                    <div className={styles.itemsSection}>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Width :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.width
                                            ? items.width + " " + items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={styles.verticalDivider}
                                    ></div>
                                    <div className={styles.itemsSubSection}>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Height :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.height
                                            ? items.height +
                                              " " +
                                              items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={styles.verticalDivider}
                                    ></div>
                                    <div className={styles.itemsSubSection}>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Depth :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.depth
                                            ? items.depth + " " + items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={styles.verticalDivider}
                                    ></div>
                                    <div className={styles.itemsSubSection}>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Weight :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.weight
                                            ? items.weight +
                                              " " +
                                              items.weightUnit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className={styles.topDimensionWrapper}>
                                    <div className={styles.innerSection}>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Width :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.width
                                            ? items.width + " " + items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Depth :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.depth
                                            ? items.depth + " " + items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                    <div className={styles.innerSection}>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Height :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.height
                                            ? items.height +
                                              " " +
                                              items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Weight :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.weight
                                            ? items.weight +
                                              " " +
                                              items.weightUnit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        {third?.customItems?.length > 1 && (
                          <Slider {...settings2}>
                            {third?.customItems?.map((items, ind, arr) => {
                              return (
                                <>
                                  <div
                                    className={styles.furnitureContainerSlider}
                                  >
                                    <div className={styles.itemsSection}>
                                      <div className={styles.titleName}>
                                        {items.name}
                                      </div>
                                      <div>
                                        <span>{ind + 1}</span>
                                        <span className={styles.tabCount}>
                                          /{arr.length}
                                        </span>
                                      </div>
                                    </div>
                                    <div className={styles.ownQuantity}>
                                      <span className={styles.tabCount}>
                                        Quantity :{" "}
                                      </span>
                                      {items.quantity}
                                    </div>
                                    <hr />
                                    <div className={styles.titleName}>
                                      {t("ESTIMATED_DIMENSIONS")}
                                    </div>
                                    {/* <div className={styles.itemsSection}>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Width :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.width
                                            ? items.width + " " + items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Height :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.height
                                            ? items.height +
                                              " " +
                                              items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Depth :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.depth
                                            ? items.depth + " " + items.unit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                      <div>
                                        <span className={styles.dimensionType}>
                                          Weight :{" "}
                                        </span>
                                        <span className={styles.dimensionValue}>
                                          {items.weight
                                            ? items.weight +
                                              " " +
                                              items.weightUnit.val
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div> */}
                                    <div className={styles.topDimensionWrapper}>
                                      <div className={styles.innerSection}>
                                        <div>
                                          <span
                                            className={styles.dimensionType}
                                          >
                                            Width :{" "}
                                          </span>
                                          <span
                                            className={styles.dimensionValue}
                                          >
                                            {items.width
                                              ? items.width +
                                                " " +
                                                items.unit.val
                                              : "NA"}
                                          </span>
                                        </div>
                                        <div>
                                          <span
                                            className={styles.dimensionType}
                                          >
                                            Depth :{" "}
                                          </span>
                                          <span
                                            className={styles.dimensionValue}
                                          >
                                            {items.depth
                                              ? items.depth +
                                                " " +
                                                items.unit.val
                                              : "NA"}
                                          </span>
                                        </div>
                                      </div>
                                      <div className={styles.innerSection}>
                                        <div>
                                          <span
                                            className={styles.dimensionType}
                                          >
                                            Height :{" "}
                                          </span>
                                          <span
                                            className={styles.dimensionValue}
                                          >
                                            {items.height
                                              ? items.height +
                                                " " +
                                                items.unit.val
                                              : "NA"}
                                          </span>
                                        </div>
                                        <div>
                                          <span
                                            className={styles.dimensionType}
                                          >
                                            Weight :{" "}
                                          </span>
                                          <span
                                            className={styles.dimensionValue}
                                          >
                                            {items.weight
                                              ? items.weight +
                                                " " +
                                                items.weightUnit.val
                                              : "NA"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </Slider>
                        )}

                        <div className={styles.titleHeading}>
                          {t("VEHICLE_TYPE")}
                        </div>
                        <div className={styles.furnitureContainer}>
                          <div className={styles.vehicleType}>
                            <div>
                              <div className={styles.subTitleName}>
                                {fourth.selectedVehicle?.vehicle_name}
                              </div>
                              <div className={styles.titleDescription}>
                                {fourth.selectedVehicle?.vehicle_description}
                              </div>
                            </div>
                            <div>
                              <img
                                src={fourth.selectedVehicle?.vehicle_image}
                                alt="lutonVan"
                                className={styles.vehicleImage}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.titleHeading}>
                          {t("HELPER_AVAILABILITY")}
                        </div>
                        <div className={styles.furnitureContainer}>
                          <div className={styles.vehicleType}>
                            <div>
                              <div className={styles.subTitleName}>
                                {t("HELPER")}
                              </div>
                            </div>
                            <div className={styles.isHelper}>
                              <div className={styles.availabilityDay}>
                                {fifth.helper.val}
                              </div>
                              {/* <hr className={styles.verticalDivider} />
                        <div>
                          <span className={styles.tabCount}>Floor :</span> 03
                        </div> */}
                            </div>
                          </div>
                        </div>
                        <div className={styles.availabilityContainer}>
                          <div className={styles.vehicleType}>
                            <div>
                              <div className={styles.subTitleName}>
                                {t("TIME_OF_JOB")}
                              </div>
                            </div>
                            <div className={styles.availabilityDay}>
                              {dayjs(fifth.date).format("DD MMM YYYY")},{" "}
                              {dayjs(fifth.time).format("h:mmA")}
                            </div>
                          </div>
                        </div>
                        <div className={styles.estimatedTime}>
                          <div style={{ paddingTop: "0px" }}>
                            {t("ESTIMATED_TIME_JOB")} -{" "}
                            <span>
                              {formatJobTime(
                                fifth.estimatedJobTime.hour,
                                fifth.estimatedJobTime.min
                              )}
                            </span>
                          </div>
                        </div>
                        <div className={styles.titleHeading}>
                          {t("JOB_TYPE")}
                        </div>
                        <div className={styles.jobTypeRadio}>
                          <div className={styles.jobRadioBtn}>
                            <input
                              type="radio"
                              id="systemJob"
                              name="jobType"
                              value="systemJob"
                              checked={jobType === "systemJob"}
                              onChange={handleJobTypeChange}
                            />
                            <label htmlFor="systemJob">System Job</label>
                          </div>
                          <div className={styles.jobRadioBtn}>
                            <input
                              type="radio"
                              id="customJob"
                              name="jobType"
                              value="customJob"
                              checked={jobType === "customJob"}
                              onChange={handleJobTypeChange}
                            />
                            <label htmlFor="customJob">Custom Job</label>
                          </div>
                        </div>
                        {jobType == "customJob" && (
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="popupInput">
                                <Form.Label>
                                  {t("Customer price (£)")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="email"
                                  value={price}
                                  onChange={(e) =>
                                    handleChangeCustom(e, "price")
                                  }
                                  placeholder={t("Enter price")}
                                  autoComplete="off"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="popupInput">
                                <Form.Label>{t("Commission (%)")}</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={`${t("Enter commission")}`}
                                  value={commission}
                                  onChange={(e) =>
                                    handleChangeCustom(e, "commission")
                                  }
                                  autoComplete="off"
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <div className={styles.driverEarning}>
                                {t("Driver Earning")} :&nbsp;
                                <span>£ {driverEarning.toFixed(2)}</span>
                              </div>
                            </Col>
                          </Row>
                        )}
                        {/* <Row className={styles.couponSection}>
                          <Col>
                            <div className={styles.titleHeading}>
                              {t("COUPON_CODE")}
                            </div>
                            {!selectedCoupon ? (
                              <Form.Group className="formOrderSection fifthTab ">
                                <span onClick={() => handleShowCoupon()}>
                                  <p style={{ backgroundColor: "white" }}>
                                    <>{t("SELECT_COUPON_CODE")}</>
                                  </p>
                                  <Icon
                                    icon="bxs:up-arrow"
                                    color="rgba(130, 130, 130, 0.8509803921568627)"
                                    width="22"
                                    height="22"
                                    vFlip={true}
                                  />
                                </span>
                              </Form.Group>
                            ) : (
                              <div className={styles.selectedCouponView}>
                                <div className={styles.selectedCode}>
                                  {selectedCoupon.coupon_code}
                                </div>
                                <div className={styles.selectedCoupon}>
                                  <div className={styles.selectedPercent}>
                                    {selectedCoupon.value}%
                                  </div>
                                  <div>
                                    <Icon
                                      icon="ic:round-cancel"
                                      color="#626262"
                                      width="22"
                                      height="22"
                                      onClick={() => {
                                        handleRemoveCoupon();
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Col>
                        </Row> */}
                        <div>
                          <Row>
                            <Col lg={6} className="mb-3">
                              <Button
                                className={styles.bottomBtnEdit}
                                onClick={() => handleEditJob()}
                              >
                                {t("EDIT_JOB")}
                              </Button>
                            </Col>
                            <Col lg={6} className="mb-3">
                              <Button
                                className={styles.bottomBtnPrice}
                                onClick={() => getCustomersList()}
                              >
                                {t("Get customer lists")}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {/* <div className={styles.chatFeature}> */}
                    {/* <img src={chatIcon} alt="chat-image" /> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* <AddCoupon
        showCoupon={showCoupon}
        setShowCoupon={setShowCoupon}
        setSelectedCoupon={setSelectedCoupon}
      /> */}
    </>
  );
}
export default JobSummary;
