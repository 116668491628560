import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Styles from "../Livechat/Livechat.module.css";
import { Livechatcard } from "../../mockData";
// import usertable from "../../Assests/Images/Livechat1.png";
import ProfileImg from "../../Assests/Images/liveChatImg1.png";
import Form from "react-bootstrap/Form";
import Footer from "../Footer/Footer";

const Livechat = () => {
  const { t } = useTranslation();
  return (
    <div className="adminPageWrapper">
      <div className="adminPageInner">
        <div className="adminPageInner_sidebar">
          <Sidebar />
        </div>
        <div className="adminPageInner_rightside">
          <div className="headersection">
            <Header />
          </div>

          <div className="adminPageInner_heading">
            <div className="liveChatMainSection">
              {/*---search-section---*/}
              <div className="topSearchSection">
                <div className="topSearchSectionInner">
                  <h1>{t("LIVE_CHAT_TITLE")}</h1>
                </div>
              </div>

              <Row>
                <Col lg={4}>
                  <div className={Styles.livechatLeft}>
                    {Livechatcard.map((item, index) => {
                      return (
                        <div className={Styles.livechatCard} key={index}>
                          <div className={Styles.livechatCardLeft}>
                            <span>
                              <img src={item.Img} />

                              {item.StatusImg ? (
                                <h4>
                                  <img src={item.StatusImg} />
                                </h4>
                              ) : (
                                " "
                              )}
                            </span>
                            <div className={Styles.livechatLeftText}>
                              <h5>{item.Name}</h5>
                              <p>{item.Desc}</p>
                            </div>
                          </div>
                          <div className={Styles.livechatCardRight}>
                            <p>{item.time}</p>

                            {item.unread_msg !== 0 ? (
                              <span>{item.unread_msg}</span>
                            ) : (
                              " "
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="livechatRight">
                    <div className={Styles.livechatRightHeader}>
                      <div className={Styles.livechatRightHeaderInner}>
                        <span>
                          <img src={ProfileImg} />
                        </span>
                        <div className={Styles.livechatHeadeText}>
                          <h1>Toby Maghawai</h1>
                          <p>Online</p>
                        </div>
                      </div>
                    </div>

                    <div className={Styles.allChatBox}>
                      <div className={Styles.chatMessageLeft}>
                        <h2>Hello how are you? how may help you?</h2>
                        <p>03:25 PM</p>
                      </div>

                      <div className={Styles.chatMessageRight}>
                        <h2>
                          Hi Lorem ispum dollar sit amet simply dummy text
                        </h2>
                        <p>03:32 PM</p>
                      </div>

                      <div className={Styles.chatMessageLeft}>
                        <h2>Lorem ispum dollar dummy text</h2>
                        <p>03:25 PM</p>
                      </div>

                      <div className={Styles.chatMessageRight}>
                        <h2>
                          Hi Lorem ispum dollar sit amet simply text I am good
                          what about you
                        </h2>
                        <p>03:32 PM </p>
                      </div>

                      <div className={Styles.chatMessageLeft}>
                        <h2>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's 1500s,
                        </h2>
                        <p>03:25 PM</p>
                      </div>

                      <div className={Styles.chatMessageRight}>
                        <h2>
                          Hi Lorem ispum dollar sit amet simply dummy text
                        </h2>
                        <p>03:32 PM</p>
                      </div>

                      <div className={Styles.chatMessageLeft}>
                        <h2>Lorem ispum dollar dummy text</h2>
                        <p>03:25 PM</p>
                      </div>

                      <div className={Styles.chatMessageRight}>
                        <h2>
                          Hi Lorem ispum dollar sit amet simply text I am good
                          what about you
                        </h2>
                        <p>03:32 PM </p>
                      </div>

                      <div className={Styles.chatMessageLeft}>
                        <h2>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's 1500s,
                        </h2>
                        <p>03:25 PM</p>
                      </div>
                      <div className={Styles.chatMessageRight}>
                        <h2>
                          Hi Lorem ispum dollar sit amet simply dummy text
                        </h2>
                        <p>03:32 PM</p>
                      </div>

                      <div className={Styles.chatMessageLeft}>
                        <h2>Lorem ispum dollar dummy text</h2>
                        <p>03:25 PM</p>
                      </div>

                      <div className={Styles.chatMessageRight}>
                        <h2>
                          Hi Lorem ispum dollar sit amet simply text I am good
                          what about you
                        </h2>
                        <p>03:32 PM </p>
                      </div>

                      <div className={Styles.chatMessageLeft}>
                        <h2>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's 1500s,
                        </h2>
                        <p>03:25 PM</p>
                      </div>
                    </div>

                    <div className={Styles.sendMessageInput}>
                      <div className={Styles.sendMessageInputInner}>
                        <Form.Control
                          placeholder={`${t("TYPE_HERE")}`}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                        <div className={Styles.sendMessageSendBox}>
                          <h5>
                            <svg
                              width="11"
                              height="19"
                              viewBox="0 0 11 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.09457 14.0946L1.09396 5.78473C1.09063 5.195 1.20392 4.61043 1.4273 4.06464C1.65068 3.51884 1.97976 3.02259 2.3956 2.60443C2.81144 2.18626 3.30585 1.85442 3.85039 1.628C4.39492 1.40157 4.97885 1.28503 5.56859 1.28507C6.15832 1.28511 6.74224 1.40173 7.28674 1.62823C7.83125 1.85472 8.32561 2.18663 8.7414 2.60485C9.15718 3.02308 9.48619 3.51937 9.7095 4.06519C9.93281 4.61102 10.046 5.1956 10.0426 5.78533L10.0432 15.3728C10.0432 16.1639 9.72892 16.9227 9.16948 17.4821C8.61005 18.0416 7.85129 18.3559 7.06013 18.3559C6.26896 18.3559 5.51021 18.0416 4.95077 17.4821C4.39133 16.9227 4.07704 16.1639 4.07704 15.3728L4.07765 5.78412C4.08452 5.39311 4.2447 5.02044 4.52367 4.74638C4.80265 4.47232 5.17811 4.3188 5.56918 4.31888C5.96026 4.31896 6.33565 4.47263 6.61452 4.7468C6.89338 5.02098 7.05341 5.39371 7.06013 5.78473L7.05952 14.0946"
                                stroke="#828282"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </h5>
                          <span>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.5593 1.86951C13.8813 0.978719 13.0181 0.115507 12.1273 0.43828L1.24027 4.37566C0.3465 4.69918 0.238412 5.9187 1.06062 6.39504L4.53583 8.40696L7.63907 5.30372C7.77966 5.16794 7.96796 5.0928 8.16341 5.0945C8.35886 5.0962 8.54583 5.17459 8.68404 5.3128C8.82224 5.45101 8.90064 5.63798 8.90234 5.83343C8.90404 6.02888 8.8289 6.21717 8.69312 6.35776L5.58988 9.461L7.60255 12.9362C8.07813 13.7584 9.29766 13.6496 9.62118 12.7566L13.5593 1.86951Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Livechat;
