import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SwiftVanApi from "../../helpers/Api";

const initialState = {
  activeTab: "first",
  activeCategory: "",
  isLoading: false,
  categoryList: [],
  floorOptions: [],
  helpersList: [],
  unitTypesList: [],
  vehicleInfoList: [],
  selectedCoupon: "",
  selectedCustomerId: "",
  jobType: "",
  customeType: {
    price: "",
    commission: "",
  },
  createJobData: {
    first: {},
    second: {},
    third: {},
    fourth: {},
    fifth: {},
  },
  isRedirect: false,
  isBackToFifth: false,
};

// Thunk for get categoryList
export const categoryList = createAsyncThunk(
  "job/categoryList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.fetchCategoryList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get floorOptions
export const floorOptions = createAsyncThunk(
  "job/floorList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.fetchFloorOptions(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for fetchMasterDataList
export const fetchMasterDataList = createAsyncThunk(
  "job/fetchMasterDataList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.fetchMasterDataList(
        data.userToken,
        data.key
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get vehicleList
export const vehicleInfoList = createAsyncThunk(
  "job/vehicleList",
  async (token, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.fetchVehicleInfoList(token);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get vehicleList
export const getHelpersList = createAsyncThunk(
  "job/helpersList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.fetchHelpersList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get unitLists
export const getUnitTypeList = createAsyncThunk(
  "job/unitList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.fetchUnitTypes(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get getDriversList
export const getDriversLists = createAsyncThunk(
  "job/driversLists",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.getDriversList(
        data.userToken,
        data.requestData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for acceptJob
export const acceptJob = createAsyncThunk(
  "job/acceptJob",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.acceptJob(
        data.requestData,
        data.userToken
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createJobSlice = createSlice({
  name: "createJob",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
    updateJobData: (state, action) => {
      const { step, data } = action.payload;
      state.createJobData[step] = data;
    },
    updateSelectedCoupon: (state, action) => {
      state.selectedCoupon = action.payload;
    },
    updateJobType: (state, action) => {
      state.jobType = action.payload;
    },
    updateCustomeTypes: (state, action) => {
      state.customeType = action.payload;
    },
    updateSelectedCustomerId: (state, action) => {
      state.selectedCustomerId = action.payload;
    },
    resetAllJobData: (state, action) => {
      const resetAll = action.payload !== undefined ? action.payload : true;
      return {
        ...state,
        createJobData: {
          ...(resetAll ? { first: {} } : state.createJobData),
          second: {},
          third: {},
          fourth: {},
          fifth: {},
        },
        selectedCoupon: "",
        selectedCustomerId: "",
        jobType: "",
        customeType: {
          price: "",
          commission: "",
        },
        isRedirect: false, // setting false when all job data reset
      };
    },
    updateIsRedirect: (state, action) => {
      state.isRedirect = action.payload;
    },
    updateIsBackToFifth: (state, action) => {
      state.isBackToFifth = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ------Builder for categoryList-------
    builder.addCase(categoryList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(categoryList.fulfilled, (state, action) => {
      state.categoryList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(categoryList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for floorOptions-------
    builder.addCase(floorOptions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(floorOptions.fulfilled, (state, action) => {
      state.floorOptions = action.payload?.data.floors;
      state.isLoading = false;
    });

    builder.addCase(floorOptions.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for fetchMasterDataList-------
    builder.addCase(fetchMasterDataList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMasterDataList.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(fetchMasterDataList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for vehicleInfoList-------
    builder.addCase(vehicleInfoList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(vehicleInfoList.fulfilled, (state, action) => {
      state.vehicleInfoList = action.payload?.data?.vehicleList;
      state.isLoading = false;
    });

    builder.addCase(vehicleInfoList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for getHelpersList-------
    builder.addCase(getHelpersList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getHelpersList.fulfilled, (state, action) => {
      state.helpersList = action.payload?.data.helpers;
      state.isLoading = false;
    });

    builder.addCase(getHelpersList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for getUnitTypeList-------
    builder.addCase(getUnitTypeList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getUnitTypeList.fulfilled, (state, action) => {
      state.unitTypesList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(getUnitTypeList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for getDriversLists-------
    builder.addCase(getDriversLists.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getDriversLists.fulfilled, (state, action) => {
      // state.unitTypesList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(getDriversLists.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for acceptJob-------
    builder.addCase(acceptJob.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(acceptJob.fulfilled, (state, action) => {
      // state.unitTypesList = action.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(acceptJob.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setActiveTab,
  setActiveCategory,
  updateJobData,
  updateSelectedCoupon,
  updateSelectedCustomerId,
  resetAllJobData,
  updateIsRedirect,
  updateIsBackToFifth,
  updateJobType,
  updateCustomeTypes,
} = createJobSlice.actions;
export default createJobSlice.reducer;
