import React, { useEffect, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Dashboard from "../Admin/Dashboard/Dashboard";
import User from "../Admin/User/User";
import Jobs from "../Admin/Jobs/Jobs";
import Category from "../Admin/Category/Category";
import Livechat from "../Admin/Livechat/Livechat";
import Support from "../Admin/Support/Support";
import Broadcasting from "../Admin/Broadcasting/Broadcasting";
import B2Benquiry from "../Admin/B2Benquiry/B2Benquiry";
import Metadata from "../Admin/Metadata/Metadata";
import Driver from "../Admin/Driver/Driver";
import Commission from "../Admin/Commission/Commission";
import Login from "../authComponent/LoginPage/LoginPage";
import UserDetails from "../Admin/User/UserDetails";
import DriverDetails from "../Admin/Driver/DriverDetails";
import JobDetails from "../Admin/Jobs/JobDetails";
import ForgotPassword from "../authComponent/ForgotPassword/ForgotPassword";
import ResetPassword from "../authComponent/ResetPassword/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../Admin/NotFound/NotFound";
import Coupon from "../Admin/Coupon/Coupon";
import Review from "../Admin/Review/Review";
import AboutUs from "../Admin/AboutUs/AboutUs";
import TermAndCondition from "../Admin/TermAndCondition/TermAndCondition";
import PrivacyPolicy from "../Admin/PrivacyPolicy/PrivacyPolicy";
import Tutorial from "../Admin/Tutorial/Tutorial";
import ServiceCharge from "../Admin/ServiceCharge/ServiceCharge";
import CreateJob from "../Admin/CreateJob/CreateJob";
import JobSummary from "../Admin/CreateJob/jobSummary";
import Customers from "../Admin/CreateJob/Customers";
import HireDrivers from "../Admin/CreateJob/Drivers/hireDrivers";
import { resetAllJobData, setActiveTab } from "../Redux/Slices/createJob";
import Faq from "../Admin/FAQ/Faq";
import DeletedAccount from "../Admin/DeletedAccount/DeletedAccount";

const Router = () => {
  const ScrollToTop = () => {
    const pathname = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, [pathname]);

    return null;
  };

  const CurrentRoute = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const previousLocationRef = useRef();

    useEffect(() => {
      var localPath = localStorage.getItem("previousRoute");
      if (
        (localPath == "/create-job" || localPath == "/job-summary") &&
        location.pathname !== localPath &&
        location.pathname !== "/create-job" &&
        location.pathname !== "/job-summary" &&
        location.pathname !== "/customer-list"
      ) {
        dispatch(resetAllJobData());
        dispatch(setActiveTab("first"));
      }

      const unlisten = () => {
        previousLocationRef.current = location;
      };

      return () => {
        unlisten();
        localStorage.setItem("previousRoute", location.pathname);
      };
    }, [location]);
  };

  const isAuthenticated = useSelector((state) => state.user.userToken);
  const { fifth } = useSelector((state) => state.createJob.createJobData);

  const PublicRoute = ({ children }) => {
    return !isAuthenticated ? children : <Navigate to="/dashboard" />;
  };

  const PrivateRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/" />;
  };

  const HasJobData = ({ children }) => {
    if (!fifth.hasOwnProperty("isSubmit")) {
      return <Navigate to="/create-job" />;
    } else {
      return children;
    }
  };

  return (
    <React.Fragment>
      <BrowserRouter basename="/">
        <ScrollToTop />
        <CurrentRoute />
        <Routes>
          <Route path="/" element={<PublicRoute>{<Login />}</PublicRoute>} />
          <Route
            path="/forgot-password"
            element={<PublicRoute>{<ForgotPassword />}</PublicRoute>}
          />
          <Route
            path="/reset-password"
            element={<PublicRoute>{<ResetPassword />}</PublicRoute>}
          />
          <Route
            exact
            path="/dashboard"
            element={<PrivateRoute>{<Dashboard />}</PrivateRoute>}
          />
          <Route
            path="/user"
            element={<PrivateRoute>{<User />}</PrivateRoute>}
          />
          <Route
            path="/driver"
            element={<PrivateRoute>{<Driver />}</PrivateRoute>}
          />
          <Route
            path="/deleted-account"
            element={<PrivateRoute>{<DeletedAccount />}</PrivateRoute>}
          />
          <Route
            path="/jobs"
            element={<PrivateRoute>{<Jobs />}</PrivateRoute>}
          />
          <Route
            path="/create-job"
            element={<PrivateRoute>{<CreateJob />}</PrivateRoute>}
          />
          <Route
            path="/job-summary"
            element={<HasJobData>{<JobSummary />}</HasJobData>}
          />
          <Route
            path="/customer-list"
            element={<HasJobData>{<Customers />}</HasJobData>}
          />
          <Route
            path="/hire-drivers"
            element={<HasJobData>{<HireDrivers />}</HasJobData>}
          />
          <Route
            path="/jobs/job-details/:id"
            element={<PrivateRoute>{<JobDetails />}</PrivateRoute>}
          />
          <Route
            path="/commission"
            element={<PrivateRoute>{<Commission />}</PrivateRoute>}
          />
          <Route
            path="/category"
            element={<PrivateRoute>{<Category />}</PrivateRoute>}
          />
          <Route
            path="/livechat"
            element={<PrivateRoute>{<Livechat />}</PrivateRoute>}
          />
          <Route
            path="/metadata"
            element={<PrivateRoute>{<Metadata />}</PrivateRoute>}
          />
          <Route
            path="/support"
            element={<PrivateRoute>{<Support />}</PrivateRoute>}
          />
          <Route
            path="/benquiry"
            element={<PrivateRoute>{<B2Benquiry />}</PrivateRoute>}
          />
          <Route
            path="/broadcasting"
            element={<PrivateRoute>{<Broadcasting />}</PrivateRoute>}
          />
          <Route
            path="/user/user-details/:id"
            element={<PrivateRoute>{<UserDetails />}</PrivateRoute>}
          />
          <Route
            path="/driver/driver-details/:id"
            element={<PrivateRoute>{<DriverDetails />}</PrivateRoute>}
          />
          <Route
            path="/coupon"
            element={<PrivateRoute>{<Coupon />}</PrivateRoute>}
          />
          <Route
            path="/review"
            element={<PrivateRoute>{<Review />}</PrivateRoute>}
          />
          <Route
            path="/about-us"
            element={<PrivateRoute>{<AboutUs />}</PrivateRoute>}
          />

          <Route
            path="/term-condition"
            element={<PrivateRoute>{<TermAndCondition />}</PrivateRoute>}
          />

          <Route
            path="/privacy-policy"
            element={<PrivateRoute>{<PrivacyPolicy />}</PrivateRoute>}
          />

          <Route
            path="/tutorials"
            element={<PrivateRoute>{<Tutorial />}</PrivateRoute>}
          />

          <Route
            path="/service-charge"
            element={<PrivateRoute>{<ServiceCharge />}</PrivateRoute>}
          />
          <Route path="/faq" element={<PrivateRoute>{<Faq />}</PrivateRoute>} />

          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Router;
