import Modal from "react-bootstrap/Modal";
import styles from "../../Validation/Alert.module.css";
import { useTranslation } from "react-i18next";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useState } from "react";
import { MdCancel } from "react-icons/md";

function VanDetetePopup(props) {
  const authToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // ----------- function for manage checkbox----------------
  const deleteVehicleTypeLists = (id) => {
    setIsLoading(true);
    let res = SwiftVanApi.deleteVehicleTypeLists(authToken, id);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("DELETE_VEHICALE_SUCCESSFULLY"), "success");
        props.vanDeleteHandleClose();
        setIsLoading(false);
        props.vehicleTypeList();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      <Modal show={props.vanDeteteShow} onHide={props.vanDeleteHandleClose}>
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("DELETE_TITLE")}</p>
            <span className={styles.alertCross}>
              <MdCancel onClick={props.vanDeleteHandleClose} />
            </span>
          </div>

          <div className={styles.devider}></div>
          <div className={styles.alertBodyInner}>
            <div className={styles.alertLabel}>
              <p>{t("ARE_YOU_SURE_VEHICLE")}</p>
            </div>

            <div className={styles.buttonContainer}>
              <button
                className={styles.buttonNo}
                onClick={props.vanDeleteHandleClose}
              >
                {t("NO")}
              </button>
              <button
                className={styles.buttonYes}
                onClick={() => deleteVehicleTypeLists(props.id)}
              >
                {t("YES")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default VanDetetePopup;
