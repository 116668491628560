import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Styles from "../Header/header.module.css";
import { BellIconMes } from "../../mockData";

const BellIconMessage = ({ target, show }) => {
  return (
    <>
      <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
          <Tooltip
            id="overlay-example"
            {...props}
            className="bellIconMessageSection"
          >
            {BellIconMes.map((item, index) => {
              return (
                <div
                  className={Styles.bellmessageBox}
                  style={{ backgroundColor: item.color }}
                  key={index}
                >
                  <h5>
                    <img src={item.Img} />
                  </h5>
                  <div className={Styles.bellmessageBoxText}>
                    <span>
                      <b>{item.Driver_Name}</b>&nbsp; {item.Order_On_the_way}
                      {item.Order_Pickup} {item.Order_Successfully_Delivered}{" "}
                      {item.Sent_amount} {item.Order_Accept}
                      {item.Order_KM} <b>&nbsp;{item.Amount}</b>
                      {item.For}
                      <b>&nbsp; {item.Driver_Name}</b>
                    </span>
                    <p>{item.time}</p>
                  </div>
                </div>
              );
            })}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default BellIconMessage;
