import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "../Jobs/jobs.module.css";
import { useTranslation } from "react-i18next";
import Loader from "../../Validation/LoadingSpinner";
import "react-datepicker/dist/react-datepicker.css";
import { MdCancel } from "react-icons/md";
import InputSearch from "../../Assests/Images/uil_search.png";
import { IoStar } from "react-icons/io5";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { convertToHourMinuteFormat } from "../../Utils/FormateNumber";

const AssignDriverPopup = ({
  assignDriverShow,
  AssignDriverHandleClose,
  assignDriver,
  jobData,
  fetchJobDetail,
}) => {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [allIds, setAllIds] = useState([]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    let ids = [];
    const driverIds = filteredDrivers.filter((val) => val.assigned_driver == 0);
    driverIds.map((val) => {
      ids.push(val.id);
    });
    setAllIds(ids);
  }, [filteredDrivers]);

  useEffect(() => {
    if (Array.isArray(assignDriver)) {
      setFilteredDrivers(
        assignDriver.filter((driver) =>
          `${driver.first_name} ${driver.last_name}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, assignDriver]);

  const AssignJobRequest = (id) => {
    var PostData = new FormData();
    PostData.append("job_id", jobData.id);
    PostData.append("driver_id", id);
    setIsLoading(true);
    let res = SwiftVanApi.assignJob(jobData.id, PostData, userToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("SEND_REQUEST_SUCCESSFULLY"), "success");
        fetchJobDetail();
        setIsLoading(false);
        AssignDriverHandleClose();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleSendToAll = () => {
    var PostData = new FormData();
    PostData.append("job_id", jobData.id);
    PostData.append("driver_id", allIds.join(","));
    setIsLoading(true);
    let res = SwiftVanApi.assignJob(jobData.id, PostData, userToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("SEND_REQUEST_SUCCESSFULLY"), "success");
        fetchJobDetail();
        setIsLoading(false);
        AssignDriverHandleClose();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}

      <Modal
        show={assignDriverShow}
        onHide={AssignDriverHandleClose}
        className="mmm"
      >
        <div className="addVanPopupMain">
          <Modal.Header>
            <Modal.Title>{t("ASSIGN_DRIVER")} </Modal.Title>

            <MdCancel onClick={AssignDriverHandleClose} />
          </Modal.Header>

          <Modal.Body>
            <div className="SearchMainSec">
              <div className="topSearch">
                <img src={InputSearch} />
                <Form.Control
                  type="search"
                  placeholder={`${t("SEARCH_DRIVER")}`}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
              <div>
                <Button
                  disabled={allIds.length == 0}
                  onClick={() => handleSendToAll()}
                >
                  {t("SEND_TO_ALL")}
                </Button>
              </div>
            </div>

            <div className="main">
              <div className="spaceTopManage">
                <div className={Styles.topContainer}>
                  <div className={Styles.subContainer}>
                    <Row>
                      {filteredDrivers && filteredDrivers.length > 0 ? (
                        filteredDrivers.map((item, index) => {
                          return (
                            <Col
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                              key={index}
                            >
                              <div className={Styles.topDriverCard}>
                                <div className={Styles.driverRating}>
                                  <div className={Styles.driverBooked}>
                                    <div className={Styles.driverReviewRating}>
                                      {item.jobs_booked}
                                    </div>
                                    <div className={Styles.driverReviewCount}>
                                      {item.jobs_booked > 1
                                        ? t("JOBS_BOOKED")
                                        : t("JOB_BOOKED")}
                                    </div>
                                  </div>
                                  <div className={Styles.driverReviewContainer}>
                                    <div className={Styles.driverReviewRating}>
                                      <div>
                                        {item.rating.average_rating.toFixed(1)}
                                      </div>
                                      <IoStar />
                                    </div>
                                    <div className={Styles.driverReviewCount}>
                                      {item.rating.rating_count}{" "}
                                      {item.rating.rating_count > 1
                                        ? t("REVIEWS")
                                        : t("REVIEW")}
                                    </div>
                                  </div>
                                </div>
                                <div className={Styles.driverCard}>
                                  <div>
                                    <img
                                      src={item.image}
                                      className={Styles.driverImage}
                                      alt="driver-image"
                                    />
                                  </div>
                                  <div className={Styles.driverName}>
                                    {item.first_name} {item.last_name}
                                  </div>
                                  <div className={Styles.vanType}>
                                    {item.vehicle_name}
                                  </div>
                                  <div className={Styles.driverPrice}>
                                    {item.currency_symbol}
                                    {item &&
                                      Number(item.total_amount).toFixed(2)}
                                  </div>
                                  <div className={Styles.driverExtraHours}>
                                    {t("FOR_THE_FIRST")}{" "}
                                    <strong>
                                      {convertToHourMinuteFormat(
                                        jobData.estimated_time
                                      )}{" "}
                                      {t("HOURS")}{" "}
                                    </strong>
                                    {t("AND_THEN")} <br />{" "}
                                    <strong>
                                      {item.currency_symbol}
                                      {item.extra_per_hours_price}{" "}
                                    </strong>
                                    {t("PER_HOUR")}
                                  </div>
                                </div>
                                <div className={Styles.driverAccept}>
                                  {assignDriver &&
                                  assignDriver[index].assigned_driver === 0 ? (
                                    <button
                                      onClick={() => AssignJobRequest(item.id)}
                                    >
                                      {t("SEND_REQUEST")}
                                    </button>
                                  ) : (
                                    <button
                                      disabled
                                      style={{ backgroundColor: "#9cd5a9" }}
                                    >
                                      {t("SEND_REQUEST")}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      ) : (
                        <div className="jobDetailsTableRecode">
                          {t("NO_REC_FOUND")}
                        </div>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AssignDriverPopup;
