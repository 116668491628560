import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { Col, Form, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Styles from "../Metadata/metadata.module.css";
import VanPopup from "./VanPopup";
import FloorPopup from "./FloorPopup";
import PropertyPopup from "./PropertyPopup";
import EnquiryPopup from "./EnquiryPopup";
import ColorPopup from "./ColorPopup";
import { Dropdown } from "react-bootstrap";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Loader from "../../Validation/LoadingSpinner";
import Alert from "../../Validation/Alert";
import VanDetetePopup from "./VanDetetePopup";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import PropertyDelete from "./PropertyDelete";
import EnquiryDeletePopup from "./EnquiryDeletePopup";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import { useForm } from "react-hook-form";
import { COMMISSION_TYPE } from "../../Utils/Constant";
import ColorDeletePopup from "./ColorDeletePopup";

const Metadata = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // floor show popup
  const [floorshow, setShowFloor] = useState(false);
  const floorClosePopup = () => setShowFloor(false);
  const floorShowPopup = () => setShowFloor(true);

  const [propertyshow, setShowProperty] = useState(false);
  const propertyClosePopup = () => setShowProperty(false);
  const propertyShowPopup = () => setShowProperty(true);

  const [propertyDeteteShow, SetPropertyDeteteShow] = useState(false);
  const propertyDeleteHandleClose = () => SetPropertyDeteteShow(false);
  const propertyDeleteHandleShow = () => {
    SetPropertyDeteteShow(true);
  };

  const [enquiryshow, setShowEnquiry] = useState(false);
  const enquiryClosePopup = () => setShowEnquiry(false);
  const enquiryShowPopup = () => setShowEnquiry(true);

  const [enquiryDeteteShow, SetEnquiryDeteteShow] = useState(false);
  const enquiryDeleteHandleClose = () => SetEnquiryDeteteShow(false);
  const enquiryDeleteHandleShow = () => {
    SetEnquiryDeteteShow(true);
  };

  const [alertShow, setAlertShow] = useState(false);
  const alertHandleClose = () => setAlertShow(false);
  const alertHandleShow = () => {
    setAlertShow(true);
  };

  const [vanDeteteShow, SetVanDeteteShow] = useState(false);
  const vanDeleteHandleClose = () => SetVanDeteteShow(false);
  const vanDeleteHandleShow = () => {
    SetVanDeteteShow(true);
  };

  const [colorshow, setShowColor] = useState(false);
  const colorClosePopup = () => setShowColor(false);
  const colorShowPopup = () => setShowColor(true);

  const [colorDeteteShow, SetColorDeteteShow] = useState(false);
  const ColorDeleteHandleClose = () => SetColorDeteteShow(false);
  const ColorDeleteHandleShow = () => {
    SetColorDeteteShow(true);
  };

  const [colorList, setColorList] = useState([]);
  const [vehicleType, setVehicleType] = useState([]);
  const [masterList, setMasterList] = useState([]);
  const [helpersList, setHelpersList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [enquiryList, setEnquiryList] = useState([]);
  const [id, setId] = useState("");

  // update
  const [vanUpdateId, setVanUpdateId] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [floorUpdate, setFloorUpdate] = useState("");
  const [propertyUpdate, setPropertyUpdate] = useState("");
  const [colorNameUpdate, setColorNameUpdate] = useState("");
  const [enquiryUpdate, setEnquiryUpdate] = useState("");

  // ---left Time state
  const [leftTimeInput, setLeftTimeInput] = useState("");

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const addVehiclePopup = (id) => {
    setVanUpdateId("");
    handleShow();
  };

  const editPropertyPopup = (propertyListData) => {
    setPropertyUpdate(propertyListData);
    propertyShowPopup();
  };

  const editenquiryShowPopup = (enquiryDataList) => {
    setEnquiryUpdate(enquiryDataList);
    enquiryShowPopup();
  };

  const editcolorShowPopup = (colorDataList) => {
    setColorNameUpdate(colorDataList);
    colorShowPopup();
  };

  const property_types = "property_types";
  const helpers = "helpers";
  const colours = "colours";
  const floors = "floors";
  const B2B = "B2B";

  // ---vehicleType----
  const vehicleTypeList = () => {
    setIsLoading(true);
    let res = SwiftVanApi.vehicleTypeLists(authToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setVehicleType(data.data.vehicleList);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // ---vehicleChangeStatus---
  const changeStatus = (id, status) => {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("status", status);
    let res = SwiftVanApi.statusVhicleTypeLists(id, authToken, requestData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        vehicleTypeList();
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- property_types------
  const propertyDataList = () => {
    let res = SwiftVanApi.masterDataLists(authToken, property_types);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setMasterList(data.data.property_types);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- colorList------
  const colorDataList = () => {
    let res = SwiftVanApi.masterDataLists(authToken, colours);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setColorList(data.data.colours);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- helpers------
  const helperDataList = () => {
    let res = SwiftVanApi.masterDataLists(authToken, helpers);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setHelpersList(data.data.helpers);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // ---allTabsChangeStatus---
  const changeStatusMasterDataAll = (id, status) => {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("status", status);
    let res = SwiftVanApi.statusChangeMasterData(id, authToken, requestData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        helperDataList();
        propertyDataList();
        colorDataList();
        floorDataList();
        enquiryDataList();
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- floor------
  const floorDataList = () => {
    setIsLoading(true);
    let res = SwiftVanApi.masterDataLists(authToken, floors);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setFloorList(data.data.floors);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- B2B------
  const enquiryDataList = () => {
    let res = SwiftVanApi.masterDataLists(authToken, B2B);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setEnquiryList(data.data.B2B);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // ---left-time- timeChange-function
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue == "" ||
      (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 600)
    ) {
      setValue("CommissionRate", inputValue, { shouldValidate: true });
      setLeftTimeInput(inputValue);
    }
  };

  // ---left-time-get-api
  const SettingOptionData = () => {
    setIsLoading(true);
    let res = SwiftVanApi.settingOptionList(
      authToken,
      COMMISSION_TYPE.LIFT_TIME
    );
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setValue("CommissionRate", data.data[0].option_value);
        setLeftTimeInput(data.data[0].option_value);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    SettingOptionData();
  }, []);
  const onSubmit = (data) => {
    updateApi(data);
  };

  // ---left-time-get-update-api
  const updateApi = (data) => {
    var PostData = new FormData();
    PostData.append("option_name", COMMISSION_TYPE.LIFT_TIME);
    PostData.append("option_value", Number(leftTimeInput));
    PostData.append("option_type", COMMISSION_TYPE.LIFT_TIME);

    setIsLoading(true);
    let res = SwiftVanApi.postSettingOptionList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_LEFT_TIME_SUCCESSFULLY"), "success");
        SettingOptionData();
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    propertyDataList();
    vehicleTypeList();
    helperDataList();
    floorDataList();
    colorDataList();
    enquiryDataList();
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="metadataMinSection">
                <Tab.Container id="left-tabs-example" defaultActiveKey="Van">
                  <Row>
                    <Col lg={3} md={6} sm={12}>
                      <div className="metadataTapLeft">
                        <Nav variant="pills" className="flex-column">
                          <div className="metaDataTitle">
                            <h1>{t("METADATA")}</h1>
                          </div>
                          <Nav.Item>
                            <Nav.Link eventKey="Van">{t("VAN")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Helper">{t("HELPER")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Floor">{t("FLOOR")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Property">
                              {t("PROPERTY")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Colors">{t("COLORS")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="B2B Enquiry">
                              {t("B2B_ENQUIRY")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Lift Time">
                              {t("LIFT_TIME")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    <Col style={{ marginBottom: "25px" }} lg={9} md={6} sm={12}>
                      <Tab.Content
                        style={{ flexWrap: "wrap", overflow: "hidden" }}
                      >
                        {/* ---van--- */}
                        <Tab.Pane eventKey="Van">
                          <div className="tableMainSection">
                            <div className="tableMainInner">
                              <div className="vanDatatable">
                                <div className="tableBootomScroll">
                                  <div className={Styles.MetaDatVanTableInner}>
                                    <Table
                                      striped
                                      borderless
                                      responsive="lg"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <thead className="text-left">
                                        <tr
                                          className="align-middle "
                                          style={{ backgroundColor: "#555555" }}
                                        >
                                          <th
                                            className="tableThBg tablethleft"
                                            style={{
                                              minWidth: "120px",
                                              padding: "0px 20px",
                                            }}
                                          >
                                            {t("IMG")}
                                          </th>
                                          <th
                                            className="tableThBg"
                                            style={{ minWidth: "140px" }}
                                          >
                                            {t("VEHICLE_NAME")}
                                          </th>

                                          <th
                                            className="tableThBg"
                                            style={{ minWidth: "140px" }}
                                          >
                                            {t("VAN_SIZE")}
                                          </th>
                                          <th
                                            className="tableThBg "
                                            style={{ minWidth: "230px" }}
                                          >
                                            {t("DESCRIPTION")}
                                          </th>
                                          <th
                                            className="tableThBg "
                                            style={{ minWidth: "100px" }}
                                          >
                                            {t("STATUS_ITEM")}
                                          </th>
                                          <th
                                            className="tableThBg"
                                            style={{ minWidth: "100px" }}
                                          >
                                            {t("MINIMUM_TIME_Table")}
                                          </th>
                                          <th
                                            style={{ minWidth: "80px" }}
                                            className="tableThBg tablethRight text-center"
                                          >
                                            {t("ACTION")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="text-left">
                                        {vehicleType &&
                                        vehicleType.length > 0 ? (
                                          vehicleType.map(
                                            (vehicleListData, index) => {
                                              return (
                                                <tr
                                                  key={index}
                                                  className="align-middle vehicleInfoheight"
                                                >
                                                  <td
                                                    className="tableTdText"
                                                    style={{
                                                      minWidth: "130px",
                                                      padding: "0px 20px",
                                                    }}
                                                  >
                                                    <img
                                                      style={{
                                                        width: "77px",
                                                        height: "50px",
                                                        objectFit: "contain",
                                                      }}
                                                      src={
                                                        vehicleListData.vehicle_image
                                                      }
                                                      alt=""
                                                    ></img>
                                                  </td>
                                                  <td
                                                    className={`${Styles.Minimum_time} textUpperLatter`}
                                                    style={{
                                                      minWidth: "140px",
                                                    }}
                                                  >
                                                    {
                                                      vehicleListData.vehicle_name
                                                    }
                                                  </td>

                                                  <td
                                                    className={`${Styles.Minimum_time} textUpperLatter`}
                                                    style={{
                                                      minWidth: "140px",
                                                    }}
                                                  >
                                                    {vehicleListData.size}
                                                  </td>
                                                  <td
                                                    className="tableTdText textDescription "
                                                    style={{
                                                      minWidth: "230px",
                                                    }}
                                                  >
                                                    <div className="tableTextThreeLine">
                                                      {capitalizeFirstLetter(
                                                        vehicleListData.vehicle_description
                                                      )}
                                                    </div>
                                                  </td>

                                                  <td
                                                    className="tableTdText"
                                                    style={{
                                                      minWidth: "100px",
                                                    }}
                                                  >
                                                    {vehicleListData.is_active ===
                                                    1 ? (
                                                      <div className="activeClass">
                                                        {t("ACTIVE")}
                                                      </div>
                                                    ) : (
                                                      <div className="InactiveClass">
                                                        {t("INACTIVE")}
                                                      </div>
                                                    )}
                                                  </td>

                                                  <td
                                                    className={
                                                      Styles.Minimum_time
                                                    }
                                                    style={{
                                                      minWidth: "100px",
                                                    }}
                                                  >
                                                    {vehicleListData.time}
                                                  </td>
                                                  <td
                                                    className="text-center "
                                                    style={{
                                                      minWidth: "80px",
                                                      padding: "0px 20px",
                                                    }}
                                                  >
                                                    <div className="TableDropdown">
                                                      <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                          <div
                                                            className={
                                                              Styles.theredotHorizontal
                                                            }
                                                          >
                                                            <HiOutlineDotsHorizontal />
                                                          </div>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="dropDownOnlyOneList">
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              handleShow();
                                                              setVanUpdateId(
                                                                vehicleListData
                                                              );
                                                              setIsUpdate(true);
                                                            }}
                                                          >
                                                            {t("EDIT_DROPDOWN")}
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              vanDeleteHandleShow();
                                                              setId(
                                                                vehicleListData.id
                                                              );
                                                            }}
                                                          >
                                                            {t(
                                                              "DELETE_DROPDOWN"
                                                            )}
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              changeStatus(
                                                                vehicleListData.id,
                                                                vehicleListData.is_active ===
                                                                  1
                                                                  ? 0
                                                                  : 1
                                                              )
                                                            }
                                                          >
                                                            {vehicleListData.is_active ===
                                                            1
                                                              ? `${t(
                                                                  "INACTIVE"
                                                                )}`
                                                              : `${t(
                                                                  "ACTIVE"
                                                                )}`}
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="7"
                                              className="centeredCell"
                                            >
                                              <div className="dataRecord">
                                                {t("NO_REC_FOUND")}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                              <div className={Styles.vanTabAddVanButtion}>
                                <button
                                  onClick={() => {
                                    addVehiclePopup();
                                    setIsUpdate(false);
                                  }}
                                >
                                  <span>
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1 7C0.734784 7 0.48043 6.89464 0.292893 6.70711C0.105357 6.51957 0 6.26522 0 6C0 5.73478 0.105357 5.48043 0.292893 5.29289C0.48043 5.10536 0.734784 5 1 5H11C11.2652 5 11.5196 5.10536 11.7071 5.29289C11.8946 5.48043 12 5.73478 12 6C12 6.26522 11.8946 6.51957 11.7071 6.70711C11.5196 6.89464 11.2652 7 11 7H1Z"
                                        fill="white"
                                      />
                                      <path
                                        d="M5 1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0C6.26522 0 6.51957 0.105357 6.70711 0.292893C6.89464 0.48043 7 0.734784 7 1V11C7 11.2652 6.89464 11.5196 6.70711 11.7071C6.51957 11.8946 6.26522 12 6 12C5.73478 12 5.48043 11.8946 5.29289 11.7071C5.10536 11.5196 5 11.2652 5 11V1Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </span>
                                  {t("ADD_VAN")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* ---Helper--- */}
                        <Tab.Pane eventKey="Helper">
                          <div className="FloorSection">
                            <div className="tableMainSection">
                              <div className="tableMainInner">
                                <div className="helperSectionInner">
                                  <div className="tableBootomScroll">
                                    <div
                                      className={Styles.MetaDatVanTableInner}
                                    >
                                      <Table
                                        striped
                                        responsive="lg"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <thead>
                                          <tr
                                            style={{
                                              height: "45px",
                                            }}
                                            className="align-middle "
                                          >
                                            <th
                                              style={{ minWidth: "150px" }}
                                              className="leftsidepadding floorTableTitle"
                                            >
                                              {t("HELPER")}
                                            </th>
                                            <th
                                              className="floorTableTitle "
                                              style={{ minWidth: "100px" }}
                                            >
                                              {t("STATUS_ITEM")}
                                            </th>

                                            <th
                                              className="floorTableTitle rightsidepadding"
                                              style={{
                                                minWidth: "80px",
                                                textAlign: "end",
                                              }}
                                            >
                                              {t("ACTION")}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {helpersList &&
                                          helpersList.length > 0 ? (
                                            helpersList.map(
                                              (helperItemList, index) => {
                                                return (
                                                  <tr
                                                    style={{ height: "45px" }}
                                                    key={index}
                                                    className="align-middle "
                                                  >
                                                    <td
                                                      style={{
                                                        minWidth: "",
                                                        paddingLeft: "15px",
                                                      }}
                                                      className={`${Styles.floorTable} textUpperLatter`}
                                                    >
                                                      {
                                                        helperItemList.value_name
                                                      }
                                                    </td>
                                                    <td
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                    >
                                                      {helperItemList.is_active ===
                                                      1 ? (
                                                        <div className="activeClass">
                                                          {t("ACTIVE")}
                                                        </div>
                                                      ) : (
                                                        <div className="InactiveClass">
                                                          {t("INACTIVE")}
                                                        </div>
                                                      )}
                                                    </td>

                                                    <td
                                                      style={{
                                                        textAlign: "end",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      <div className="TableDropdown">
                                                        <Dropdown>
                                                          <Dropdown.Toggle id="dropdown-basic">
                                                            <div
                                                              className={
                                                                Styles.theredotHorizontal
                                                              }
                                                            >
                                                              <HiOutlineDotsHorizontal />
                                                            </div>
                                                          </Dropdown.Toggle>

                                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                                            <Dropdown.Item
                                                              onClick={() =>
                                                                changeStatusMasterDataAll(
                                                                  helperItemList.id,
                                                                  helperItemList.is_active ===
                                                                    1
                                                                    ? 0
                                                                    : 1
                                                                )
                                                              }
                                                            >
                                                              {helperItemList.is_active ===
                                                              1
                                                                ? `${t(
                                                                    "INACTIVE"
                                                                  )}`
                                                                : `${t(
                                                                    "ACTIVE"
                                                                  )}`}
                                                            </Dropdown.Item>
                                                          </Dropdown.Menu>
                                                        </Dropdown>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="centeredCell"
                                              >
                                                <div className="dataRecord">
                                                  {t("NO_REC_FOUND")}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* ---floor-- */}
                        <Tab.Pane eventKey="Floor">
                          <div className="FloorSection">
                            <div className="tableMainSection">
                              <div className="tableMainInner">
                                <div className="FloorSectionInner">
                                  <div className="tableBootomScroll">
                                    <div
                                      className={
                                        Styles.MetaDatVanTableInner_floor
                                      }
                                    >
                                      <Table
                                        striped
                                        responsive="lg"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <thead>
                                          <tr
                                            style={{ height: "45px" }}
                                            className="align-middle "
                                          >
                                            <th
                                              className="leftsidepadding floorTableTitle"
                                              style={{ minWidth: "300px" }}
                                            >
                                              {t("FLOOR")}
                                            </th>
                                            <th
                                              className="floorTableTitle "
                                              style={{ minWidth: "230px" }}
                                            >
                                              {t("STATUS_ITEM")}
                                            </th>
                                            <th
                                              className="floorTableTitle"
                                              style={{ minWidth: "230px" }}
                                            >
                                              {t("MINIMUM_TIME_Table")}
                                            </th>
                                            <th
                                              className="floorTableTitle"
                                              style={{
                                                minWidth: "80px",
                                                textAlign: "end",
                                              }}
                                            >
                                              {t("ACTION")}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {floorList && floorList.length > 0 ? (
                                            floorList.map(
                                              (floorItemList, index) => {
                                                return (
                                                  <tr
                                                    style={{ height: "45px" }}
                                                    key={index}
                                                    className="align-middle "
                                                    id={floorItemList.id}
                                                  >
                                                    <td
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      {capitalizeFirstLetter(
                                                        floorItemList.value_name
                                                      )}
                                                    </td>

                                                    <td
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      {floorItemList.is_active ===
                                                      1 ? (
                                                        <div className="activeClass">
                                                          {t("ACTIVE")}
                                                        </div>
                                                      ) : (
                                                        <div className="InactiveClass">
                                                          {t("INACTIVE")}
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      {floorItemList.time}
                                                    </td>

                                                    <td
                                                      style={{
                                                        textAlign: "end",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      <div className="TableDropdown">
                                                        <Dropdown>
                                                          <Dropdown.Toggle id="dropdown-basic">
                                                            <div
                                                              className={
                                                                Styles.theredotHorizontal
                                                              }
                                                            >
                                                              <HiOutlineDotsHorizontal />
                                                            </div>
                                                          </Dropdown.Toggle>

                                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                                            <Dropdown.Item
                                                              onClick={() => {
                                                                floorShowPopup();
                                                                setFloorUpdate(
                                                                  floorItemList
                                                                );
                                                              }}
                                                            >
                                                              {t(
                                                                "EDIT_DROPDOWN"
                                                              )}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              onClick={() =>
                                                                changeStatusMasterDataAll(
                                                                  floorItemList.id,
                                                                  floorItemList.is_active ===
                                                                    1
                                                                    ? 0
                                                                    : 1
                                                                )
                                                              }
                                                            >
                                                              {floorItemList.is_active ===
                                                              1
                                                                ? `${t(
                                                                    "INACTIVE"
                                                                  )}`
                                                                : `${t(
                                                                    "ACTIVE"
                                                                  )}`}
                                                            </Dropdown.Item>
                                                          </Dropdown.Menu>
                                                        </Dropdown>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="centeredCell"
                                              >
                                                <div className="dataRecord">
                                                  {t("NO_REC_FOUND")}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* ----Property----- */}
                        <Tab.Pane eventKey="Property">
                          <div className="FloorSection">
                            <div className="tableMainSection">
                              <div className="tableMainInner">
                                <div className="FloorSectionInner">
                                  <div className="tableBootomScroll">
                                    <div
                                      className={Styles.MetaDatVanTableInner}
                                    >
                                      <Table
                                        striped
                                        responsive="lg"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <thead>
                                          <tr
                                            style={{ height: "45px" }}
                                            className="align-middle "
                                          >
                                            <th
                                              className="leftsidepadding floorTableTitle"
                                              style={{ minWidth: "250px" }}
                                            >
                                              {t("PROPERTY_TYPE")}
                                            </th>

                                            <th
                                              className="floorTableTitle"
                                              style={{ minWidth: "250px" }}
                                            >
                                              {t("STATUS_ITEM")}
                                            </th>
                                            <th
                                              className="floorTableTitle"
                                              style={{ minWidth: "250px" }}
                                            >
                                              {t("MINIMUM_TIME_Table")}
                                            </th>

                                            <th
                                              className="floorTableTitle"
                                              style={{
                                                minWidth: "50px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {t("ACTION")}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {masterList &&
                                          masterList.length > 0 ? (
                                            masterList.map(
                                              (masterItemList, index) => {
                                                const isStatic =
                                                  masterItemList.value_code ===
                                                  "static";
                                                return (
                                                  <tr
                                                    style={{ height: "45px" }}
                                                    key={index}
                                                    className="align-middle "
                                                  >
                                                    <td
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      {
                                                        masterItemList.value_name
                                                      }
                                                    </td>

                                                    <td
                                                      className={`${Styles.floorTable} textUpperLatter`}
                                                    >
                                                      {masterItemList.is_active ===
                                                      1 ? (
                                                        <div className="activeClass">
                                                          {t("ACTIVE")}
                                                        </div>
                                                      ) : (
                                                        <div className="InactiveClass">
                                                          {t("INACTIVE")}
                                                        </div>
                                                      )}
                                                    </td>

                                                    <td
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      {masterItemList.time}
                                                    </td>

                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      <div className="TableDropdown">
                                                        <Dropdown>
                                                          <Dropdown.Toggle id="dropdown-basic">
                                                            <div
                                                              className={
                                                                Styles.theredotHorizontal
                                                              }
                                                            >
                                                              <HiOutlineDotsHorizontal />
                                                            </div>
                                                          </Dropdown.Toggle>

                                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                                            <Dropdown.Item
                                                              onClick={() => {
                                                                editPropertyPopup(
                                                                  masterItemList
                                                                );
                                                                setIsUpdate(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              {t(
                                                                "EDIT_DROPDOWN"
                                                              )}
                                                            </Dropdown.Item>
                                                            {!isStatic && (
                                                              <>
                                                                <Dropdown.Item
                                                                  onClick={() => {
                                                                    propertyDeleteHandleShow();
                                                                    setId(
                                                                      masterItemList.id
                                                                    );
                                                                  }}
                                                                >
                                                                  {t(
                                                                    "DELETE_DROPDOWN"
                                                                  )}
                                                                </Dropdown.Item>
                                                              </>
                                                            )}
                                                            <Dropdown.Item
                                                              onClick={() =>
                                                                changeStatusMasterDataAll(
                                                                  masterItemList.id,
                                                                  masterItemList.is_active ===
                                                                    1
                                                                    ? 0
                                                                    : 1
                                                                )
                                                              }
                                                            >
                                                              {masterItemList.is_active ===
                                                              1
                                                                ? `${t(
                                                                    "INACTIVE"
                                                                  )}`
                                                                : `${t(
                                                                    "ACTIVE"
                                                                  )}`}
                                                            </Dropdown.Item>
                                                          </Dropdown.Menu>
                                                        </Dropdown>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="centeredCell"
                                              >
                                                <div className="dataRecord">
                                                  {t("NO_REC_FOUND")}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                                <div className={Styles.vanTabAddVanButtion}>
                                  <button
                                    onClick={() => {
                                      editPropertyPopup("");
                                      setIsUpdate(false);
                                    }}
                                  >
                                    <span>
                                      <svg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 7C0.734784 7 0.48043 6.89464 0.292893 6.70711C0.105357 6.51957 0 6.26522 0 6C0 5.73478 0.105357 5.48043 0.292893 5.29289C0.48043 5.10536 0.734784 5 1 5H11C11.2652 5 11.5196 5.10536 11.7071 5.29289C11.8946 5.48043 12 5.73478 12 6C12 6.26522 11.8946 6.51957 11.7071 6.70711C11.5196 6.89464 11.2652 7 11 7H1Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M5 1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0C6.26522 0 6.51957 0.105357 6.70711 0.292893C6.89464 0.48043 7 0.734784 7 1V11C7 11.2652 6.89464 11.5196 6.70711 11.7071C6.51957 11.8946 6.26522 12 6 12C5.73478 12 5.48043 11.8946 5.29289 11.7071C5.10536 11.5196 5 11.2652 5 11V1Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </span>
                                    {t("ADD_PROPERTY")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* ----Colors----- */}
                        <Tab.Pane eventKey="Colors">
                          <div className="FloorSection">
                            <div className="tableMainSection">
                              <div className="tableMainInner">
                                <div className="FloorSectionInner">
                                  <div className="tableBootomScroll">
                                    <div
                                      className={Styles.MetaDatVanTableInner}
                                    >
                                      <Table
                                        striped
                                        responsive="lg"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <thead>
                                          <tr
                                            style={{ height: "45px" }}
                                            className="align-middle "
                                          >
                                            <th
                                              className="leftsidepadding floorTableTitle"
                                              style={{ minWidth: "200px" }}
                                            >
                                              {t("COLORS")}
                                            </th>
                                            <th
                                              className=" floorTableTitle"
                                              style={{ minWidth: "200px" }}
                                            >
                                              {t("COLOR_CODE_NAME")}
                                            </th>
                                            <th
                                              className=" floorTableTitle"
                                              style={{ minWidth: "200px" }}
                                            >
                                              {t("STATUS_ITEM")}
                                            </th>
                                            <th
                                              className="floorTableTitle"
                                              style={{
                                                minWidth: "50px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {t("ACTION")}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {colorList && colorList.length > 0 ? (
                                            colorList.map(
                                              (colorItemList, index) => {
                                                return (
                                                  <tr
                                                    style={{ height: "45px" }}
                                                    key={index}
                                                    className="align-middle "
                                                  >
                                                    <td
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                      className={`${Styles.floorTable} textUpperLatter`}
                                                    >
                                                      {colorItemList.value_name}
                                                    </td>
                                                    <td
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      {colorItemList.value_code}
                                                    </td>
                                                    <td
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      {colorItemList.is_active ===
                                                      1 ? (
                                                        <div className="activeClass">
                                                          {t("ACTIVE")}
                                                        </div>
                                                      ) : (
                                                        <div className="InactiveClass">
                                                          {t("INACTIVE")}
                                                        </div>
                                                      )}
                                                    </td>

                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      <div className="TableDropdown ">
                                                        <Dropdown>
                                                          <Dropdown.Toggle id="dropdown-basic">
                                                            <div
                                                              className={
                                                                Styles.theredotHorizontal
                                                              }
                                                            >
                                                              <HiOutlineDotsHorizontal />
                                                            </div>
                                                          </Dropdown.Toggle>
                                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                                            <Dropdown.Item
                                                              href=""
                                                              onClick={() => {
                                                                editcolorShowPopup(
                                                                  colorItemList
                                                                );
                                                                setIsUpdate(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              {t(
                                                                "EDIT_DROPDOWN"
                                                              )}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              onClick={() => {
                                                                ColorDeleteHandleShow();
                                                                setId(
                                                                  colorItemList.id
                                                                );
                                                              }}
                                                            >
                                                              {t(
                                                                "DELETE_DROPDOWN"
                                                              )}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              onClick={() =>
                                                                changeStatusMasterDataAll(
                                                                  colorItemList.id,
                                                                  colorItemList.is_active ===
                                                                    1
                                                                    ? 0
                                                                    : 1
                                                                )
                                                              }
                                                            >
                                                              {colorItemList.is_active ===
                                                              1
                                                                ? `${t(
                                                                    "INACTIVE"
                                                                  )}`
                                                                : `${t(
                                                                    "ACTIVE"
                                                                  )}`}
                                                            </Dropdown.Item>
                                                          </Dropdown.Menu>
                                                        </Dropdown>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="centeredCell"
                                              >
                                                <div className="dataRecord">
                                                  {t("NO_REC_FOUND")}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                                <div className={Styles.vanTabAddVanButtion}>
                                  <button
                                    onClick={() => {
                                      editcolorShowPopup("");
                                      setIsUpdate(false);
                                    }}
                                  >
                                    <span>
                                      <svg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 7C0.734784 7 0.48043 6.89464 0.292893 6.70711C0.105357 6.51957 0 6.26522 0 6C0 5.73478 0.105357 5.48043 0.292893 5.29289C0.48043 5.10536 0.734784 5 1 5H11C11.2652 5 11.5196 5.10536 11.7071 5.29289C11.8946 5.48043 12 5.73478 12 6C12 6.26522 11.8946 6.51957 11.7071 6.70711C11.5196 6.89464 11.2652 7 11 7H1Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M5 1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0C6.26522 0 6.51957 0.105357 6.70711 0.292893C6.89464 0.48043 7 0.734784 7 1V11C7 11.2652 6.89464 11.5196 6.70711 11.7071C6.51957 11.8946 6.26522 12 6 12C5.73478 12 5.48043 11.8946 5.29289 11.7071C5.10536 11.5196 5 11.2652 5 11V1Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </span>
                                    {t("ADD_COLOR")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* ----B2B Enquiry----- */}
                        <Tab.Pane eventKey="B2B Enquiry">
                          <div className="FloorSection">
                            <div className="tableMainSection">
                              <div className="tableMainInner">
                                <div className="FloorSectionInner">
                                  <div className="tableBootomScroll">
                                    <div
                                      className={Styles.MetaDatVanTableInner}
                                    >
                                      <Table
                                        striped
                                        responsive="lg"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <thead>
                                          <tr
                                            style={{ height: "45px" }}
                                            className="align-middle "
                                          >
                                            <th
                                              className="leftsidepadding floorTableTitle"
                                              style={{ minWidth: "300px" }}
                                            >
                                              {t("B2B_ENQUIRY_CATEGORY")}
                                            </th>
                                            <th
                                              className="leftsidepadding floorTableTitle"
                                              style={{ minWidth: "200px" }}
                                            >
                                              {t("STATUS_ITEM")}
                                            </th>

                                            <th
                                              className="floorTableTitle"
                                              style={{
                                                minWidth: "50px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {t("ACTION")}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {enquiryList &&
                                          enquiryList.length > 0 ? (
                                            enquiryList.map(
                                              (enquiryItemList, index) => {
                                                return (
                                                  <tr
                                                    style={{ height: "45px" }}
                                                    key={index}
                                                    className="align-middle "
                                                  >
                                                    <td
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                      className={`${Styles.floorTable} textUpperLatter`}
                                                    >
                                                      {
                                                        enquiryItemList.value_name
                                                      }
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      {enquiryItemList.is_active ===
                                                      1 ? (
                                                        <div className="activeClass">
                                                          {t("ACTIVE")}
                                                        </div>
                                                      ) : (
                                                        <div className="InactiveClass">
                                                          {t("INACTIVE")}
                                                        </div>
                                                      )}
                                                    </td>

                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                      className={
                                                        Styles.floorTable
                                                      }
                                                    >
                                                      <div className="TableDropdown">
                                                        <Dropdown>
                                                          <Dropdown.Toggle id="dropdown-basic">
                                                            <div
                                                              className={
                                                                Styles.theredotHorizontal
                                                              }
                                                            >
                                                              <HiOutlineDotsHorizontal />
                                                            </div>
                                                          </Dropdown.Toggle>

                                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                                            <Dropdown.Item
                                                              onClick={() => {
                                                                editenquiryShowPopup(
                                                                  enquiryItemList
                                                                );
                                                                setIsUpdate(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              {t(
                                                                "EDIT_DROPDOWN"
                                                              )}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              onClick={() => {
                                                                enquiryDeleteHandleShow();
                                                                setId(
                                                                  enquiryItemList.id
                                                                );
                                                              }}
                                                            >
                                                              {t(
                                                                "DELETE_DROPDOWN"
                                                              )}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              onClick={() =>
                                                                changeStatusMasterDataAll(
                                                                  enquiryItemList.id,
                                                                  enquiryItemList.is_active ===
                                                                    1
                                                                    ? 0
                                                                    : 1
                                                                )
                                                              }
                                                            >
                                                              {enquiryItemList.is_active ===
                                                              1
                                                                ? `${t(
                                                                    "INACTIVE"
                                                                  )}`
                                                                : `${t(
                                                                    "ACTIVE"
                                                                  )}`}
                                                            </Dropdown.Item>
                                                          </Dropdown.Menu>
                                                        </Dropdown>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="centeredCell"
                                              >
                                                <div className="dataRecord">
                                                  {t("NO_REC_FOUND")}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                                <div className={Styles.vanTabAddVanButtion}>
                                  <button
                                    onClick={() => {
                                      editenquiryShowPopup("");
                                      setIsUpdate(false);
                                    }}
                                  >
                                    <span>
                                      <svg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 7C0.734784 7 0.48043 6.89464 0.292893 6.70711C0.105357 6.51957 0 6.26522 0 6C0 5.73478 0.105357 5.48043 0.292893 5.29289C0.48043 5.10536 0.734784 5 1 5H11C11.2652 5 11.5196 5.10536 11.7071 5.29289C11.8946 5.48043 12 5.73478 12 6C12 6.26522 11.8946 6.51957 11.7071 6.70711C11.5196 6.89464 11.2652 7 11 7H1Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M5 1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0C6.26522 0 6.51957 0.105357 6.70711 0.292893C6.89464 0.48043 7 0.734784 7 1V11C7 11.2652 6.89464 11.5196 6.70711 11.7071C6.51957 11.8946 6.26522 12 6 12C5.73478 12 5.48043 11.8946 5.29289 11.7071C5.10536 11.5196 5 11.2652 5 11V1Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </span>
                                    {t("ADD_CATEGORY")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* ----Lift Time----- */}
                        <Tab.Pane eventKey="Lift Time">
                          <div className="FloorSection">
                            <div className="tableMainSection">
                              <div className="tableMainInner">
                                <div className="FloorSectionInner">
                                  <div className="tableBootomScroll">
                                    <div className={Styles.leftTimeMain}>
                                      <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                          <Col lg={8}>
                                            <Form.Group className="CreateInput">
                                              <Form.Label>
                                                {t("MINIMUM_TIME")}
                                              </Form.Label>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  position: "relative",
                                                }}
                                              >
                                                <div style={{ width: "100%" }}>
                                                  <Form.Control
                                                    type="text"
                                                    name="CommissionRate"
                                                    {...register(
                                                      "CommissionRate",
                                                      {
                                                        required: {
                                                          value: true,
                                                          message: `${t(
                                                            "ENTER_MINIMUM_TIME"
                                                          )}`,
                                                        },
                                                      }
                                                    )}
                                                    value={leftTimeInput}
                                                    onChange={handleInputChange}
                                                    placeholder={`${t(
                                                      "MINIMUM_TIME_INPUT"
                                                    )}`}
                                                    min={1}
                                                    max={600}
                                                  />

                                                  {formState.errors
                                                    .CommissionRate && (
                                                    <p
                                                      style={{
                                                        color: "red",
                                                        mb: "1",
                                                      }}
                                                    >
                                                      {t(
                                                        formState.errors
                                                          .CommissionRate
                                                          .message
                                                      )}
                                                    </p>
                                                  )}
                                                </div>

                                                <div
                                                  className={
                                                    Styles.minTimeInput
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      Styles.CategoryDevider
                                                    }
                                                  ></div>
                                                  <div
                                                    className={
                                                      Styles.SelectDuration
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        Styles.SelectDurationInner
                                                      }
                                                    >
                                                      {t("MIN")}
                                                      <p></p>{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Form.Group>

                                            <div className={Styles.leftTimeBtn}>
                                              <button type="submit">
                                                {t("SUBMIT")}
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>

                <VanPopup
                  show={show}
                  handleClose={handleClose}
                  setShow={setShow}
                  vehicleTypeList={vehicleTypeList}
                  vanUpdateId={vanUpdateId}
                  isUpdate={isUpdate}
                />

                <FloorPopup
                  floorshow={floorshow}
                  floorClosePopup={floorClosePopup}
                  floorUpdate={floorUpdate}
                  setShowFloor={setShowFloor}
                  floorDataList={floorDataList}
                />
                <PropertyPopup
                  propertyshow={propertyshow}
                  propertyClosePopup={propertyClosePopup}
                  propertyDataList={propertyDataList}
                  setShowProperty={setShowProperty}
                  propertyUpdate={propertyUpdate}
                  isUpdate={isUpdate}
                />
                <EnquiryPopup
                  enquiryshow={enquiryshow}
                  enquiryClosePopup={enquiryClosePopup}
                  enquiryDataList={enquiryDataList}
                  enquiryUpdate={enquiryUpdate}
                  isUpdate={isUpdate}
                />
                <EnquiryDeletePopup
                  enquiryDeleteHandleClose={enquiryDeleteHandleClose}
                  enquiryDeteteShow={enquiryDeteteShow}
                  enquiryDataList={enquiryDataList}
                  id={id}
                />
                <ColorPopup
                  colorshow={colorshow}
                  colorClosePopup={colorClosePopup}
                  colorDataList={colorDataList}
                  colorNameUpdate={colorNameUpdate}
                  colorList={colorList}
                  isUpdate={isUpdate}
                />
                <ColorDeletePopup
                  ColorDeleteHandleClose={ColorDeleteHandleClose}
                  colorDeteteShow={colorDeteteShow}
                  colorDataList={colorDataList}
                  id={id}
                />

                <Alert
                  alertShow={alertShow}
                  alertHandleClose={alertHandleClose}
                  alertHandleShow={alertHandleShow}
                />
                <VanDetetePopup
                  vanDeteteShow={vanDeteteShow}
                  vanDeleteHandleClose={vanDeleteHandleClose}
                  vanDeleteHandleShow={vanDeleteHandleShow}
                  id={id}
                  vehicleTypeList={vehicleTypeList}
                />

                <PropertyDelete
                  propertyDeteteShow={propertyDeteteShow}
                  propertyDeleteHandleClose={propertyDeleteHandleClose}
                  vanDeleteHandleShow={vanDeleteHandleShow}
                  propertyDataList={propertyDataList}
                  id={id}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Metadata;
