import React, { useState } from "react";
import logo from "../../Assests/Images/logo.png";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./login.module.css";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import Container from "react-bootstrap/esm/Container";
import { useForm } from "react-hook-form";
import { Validation } from "../../Validation/Validation";
import Toster from "../../Utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../Redux/Slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";

const Login = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const userDetails = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const trimmedEmail = data.email.trim();
    const trimmedPassword = data.password.trim();

    const PostData = {
      email: trimmedEmail,
      password: trimmedPassword,
    };

    dispatch(userLogin(PostData)).then((res) => {
      const response = res.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      // const ResponseCode = STATUS_CODES[response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        // Toster(t("LOGIN_SUCCESSFULLY"), "success");
        navigate("/dashboard");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      <div className={styles.loginMain}>
        <Container>
          <div className={styles.loginInner}>
            <div className={styles.loginBoxInner}>
              <div className={styles.loginBoxTop}>
                <div className={styles.loginBoxLogo}>
                  <div className={styles.loginLogoIn}>
                    <img src={logo} alt="profile img" />
                  </div>
                </div>
                <form
                  className={styles.loginForm}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className={styles.formGroup}>
                    <label htmlFor="username" className={styles.loginLable}>
                      {t("EMAIL")}
                    </label>
                    <br />
                    <input
                      type="text"
                      name="email"
                      placeholder={`${t("ENTER")} ${t("EMAIL")}`}
                      {...register("email", Validation.email)}
                      onChange={(e) => {
                        setValue("email", e.target.value.replace(/\s/g, ""), {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {formState.errors.email && (
                      <p style={{ color: "red" }}>
                        {t(formState.errors.email.message)}
                      </p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.loginLable} htmlFor="password">
                      {t("PASSWORD")}
                    </label>
                    <br />

                    <input
                      type={isVisible ? "text" : "password"}
                      placeholder={`${t("ENTER")} ${t("PASSWORD")}`}
                      name="password"
                      {...register("password", Validation.password)}
                      onChange={(e) => {
                        setValue(
                          "password",
                          e.target.value.replace(/\s/g, ""),
                          {
                            shouldValidate: true,
                          }
                        );
                      }}
                    />
                    {formState.errors.password && (
                      <p style={{ color: "red", mb: 1 }}>
                        {t(formState.errors.password.message)}
                      </p>
                    )}
                    {isVisible ? (
                      <FaRegEye
                        className="faRegEye"
                        onClick={() => setIsVisible(false)}
                      />
                    ) : (
                      <FaRegEyeSlash
                        className="faRegEye"
                        onClick={() => setIsVisible(true)}
                      />
                    )}
                  </div>

                  <div className={styles.forgetPassword}>
                    <NavLink to="/forgot-password">
                      {t("FORGOT_PASSWORD")}
                    </NavLink>
                  </div>
                  <div className={styles.loginButton}>
                    <button type="submit">{t("LOGIN")}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* {userDetails.isLoading && <Loader />} */}
    </>
  );
};

export default Login;
