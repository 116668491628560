import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "./metadata.module.css";
import { useTranslation } from "react-i18next";
import { Validation } from "../../Validation/Validation";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";

const EnquiryPopup = ({
  enquiryshow,
  enquiryClosePopup,
  enquiryDataList,
  isUpdate,
  enquiryUpdate,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue, formState, reset, clearErrors } =
    useForm({
      mode: "onChange",
    });

  const clearData = () => {
    setValue("EnquiryCategory", "");
  };

  useEffect(() => {
    if (isUpdate === false) {
      reset();
      clearErrors();
    }
  }, [enquiryshow]);

  useEffect(() => {
    reset();
    if (Object.keys(enquiryUpdate).length > 0) {
      setValue("EnquiryCategory", enquiryUpdate.value_name);
    } else {
      setValue("EnquiryCategory", "");
    }
  }, [enquiryUpdate, enquiryshow]);

  const onSubmit = (data) => {
    isUpdate === true ? updateApi(data) : createApi(data);
  };

  const createApi = (data) => {
    const trimmedEnquiryCategory =
      data && data.EnquiryCategory && data.EnquiryCategory.trim();

    var PostData = new FormData();
    PostData.append("master_id", enquiryUpdate.id ? enquiryUpdate.id : "");
    PostData.append("name", trimmedEnquiryCategory);
    PostData.append("keyName", "B2B");
    setIsLoading(true);
    let res = SwiftVanApi.postMasterDataList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("CREATE_ENQUIRY_SUCCESSFULLY"), "success");
        enquiryDataList();
        enquiryClosePopup();
        clearData();
        reset();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const updateApi = (data) => {
    const trimmedEnquiryCategory =
      data && data.EnquiryCategory && data.EnquiryCategory.trim();

    var PostData = new FormData();
    PostData.append("master_id", enquiryUpdate.id ? enquiryUpdate.id : "");
    PostData.append("name", trimmedEnquiryCategory);
    PostData.append("keyName", "B2B");
    setIsLoading(true);
    let res = SwiftVanApi.UpdateMasterDataList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("CREATE_ENQUIRY_UPDATE_SUCCESSFULLY"), "success");
        enquiryDataList();
        enquiryClosePopup();
        clearData();
        reset();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={enquiryshow} onHide={enquiryClosePopup}>
        <div className="helperPopupMain">
          <div className="addVanPopupMain">
            <Modal.Header>
              {enquiryUpdate && enquiryUpdate.id ? (
                <Modal.Title>{t("UPDATE_B2B_ENQUIRY_CATEGORY")}</Modal.Title>
              ) : (
                <Modal.Title>{t("ADD_B2B_ENQUIRY_CATEGORY")}</Modal.Title>
              )}

              <MdCancel onClick={enquiryClosePopup} />
            </Modal.Header>

            <Modal.Body>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("CATEGORY")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="EnquiryCategory"
                        {...register(
                          "EnquiryCategory",
                          Validation.EnquiryCategory
                        )}
                        onChange={(e) => {
                          setValue("EnquiryCategory", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        placeholder={t("ENTER_CATEGORY_TYPE")}
                        autoComplete="off"
                      />
                      {formState.errors.EnquiryCategory && (
                        <p style={{ color: "red", mb: 1 }}>
                          {t(formState.errors.EnquiryCategory.message)}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className={Styles.AddVanBtn}>
                  {enquiryUpdate && enquiryUpdate.id ? (
                    <button type="submit">{t("UPDATE_CATEGORY_BTN")}</button>
                  ) : (
                    <button type="submit">{t("ADD_CATEGORY")}</button>
                  )}
                </div>
              </form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EnquiryPopup;
