import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Validation } from "../../Validation/Validation";
import { MdCancel } from "react-icons/md";
import Styles from "./Review.module.css";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Utils/Toster";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Loader from "../../Validation/LoadingSpinner";
import { Rating } from "@mui/material";

const ReviewPopup = ({
  reviewPopupShow,
  ReviewPopupHandleClose,
  SetReviewPopupShow,
  ReviewDataList,
  reviewUpdateData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [ratingUpdate, setRatingUpdate] = useState("");

  const { register, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onChange",
  });

  const handleRatingChange = (newValue) => {
    setRatingUpdate(newValue);
  };

  useEffect(() => {
    reset();
    if (Object.keys(reviewUpdateData).length > 0) {
      setValue(
        "ReviewTitle",
        reviewUpdateData ? reviewUpdateData.review_title : ""
      );
      setValue(
        "ReviewDescription",
        reviewUpdateData ? reviewUpdateData.review_desc : ""
      );
      setRatingUpdate(reviewUpdateData ? reviewUpdateData.rating : "");
    }
  }, [reviewUpdateData, reviewPopupShow]);

  const onSubmit = (data) => {
    updateApi(data);
  };

  // ---review update
  const updateApi = (data) => {
    const trimmedReviewTitle =
      data && data.ReviewTitle && data.ReviewTitle.trim();
    const trimmedReviewDescription =
      data && data.ReviewDescription && data.ReviewDescription.trim();

    var PostData = new FormData();
    PostData.append(
      "rating_id",
      reviewUpdateData.id ? reviewUpdateData.id : ""
    );
    PostData.append("review_title", trimmedReviewTitle);
    PostData.append("review_desc", trimmedReviewDescription);
    PostData.append("rating", Number(ratingUpdate));

    setIsLoading(true);
    let res = SwiftVanApi.postReviewData(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_RATING_SUCCESSFULLY"), "success");
        ReviewDataList();
        SetReviewPopupShow(false);
        setIsLoading(false);
        reset();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    reset();
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={reviewPopupShow} onHide={ReviewPopupHandleClose}>
        <div className="CouponPopupMain">
          <div className="addVanPopupMain">
            <div className={Styles.reviewMain}>
              <Modal.Header>
                <Modal.Title>{t("ADD_REVIEW")} </Modal.Title>

                <MdCancel onClick={ReviewPopupHandleClose} />
              </Modal.Header>

              <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="popupInput">
                        <Form.Label>{t("TITLE")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="ReviewTitle"
                          {...register("ReviewTitle", Validation.ReviewTitle)}
                          onChange={(e) => {
                            setValue("ReviewTitle", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          placeholder={t("ENTER_TITLE")}
                          autoComplete="off"
                        />
                        {formState.errors.ReviewTitle && (
                          <p style={{ color: "red", mb: 1 }}>
                            {t(formState.errors.ReviewTitle.message)}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="popupInput">
                        <Form.Label>{t("REVIEW_RATING")}</Form.Label>
                        <div className={Styles.ratingdiv}>
                          <Rating
                            name="ReviewRating"
                            onChange={(e, newValue) => {
                              handleRatingChange(newValue);
                            }}
                            value={ratingUpdate}
                            precision={1}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="popupInput">
                        <Form.Label>{t("DESCRIPTION")}</Form.Label>

                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="ReviewDescription"
                          placeholder={`${t("ENTER_DESCRIPTION")}`}
                          autoComplete="off"
                          {...register(
                            "ReviewDescription",
                            Validation.ReviewDescription
                          )}
                          onChange={(e) => {
                            setValue("ReviewDescription", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                        {formState.errors.ReviewDescription && (
                          <p style={{ color: "red" }}>
                            {t(formState.errors.ReviewDescription.message)}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className={Styles.AddVanBtn}>
                    <button type="submit">{t("ADD_REVIEW")}</button>
                  </div>
                </Form>
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReviewPopup;
