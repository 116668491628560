import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar/Sidebar";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputSearch from "../../Assests/Images/uil_search.png";
import { jobStatus, jobTrackingStatus } from "../../mockData";
import { Col, Row } from "react-bootstrap";
import Styles from "../User/UserDetails.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useDispatch, useSelector } from "react-redux";
import { SlOptions } from "react-icons/sl";
import { LIST_FOR, PAGE } from "../../Utils/Constant";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Loader from "../../Validation/LoadingSpinner";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { formatPhoneNumber } from "../../Utils/FormateMobileNumber";
import { calculateTotalAmountWithDiscount } from "../../Utils/CalculateAmountJob";
import { RangePicker, rangePresets } from "../../Utils/DateRange";

//-----function for user details-------
const UserDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const { id } = useParams();
  const [userData, setUserData] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [jobList, setJobList] = useState();
  const [search, setSearch] = useState("");
  const [filterByStartDate, setFilterByStartDate] = useState(null);
  const [filterByEndDate, setFilterByEndDate] = useState(null);
  const [filterByTrackJob, setFilterByTrackJob] = useState("");
  const [filterByJobStatus, setFilterByJobStatus] = useState("");
  const [filterByCategory, setFilterByCategory] = useState("");
  const [categegory, setCategegory] = useState("");
  const itemsPerPage = PAGE.PAGE_COUNT;
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const handleDateRangeChange = (dates) => {
    if (!dates) {
      resetJobList();
      return;
    }
    const [start, end] = dates;
    setFilterByStartDate(start);
    setFilterByEndDate(end);
  };

  // function for change category
  const ChangeCategory = (e) => {
    setFilterByCategory(e.target.value);
  };

  // function for change tracking status
  const trackingStatus = (e) => {
    setFilterByTrackJob(e.target.value);
  };

  // function for change job status
  const JobStatus = (e) => {
    setFilterByJobStatus(e.target.value);
  };

  // function for handle search
  const hadleSearch = (e) => {
    setSearch(e.target.value);
  };

  // function for get user details api calling
  async function getUserDetails() {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("customer_id", id);
    const details = await SwiftVanApi.userDetails(requestData, userToken);
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setUserData(details && details.data && details.data.customerData);
      setIsLoading(false);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  useEffect(() => {
    getUserDetails();
  }, [id]);

  // function for get category list
  useEffect(() => {
    async function getCategoryList(id = "", level = "") {
      const details = await SwiftVanApi.getCategoryList(userToken, id, level);
      if (details && details.code === STATUS_CODES.SUCCESS) {
        setCategegory(details && details.data);
      } else if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      }
    }
    getCategoryList();
  }, []);

  // function for get job list
  async function getJobLists(reset = false) {
    let formattedStartDate = "";
    let formattedEndDate = "";

    if (!reset && filterByStartDate && filterByEndDate) {
      formattedStartDate = dayjs(filterByStartDate).format("YYYY-MM-DD");
      formattedEndDate = dayjs(filterByEndDate).format("YYYY-MM-DD");
    }
    const data = await SwiftVanApi.getJobList(
      userToken,
      pageNumber,
      search,
      LIST_FOR.CUSTOMER,
      "",

      id,
      formattedStartDate,
      formattedEndDate,
      reset == true ? "" : filterByCategory,
      reset == true ? "" : filterByJobStatus,
      reset == true ? "" : filterByTrackJob
    );
    if (data && data.code === STATUS_CODES.SUCCESS) {
      setJobList(data && data.data && data.data.jobList);
      setTotalRecords(data && data.data && data.data.jobListCount);
    } else if (
      data &&
      data.data &&
      data.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    }
  }

  useEffect(() => {
    getJobLists();
  }, [pageNumber, search, id]);

  function resetJobList() {
    setFilterByTrackJob("");
    setFilterByJobStatus("");
    setFilterByCategory("");
    getJobLists(true);
    setFilterByEndDate("");
    setFilterByStartDate("");
    setPageNumber(1);
  }

  function applyFilterCall() {
    setPageNumber(1);
    getJobLists();
  }

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="userDetailMain">
                {/* ---userDetail--BackBtn---- */}
                <div className="userDetailsBackBtn">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/user");
                    }}
                  >
                    <svg
                      width="58"
                      height="58"
                      viewBox="0 0 58 58"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_1734_2262)">
                        <circle cx="29" cy="29" r="25" fill="#ECECEC" />
                      </g>
                      <path
                        d="M23.4114 29.958L31.578 38.1247L29.4999 40.1663L17.8333 28.4997L29.4999 16.833L31.578 18.8747L23.4114 27.0413H41.1666V29.958H23.4114Z"
                        fill="#202020"
                      />
                      <defs>
                        <filter
                          id="filter0_d_1734_2262"
                          x="0"
                          y="0"
                          width="58"
                          height="58"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1734_2262"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1734_2262"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </span>
                  <h1>{t("USER_DETAILS")}</h1>
                </div>

                {/* ---userDetail--profile---- */}
                <div className="userDetailsProfile">
                  <div className="userDetailsProfileInner">
                    <div className="driverDetailsImg">
                      <img
                        src={userData && userData.profile_image}
                        alt="profile-image"
                      />
                    </div>
                    <h1>
                      {userData && userData.first_name}{" "}
                      {userData && userData.last_name}
                    </h1>
                    <p>{userData && userData.email}</p>
                    <h5>
                      {" "}
                      {userData &&
                      userData.dial_code &&
                      !userData.dial_code.includes("+")
                        ? `+${userData && userData.dial_code}`
                        : `${
                            userData && userData.dial_code
                              ? userData.dial_code
                              : ""
                          }`}{" "}
                      {formatPhoneNumber(userData && userData.phone_number)}
                    </h5>
                  </div>
                </div>

                {/*---search-section---*/}
                <div className="topSearchSection">
                  <div className="topSearchSectionInner">
                    <h1>{t("JOB_LIST")}</h1>
                    <div className="topSearchMain">
                      <img src={InputSearch} alt="" />
                      <Form.Control
                        type="search"
                        placeholder={`${t("SEARCH_FOR_USER")}`}
                        value={search}
                        onChange={(e) => hadleSearch(e)}
                      />
                    </div>
                  </div>
                </div>

                {/* ---userDetail---dropdown-dateall */}
                <div className="UserDetailDropMenu">
                  <Row xs={1} sm={2} md={2} lg={3} xl={4}>
                    <Col className="mb-3" lg={4} xl={3}>
                      <Form.Label>{t("DATE")}</Form.Label>
                      <Form.Group className={Styles.selectDateRange}>
                        <Space
                          direction="vertical"
                          size={12}
                          style={{ width: "100%" }}
                        >
                          <RangePicker
                            inputReadOnly={true}
                            className="ant-picker-outlined"
                            presets={rangePresets}
                            value={[filterByStartDate, filterByEndDate]}
                            onChange={handleDateRangeChange}
                            format="DD/MM/YYYY"
                          />
                        </Space>
                      </Form.Group>
                    </Col>

                    <Col className="mb-3" lg={4} xl={3}>
                      <Form.Label>{t("JOB_TRACK")}</Form.Label>
                      <Form.Select
                        className="inputDrop"
                        aria-label="Default select example"
                        type="date"
                        value={filterByTrackJob}
                        onChange={(value) => trackingStatus(value)}
                      >
                        <option key={0} value="">
                          {t("SELECT_STATUS")}{" "}
                        </option>
                        {jobTrackingStatus.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.value}>
                              {item.text}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>

                    <Col className="mb-3" lg={4} xl={3}>
                      <Form.Label>{t("PAYMENT_STATUS")}</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="inputDrop"
                        value={filterByJobStatus}
                        onChange={(value) => JobStatus(value)}
                      >
                        <option key={0} value="">
                          {t("SELECT_PAYMENT_STATUS")}
                        </option>
                        {jobStatus.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.value}>
                              {item.text}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>

                    <Col className="mb-3" lg={4} xl={3}>
                      <Form.Label>{t("CATEGORY")}</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="inputDrop"
                        value={filterByCategory}
                        onChange={(value) => ChangeCategory(value)}
                      >
                        <option key={0} value="">
                          {t("SELECT_CATEGORY")}
                        </option>
                        {categegory &&
                          categegory.length > 0 &&
                          categegory.map((item, index) => {
                            return (
                              <option key={index + 1} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Col>

                    <Col
                      className="mb-3"
                      lg={4}
                      xl={4}
                      style={{ marginTop: "10px" }}
                    >
                      <div className="userDetailsBtn">
                        <button
                          className="filterBtn"
                          onClick={() => applyFilterCall()}
                        >
                          {t("APPLY_FILTER")}
                        </button>
                        <button
                          className="resetBtn"
                          onClick={() => resetJobList()}
                        >
                          {t("RESET")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* table */}
                <div className="tableMainSection">
                  <div className="tableMainInner">
                    <div className="tableBootomScroll">
                      <div className="userDetailsTable">
                        <Table
                          className="tabledashboard"
                          striped
                          responsive="md"
                        >
                          <thead>
                            <tr className="align-middle">
                              <th
                                className="tableThBg tablethleft  leftsidepadding"
                                style={{ minWidth: "300px" }}
                              >
                                {t("SOURCE_LOCATION")}
                              </th>
                              <th
                                className="tableThBg "
                                style={{ minWidth: "300px" }}
                              >
                                {t("DESTINATION_LOCATION")}
                              </th>

                              <th
                                className="tableThBg"
                                style={{ minWidth: "150px" }}
                              >
                                {t("DRIVER")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "100px" }}
                              >
                                {t("DATE")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "100px" }}
                              >
                                {t("PAYMENT_STATUS")}
                              </th>
                              <th
                                className="tableThBg text-center "
                                style={{ minWidth: "80px" }}
                              >
                                {t("STOPS")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "120px" }}
                              >
                                {t("JOB_TRACK")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "120px" }}
                              >
                                {t("COMMISSION")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "120px" }}
                              >
                                {t("AMOUNT")}
                              </th>
                              <th className="tableThBg tablethRight rightsidepadding">
                                {t("ACTION")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {jobList && jobList.length > 0 ? (
                              jobList.map((item, index) => {
                                return (
                                  <tr
                                    className="tableDashtd align-middle"
                                    key={index}
                                  >
                                    <td className="dashboardTdSource leftsidepadding ">
                                      <div className="tableTextThreeLine">
                                        {item && item.source_location ? (
                                          <>
                                            {capitalizeFirstLetter(
                                              item && item.source_location
                                            )}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td className="dashboardTdSource ">
                                      <div className="tableTextThreeLine">
                                        {item && item.destination_location ? (
                                          <>
                                            {capitalizeFirstLetter(
                                              item && item.destination_location
                                            )}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      className={`${Styles.tableDr} textUpperLatter`}
                                    >
                                      {item && item.driver_first_name ? (
                                        <>
                                          {item && item.driver_first_name}
                                          &nbsp;
                                          {item && item.driver_last_name}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td
                                      className={Styles.tableDate}
                                      style={{ Color: "#000000" }}
                                    >
                                      {item && item.date ? (
                                        <>
                                          {moment(item && item.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td
                                      className={`${Styles.paymentStatus} textUpperLatter`}
                                      style={{ color: item.Color }}
                                    >
                                      {item && item.job_status ? (
                                        <>
                                          {item &&
                                            item.job_status == "pending" && (
                                              <div className="PendingClass">
                                                {t("PENDING")}
                                              </div>
                                            )}
                                          {item &&
                                            item.job_status == "inprogress" && (
                                              <div>{t("INPROGRESS")}</div>
                                            )}
                                          {item &&
                                            item.job_status == "complete" && (
                                              <div className="activeClass">
                                                {t("COMPLETE")}
                                              </div>
                                            )}
                                          {item &&
                                            item.job_status ==
                                              "cancel_by_admin" && (
                                              <div className="InactiveClass">
                                                {t("CANCEL_ADMIN")}
                                              </div>
                                            )}
                                          {item &&
                                            item.job_status ==
                                              "cancel_customer" && (
                                              <div className="InactiveClass">
                                                {t("CANCEL_CUSTOMER")}
                                              </div>
                                            )}
                                          {item &&
                                            item.job_status ==
                                              "cancel_driver" && (
                                              <div className="InactiveClass">
                                                {t("CANCEL_DRIVER")}
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td className="tableTdStops text-center">
                                      {item && item.stops}
                                    </td>
                                    <td
                                      className={`${Styles.tableStatu} textUpperLatter`}
                                    >
                                      {item && item.track_status ? (
                                        <>
                                          {item &&
                                            item.track_status ==
                                              "on-the-way" && (
                                              <div className="PendingClass">
                                                {t("ON_THE_WAY")}
                                              </div>
                                            )}
                                          {item &&
                                            item.track_status == "pickup" && (
                                              <div>{t("PICKUP")}</div>
                                            )}
                                          {item &&
                                            item.track_status == "stop" && (
                                              <div className="PendingClass">
                                                {t("ON_THE_STOP")}
                                              </div>
                                            )}
                                          {item &&
                                            item.track_status ==
                                              "delivered" && (
                                              <div className="activeClass">
                                                {t("DELIVERED")}
                                              </div>
                                            )}
                                          {item &&
                                            item.track_status == "start" && (
                                              <div className="PendingClass">
                                                {t("START")}
                                              </div>
                                            )}
                                          {item &&
                                            item.track_status == "reached" && (
                                              <div className="activeClass">
                                                {t("REACHED")}
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>

                                    <td className={Styles.tableAmount}>
                                      {item &&
                                        item.payment_summary.map(
                                          (com, index) => {
                                            return (
                                              <>
                                                {com && com.admin_commission ? (
                                                  <span
                                                    className={
                                                      Styles.currencySize
                                                    }
                                                    key={index}
                                                  >
                                                    <span
                                                      className={
                                                        Styles.currancySymbol
                                                      }
                                                    >
                                                      {item &&
                                                        item.payment_summary[0]
                                                          .currency_symbol}
                                                    </span>
                                                    {parseFloat(
                                                      calculateTotalAmountWithDiscount(
                                                        item,
                                                        "commission"
                                                      )
                                                    ).toFixed(2)}
                                                  </span>
                                                ) : (
                                                  "NA "
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                    </td>

                                    <td className={Styles.tableAmount}>
                                      {item &&
                                      item.payment_summary[0].amount ? (
                                        <span className={Styles.currencySize}>
                                          <span
                                            className={Styles.currancySymbol}
                                          >
                                            {item &&
                                              item.payment_summary[0]
                                                .currency_symbol}
                                          </span>
                                          {parseFloat(
                                            item &&
                                              item.payment_summary[0].amount
                                          ).toFixed(2)}
                                        </span>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>

                                    <td className="text-center tableTextStartTop rightsidepadding">
                                      <div className="TableDropdown">
                                        <Dropdown>
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <div className="optionListNew">
                                              <SlOptions />
                                            </div>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                            <Dropdown.Item
                                              onClick={() => {
                                                navigate(
                                                  `/jobs/job-details/${item.id}`
                                                );
                                              }}
                                            >
                                              {t("VIEW_DROPDOWN")}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="centeredCell">
                                  <div className="dataRecord">
                                    {t("NO_REC_FOUND")}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    {/* pagination */}
                    <div className="tablePaginationDash">
                      {totalRecords > itemsPerPage && (
                        <ReactPaginate
                          previousLabel={<MdKeyboardArrowLeft />}
                          nextLabel={<MdKeyboardArrowRight />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          pageCount={Math.ceil(totalRecords / itemsPerPage)}
                          breakLabel={<span className="page-link">...</span>}
                          marginPagesDisplayed={0}
                          forcePage={pageNumber - 1}
                          pageRangeDisplayed={window.innerWidth > 768 ? 10 : 2}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UserDetails;
