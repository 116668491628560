import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Styles from "./Faq.module.css";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react/dist/iconify.js";
import AddFaq from "./AddFaq";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import Loader from "../../Validation/LoadingSpinner";
import Confirm from "../CreateJob/Modals/confirm";

export default function Faq() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);

  const [showFaq, setShowFaq] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState({ id: "", show: false });

  const fetchFaqList = () => {
    setIsLoading(true);

    let res = SwiftVanApi.fetchAdminFaqList(authToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setFaqList(data.data);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    fetchFaqList();
  }, []);

  const handleAddNewFaq = () => {
    setShowFaq(true);
  };

  const handleDeleteFAQ = (id) => {
    setIsLoading(true);

    let res = SwiftVanApi.deleteAdminFaq(authToken, id);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setShowConfirm(() => ({
          id: "",
          show: false,
        }));
        setIsLoading(false);
        Toster(t("FAQ_DELETE_SUCCESS"), "success");
        fetchFaqList();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleConfirm = () => {
    handleDeleteFAQ(showConfirm.id);
  };

  const handleCancel = () => {
    setShowConfirm((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  const handleShowConfirm = (id) => {
    setShowConfirm(() => ({
      id,
      show: true,
    }));
  };

  return (
    <>
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>
            {isLoading && <Loader />}
            <div className="adminPageInner_heading">
              <div className="categoryMinSection">
                <div className={Styles.faqHeading}>
                  <h1>{t("FAQ")}</h1>
                  <span onClick={() => handleAddNewFaq()}>{t("ADD_NEW")}</span>
                </div>
                <div className={Styles.faqWrapper}>
                  {faqList.map((val) => {
                    return (
                      <>
                        <div className={Styles.faqInfo}>
                          <div className={Styles.faqSubInfo}>
                            <div className={Styles.faqTitle}>
                              {val.question}
                            </div>
                            <div className={Styles.faqAnswer}>{val.answer}</div>
                          </div>
                          <div>
                            <Icon
                              icon="material-symbols:delete-outline"
                              color="#EC3B1E"
                              height="24"
                              width="24"
                              onClick={() => handleShowConfirm(val.id)}
                            />
                          </div>
                        </div>
                        <div className={Styles.dividerLine}></div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddFaq
        showFaq={showFaq}
        setShowFaq={setShowFaq}
        fetchFaqList={fetchFaqList}
      />
      <Confirm
        show={showConfirm.show}
        subTitle={t("SURE_DELETE_FAQ")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  );
}
