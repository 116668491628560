import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "./B2Bqnquiry.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Loader from "../../Validation/LoadingSpinner";
import "react-datepicker/dist/react-datepicker.css";
import { MdCancel } from "react-icons/md";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";

const ReplyMail = ({ showReply, setShowReply, type, onSubmitSuceess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);

  const { register, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onChange",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue("email", showReply.email);
  }, [showReply]);

  const onSubmit = (data) => {
    if (type == "b2b") {
      replyToB2b(data);
    } else if (type == "support") {
      replyMailToSupport(data);
    }
  };

  const replyToB2b = (data) => {
    setIsLoading(true);
    const PostData = new FormData();
    PostData.append("enquiry_id", showReply.id);
    PostData.append("reply", data.description);

    let res = SwiftVanApi.replyB2bEnquiry(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        onSubmitSuceess();
        Toster(t("REPLIED_SUCCESS"), "success");
        setIsLoading(false);
        onClose();
        reset();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const replyMailToSupport = (data) => {
    setIsLoading(true);
    const PostData = new FormData();
    PostData.append("support_id", showReply.id);
    PostData.append("reply", data.description);

    let res = SwiftVanApi.replyToSupport(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        onSubmitSuceess();
        Toster(t("REPLIED_SUCCESS"), "success");
        setIsLoading(false);
        onClose();
        reset();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const onClose = () => {
    setShowReply((pre) => ({ ...pre, show: false }));
  };

  // disable space on key press input
  const onInputChange = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={showReply.show} onHide={() => onClose()}>
        <div className="CouponPopupMain">
          <div className="addVanPopupMain">
            <Modal.Header>
              <Modal.Title>{t("REPLY")} </Modal.Title>
              <MdCancel onClick={() => onClose()} />
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("EMAIL")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        {...register("email")}
                        placeholder={t("Enter_Email")}
                        autoComplete="off"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("DESCRIPTION")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder={`${t("ENTER_DESCRIPTION")}`}
                        autoComplete="off"
                        {...register("description", {
                          onChange: (e) => onInputChange(e),
                          required: {
                            value: true,
                            message: `${t("PLS_ENTER_DESC")}`,
                          },
                          maxLength: {
                            value: 500,
                            message: `${t("MAX_500_CHAR_ALLOWED")}`,
                          },
                        })}
                        isInvalid={!!formState.errors.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors?.description?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className={Styles.replySubmitBtn}>
                  <Button type="submit">{t("SUBMIT")}</Button>
                </div>
              </Form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReplyMail;
