import Modal from "react-bootstrap/Modal";
import styles from "./Alert.module.css";
import { useTranslation } from "react-i18next";
import { MdCancel } from "react-icons/md";

function Alert(props) {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={props.alertShow} onHide={props.alertHandleClose}>
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("ALERT")}</p>
            <span className={styles.alertCross}>
              <MdCancel onClick={props.alertHandleClose} />
            </span>
          </div>

          <div className={styles.devider}></div>
          <div className={styles.alertBodyInner}>
            <div className={styles.alertLabel}>
              <p>{t("ARE_YOU_SURE")}.</p>
            </div>

            <div className={styles.buttonContainer}>
              <button
                className={styles.buttonNo}
                onClick={props.alertHandleClose}
              >
                {t("NO")}
              </button>
              <button className={styles.buttonYes}>{t("YES")}</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Alert;
