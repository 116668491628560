import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import InputSearch from "../../Assests/Images/uil_search.png";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { driverStatus, ratingStatus } from "../../mockData";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import Styles from "../Driver/Driver.module.css";
import { Dropdown } from "react-bootstrap";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import ReactPaginate from "react-paginate";
import Toster from "../../Utils/Toster";
import { PAGE } from "../../Utils/Constant";
import { SlOptions } from "react-icons/sl";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import "../../Assests/style/common.css";
import Loader from "../../Validation/LoadingSpinner";
import { formatNumberOneDigit } from "../../Utils/FormateNumber";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { formatPhoneNumber } from "../../Utils/FormateMobileNumber";

//---function for driver list------
const Driver = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPage = PAGE.PAGE_COUNT;
  const { userToken } = useSelector((state) => state.user);
  const [driverList, setDriverList] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [rating, setRating] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // function for change document status
  const statusChange = (e) => {
    setStatus(e.target.value);
  };

  // function for handle pagination
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  // function for handle search
  const hadleSearch = (e) => {
    setSearch(e.target.value);
    setPageNumber(1);
  };

  // function for get driver list api calling
  async function getDriverList(reset = false) {
    setIsLoading(true);
    const details = await SwiftVanApi.getDriverList(
      userToken,
      pageNumber,
      search,
      reset == true ? "" : status,
      reset == true ? "" : rating
    );
    setIsLoading(false);
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setDriverList(details && details.data && details.data.driverList);
      setTotalRecords(details && details.data && details.data.driverCount);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    }
  }

  useEffect(() => {
    getDriverList();
  }, [pageNumber, search]);

  function resetDriverList() {
    setStatus("");
    setRating("");
    setPageNumber(1);
    getDriverList(true);
  }

  function applyFilterCall() {
    setPageNumber(1);
    getDriverList();
  }

  // function for change user status api calling
  async function changeUserStatus(id) {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("user_id", id);
    const details = await SwiftVanApi.changeUserStatus(requestData, userToken);
    setIsLoading(false);
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      getDriverList(true);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    } else {
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="driverMainSection">
                {/*---search-section---*/}
                <div className="topSearchSection">
                  <div className="topSearchSectionInner">
                    <h1>{t("DRIVERS")}</h1>
                    <div className="topSearchMain">
                      <img src={InputSearch} alt="" />
                      <Form.Control
                        type="search"
                        placeholder={`${t("SEARCH_BY_NAME_EMAIL")}`}
                        value={search}
                        onChange={(e) => hadleSearch(e)}
                      />
                    </div>
                  </div>
                </div>

                {/* ---userDetail---dropdown-dateall */}
                <div className="UserDetailDropMenu">
                  <Row xs={1} sm={2} md={2} lg={2} xl={4}>
                    <Col className="mb-3">
                      <Form.Label>{t("RATING")}</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="inputDrop"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      >
                        <option key={0} value="">
                          {t("SELECT_RATING")}
                        </option>
                        {ratingStatus.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.value}>
                              {item.text}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>

                    <Col className="mb-3">
                      <Form.Label>{t("DOCUMENT_VERIFICATION")}</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="inputDrop"
                        value={status}
                        onChange={(value) => statusChange(value)}
                      >
                        <option key={0} value="">
                          {t("SELECT")}
                        </option>
                        {driverStatus.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.value}>
                              {item.text}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>

                    <Col className={Styles.colRemoveResponsive}></Col>

                    <Col className="mb-3">
                      <div className="userDetailsBtn">
                        <button
                          className="filterBtn"
                          onClick={() => applyFilterCall()}
                        >
                          {t("APPLY_FILTER")}
                        </button>
                        <button
                          className="resetBtn"
                          onClick={() => {
                            resetDriverList();
                          }}
                        >
                          {t("RESET")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* table */}
                <div className="tableMainSection">
                  <div className="tableMainInner">
                    <div className="tableBootomScroll">
                      <div className="DriverTableInner">
                        <Table striped responsive="lg">
                          <thead>
                            <tr className="align-middle">
                              <th className="tableThBg tablethleft text-center">
                                {t("IMG")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "180px" }}
                              >
                                {t("NAME")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "220px" }}
                              >
                                {t("EMAIL")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "220px" }}
                              >
                                {t("UNIQUE_ID")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "180px" }}
                              >
                                {t("CONTACT_NUMBER")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "180px" }}
                              >
                                {t("COMPONY_NAME")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "80px" }}
                              >
                                {t("JOBS")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "80px" }}
                              >
                                {t("RATING")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "80px" }}
                              >
                                {t("STATUS_ITEM")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "180px" }}
                              >
                                {t("DOCUMENT_VERIFICATION")}
                              </th>
                              <th className="tableThBg tablethRight text-center">
                                {t("ACTION")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {driverList && driverList.length > 0 ? (
                              driverList.map((item, index) => {
                                return (
                                  <tr key={index} className="align-middle">
                                    <td className="tableTdText text-center tableImgMain ">
                                      <span>
                                        <img
                                          src={item && item.profile_image}
                                          alt=""
                                        />
                                      </span>
                                    </td>
                                    <td className="tableTdText textUpperLatter">
                                      {item && item.first_name ? (
                                        <>
                                          {item && item.first_name}{" "}
                                          {item && item.last_name}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td className="tableTdText">
                                      {item && item.email
                                        ? item && item.email
                                        : "NA"}
                                    </td>
                                    <td className="tableTdText">
                                      {item && item.unique_id
                                        ? item && item.unique_id
                                        : "NA"}
                                    </td>
                                    <td className="tableTdText">
                                      {item && item.phone_number ? (
                                        <>
                                          {item &&
                                          item.dial_code &&
                                          !item.dial_code.includes("+")
                                            ? `+${item && item.dial_code}`
                                            : `${item && item.dial_code}`}{" "}
                                          {formatPhoneNumber(
                                            item && item.phone_number
                                          )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td className="tableTdText">
                                      {item && item.company_name
                                        ? item.company_name
                                        : "NA"}
                                    </td>
                                    <td className="tableTdText">
                                      {item && item.jobs_count}
                                    </td>
                                    <td className="tableTdText">
                                      {item &&
                                        item.rating &&
                                        item.rating.average_rating && (
                                          <>
                                            {formatNumberOneDigit(
                                              item &&
                                                item.rating &&
                                                item.rating.average_rating
                                            )}
                                          </>
                                        )}
                                    </td>
                                    <td className="tableTdText">
                                      {item && item.is_active === 1 ? (
                                        <div className="activeClass">
                                          {t("ACTIVE")}
                                        </div>
                                      ) : (
                                        <div className="InactiveClass">
                                          {t("INACTIVE")}
                                        </div>
                                      )}
                                    </td>
                                    <td className="tableTdText">
                                      {item &&
                                        item.user_doc_status == "pending" && (
                                          <div className="PendingClass">
                                            {t("PENDING")}
                                          </div>
                                        )}
                                      {item &&
                                        item.user_doc_status == "rejected" && (
                                          <div className="InactiveClass">
                                            {t("REJECT")}
                                          </div>
                                        )}
                                      {item &&
                                        item.user_doc_status == "approved" && (
                                          <div className="activeClass">
                                            {t("APPROVED")}
                                          </div>
                                        )}
                                    </td>

                                    <td className="text-center">
                                      <div className="TableDropdown">
                                        <Dropdown>
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <div className="optionList">
                                              <SlOptions />
                                            </div>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                            <Dropdown.Item
                                              onClick={() => {
                                                navigate(
                                                  `/driver/driver-details/${item.id}`
                                                );
                                              }}
                                            >
                                              {t("VIEW_DROPDOWN")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                changeUserStatus(
                                                  item && item.id
                                                )
                                              }
                                            >
                                              {item && item.is_active === 1
                                                ? `${t("INACTIVE")}`
                                                : `${t("ACTIVE")}`}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="centeredCell">
                                  <div className="dataRecord">
                                    {t("NO_REC_FOUND")}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    {/* pagination */}
                    <div className="tablePaginationDash">
                      {totalRecords > itemsPerPage && (
                        <ReactPaginate
                          previousLabel={<MdKeyboardArrowLeft />}
                          nextLabel={<MdKeyboardArrowRight />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          pageCount={Math.ceil(totalRecords / itemsPerPage)}
                          breakLabel={<span className="page-link">...</span>}
                          marginPagesDisplayed={0}
                          forcePage={pageNumber - 1}
                          pageRangeDisplayed={window.innerWidth > 768 ? 10 : 2}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Driver;
