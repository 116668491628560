import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Styles from "../Jobs/jobs.module.css";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { Rating } from "@mui/material";
import moment from "moment";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import Loader from "../../Validation/LoadingSpinner";
import MapView from "./MapView";
import { GrFormSubtract } from "react-icons/gr";
import AssignDriverPopup from "./AssignDriverPopup";
import { Form } from "react-bootstrap";
import { calculateTotalAmountWithDiscount } from "../../Utils/CalculateAmountJob";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stack from "@mui/material/Stack";
import { formatDateTime } from "../../Utils/FormateNumber";
import activeImg from "../../Assests/Images/Reached.svg";
import inActiveImg from "../../Assests/Images/ReachedInActive.svg";
import deliveredIconActiveImg from "../../Assests/Images/Delivered_icon_active.svg";
import deliveredIconInActiveImg from "../../Assests/Images/Delivered_icon_inactive.svg";
import OnTheWayIconActiveImg from "../../Assests/Images/On_the_way_Active_Icon.svg";
import OnTheWayIconInActiveImg from "../../Assests/Images/On_the_way_InActive_Icon.svg";
import PickupIconActiveImg from "../../Assests/Images/Pickup_Order_Active_icon.svg";
import PickupIconInActiveImg from "../../Assests/Images/Pickup_Order_InActive_icon.svg";
import { AiFillCloseSquare } from "react-icons/ai";
import RemoveUserAssignedPopup from "./RemoveUserAssignedPopup";
import PaymentReleasePopup from "./PaymentReleasePopup";

import PickupIconActiveImgNew from "../../Assests/Images/pickupnewiconActive.svg";
import PickupIconInActiveImgNew from "../../Assests/Images/pickupnewiconInActive.svg";
import Confirm from "../CreateJob/Modals/confirm";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Icon } from "@iconify/react/dist/iconify.js";
import manWithBoxes from "../../Assests/Images/manWithBoxes.svg";
import Reschedule from "./Reschedule";
import ChangeDriver from "./ChangeDriver";

//----function for job details page-----------
const JobDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userToken } = useSelector((state) => state.user);
  const [jobData, setJobData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeStepNumber, setActiveStepNumber] = useState(null);
  const [assignDriver, setAssignDriver] = useState("");
  const [driverid, setDriverId] = useState("");
  const [pickupImages, setPickupImages] = useState([]);
  const [deliveredImages, setDeliveredImages] = useState([]);
  const [stopsImages, setStopsImages] = useState([]);

  // --coupon show popoup
  const [assignDriverShow, SetAssignDriverShow] = useState(false);
  const AssignDriverHandleClose = () => SetAssignDriverShow(false);
  const AssignDriverHandleShow = () => {
    AssignDriverLists();
    SetAssignDriverShow(true);
  };

  const [userAssignDelete, setUserAssignDetete] = useState(false);
  const UserAssignDeteteClosePopup = () => setUserAssignDetete(false);
  const UserAssignDeleteShowPopup = () => setUserAssignDetete(true);

  const [paymentReleasePopup, setPaymentReleasePopup] = useState(false);
  const paymentReleaseClosePopup = () => setPaymentReleasePopup(false);
  const paymentReleaseShowPopup = () => setPaymentReleasePopup(true);

  const [jobAmount, setJobAmount] = useState();
  const [jobCommission, setJobCommission] = useState();
  const [showCommission, setShowCommission] = useState();
  const [showConfirm, setShowConfirm] = useState({ id: "", show: false });
  const [driverEarning, setDriverEarning] = useState();
  const [trackingInfo, setTrackingInfo] = useState([]);
  const [dynamicSteps, setDynamicSteps] = useState([]);
  const [stopsCount, setStopsCount] = useState("");
  const [isReschedule, setIsReschedule] = useState(false);
  const [isChangeDriver, setIsChangeDriver] = useState(false);

  const steps = ["Start", "Pickup", "On the way", "Reached", "Delivered"];

  // function for get job details api calling
  async function fetchJobDetail() {
    let requestData = new FormData();
    requestData.append("job_id", id);
    setIsLoading(true);
    const details = await SwiftVanApi.jobDetails(requestData, userToken);
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setJobData(details && details.data);
      if (details.data.jobTrackList && details.data.jobTrackList.length > 0) {
        setActiveStepNumber(
          details.data.jobTrackList && details.data.jobTrackList.length - 1
        );
      } else {
        setActiveStepNumber(null);
      }
      setIsLoading(false);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  useEffect(() => {
    fetchJobDetail();
  }, [id]);

  useEffect(() => {
    if (jobData) {
      if (jobData.stops) {
        const count = jobData.stops.length;
        setStopsCount(count);
      } else {
        setStopsCount(0);
      }
      if (jobData) {
        if (jobData.jobTrackList) {
          setTrackingInfo(jobData.jobTrackList);
        }
      }
    }
  }, [jobData]);

  useEffect(() => {
    if (jobData && jobData.jobTrackList) {
      jobData.jobTrackList.map((val) => {
        if (val.action == "pickup") {
          setPickupImages(val.image);
        }
        if (val.action == "delivered") {
          setDeliveredImages(val.image);
        }

        // Process the jobTrackList to extract stop images
        const processedStopsImages = [];
        jobData.jobTrackList.forEach((item) => {
          if (
            item.action === "stop" &&
            item.image &&
            item.stop_number !== null
          ) {
            // Find the index of the stop in processedStopsImages array
            const index = processedStopsImages.findIndex(
              (stop) => stop.stop === item.stop_number
            );
            if (index === -1) {
              // If stop number not found, add a new object
              processedStopsImages.push({
                stop: item.stop_number,
                // images: [item.image[0]],
                images: item.image,
              });
            } else {
              // If stop number found, push the image to existing array
              processedStopsImages[index].images.push(item.image[0]);
            }
          }
        });

        // Set the state with processed data
        setStopsImages(processedStopsImages);
      });
    }
  }, [jobData]);

  useEffect(() => {
    if (jobData) {
      if (jobData?.payment_summary[0]?.admin_amount) {
        setJobAmount(jobData?.payment_summary[0]?.admin_amount);
      } else {
        setJobAmount(jobData?.payment_summary[0]?.amount);
      }
    }
    if (jobData) {
      if (jobData?.payment_summary[0]?.new_admin_commission) {
        setJobCommission(jobData?.payment_summary[0]?.new_admin_commission);
        setShowCommission(jobData?.payment_summary[0]?.new_admin_commission);
      } else {
        setJobCommission(jobData?.payment_summary[0]?.admin_commission);
        setShowCommission(jobData?.payment_summary[0]?.admin_commission);
      }
    }
    if (jobData) {
      if (jobData?.payment_summary[0]?.admin_amount) {
        setDriverEarning(jobData?.payment_summary[0]?.admin_amount);
      } else {
        setDriverEarning(jobData?.payment_summary[0]?.amount);
      }
    }
  }, [jobData]);

  const updatedTableList = jobData?.items?.filter((job) => {
    return job.is_custom_item === 0;
  });

  const updatedOwnItemTableList = jobData?.items?.filter((job) => {
    return job.is_custom_item === 1;
  });

  // ------------------progress-bar-----------------
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#0F7DFF",
      color: "white",

      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#0F7DFF",
      color: "white",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1:
        active || completed ? (
          <img src={PickupIconActiveImg} />
        ) : (
          <img src={PickupIconInActiveImg} />
        ),
      2:
        active || completed ? (
          <img src={PickupIconActiveImgNew} />
        ) : (
          <img src={PickupIconInActiveImgNew} />
        ),
      3:
        active || completed ? (
          <img src={OnTheWayIconActiveImg} />
        ) : (
          <img src={OnTheWayIconInActiveImg} />
        ),
      4:
        active || completed ? (
          <img src={activeImg} />
        ) : (
          <img src={inActiveImg} />
        ),
      5:
        active || completed ? (
          <img src={deliveredIconActiveImg} />
        ) : (
          <img src={deliveredIconInActiveImg} />
        ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 30,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0F7DFF",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0F7DFF",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  // ----------------assign-driver-list---------------

  const AssignDriverLists = () => {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("job_id", jobData.id);
    let res = SwiftVanApi.assignDriverList(requestData, userToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setAssignDriver(data.data);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // ----------------assign-driver-list---------------
  const JobAmountUpdate = () => {
    if (!jobAmount) {
      Toster(t("PLZ_ENTER_AMOUNT"), "error");
      return;
    }
    var PostData = new FormData();
    PostData.append("job_id", jobData.id ? jobData.id : "");
    PostData.append("amount", jobAmount);
    PostData.append("commission", jobCommission);
    setIsLoading(true);
    let res = SwiftVanApi.updateJobAmount(jobData.id, PostData, userToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_JOBE_AMOUNT_SUCCESSFULLY"), "success");
        fetchJobDetail();
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // Time of job
  const formattedDate = moment(jobData.availability_date)
    .subtract(0, "year")
    .format("DD MMM YYYY");

  const formattedTime = moment(jobData.availability_time, "HH:mm:ss").format(
    "hh:mm A"
  );

  const handleJobAmountChange = (e, type) => {
    const value = e.target.value;
    const onlyNumsAndPoint = value.replace(/[^0-9.]/g, "");
    const decimalCount = (onlyNumsAndPoint.match(/\./g) || []).length;
    if (
      value.startsWith(0) ||
      value.startsWith(".") ||
      Number(value) >= 100000000
    ) {
      return;
    }
    if (decimalCount <= 1) {
      const parts = onlyNumsAndPoint.split(".");
      if (type == "price") {
        if (parts.length === 2 && parts[1].length > 2) {
          setJobAmount(`${parts[0]}.${parts[1].substring(0, 2)}`);
        } else {
          setJobAmount(onlyNumsAndPoint);
        }
      } else {
        if (parts.length === 2 && parts[1].length > 2) {
          setJobCommission(`${parts[0]}.${parts[1].substring(0, 2)}`);
        } else {
          setJobCommission(onlyNumsAndPoint);
        }
      }
    }
  };

  const CancelJobList = (id) => {
    setIsLoading(true);
    let res = SwiftVanApi.cancelJob(userToken, id);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("CANCEL_JOB_SUCCESSFULLY"), "success");
        fetchJobDetail();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const viewFullImage = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleConfirm = () => {
    setIsLoading(true);
    let res = SwiftVanApi.unAssignJob(userToken, jobData.id);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("UNASSIGN_JOB_SUCCESSFULLY"), "success");
        setShowConfirm(() => ({
          show: false,
        }));
        fetchJobDetail();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleCancel = () => {
    setShowConfirm((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  const handleShowConfirm = () => {
    setShowConfirm(() => ({
      show: true,
    }));
  };

  useEffect(() => {
    const steps = generateSteps(stopsCount);
    setDynamicSteps(steps);
  }, [stopsCount]);

  const generateSteps = (stopsCount) => {
    const steps = ["Start", "Pickup Order"];

    if (stopsCount > 0) {
      for (let i = 1; i <= stopsCount; i++) {
        steps.push(`Via-${i} Reached`);
        steps.push(`Via-${i} Resume`);
      }
    } else {
      steps.push("On the way");
    }

    steps.push("Reached");
    steps.push("Delivered");

    return steps;
  };

  const getDynamicIcons = () => {
    const hasStops = [
      { label: "Start", icon: <LocationOnRoundedIcon /> },
      {
        label: "Pickup Order",
        icon: <img src={manWithBoxes} alt="Man with Boxes" />,
        // icon: <LocationOnRoundedIcon />,
      },
      // { label: "On the way", icon: <LocalShippingIcon /> },
      {
        label: "Reached",
        icon: (
          <Icon
            icon="mdi:package-variant-closed-delivered"
            height="25"
            width="25"
          />
        ),
      },
      { label: "Delivered", icon: <CheckCircleIcon /> },
    ];

    const notValidStops = [
      { label: "Start", icon: <LocationOnRoundedIcon /> },
      {
        label: "Pickup Order",
        icon: <img src={manWithBoxes} alt="Man with Boxes" />,
        // icon: <LocationOnRoundedIcon />,
      },
      { label: "On the way", icon: <LocalShippingIcon /> },
      {
        label: "Reached",
        icon: (
          <Icon
            icon="mdi:package-variant-closed-delivered"
            height="25"
            width="25"
          />
        ),
      },
      { label: "Delivered", icon: <CheckCircleIcon /> },
    ];

    const dynamicStepsFilter = dynamicSteps
      .filter((step) => step.includes("Via"))
      .map(() => ({
        label: "Stop",
        icon: <Icon icon="ion:hand-left" height="25" width="25" />,
      }));

    const combinedStepsWithoutStop = [
      notValidStops[0],
      notValidStops[1],
      notValidStops[2],
      // ...dynamicStepsFilter,
      notValidStops[3],
      notValidStops[4],
    ];

    const combinedStepsWithStop = [
      hasStops[0],
      hasStops[1],
      ...dynamicStepsFilter,
      hasStops[2],
      hasStops[3],
    ];

    const stepIcons = {};
    if (stopsCount !== 0) {
      combinedStepsWithStop.forEach((step, index) => {
        stepIcons[index + 1] = step.icon;
      });
      return stepIcons;
    } else {
      combinedStepsWithoutStop.forEach((step, index) => {
        stepIcons[index + 1] = step.icon;
      });
      return stepIcons;
    }
  };

  function ColorlibStepIcon2(props) {
    const { active, completed, className } = props;
    const icons = getDynamicIcons();

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const getJobTime = (ind) => {
    if (ind >= 0 && ind < trackingInfo.length) {
      return formatDateTime(trackingInfo[ind].created_at);
    }
    return ""; // Return an empty string if the index is out of bounds
  };

  const getActiveStep = useCallback(() => {
    if (jobData) {
      const arrLenght = jobData.jobTrackList.length;
      if (arrLenght !== 0) {
        return arrLenght - 1;
      } else {
        return "";
      }
    }
  }, [jobData]);

  const handleRefundPayment = async () => {
    setIsLoading(true);
    const details = await SwiftVanApi.refundAmount(userToken, id);
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      Toster(t("REFUND_PAYMENT_SUCCESS"), "success");
      setIsLoading(false);
      fetchJobDetail();
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  };

  const handleRescheduleJob = () => {
    setIsReschedule(true);
  };

  function convertMinutesToHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let formattedTime = "";
    if (hours > 0) {
      formattedTime += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    if (minutes > 0) {
      formattedTime += `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }

    return formattedTime.trim(); // Remove trailing space if no minutes
  }

  const handleReplaceDriver = () => {
    setIsChangeDriver(true);
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading hh">
              <div className="jobDetailsSection">
                {/* ---userDetail--BackBtn---- */}
                <div className="userDetailsBackBtn">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <svg
                      width="58"
                      height="58"
                      viewBox="0 0 58 58"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_1734_2262)">
                        <circle cx="29" cy="29" r="25" fill="#ECECEC" />
                      </g>
                      <path
                        d="M23.4114 29.958L31.578 38.1247L29.4999 40.1663L17.8333 28.4997L29.4999 16.833L31.578 18.8747L23.4114 27.0413H41.1666V29.958H23.4114Z"
                        fill="#202020"
                      />
                      <defs>
                        <filter
                          id="filter0_d_1734_2262"
                          x="0"
                          y="0"
                          width="58"
                          height="58"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1734_2262"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1734_2262"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </span>
                  <h1>{t("JOBS_DETAIL")}</h1>
                </div>

                <div className="jobDetailsInner">
                  {/* --Status-- */}
                  <div className={Styles.statusText}>
                    <div>
                      <span>
                        {t("PAYMENT_STATUS")}&nbsp;:&nbsp;
                        {jobData && jobData.job_status == "pending" && (
                          <p className="PendingClass">{t("PENDING")}</p>
                        )}
                        {jobData && jobData.job_status == "inprogress" && (
                          <p>{t("INPROGRESS")}</p>
                        )}
                        {jobData && jobData.job_status == "complete" && (
                          <p className="activeClass">{t("COMPLETE")}</p>
                        )}
                        {jobData && jobData.job_status == "cancel_by_admin" && (
                          <p className="InactiveClass">{t("CANCEL_ADMIN")}</p>
                        )}
                        {jobData && jobData.job_status == "cancel_customer" && (
                          <p className="InactiveClass">
                            {t("CANCEL_CUSTOMER")}
                          </p>
                        )}
                        {jobData && jobData.job_status == "cancel_driver" && (
                          <p className="InactiveClass">{t("CANCEL_DRIVER")}</p>
                        )}
                      </span>
                      <span>
                        {t("REQUEST_STATUS_TEXT")}&nbsp;:&nbsp;
                        {jobData && jobData.request_status ? (
                          <>
                            {jobData.request_status == "pending" && (
                              <div className="PendingClass">
                                {jobData.request_status}
                              </div>
                            )}
                            {jobData.request_status == "accept" && (
                              <div className="activeClass">{t("Accept")}</div>
                            )}
                            {jobData.request_status == "reject_driver" && (
                              <div className="InactiveClass">
                                {t("REJECT_DRIVER")}
                              </div>
                            )}
                          </>
                        ) : (
                          "NA"
                        )}
                      </span>
                    </div>
                    <div className={Styles.cancelSection}>
                      <div className="InactiveClass">
                        {jobData && jobData.is_admin_created == 1
                          ? t("Job created by Admin")
                          : ""}
                      </div>
                      <div>
                        {jobData && jobData.job_status === "pending" ? (
                          <button onClick={() => CancelJobList(jobData.id)}>
                            {t("CANCEL_JOB_BTN")}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {/* ---Pickup Order--- */}
                  {/* <div className={Styles.pickupOrderStatus}>
                    <div className="progressBar">
                      <Stack sx={{ width: "100%" }}>  
                        <Stepper
                          alternativeLabel
                          activeStep={activeStepNumber}
                          connector={<ColorlibConnector />}
                        >
                          {steps.map((label, index) => (
                            <Step key={index}>
                              <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <div className={Styles.statusInnerPickup}>
                                  <h1>{label}</h1>
                                  <p>
                                    {jobData.jobTrackList &&
                                      jobData.jobTrackList[index] &&
                                      formatDateTime(
                                        jobData.jobTrackList[index].created_at
                                      )}
                                  </p>
                                </div>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Stack>
                    </div>
                  </div> */}
                  <div className={Styles.pickupOrderStatus}>
                    <div className="progressBar">
                      <Stack sx={{ width: "100%" }} spacing={3}>
                        <Stepper
                          alternativeLabel
                          activeStep={getActiveStep()}
                          connector={<ColorlibConnector />}
                          className="trackJobStepper"
                        >
                          {dynamicSteps.map((step, index) => (
                            <Step key={step}>
                              <StepLabel StepIconComponent={ColorlibStepIcon2}>
                                <div className={Styles.trackLabel}>{step}</div>
                                <p className={Styles.trackingDateTime}>
                                  {getJobTime(index)}
                                </p>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Stack>
                    </div>
                  </div>

                  <Row>
                    <Col lg={8}>
                      <div className={Styles.furnitureMain}>
                        {/* ---Furniture & Other Items-- */}
                        {updatedTableList && updatedTableList.length > 0 ? (
                          <>
                            <div>
                              <div className={Styles.furnitureinnerTopText}>
                                <h1>{jobData && jobData.name}</h1>
                              </div>

                              <div className="tableMainSection">
                                <div className="tableMainInnerNew">
                                  <div className="jobdetailsTableInner">
                                    <Table
                                      className="mb-0"
                                      striped
                                      responsive="lg"
                                    >
                                      <thead>
                                        <tr className="align-middle">
                                          <th className="jobDettable subCategoryPadding">
                                            {t("SUB_CATEGORY")}
                                          </th>
                                          <th className="jobDettable">
                                            {t("ITEM_NAME")}
                                          </th>
                                          <th className="jobDettable tablethRight ">
                                            {t("QUANTITY")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {updatedTableList &&
                                        updatedTableList.length > 0 ? (
                                          updatedTableList.map(
                                            (item, index) => {
                                              return (
                                                <tr
                                                  key={index}
                                                  className="align-middle"
                                                >
                                                  <td
                                                    className="jobDettabletableTdText  subCategoryPadding"
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                  >
                                                    {item &&
                                                    item.category_name ? (
                                                      <>
                                                        {capitalizeFirstLetter(
                                                          item &&
                                                            item.category_name
                                                        )}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className="jobDettabletableTdText"
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                  >
                                                    {item && item.item ? (
                                                      <>
                                                        {capitalizeFirstLetter(
                                                          item && item.item
                                                        )}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className="jobDettabletableTdText"
                                                    style={{ minWidth: "50px" }}
                                                  >
                                                    {item && item.quantity ? (
                                                      <>
                                                        {item && item.quantity}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="7"
                                              className="centeredCell"
                                            >
                                              <div className="dataRecord">
                                                {t("NO_REC_FOUND")}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className={Styles.jobDetailsline} />
                          </>
                        ) : (
                          ""
                        )}

                        {/* --Own Item-- */}
                        <div className={Styles.ownItemTable}>
                          {updatedOwnItemTableList &&
                          updatedOwnItemTableList.length > 0 ? (
                            <>
                              <div className={Styles.furnitureinnerTopText}>
                                <h1>{t("OWN_ITEM")}</h1>
                              </div>

                              <div className="tableMainSection">
                                <div className="tableMainInnerNew">
                                  <div className="jobdetailsTableInner">
                                    <Table
                                      className="mb-0"
                                      striped
                                      responsive="lg"
                                    >
                                      <thead>
                                        <tr className="align-middle">
                                          <th className="jobDettable subCategoryPadding">
                                            {t("ITEM_NAME")}
                                          </th>
                                          <th className="jobDettable">
                                            {t("WIDTH")}
                                          </th>
                                          <th className="jobDettable">
                                            {t("HEIGHT")}
                                          </th>
                                          <th className="jobDettable">
                                            {t("DEPTH")}
                                          </th>
                                          <th className="jobDettable">
                                            {t("WEIGHT")}
                                          </th>
                                          <th className="jobDettable tablethRight ">
                                            {t("QUANTITY")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {updatedOwnItemTableList &&
                                        updatedOwnItemTableList.length > 0 ? (
                                          updatedOwnItemTableList.map(
                                            (item, index) => {
                                              return (
                                                <tr
                                                  key={index}
                                                  className="align-middle"
                                                >
                                                  <td
                                                    className="jobDettabletableTdText subCategoryPadding"
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                  >
                                                    {item && item.item ? (
                                                      <>
                                                        {capitalizeFirstLetter(
                                                          item && item.item
                                                        )}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className="jobDettabletableTdText"
                                                    style={{ minWidth: "60px" }}
                                                  >
                                                    {item && item.width ? (
                                                      <>
                                                        {item && item.width}
                                                        &nbsp;
                                                        {item && item.unit}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className="jobDettabletableTdText"
                                                    style={{ minWidth: "60px" }}
                                                  >
                                                    {item && item.height ? (
                                                      <>
                                                        {item && item.height}
                                                        &nbsp;
                                                        {item && item.unit}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className="jobDettabletableTdText"
                                                    style={{ minWidth: "60px" }}
                                                  >
                                                    {item && item.depth ? (
                                                      <>
                                                        {item && item.depth}
                                                        &nbsp;
                                                        {item && item.unit}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className="jobDettabletableTdText"
                                                    style={{ minWidth: "60px" }}
                                                  >
                                                    {item &&
                                                    item.estimated_weight ? (
                                                      <>
                                                        {item &&
                                                          item.estimated_weight}
                                                        &nbsp;
                                                        {item &&
                                                          item.weight_unit}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className="jobDettabletableTdText"
                                                    style={{ minWidth: "50px" }}
                                                  >
                                                    {item && item.quantity ? (
                                                      <>
                                                        {item && item.quantity}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="7"
                                              className="centeredCellJobDetails"
                                            >
                                              <div className="jobDetailsTableRecode">
                                                {t("NO_REC_FOUND")}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className={Styles.paymentStatus}>
                          <Row>
                            <Col lg={6}>
                              <div className={Styles.helpertabs}>
                                <h1>{t("HELPER")}</h1>
                                <p>
                                  {jobData && jobData.helper_type
                                    ? jobData.helper_type
                                    : "NA"}
                                </p>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className={Styles.helperRight}>
                                <h1>{t("TIME_OF_JOB")}</h1>
                                <div>
                                  <span>
                                    {formattedDate},&nbsp;{formattedTime}
                                  </span>
                                  {jobData.job_status == "pending" && (
                                    <Button
                                      size="sm"
                                      onClick={() => handleRescheduleJob()}
                                    >
                                      Re-schedule
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className={Styles.customerInfo}>
                                <h1>{t("CUSTOMER_INFO")}</h1>
                                <div className={Styles.customerInfoInner}>
                                  <span>
                                    <img
                                      src={
                                        jobData &&
                                        jobData.customer_profile_image
                                      }
                                    />
                                  </span>
                                  <div className={Styles.customerText}>
                                    <h1>
                                      {jobData && jobData.customer_first_name}{" "}
                                      {jobData && jobData.customer_last_name}
                                    </h1>
                                    <p>{jobData && jobData.customer_email}</p>
                                  </div>
                                </div>
                              </div>
                            </Col>

                            {jobData && jobData.driver_email !== null && (
                              <Col>
                                <div className={Styles.customerInfo}>
                                  {jobData.job_status == "pending" && (
                                    <AiFillCloseSquare
                                      onClick={() => handleShowConfirm()}
                                      className={Styles.closeIconDriverInfo}
                                    />
                                  )}
                                  {jobData.job_status == "inprogress" && (
                                    <Icon
                                      icon="ic:baseline-change-circle"
                                      onClick={() => handleReplaceDriver()}
                                      className={Styles.closeIconDriverInfo}
                                      height={25}
                                      width={25}
                                      color="red"
                                    />
                                  )}
                                  <h1>{t("DRIVER_INFO")}</h1>

                                  <div className={Styles.customerInfoInner}>
                                    <span>
                                      <img
                                        src={
                                          jobData &&
                                          jobData.driver_profile_image
                                        }
                                      />
                                    </span>
                                    <div className={Styles.customerText}>
                                      <h1>
                                        {jobData && jobData.driver_first_name}{" "}
                                        {jobData && jobData.driver_last_name}
                                      </h1>
                                      <p>{jobData && jobData.driver_email}</p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </div>

                        {jobData &&
                          (jobData.request_status === "reject_driver" ||
                            jobData.job_status === "cancel_driver" ||
                            jobData.driver_email == null) &&
                          jobData.job_status !== "cancel_by_admin" && (
                            <div className={Styles.driverInfoMain}>
                              <div className={Styles.driverInfoInner}>
                                <div className={Styles.driverInfoMainInnerTop}>
                                  <p>{t("DRIVER_INFO")}</p>
                                  {jobData.request_status ==
                                    "reject_driver" && (
                                    <span>{t("REJECTED_BTN")}</span>
                                  )}
                                </div>
                                <div className={Styles.driverInfoBootomMian}>
                                  <span>
                                    <img
                                      src={
                                        jobData &&
                                        jobData.customer_profile_image
                                      }
                                    />
                                    <button
                                      onClick={() => {
                                        AssignDriverHandleShow();
                                      }}
                                    >
                                      {t("ASSIGN_DRIVER")}
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        {jobData?.driverList?.length !== 0 ? (
                          <div className={Styles.driverInfoListMain}>
                            <div className={Styles.customerInfo}>
                              <h1>{t("DRIVER_INFO")}</h1>
                              <Row>
                                {jobData &&
                                  jobData.driverList &&
                                  jobData.driverList.map((item, index) => {
                                    return (
                                      <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                        key={index}
                                      >
                                        <div className={Styles.driverInfoList}>
                                          <AiFillCloseSquare
                                            onClick={() => {
                                              UserAssignDeleteShowPopup();
                                              setDriverId(item && item.id);
                                            }}
                                            className={
                                              Styles.closeIconDriverInfo
                                            }
                                          />
                                          <div
                                            className={Styles.customerInfoInner}
                                          >
                                            <span>
                                              <img
                                                src={
                                                  item &&
                                                  item.driver_profile_image
                                                }
                                              />
                                            </span>
                                            <div
                                              className={Styles.customerText}
                                            >
                                              <h1>
                                                {item && item.driver_first_name}{" "}
                                                {item && item.driver_last_name}
                                              </h1>
                                              <p>{item && item.driver_email}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className={Styles.paymentAmountSummary}>
                          <h6>{t("CUSTOMER_SUMMARY")}</h6>
                          <Row>
                            <Col lg={12}>
                              <div
                                className={Styles.paymentAmount}
                                style={{ borderTopRightRadius: "4px" }}
                              >
                                <div className={Styles.helpertabs}>
                                  <h1>{t("AMOUNT")}</h1>
                                  {jobData && jobData.payment_summary ? (
                                    <p>
                                      <span className={Styles.currancySymbol}>
                                        {jobData &&
                                          jobData.payment_summary[0]
                                            .currency_symbol}
                                      </span>
                                      {parseFloat(
                                        jobData &&
                                          jobData.payment_summary[0].amount
                                      ).toFixed(2)}
                                    </p>
                                  ) : (
                                    "0.00"
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className={Styles.paymentAmount}>
                                <div className={Styles.helpertabs}>
                                  <h1>
                                    {t("ADMIN_COMMISSION")}&nbsp;(
                                    {showCommission}
                                    %){" "}
                                  </h1>
                                  {jobData &&
                                  jobData.payment_summary[0]
                                    .admin_commission ? (
                                    <p>
                                      <span className={Styles.currancySymbol}>
                                        {jobData &&
                                          jobData.payment_summary[0]
                                            .currency_symbol}
                                      </span>
                                      {/* {parseFloat(
                                        calculateTotalAmountWithDiscount(
                                          jobData,
                                          "commission"
                                        )
                                      ).toFixed(2)} */}
                                      {jobData &&
                                        jobData.payment_summary[0]
                                          .admin_commission_amount}
                                    </p>
                                  ) : (
                                    "£0.00"
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className={Styles.paymentAmount}>
                                <div className={Styles.helpertabs}>
                                  <h1>
                                    {t("APPLIED_COUPON")}&nbsp;(
                                    {jobData &&
                                      jobData.payment_summary[0]
                                        .coupon_code_value}
                                    %){" "}
                                  </h1>
                                  {jobData &&
                                  jobData.payment_summary[0]
                                    .coupon_code_value ? (
                                    <p className={Styles.currancyRed}>
                                      <GrFormSubtract />
                                      <span className={Styles.currancySymbol}>
                                        {jobData &&
                                          jobData.payment_summary[0]
                                            .currency_symbol}
                                      </span>
                                      {/* {calculateTotalAmountWithDiscount(
                                        jobData,
                                        "discount"
                                      )} */}
                                      {jobData &&
                                        jobData.payment_summary[0].discount}
                                    </p>
                                  ) : (
                                    <span className={Styles.naClass}>
                                      <span className={Styles.currancySymbol}>
                                        {jobData &&
                                          jobData.payment_summary[0]
                                            .currency_symbol}
                                      </span>
                                      0.00
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Col>
                            {/* <Col lg={12}>
                              <div className={Styles.paymentAmount}>
                                <div className={Styles.helpertabs}>
                                  <h1>{t("Admin Commission")}</h1>
                                  <p>
                                    <span className={Styles.currancySymbol}>
                                      {jobData &&
                                        jobData.payment_summary[0]
                                          .currency_symbol}
                                    </span>
                                    {jobData &&
                                      jobData.payment_summary[0].processing_fee}
                                  </p>
                                </div>
                              </div>
                            </Col> */}

                            <Col lg={12}>
                              <div className={Styles.paymentAmount}>
                                <div className={Styles.helpertabs}>
                                  <h1>{t("TOTAL_AMOUNT")}</h1>
                                  {jobData &&
                                  jobData.payment_summary[0].total_amount ? (
                                    <p>
                                      <span className={Styles.currancySymbol}>
                                        {jobData &&
                                          jobData.payment_summary[0]
                                            .currency_symbol}
                                      </span>
                                      {jobData.payment_summary[0].total_amount}
                                    </p>
                                  ) : (
                                    "£0.00"
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className={Styles.paymentAmountSummary}>
                          <h6>{t("DRIVER_SUMMARY")}</h6>
                          <Row>
                            <Col lg={12}>
                              <div
                                className={Styles.paymentAmount}
                                style={{ borderTopRightRadius: "4px" }}
                              >
                                <div className={Styles.helpertabs}>
                                  <h1>{t("AMOUNT")}</h1>
                                  {jobData && jobData.payment_summary ? (
                                    <p>
                                      <span className={Styles.currancySymbol}>
                                        {jobData &&
                                          jobData.payment_summary[0]
                                            .currency_symbol}
                                      </span>
                                      {parseFloat(
                                        jobData &&
                                          jobData.payment_summary[0].amount
                                      ).toFixed(2)}
                                    </p>
                                  ) : (
                                    "0.00"
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className={Styles.paymentAmount}>
                                <div className={Styles.helpertabs}>
                                  <h1>{t("ADMIN_GIVEN_PRICE")}</h1>
                                  <div className={Styles.totalAmoutRight}>
                                    <InputGroup
                                      className={Styles.inputGroupAmount}
                                    >
                                      <InputGroup.Text
                                        className={
                                          Styles.formControlInputGroupText
                                        }
                                        id="basic-addon1"
                                      >
                                        <span className={Styles.currancySymbol}>
                                          {jobData &&
                                            jobData.payment_summary[0]
                                              .currency_symbol}
                                        </span>
                                      </InputGroup.Text>
                                      <Form.Control
                                        disabled={
                                          jobData &&
                                          jobData.job_status === "pending"
                                            ? false
                                            : true
                                        }
                                        type="text"
                                        name="JobAmount"
                                        value={jobAmount}
                                        onChange={(e) =>
                                          handleJobAmountChange(e, "price")
                                        }
                                        className={Styles.formControlInput}
                                        placeholder={t("Enter Amount")}
                                      />
                                    </InputGroup>

                                    {jobData.job_status === "pending" ? (
                                      <p>
                                        <button
                                          onClick={() => {
                                            JobAmountUpdate();
                                          }}
                                        >
                                          {t("UPDATE")}
                                        </button>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            {/* <Col lg={12}>
                              <div className={Styles.paymentAmount}>
                                <div className={Styles.helpertabs}>
                                  <h1>{t("ADMIN_COMMISSION")}&nbsp;</h1>
                                  <div className={Styles.totalAmoutRight}>
                                    <InputGroup
                                      className={Styles.inputGroupAmount}
                                    >
                                      <InputGroup.Text
                                        className={
                                          Styles.formControlInputGroupText
                                        }
                                        id="basic-addon1"
                                      >
                                        <span className={Styles.currancySymbol}>
                                          %
                                        </span>
                                      </InputGroup.Text>
                                      <Form.Control
                                        disabled={
                                          jobData &&
                                          jobData.job_status === "pending"
                                            ? false
                                            : true
                                        }
                                        type="text"
                                        name="JobAmount"
                                        value={jobCommission}
                                        onChange={(e) =>
                                          handleJobAmountChange(e, "commission")
                                        }
                                        className={Styles.formControlInput}
                                        placeholder={t("Enter Amount")}
                                      />
                                    </InputGroup>

                                    {jobData.job_status === "pending" ? (
                                      <p
                                        onClick={() => {
                                          JobAmountUpdate();
                                        }}
                                      >
                                        <button>{t("UPDATE")}</button>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col> */}
                            {/* <Col lg={12}>
                              <div className={Styles.paymentAmount}>
                                <div className={Styles.helpertabs}>
                                  <h1>
                                    {t("ADMIN_COMMISSION")}&nbsp;(
                                    {showCommission}
                                    %){" "}
                                  </h1>
                                  {jobData &&
                                  jobData.payment_summary[0]
                                    .admin_commission ? (
                                    <p>
                                      <span className={Styles.currancySymbol}>
                                        {jobData &&
                                          jobData.payment_summary[0]
                                            .currency_symbol}
                                      </span>
                                      {parseFloat(
                                        calculateTotalAmountWithDiscount(
                                          jobData,
                                          "commission"
                                        )
                                      ).toFixed(2)}
                                    </p>
                                  ) : (
                                    "£0.00"
                                  )}
                                </div>
                              </div>
                            </Col> */}
                            <Col lg={12}>
                              <div className={Styles.paymentAmount}>
                                <div className={Styles.helpertabs}>
                                  <h1>{t("DRIVER_EARNING")}</h1>
                                  <p>
                                    <span className={Styles.currancySymbol}>
                                      {jobData &&
                                        jobData.payment_summary[0]
                                          .currency_symbol}
                                    </span>
                                    {/* {calculateTotalAmountWithDiscount(
                                      jobData,
                                      "driver_earning"
                                    )} */}
                                    {}
                                    {driverEarning}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div className={Styles.statusTextMain}>
                            <div className={Styles.statusText}>
                              <span>
                                {t("PAYMENT")}&nbsp;:&nbsp;
                                {jobData && jobData.payment_status ? (
                                  <>
                                    {jobData &&
                                      jobData.payment_status == "complete" && (
                                        <p className="activeClass">
                                          {t("COMPLETE")}
                                        </p>
                                      )}
                                    {jobData &&
                                      jobData.payment_status == "refunded" && (
                                        <p className="PendingClass">
                                          {t("REFUND")}
                                        </p>
                                      )}
                                    {jobData &&
                                      jobData.payment_status == "pending" && (
                                        <p>
                                          {jobData && jobData.payment_status}
                                        </p>
                                      )}
                                  </>
                                ) : (
                                  "NA"
                                )}
                              </span>
                            </div>
                            {jobData &&
                              jobData.job_status == "cancel_customer" &&
                              jobData.payment_status !== "refunded" && (
                                <div>
                                  <span>
                                    <Button
                                      variant="success"
                                      onClick={() => handleRefundPayment()}
                                    >
                                      {t("REFUND")}
                                    </Button>
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {pickupImages.length > 0 ? (
                        <div className={Styles.pickUpTitle}>
                          {t("PICKUP_IMAGE")}
                        </div>
                      ) : (
                        ""
                      )}
                      {pickupImages?.lenght !== 0 ? (
                        <div className={Styles.imageWrapper}>
                          {pickupImages.map((el) => {
                            return (
                              <div className="mb-2 mt-2">
                                <img
                                  src={el}
                                  className={Styles.pickUpImage}
                                  onClick={() => viewFullImage(el)}
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                      {stopsImages.length != 0 && (
                        <>
                          <Row className={Styles.imageWrapper}>
                            {stopsImages.map((via, index) => {
                              return (
                                <>
                                  <div className={Styles.pickUpTitle}>
                                    {`${t("VIA")}-${index + 1}`}
                                  </div>
                                  <div className={Styles.imageWrapper}>
                                    {via.images.map((val) => {
                                      return (
                                        <div className="mb-3">
                                          <img
                                            src={val}
                                            className={Styles.pickUpImage}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              );
                            })}
                          </Row>
                        </>
                      )}
                      {deliveredImages.length != 0 && (
                        <>
                          <div className={Styles.pickUpTitle}>
                            {t("DELIVERY_IMAGE")}
                          </div>
                          <div className={Styles.imageWrapper}>
                            {deliveredImages.map((val) => {
                              return (
                                <div className="mb-2 mt-2">
                                  <img
                                    src={val}
                                    className={Styles.pickUpImage}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      <div className={Styles.releasePaymentBtn}>
                        {jobData &&
                        jobData.payment_release === "request_payment" ? (
                          <button
                            onClick={() => {
                              paymentReleaseShowPopup();
                            }}
                          >
                            {t("RELEASE_PAYMENT")}
                          </button>
                        ) : (
                          " "
                        )}
                      </div>
                    </Col>

                    {/* ----right---- */}
                    <Col lg={4}>
                      <div className={Styles.MapMainSection}>
                        <div className={Styles.MapMainSectionInner}>
                          <Row>
                            <Col className="mb-3">
                              <div className={Styles.MapImg}>
                                <MapView jobData={jobData} />
                              </div>
                            </Col>
                          </Row>

                          <div className={Styles.allLocationMainDiv}>
                            <div className={Styles.sourseLocation}>
                              <p>{t("SOURCE_LOCATION")}</p>
                              <h1>
                                {jobData && jobData.source_location && (
                                  <>
                                    {capitalizeFirstLetter(
                                      jobData && jobData.source_location
                                    )}
                                  </>
                                )}
                              </h1>
                              <div className={Styles.floorDetails}>
                                <span>
                                  {jobData && jobData.source_property && (
                                    <>
                                      {t("PROPERTY")}&nbsp;:&nbsp;
                                      <p>
                                        {jobData && jobData.source_property}
                                      </p>
                                    </>
                                  )}
                                  {jobData && jobData.source_floor_name && (
                                    <>
                                      {t("FLOOR")}&nbsp;:&nbsp;
                                      <p>
                                        {jobData && jobData.source_floor_name}
                                      </p>
                                    </>
                                  )}
                                  {jobData && jobData.source_floor_name && (
                                    <>
                                      {t("LIFT")} {t("AVAILABLE")}&nbsp;:&nbsp;
                                      <p className={Styles.liftAvailableLines}>
                                        {jobData &&
                                        jobData.is_source_lift == 0 ? (
                                          <span className="InactiveClass">
                                            {t("NO")}
                                          </span>
                                        ) : (
                                          <span className="activeClass">
                                            {t("YES")}
                                          </span>
                                        )}
                                      </p>
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className={Styles.sourseLocation}>
                              <p>{t("DESTINATION_LOCATION")}</p>
                              <h1>
                                {jobData && jobData.destination_location && (
                                  <>
                                    {" "}
                                    {capitalizeFirstLetter(
                                      jobData && jobData.destination_location
                                    )}
                                  </>
                                )}
                              </h1>
                              <div className={Styles.floorDetails}>
                                <span>
                                  {jobData && jobData.destination_property && (
                                    <>
                                      {t("PROPERTY")}&nbsp;:&nbsp;
                                      <p>
                                        {jobData &&
                                          jobData.destination_property}
                                      </p>
                                    </>
                                  )}
                                  {jobData &&
                                    jobData.destination_floor_name && (
                                      <>
                                        {t("FLOOR")}&nbsp;:&nbsp;
                                        <p>
                                          {jobData &&
                                            jobData.destination_floor_name}
                                        </p>
                                      </>
                                    )}
                                  {jobData &&
                                    jobData.destination_floor_name && (
                                      <>
                                        {t("LIFT")} {t("AVAILABLE")}
                                        &nbsp;:&nbsp;
                                        <p className={Styles.liftAvailableLine}>
                                          {" "}
                                          {jobData &&
                                          jobData.is_destination_lift == 0 ? (
                                            <span className="InactiveClass">
                                              {t("NO")}
                                            </span>
                                          ) : (
                                            <span className="activeClass">
                                              {t("YES")}
                                            </span>
                                          )}
                                        </p>
                                      </>
                                    )}
                                </span>
                              </div>
                            </div>
                            <div className={Styles.destinationlocation}></div>
                          </div>
                        </div>
                        {jobData &&
                          jobData.stops &&
                          jobData.stops.length > 0 && (
                            <div className={Styles.stopsBootom}>
                              <h1>{t("VIA")}</h1>
                              {jobData &&
                                jobData.stops &&
                                jobData.stops.length > 0 &&
                                jobData.stops.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      {index !== 0 && (
                                        <span
                                          className={Styles.stopsBootomLine}
                                        />
                                      )}
                                      <div className={Styles.stopsBootomInner}>
                                        <p>
                                          {capitalizeFirstLetter(
                                            item && item.location
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                      </div>
                      {jobData.new_location && (
                        <div className={Styles.mileageAndTime}>
                          <div>
                            <span className={Styles.timeTitle}>
                              {t("NEW_LOCATION")}
                            </span>{" "}
                            :{" "}
                            <span className={Styles.timeValue}>
                              {jobData.new_location}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className={Styles.mileageAndTime}>
                        <div>
                          <span className={Styles.timeTitle}>
                            {t("TOTAL_MILEAGE")}
                          </span>{" "}
                          :{" "}
                          <span className={Styles.timeValue}>
                            {jobData &&
                              Number(jobData.total_distance)?.toFixed(2)}{" "}
                            miles
                          </span>
                        </div>
                        <div>
                          <span className={Styles.timeTitle}>
                            {t("ESTIMATED_TIME_JOB")}
                          </span>{" "}
                          :{" "}
                          <span className={Styles.timeValue}>
                            {convertMinutesToHoursAndMinutes(
                              jobData.estimated_time
                            )}
                          </span>
                        </div>
                      </div>
                      <div className={Styles.SelectVan}>
                        <div className={Styles.SelectVanInner}>
                          <h1>{jobData && jobData.vehicle_name}</h1>
                          {/* <p>TF U 157</p> */}
                        </div>
                        <span>
                          <img src={jobData && jobData.vehicle_image} />
                        </span>
                      </div>

                      {jobData &&
                        jobData.rating &&
                        jobData.rating.length > 0 && (
                          <div className={Styles.ratingSectionMain}>
                            <div className={Styles.ratingSection}>
                              <Rating
                                name="read-only"
                                value={jobData.rating[0].rating}
                                precision={0.5}
                                readOnly
                              />{" "}
                              <h5>{jobData.rating[0].rating}</h5>
                            </div>
                            <p>{jobData.rating[0].review}</p>
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <PaymentReleasePopup
        paymentReleasePopup={paymentReleasePopup}
        paymentReleaseClosePopup={paymentReleaseClosePopup}
        jobData={jobData}
        fetchJobDetail={fetchJobDetail}
      />

      <AssignDriverPopup
        assignDriverShow={assignDriverShow}
        AssignDriverHandleClose={AssignDriverHandleClose}
        assignDriver={assignDriver}
        jobData={jobData}
        fetchJobDetail={fetchJobDetail}
      />

      <RemoveUserAssignedPopup
        UserAssignDeteteClosePopup={UserAssignDeteteClosePopup}
        userAssignDelete={userAssignDelete}
        UserAssignDeleteShowPopup={UserAssignDeleteShowPopup}
        fetchJobDetail={fetchJobDetail}
        driverid={driverid}
      />
      <Confirm
        show={showConfirm.show}
        subTitle={t("SURE_UNASSIGN_DRIVER")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <Reschedule
        id={id}
        jobData={jobData}
        isReschedule={isReschedule}
        setIsReschedule={setIsReschedule}
        fetchJobDetail={fetchJobDetail}
      />
      <ChangeDriver
        jobData={jobData}
        isChangeDriver={isChangeDriver}
        setIsChangeDriver={setIsChangeDriver}
        fetchJobDetail={fetchJobDetail}
      />
    </>
  );
};

export default JobDetails;
