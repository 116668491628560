import { toast } from "react-toastify";

export default function Toster(text, status) {
  if (status === "success") {
    toast.success(text, {
      autoClose: 4000,
      toastId: text, // Use text as the toastId
      pauseOnFocusLoss: false,
      pauseOnHover: false,
    });
  } else if (status === "error") {
    toast.error(text, {
      autoClose: 4000,
      toastId: text, // Use text as the toastId
      pauseOnFocusLoss: false,
      pauseOnHover: false,
    });
  }
}
