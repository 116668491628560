import { Button, Col, Form, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import styles from "../CreateJob.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectFloor from "../Modals/selectFloor";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryList,
  fetchMasterDataList,
  floorOptions,
  getHelpersList,
  resetAllJobData,
  setActiveCategory,
  setActiveTab,
  updateIsBackToFifth,
  updateJobData,
  vehicleInfoList,
} from "../../../Redux/Slices/createJob";
import { getActiveTab } from "../helper";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@react-google-maps/api";
import SelectProperty from "../Modals/selectProperty";
import { STATUS_CODES } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import SwiftVanApi from "../../../helpers/Api";
import Toster from "../../../Utils/Toster";
import Loader from "../../../Validation/LoadingSpinner";

function First() {
  var date = new Date().getTime();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;
  const { userToken } = useSelector((state) => state.user);
  const createJobData = useSelector((state) => state.createJob);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [isHideSource, setIsHideSource] = useState(false);
  const [isHideDestination, setIsHideDestination] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${placeApiKey}&libraries=places`;
    script.async = true;
    script.onload = () => {
      setGoogleMapsLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [createJobData.activeTab]);

  useEffect(() => {
    if (
      createJobData.createJobData &&
      createJobData.createJobData.first &&
      createJobData.createJobData.first.isSubmit
    ) {
      setValue(
        "sourceLocation",
        createJobData.createJobData.first.sourceLocation
      );
      setValue(
        "destinationLocation",
        createJobData.createJobData.first.destinationLocation
      );
      setValue(
        "hasSourceLift",
        createJobData.createJobData.first.hasSourceLift
      );
      setValue(
        "hasDestinationLift",
        createJobData.createJobData.first.hasDestinationLift
      );
      setSourceFloor(createJobData.createJobData.first.sourceFloor);
      setDestinationFloor(createJobData.createJobData.first.destinationFloor);
      setStops(createJobData.createJobData.first.stops);
      setSourceGeometry({
        address: createJobData.createJobData.first.sourceLocation,
        lat: createJobData.createJobData.first.source_latitude,
        lng: createJobData.createJobData.first.source_longitude,
        postalCode: createJobData.createJobData.first.source_postalcode,
        city: createJobData.createJobData.first.source_city,
      });
      setDestinationGeometry({
        address: createJobData.createJobData.first.destinationLocation,
        lat: createJobData.createJobData.first.destination_latitude,
        lng: createJobData.createJobData.first.destination_longitude,
        postalCode: createJobData.createJobData.first.destination_postalcode,
        city: createJobData.createJobData.first.destination_city,
      });
      setSourceProperty(
        createJobData.createJobData.first.propertyType?.sourceProperty
      );
      setDestinationProperty(
        createJobData.createJobData.first.propertyType?.destinationProperty
      );
    }
  }, []);

  useEffect(() => {
    if (location.state?.fromHomePage) {
      setSourceGeometry(location.state.sourceGeometry);
      setDestinationGeometry(location.state.destinationGeometry);
      setValue("sourceLocation", location.state.sourceGeometry.address);
      setValue(
        "destinationLocation",
        location.state.destinationGeometry.address
      );

      if (location.state.selectedB2bOption == "enquiry") {
        dispatch(setActiveTab("b2bEnquiry"));
        return;
      }
    }
  }, []);

  useEffect(() => {
    const data = { userToken, key: "property_types" };
    dispatch(fetchMasterDataList(data)).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const responseData = response.data.property_types;
        const staticTypes = responseData?.filter(
          (val) => val?.value_code == "static"
        );
        const staticNames = staticTypes?.map((item) => item?.value_name);
        setPropertyTypes(responseData);
        setPropertTypesHide(staticNames);
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  }, []);

  const getSettingOptions = () => {
    setIsLoading(true);
    let res = SwiftVanApi.fetchSettingsOptionsList(userToken, "lift_time");
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response?.code === STATUS_CODES.SUCCESS) {
        setLiftTime(response.data[0]);
        setIsLoading(false);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getSettingOptions();
  }, []);

  const [showSelectFloor, setShowSelectFloor] = useState({
    show: false,
    type: "",
    value: { id: "", val: "" },
  });
  const [sourceGeometry, setSourceGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  const [destinationGeometry, setDestinationGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  const [sourceFloor, setSourceFloor] = useState({ id: "", val: "", time: "" });
  const [destinationFloor, setDestinationFloor] = useState({
    id: "",
    val: "",
    time: "",
  });

  const [liftTime, setLiftTime] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertTypesHide, setPropertTypesHide] = useState([]);

  const [showSelectProperty, setShowSelectProperty] = useState({
    show: false,
    type: "",
    value: { id: "", val: "" },
  });

  const [sourceProperty, setSourceProperty] = useState({
    id: "",
    val: "",
    time: "",
  });

  const [destinationProperty, setDestinationProperty] = useState({
    id: "",
    val: "",
    time: "",
  });

  const handleShowProperty = (value) => {
    setShowSelectProperty({
      show: true,
      type: value,
      value: value == "source" ? sourceProperty : destinationProperty,
    });
  };

  const [stops, setStops] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("lejRej");
  const autocomplete = useRef(null);
  const autocomplete2 = useRef(null);
  const autocomplete3 = useRef(null);
  const autocompleteRefs = useRef([]);

  useEffect(() => {
    dispatch(categoryList(userToken)).then((response) => {});
    dispatch(floorOptions(userToken)).then((response) => {});
    dispatch(vehicleInfoList(userToken)).then((response) => {});
    dispatch(getHelpersList(userToken)).then((response) => {});
  }, []);

  useEffect(() => {
    if (createJobData && createJobData.activeCategory) {
      setSelectedCategory(createJobData.activeCategory);
    }
  }, []);

  useEffect(() => {
    if (!createJobData.createJobData.first.isSubmit) {
      const groundFloor = createJobData?.floorOptions?.find(
        (val) => val.value_name.toLowerCase() == "Ground floor".toLowerCase()
      );
      if (groundFloor == undefined) {
        return;
      }
      setSourceFloor({
        id: groundFloor.id,
        val: groundFloor.value_name,
        time: groundFloor.time,
      });
      setDestinationFloor({
        id: groundFloor.id,
        val: groundFloor.value_name,
        time: groundFloor.time,
      });
    }
  }, [createJobData]);

  useEffect(() => {
    if (selectedCategory !== "mbk5ez") {
      setIsHideSource(false);
      setIsHideDestination(false);
      return;
    }

    if (propertTypesHide.includes(sourceProperty.val)) {
      setIsHideSource(true);
    } else {
      setIsHideSource(false);
    }
    if (propertTypesHide.includes(destinationProperty.val)) {
      setIsHideDestination(true);
    } else {
      setIsHideDestination(false);
    }
  }, [sourceProperty, destinationProperty, selectedCategory, createJobData]);

  const handleSelectFloor = (value) => {
    setShowSelectFloor({
      show: true,
      type: value,
      value: value == "source" ? sourceFloor : destinationFloor,
    });
  };

  const handleAddStops = () => {
    if (stops.length > 8) {
      Toster(t("MAXIMUM_STOPS_ALLOWED"), "error");
      return;
    }
    setStops((prevStops) => [
      ...prevStops,
      { id: date, location: "", lat: "", lng: "" },
    ]);
    autocomplete3.current = null;
  };

  const handleAddressChange = (e, stopId) => {
    const { value } = e.target;
    setStops((prevStops) =>
      prevStops.map((stop) =>
        stop.id === stopId
          ? {
              ...stop,
              location: value,
              lat: "",
              lng: "",
            }
          : stop
      )
    );
  };

  const handleAddressSelect = (place, stopId, index) => {
    setStops((prevStops) =>
      prevStops.map((stop, i) =>
        stop.id === stopId
          ? {
              ...stop,
              location:
                autocompleteRefs.current[index].current?.getPlace()
                  ?.formatted_address,
              lat: autocompleteRefs.current[index].current
                ?.getPlace()
                ?.geometry.location.lat(),
              lng: autocompleteRefs.current[index].current
                ?.getPlace()
                ?.geometry.location.lng(),
            }
          : stop
      )
    );
  };

  const handleDeleteStop = (stopId) => {
    setStops((prevStops) => prevStops.filter((stop) => stop.id !== stopId));
  };

  const areAllStopsValid = () => {
    return stops.every(
      (stop) =>
        stop.location?.trim() !== "" &&
        stop.lat !== "" &&
        stop.lat !== undefined
    );
  };

  const onSubmit = async (formData) => {
    dispatch(updateIsBackToFifth(false));
    autocomplete3.current = null;
    setShowErrorMsg(true);
    if (
      !sourceGeometry.lat ||
      !sourceGeometry.address ||
      !destinationGeometry.address ||
      !destinationGeometry.lat
    ) {
      Toster(t("SELECT_LOCATION"), "error");
      return;
    }

    if (
      (!sourceProperty.val || !destinationProperty.val) &&
      selectedCategory == "mbk5ez"
    ) {
      Toster(t("SELECT_PRO_TYPE"), "error");
      return;
    }

    if (!destinationFloor.val || !sourceFloor.val) {
      Toster(t("PLEASE_SELECT_FLOOR"), "error");
      return;
    }

    if (!areAllStopsValid()) {
      Toster(t("SELECT_ALL_STOPS"), "error");
      return;
    }
    if (createJobData && createJobData.activeCategory !== selectedCategory) {
      dispatch(resetAllJobData(false));
    }
    const finalData = {
      ...formData,
      sourceFloor,
      source_latitude: sourceGeometry.lat,
      source_longitude: sourceGeometry.lng,
      source_city: sourceGeometry.city,
      source_postalcode: sourceGeometry.postalCode,
      destination_latitude: destinationGeometry.lat,
      destination_longitude: destinationGeometry.lng,
      destination_city: destinationGeometry.city,
      destination_postalcode: destinationGeometry.postalCode,
      destinationFloor,
      liftTime,
      propertyType: { sourceProperty, destinationProperty },
      stops,
      propertTypesHide,
      isSubmit: true,
    };
    if (sourceFloor.val && destinationFloor.val) {
      dispatch(updateJobData({ step: "first", data: finalData }));
      dispatch(setActiveTab("second"));
      dispatch(setActiveCategory(selectedCategory));
    }
  };

  const onBack = () => {
    dispatch(resetAllJobData());
    dispatch(setActiveCategory(""));
    navigate("/");
  };

  const handleCategory = (id) => {
    setSelectedCategory(id);
    // dispatch(resetAllJobData(false));
  };

  const handlePlaceSelected = (place, type) => {
    const getAddressComponent = (components, type) => {
      return (
        components.find((component) => component.types.includes(type))
          ?.long_name || ""
      );
    };

    if (type === "sourceLocation") {
      const placeDetails = autocomplete.current.getPlace();
      const addressComponents = placeDetails.address_components;
      const city = getAddressComponent(addressComponents, "locality");
      const postalCode = getAddressComponent(addressComponents, "postal_code");
      setValue(
        "sourceLocation",
        autocomplete.current.getPlace().formatted_address
      );
      setSourceGeometry({
        address: placeDetails.formatted_address,
        lat: placeDetails.geometry.location.lat(),
        lng: placeDetails.geometry.location.lng(),
        city: city,
        postalCode: postalCode,
      });
    } else if (type === "destinationLocation") {
      const placeDetails = autocomplete2.current.getPlace();
      const addressComponents = placeDetails.address_components;
      const city = getAddressComponent(addressComponents, "locality");
      const postalCode = getAddressComponent(addressComponents, "postal_code");
      setValue(
        "destinationLocation",
        autocomplete2.current.getPlace().formatted_address
      );
      setDestinationGeometry({
        address: placeDetails.formatted_address,
        lat: placeDetails.geometry.location.lat(),
        lng: placeDetails.geometry.location.lng(),
        city: city,
        postalCode: postalCode,
      });
    }
  };

  const handleLocationChange = (value, type) => {
    if (type == "sourceLocation") {
      setSourceGeometry((prev) => ({
        ...prev,
        lat: "",
        lng: "",
        address: value,
      }));
    } else if (type == "destinationLocation") {
      setDestinationGeometry((prev) => ({
        ...prev,
        lat: "",
        lng: "",
        address: value,
      }));
    }
  };

  const handleKeyDown = (e, type) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
      if (type == "sourceLocation") {
        const selectedPlace = autocomplete.current?.getPlace();
        handlePlaceSelected(selectedPlace, "sourceLocation");
      } else if (type == "destinationLocation") {
        const selectedPlace = autocomplete2.current?.getPlace();
        handlePlaceSelected(selectedPlace, "destinationLocation");
      }
    }
  };

  return (
    <>
      {(createJobData.isLoading || isLoading) && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.topHead}>
            <div className="backarrow">
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div className="backarrow">
              <span className={styles.tabCount}>
                <strong>{getActiveTab(createJobData.activeTab)}</strong>
                <span>/4</span>
              </span>
            </div>
          </div>
          <div className={styles.topSubContainer}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.topSubHead}>
                <div className={styles.topHeading}>
                  <div className={styles.backarrow}>
                    <FiArrowLeft onClick={() => onBack()} />
                  </div>
                  <div>
                    <div className={styles.topSubHeadTitle}>
                      {t("Book you move in 60 sec")}
                    </div>
                    {/* <div className={styles.topSuggestText}>
                      {t("HOME_TAB_TEXT")}
                    </div>
                    <div className={styles.topServiceText}>
                      {t("PLEASE_SELECT_SERVICE_REQUIRE")}
                    </div> */}
                  </div>
                  <div className={styles.tabCounting}>
                    <strong>{getActiveTab(createJobData.activeTab)}</strong>
                    <span>/4</span>
                  </div>
                </div>

                <div className={styles.topSubHeadDesc}>
                  {/* Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type. */}
                </div>
                {/* <div className={styles.imageBox}>
                  {createJobData?.categoryList?.map((list) => {
                    return (
                      <>
                        <div className={styles.imageContainer}>
                          <img
                            src={list.icon}
                            alt="User"
                            className={styles.image}
                            style={
                              list.id == selectedCategory
                                ? {
                                    border: "2px solid #0F7DFF",
                                    borderRadius: "50%",
                                  }
                                : {}
                            }
                            onClick={() => handleCategory(list.id)}
                          />
                          <p>{t(list.name)}</p>
                        </div>
                      </>
                    );
                  })}
                </div> */}
                <div className={styles.categoryListSection}>
                  {createJobData &&
                    createJobData?.categoryList?.map((list) => {
                      return (
                        <>
                          <div className={styles.tabContainer}>
                            <p
                              onClick={() => handleCategory(list.id)}
                              style={
                                list.id == selectedCategory
                                  ? {
                                      background: "#4770EB",
                                      color: "white",
                                    }
                                  : {}
                              }
                            >
                              {t(list.name)}
                            </p>
                          </div>
                        </>
                      );
                    })}
                </div>

                <div className={styles.mainFieldContainer}>
                  <div className={styles.fieldContainer}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="formOrderSection firstTabForm collectAddress">
                          <Icon
                            icon="line-md:my-location"
                            width="28"
                            height="28"
                          />
                          {/* <Form.Label>{t("COLLECTION_ADDRESS")}</Form.Label> */}
                          {googleMapsLoaded && (
                            <Autocomplete
                              placeApiKey={placeApiKey}
                              onLoad={(auto) => {
                                autocomplete.current = auto;
                              }}
                              onPlaceChanged={(place) =>
                                handlePlaceSelected(place, "sourceLocation")
                              }
                            >
                              <input
                                type="text"
                                placeholder={t("COLLECTION_ADDRESS")}
                                value={sourceGeometry.address}
                                onChange={(e) =>
                                  handleLocationChange(
                                    e.target.value,
                                    "sourceLocation"
                                  )
                                }
                                onKeyDown={(e) =>
                                  handleKeyDown(e, "sourceLocation")
                                }
                                className="form-control"
                                style={{
                                  border:
                                    showErrorMsg && !sourceGeometry.lat
                                      ? "1px solid #dc3545"
                                      : "",
                                }}
                              />
                            </Autocomplete>
                          )}
                        </Form.Group>
                      </Col>
                      {!isHideSource && (
                        <Col lg={6}>
                          <Form.Group className="formOrderSection orderSection">
                            <div className="orderSectionLable">
                              {t("SELECT_FLOOR")}
                            </div>
                            <p
                              style={{
                                border:
                                  showErrorMsg && !sourceFloor.val
                                    ? "1px solid #dc3545"
                                    : "",
                              }}
                              onClick={() => {
                                handleSelectFloor("source");
                              }}
                            >
                              {sourceFloor.val === "" ? (
                                <>{t("SELECT_FLOOR")}</>
                              ) : (
                                <span className="valueBoldText">
                                  {sourceFloor.val}
                                </span>
                              )}
                            </p>
                            <Icon
                              icon="bxs:up-arrow"
                              color="rgba(130, 130, 130, 0.8509803921568627)"
                              width="22"
                              height="22"
                              vFlip={true}
                              onClick={() => {
                                handleSelectFloor("source");
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="liftSelection">
                            <Form.Check
                              label={t("LIFT_AVAILABLE")}
                              id={`source-lift`}
                              {...register("hasSourceLift", {
                                defaultValue: false,
                              })}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                    {selectedCategory == "mbk5ez" && (
                      <Row style={{ marginBottom: "12px" }}>
                        <Col lg={6}>
                          <Form.Group className="formOrderSection propertySection">
                            <div className="orderSectionLable">{t("")}</div>
                            <p
                              style={{
                                border:
                                  showErrorMsg && !sourceProperty.val
                                    ? "1px solid #dc3545"
                                    : "",
                              }}
                              onClick={() => {
                                handleShowProperty("source");
                              }}
                            >
                              {sourceProperty.val === "" ? (
                                <>{t("SELECT_PROPERTY")}</>
                              ) : (
                                <span className="valueBoldText">
                                  {sourceProperty.val}
                                </span>
                              )}
                            </p>
                            <Icon
                              icon="bxs:up-arrow"
                              color="rgba(130, 130, 130, 0.8509803921568627)"
                              width="22"
                              height="22"
                              vFlip={true}
                              onClick={() => {
                                handleShowProperty("source");
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    {stops.length > 0 && (
                      <div className={styles.viaContainer}>
                        <div>
                          <Row>
                            {stops.map((field, ind) => {
                              if (!autocompleteRefs.current[ind]) {
                                autocompleteRefs.current[ind] =
                                  React.createRef();
                              }
                              return (
                                <Col lg={6}>
                                  <Form.Group className="formOrderSection stopsField">
                                    <Form.Label>
                                      {t("STOP")}{" "}
                                      {stops.length > 1 ? ind + 1 : ""}
                                    </Form.Label>
                                    <Autocomplete
                                      // onLoad={(auto) => {
                                      //   autocomplete3.current = auto;
                                      // }}
                                      onLoad={(auto) => {
                                        autocompleteRefs.current[ind].current =
                                          auto;
                                      }}
                                      onPlaceChanged={(place) =>
                                        handleAddressSelect(
                                          place,
                                          field.id,
                                          ind
                                        )
                                      }
                                    >
                                      <input
                                        type="text"
                                        placeholder={t("ENTER_VIA")}
                                        value={field.location}
                                        onChange={(e) =>
                                          handleAddressChange(e, field.id)
                                        }
                                        className="form-control"
                                        style={{
                                          border:
                                            !field.lat && showErrorMsg
                                              ? "1px solid #dc3545"
                                              : "",
                                        }}
                                      />
                                    </Autocomplete>
                                    <Icon
                                      icon="ic:round-cancel"
                                      width="22"
                                      height="22"
                                      onClick={() => {
                                        handleDeleteStop(field.id);
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </div>
                    )}
                    <div className={styles.addViaBtn}>
                      <span onClick={() => handleAddStops()}>
                        {stops.length == 0 ? t("ADD_VIA") : t("ADD_MORE_VIA")}
                      </span>
                    </div>

                    <Row>
                      <Col lg={6}>
                        <Form.Group className="formOrderSection firstTabForm collectAddress">
                          {/* <Form.Label>{t("DELIVERY_ADDRESS")}</Form.Label> */}
                          <Icon
                            icon="mingcute:location-2-line"
                            width="28"
                            height="28"
                          />
                          {googleMapsLoaded && (
                            <Autocomplete
                              onLoad={(auto) => {
                                autocomplete2.current = auto;
                              }}
                              onPlaceChanged={(place) =>
                                handlePlaceSelected(
                                  place,
                                  "destinationLocation"
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={t("DELIVERY_ADDRESS")}
                                value={destinationGeometry.address}
                                onChange={(e) =>
                                  handleLocationChange(
                                    e.target.value,
                                    "destinationLocation"
                                  )
                                }
                                onKeyDown={(e) =>
                                  handleKeyDown(e, "destinationLocation")
                                }
                                className="form-control"
                                style={{
                                  border:
                                    showErrorMsg && !destinationGeometry.lat
                                      ? "1px solid #dc3545"
                                      : "",
                                }}
                              />
                            </Autocomplete>
                          )}
                        </Form.Group>
                      </Col>
                      {!isHideDestination && (
                        <Col lg={6}>
                          <Form.Group className="formOrderSection orderSection">
                            <div className="orderSectionLable">
                              {t("SELECT_FLOOR")}
                            </div>
                            <p
                              style={{
                                border:
                                  showErrorMsg && !destinationFloor.val
                                    ? "1px solid #dc3545"
                                    : "",
                              }}
                              onClick={() => {
                                handleSelectFloor("destination");
                              }}
                            >
                              {destinationFloor.val === "" ? (
                                <>{t("SELECT_FLOOR")}</>
                              ) : (
                                <span className="valueBoldText">
                                  {destinationFloor.val}
                                </span>
                              )}
                            </p>
                            <Icon
                              icon="bxs:up-arrow"
                              color="rgba(130, 130, 130, 0.8509803921568627)"
                              width="22"
                              height="22"
                              vFlip={true}
                              onClick={() => {
                                handleSelectFloor("destination");
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="liftSelection">
                            <Form.Check
                              label={t("LIFT_AVAILABLE")}
                              id={`destination-lift`}
                              {...register("hasDestinationLift", {
                                defaultValue: false,
                              })}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                    {selectedCategory == "mbk5ez" && (
                      <Row style={{ marginBottom: "12px" }}>
                        <Col lg={6}>
                          <Form.Group className="formOrderSection propertySection">
                            <div className="orderSectionLable">{t("")}</div>
                            <p
                              style={{
                                border:
                                  showErrorMsg && !destinationProperty.val
                                    ? "1px solid #dc3545"
                                    : "",
                              }}
                              onClick={() => {
                                handleShowProperty("destination");
                              }}
                            >
                              {destinationProperty.val === "" ? (
                                <>{t("SELECT_PROPERTY")}</>
                              ) : (
                                <span className="valueBoldText">
                                  {destinationProperty.val}
                                </span>
                              )}
                            </p>
                            <Icon
                              icon="bxs:up-arrow"
                              color="rgba(130, 130, 130, 0.8509803921568627)"
                              width="22"
                              height="22"
                              vFlip={true}
                              onClick={() => {
                                handleSelectFloor("destination");
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.nextBtnFooter}>
                <button className={styles.nextBtn} type="sumbit">
                  <span>{t("NEXT")}</span>
                </button>
                <div className={styles.chatFeature}>
                  {/* <img src={chatIcon} alt="chat-image" /> */}
                </div>
              </div>
            </Form>
          </div>
          <SelectFloor
            showSelectFloor={showSelectFloor}
            setShowSelectFloor={setShowSelectFloor}
            setSourceFloor={setSourceFloor}
            setDestinationFloor={setDestinationFloor}
          />
          <SelectProperty
            showSelectProperty={showSelectProperty}
            setShowSelectProperty={setShowSelectProperty}
            propertyTypes={propertyTypes}
            setSourceProperty={setSourceProperty}
            setDestinationProperty={setDestinationProperty}
          />
        </div>
      </div>
    </>
  );
}
export default First;
