import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button, Col, Form, Row } from "react-bootstrap";
import styles from "./Schedule.module.css";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import Loader from "../../Validation/LoadingSpinner";

function Reschedule({
  id,
  jobData,
  isReschedule,
  setIsReschedule,
  fetchJobDetail,
  selectDate,
  setSelectDate,
  selectTime,
  setIsDayToday,
  setShowTimeError,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);

  const [currentDate, setCurrentDate] = useState();
  const [jobTime, setJobTime] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (jobData) {
      setCurrentDate(dayjs(jobData.availability_date));
      // setJobTime(dayjs(jobData.availability_time));
      setJobTime(dayjs(jobData.availability_time, "HH:mm:ss"));
    }
  }, [jobData, isReschedule]);

  const currentTime = dayjs();

  // Add 1 hour to the current time
  const oneHourLater = currentTime.add(1, "hour");

  // Assuming selectedTime is the time selected by the user
  const selectedTime = dayjs(selectTime); // Replace with the actual selected time

  // Check if the selected time is after 1 hour from the current hour
  const isAfterOneHour = selectedTime.isAfter(oneHourLater);

  const isToday2 = (date_) => {
    const date = dayjs(selectDate.val);
    // Get the current date
    const currentDate = dayjs();
    // Check if the date is today
    const isToday = date_.isSame(currentDate, "day");
    return isToday; // Compares only the date portion
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const postData = new FormData();
    postData.append(
      "availability_date",
      dayjs(currentDate).format("YYYY-MM-DD")
    );
    postData.append("availability_time", dayjs(jobTime).format("HH:mm:ss"));
    const details = await SwiftVanApi.reScheduleJob(userToken, id, postData);
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setIsLoading(false);
      setIsReschedule(false);
      fetchJobDetail();
      Toster(t("Job rescheduled successfully."), "success");
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  };

  const onClose = () => {
    setIsReschedule(false);
  };

  return (
    <>
      <Modal
        show={isReschedule}
        onHide={() => {
          onClose();
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("Select date & time")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                onClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {isLoading && <Loader />}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                // defaultValue={currentDate}
                value={currentDate}
                onChange={(newValue) => {
                  setCurrentDate(newValue);
                }}
                views={["day"]}
                minDate={dayjs()}
              />
            </LocalizationProvider>
            <Row>
              <Col lg={12}>
                <Form.Group className="formOrderSection fifthTab timePicker">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <DesktopTimePicker
                        label={t("SELECT_TIME")}
                        value={jobTime}
                        // disabled={!selectDate.val}
                        // format={"HH:mm"}
                        ampm={false}
                        onChange={(newTime) => setJobTime(newTime)}
                        className="customTimePicker"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {/* <div className={styles.formateText}>
                    {t("CLOCK_24_FORMATE")}
                  </div> */}
                  {/* {!selectTime && showErrorMsg && (
                    <span className="validateError">{t("Select time")}</span>
                  )} */}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.floorSubmitBtn}
              onClick={() => handleSubmit()}
            >
              {t("Reschedule")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default Reschedule;
