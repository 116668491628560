import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar/Sidebar";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputSearch from "../../Assests/Images/uil_search.png";
import { useNavigate } from "react-router-dom";
import UserShowMessage from "./UserShowMessage";
import { Dropdown } from "react-bootstrap";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import ReactPaginate from "react-paginate";
import { PAGE } from "../../Utils/Constant";
import { MdRemoveRedEye } from "react-icons/md";
import { SlOptions } from "react-icons/sl";
import Loader from "../../Validation/LoadingSpinner";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { formatPhoneNumber } from "../../Utils/FormateMobileNumber";
import { STATUS_MSG } from "../../Utils/StatusMsg";

const User = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [userList, setUserList] = useState("");
  const itemsPerPage = PAGE.PAGE_COUNT;
  const [totalRecords, setTotalRecords] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // function for handle pagination
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  // function for handle search
  const hadleSearch = (e) => {
    setSearch(e.target.value);
  };

  // function for get user list api calling
  async function getUserList(pageNumber) {
    setIsLoading(true);
    const details = await SwiftVanApi.getUserList(
      userToken,
      pageNumber,
      search
    );
    setIsLoading(false);
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setUserList(details && details.data && details.data.customerList);
      setTotalRecords(details && details.data && details.data.customerCount);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    }
  }

  useEffect(() => {
    getUserList(pageNumber);
  }, [pageNumber, search]);

  // function for change user status api calling
  async function changeUserStatus(id) {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("user_id", id);
    const details = await SwiftVanApi.changeUserStatus(requestData, userToken);
    setIsLoading(false);
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      getUserList();
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    } else {
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="UserDetailsMainSection">
                {/*---search-section---*/}
                <div className="topSearchSection">
                  <div className="topSearchSectionInner">
                    <h1>{t("USERS")}</h1>
                    <div className="topSearchMain">
                      <img src={InputSearch} />
                      <Form.Control
                        type="search"
                        placeholder={`${t("SEARCH_BY_NAME_EMAIL")}`}
                        value={search}
                        onChange={(e) => hadleSearch(e)}
                      />
                    </div>
                  </div>
                </div>

                {/* table */}
                <div className="tableMainSection">
                  <div className="tableMainInner">
                    <div className="tableBootomScroll">
                      <div className="UserTableInner">
                        <Table striped responsive="lg">
                          <thead>
                            <tr className="align-middle">
                              <th className="tableThBg tablethleft text-center">
                                {t("IMG")}
                              </th>
                              <th className="tableThBg">{t("NAME")}</th>
                              <th className="tableThBg">{t("EMAIL")}</th>
                              <th className="tableThBg">
                                {t("CONTACT_NUMBER")}
                              </th>
                              <th className="tableThBg">{t("JOBS")}</th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "80px" }}
                              >
                                {t("STATUS_ITEM")}
                              </th>
                              <th className="tableThBg text-center">
                                {t("VIEW")}
                              </th>
                              <th className="tableThBg tablethRight text-center">
                                {t("ACTION")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userList && userList.length > 0 ? (
                              userList.map((item, index) => {
                                return (
                                  <tr key={index} className="align-middle">
                                    <td className="tableTdText text-center tableImgMain ">
                                      <span>
                                        <img
                                          src={item && item.profile_image}
                                          alt="img"
                                        />
                                      </span>
                                    </td>
                                    <td
                                      className="tableTdText textUpperLatter"
                                      style={{ minWidth: "180px" }}
                                    >
                                      {item && item.first_name ? (
                                        <>
                                          {item && item.first_name}{" "}
                                          {item && item.last_name}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td
                                      className="tableTdText"
                                      style={{ minWidth: "200px" }}
                                    >
                                      {item && item.email ? item.email : "NA"}
                                    </td>
                                    <td
                                      className="tableTdText"
                                      style={{ minWidth: "180px" }}
                                    >
                                      {item && item.phone_number ? (
                                        <>
                                          {item &&
                                          item.dial_code &&
                                          !item.dial_code.includes("+")
                                            ? `+${item && item.dial_code}`
                                            : `${item && item.dial_code}`}{" "}
                                          {formatPhoneNumber(
                                            item && item.phone_number
                                          )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td
                                      className="tableTdText"
                                      style={{ minWidth: "80px" }}
                                    >
                                      {item && item.jobs_count}
                                    </td>
                                    <td className="tableTdText">
                                      {item && item.is_active === 1 ? (
                                        <div className="activeClass">
                                          {t("ACTIVE")}
                                        </div>
                                      ) : (
                                        <div className="InactiveClass">
                                          {t("INACTIVE")}
                                        </div>
                                      )}
                                    </td>

                                    <td
                                      className="text-center"
                                      style={{ minWidth: "120px" }}
                                    >
                                      <MdRemoveRedEye
                                        className="eyeIcon"
                                        onClick={() => {
                                          navigate(
                                            `/user/user-details/${item.id}`
                                          );
                                        }}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <div className="TableDropdown">
                                        <Dropdown>
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <div className="optionList">
                                              <SlOptions />
                                            </div>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                            <Dropdown.Item
                                              onClick={() =>
                                                changeUserStatus(
                                                  item && item.id
                                                )
                                              }
                                            >
                                              {item && item.is_active === 1
                                                ? `${t("INACTIVE")}`
                                                : `${t("ACTIVE")}`}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="centeredCell">
                                  <div className="dataRecord">
                                    {t("NO_REC_FOUND")}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    {/* pagination */}
                    <div className="tablePaginationDash">
                      {totalRecords > itemsPerPage && (
                        <ReactPaginate
                          previousLabel={<MdKeyboardArrowLeft />}
                          nextLabel={<MdKeyboardArrowRight />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          pageCount={Math.ceil(totalRecords / itemsPerPage)}
                          breakLabel={<span className="page-link">...</span>}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={window.innerWidth > 768 ? 10 : 2}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserShowMessage target={target} show={show} />
        <Footer />
      </div>
    </>
  );
};

export default User;
