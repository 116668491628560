import React, { useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Styles from "../Jobs/jobs.module.css";
import { IoStar } from "react-icons/io5";
import { convertToHourMinuteFormat } from "../../Utils/FormateNumber";
import { Autocomplete } from "@react-google-maps/api";
import SwiftVanApi from "../../helpers/Api";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Validation/LoadingSpinner";

export default function ChangeDriver({
  jobData,
  isChangeDriver,
  setIsChangeDriver,
  fetchJobDetail,
}) {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;
  const autocomplete = useRef(null);

  const [newLocation, setNewLocation] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  const [driversData, setDriversData] = useState([]);
  const [isNoRecords, setIsNoRecords] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLocationChange = (value, type) => {
    if (type == "sourceLocation") {
      setNewLocation((prev) => ({
        ...prev,
        lat: "",
        lng: "",
        address: value,
      }));
    }
  };

  const handleKeyDown = (e, type) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
      if (type == "sourceLocation") {
        const selectedPlace = autocomplete.current?.getPlace();
        handlePlaceSelected(selectedPlace, "sourceLocation");
      }
    }
  };

  const handlePlaceSelected = (place, type) => {
    if (type === "sourceLocation") {
      const placeDetails = autocomplete.current.getPlace();
      setNewLocation({
        address: placeDetails.formatted_address,
        lat: placeDetails.geometry.location.lat(),
        lng: placeDetails.geometry.location.lng(),
      });
    }
  };

  const handleGetDriver = () => {
    if (!newLocation.lat || !newLocation.address) {
      Toster(t("SELECT_LOCATION"), "error");
      setShowErrorMsg(true);
      return;
    }
    setIsLoading(true);
    var PostData = new FormData();
    PostData.append("new_location", newLocation.address);
    PostData.append("new_latitude", newLocation.lat);
    PostData.append("new_longitude", newLocation.lng);
    let res = SwiftVanApi.getDriverShufflingList(
      userToken,
      jobData.id,
      PostData
    );
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        setDriversData(data.data);
        setIsNoRecords(data.data.length == 0 ? true : false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const AssignJobToDriver = (driver_id) => {
    var PostData = new FormData();
    PostData.append("driver_id", driver_id);
    setIsLoading(true);
    let res = SwiftVanApi.driverShufflingProcess(
      userToken,
      jobData.id,
      PostData
    );
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("NEW_DRIVER_ASSIGNED_SECCESS"), "success");
        setIsLoading(false);
        onclose();
        fetchJobDetail();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const onclose = () => {
    setNewLocation({ address: "", lat: "", lng: "" });
    setDriversData([]);
    setShowErrorMsg(false);
    setIsChangeDriver(false);
    setIsNoRecords(false);
  };

  return (
    <>
      <Modal show={isChangeDriver} onHide={() => onclose()} className="mmm">
        {isLoading && <Loader />}

        <div className="addVanPopupMain">
          <Modal.Header>
            <Modal.Title>{t("ASSIGN_DRIVER")} </Modal.Title>
            <MdCancel onClick={() => onclose()} />
          </Modal.Header>

          <Modal.Body>
            <div className={Styles.newDriverText}>{t("NEW_LOCATION")}</div>
            <div className={Styles.driverTopSection}>
              <div style={{ width: "50%" }}>
                <Form.Group className="formOrderSection firstTabForm">
                  <Autocomplete
                    placeApiKey={placeApiKey}
                    onLoad={(auto) => {
                      autocomplete.current = auto;
                    }}
                    onPlaceChanged={(place) =>
                      handlePlaceSelected(place, "sourceLocation")
                    }
                  >
                    <input
                      type="text"
                      placeholder={t("ENTER_NEW_LOCATION")}
                      value={newLocation.address}
                      onChange={(e) =>
                        handleLocationChange(e.target.value, "sourceLocation")
                      }
                      onKeyDown={(e) => handleKeyDown(e, "sourceLocation")}
                      className="form-control"
                      style={{
                        border:
                          showErrorMsg && !newLocation.lat
                            ? "1px solid #dc3545"
                            : "",
                      }}
                    />
                  </Autocomplete>
                </Form.Group>
              </div>
              <div>
                <Button onClick={() => handleGetDriver()}>
                  {t("GET_DRIVERS")}
                </Button>
              </div>
            </div>

            <div className="main">
              <div className="spaceTopManage">
                <div
                  className={Styles.topContainer}
                  style={{ marginTop: "12px", background: "none" }}
                >
                  <div className={Styles.subContainer}>
                    <Row>
                      {driversData && driversData.length > 0
                        ? driversData.map((item, index) => {
                            return (
                              <Col
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                                key={index}
                              >
                                <div className={Styles.topDriverCard}>
                                  <div className={Styles.driverRating}>
                                    <div className={Styles.driverBooked}>
                                      <div
                                        className={Styles.driverReviewRating}
                                      >
                                        {item.jobs_booked}
                                      </div>
                                      <div className={Styles.driverReviewCount}>
                                        {item.jobs_booked > 1
                                          ? t("JOBS_BOOKED")
                                          : t("JOB_BOOKED")}
                                      </div>
                                    </div>
                                    <div
                                      className={Styles.driverReviewContainer}
                                    >
                                      <div
                                        className={Styles.driverReviewRating}
                                      >
                                        <div>
                                          {item.rating.average_rating.toFixed(
                                            1
                                          )}
                                        </div>
                                        <IoStar />
                                      </div>
                                      <div className={Styles.driverReviewCount}>
                                        {item.rating.rating_count}{" "}
                                        {item.rating.rating_count > 1
                                          ? t("REVIEWS")
                                          : t("REVIEW")}
                                      </div>
                                    </div>
                                  </div>
                                  <div className={Styles.driverCard}>
                                    <div>
                                      <img
                                        src={item.image}
                                        className={Styles.driverImage}
                                        alt="driver-image"
                                      />
                                    </div>
                                    <div className={Styles.driverName}>
                                      {item.first_name} {item.last_name}
                                    </div>
                                    <div className={Styles.vanType}>
                                      {item.vehicle_name}
                                    </div>
                                    <div className={Styles.driverPrice}>
                                      {item.currency_symbol}
                                      {item &&
                                        Number(item.total_amount).toFixed(2)}
                                    </div>
                                    <div className={Styles.driverExtraHours}>
                                      {t("FOR_THE_FIRST")}{" "}
                                      <strong>
                                        {convertToHourMinuteFormat(
                                          jobData.estimated_time
                                        )}{" "}
                                        {t("HOURS")}{" "}
                                      </strong>
                                      {t("AND_THEN")} <br />{" "}
                                      <strong>
                                        {item.currency_symbol}
                                        {item.extra_per_hours_price}{" "}
                                      </strong>
                                      {t("PER_HOUR")}
                                    </div>
                                  </div>
                                  <div className={Styles.driverAccept}>
                                    {item.assigned_driver === 0 ? (
                                      <button
                                        onClick={() =>
                                          AssignJobToDriver(item.id)
                                        }
                                      >
                                        {t("Assign")}
                                      </button>
                                    ) : (
                                      <button
                                        disabled
                                        style={{ backgroundColor: "#9cd5a9" }}
                                      >
                                        {t("Assign")}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            );
                          })
                        : isNoRecords && (
                            <div className="jobDetailsTableRecode">
                              {t("NO_REC_FOUND")}
                            </div>
                          )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}
