import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function SelectProperty({
  showSelectProperty,
  setShowSelectProperty,
  propertyTypes,
  setSourceProperty,
  setDestinationProperty,
}) {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState({
    val: "",
    id: "",
  });

  useEffect(() => {
    setCurrentValue(showSelectProperty.value);
  }, [showSelectProperty]);

  const handleCheckboxChange = (id, value, time) => {
    setCurrentValue({ val: value, id: id, time: time });
    if (showSelectProperty.type == "source") {
      setSourceProperty({ val: value, id: id, time: time });
      setShowSelectProperty((prevState) => ({ ...prevState, show: false }));
    } else {
      setDestinationProperty({ val: value, id: id, time: time });
      setShowSelectProperty((prevState) => ({ ...prevState, show: false }));
    }
  };

  return (
    <>
      <Modal
        show={showSelectProperty.show}
        onHide={() => {
          setShowSelectProperty(false);
        }}
      >
        <div className="selectModal">
          <Modal.Header>
            <Modal.Title>{t("SELECT_PROPERTY")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                setShowSelectProperty(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {propertyTypes?.map((checkbox) => (
              <div
                key={checkbox.value_code}
                className="floorCheckboxes"
                onClick={(e) => {
                  handleCheckboxChange(
                    checkbox.id,
                    checkbox.value_name,
                    checkbox.time
                  );
                }}
              >
                <label
                  className={
                    currentValue?.val === checkbox.value_name
                      ? styles.activeFloor
                      : ""
                  }
                >
                  {checkbox.value_name}
                </label>
                {/* <Button
                  variant={
                    currentValue?.val === checkbox.value_name
                      ? "primary"
                      : "secondary"
                  }
                  className={`${styles.selectedBtn}`}
                  onClick={(e) => {
                    handleCheckboxChange(
                      checkbox.id,
                      checkbox.value_name,
                      checkbox.time
                    );
                  }}
                >
                  {currentValue?.val === checkbox.value_name
                    ? t("SELECTED")
                    : t("SELECT")}
                </Button> */}
              </div>
            ))}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}
