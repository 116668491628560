import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import { Dropdown } from "react-bootstrap";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { SlOptions } from "react-icons/sl";
import { PAGE } from "../../Utils/Constant";
import ReactPaginate from "react-paginate";
import B2BenquiryDeletePopup from "./B2BenquiryDeletePopup";
import Styles from "./B2Bqnquiry.module.css";
import Loader from "../../Validation/LoadingSpinner";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { formatPhoneNumber } from "../../Utils/FormateMobileNumber";
import ReplyMail from "./ReplyMail";
import moment from "moment";

const Benquiry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [bToBList, setBTOBList] = useState("");
  const itemsPerPage = PAGE.PAGE_COUNT;

  const [b2bEnquiryDelete, setShowB2bEnquiryDetete] = useState(false);
  const b2bEnquiryDeteteClosePopup = () => setShowB2bEnquiryDetete(false);
  const b2bEnquiryDeleteShowPopup = () => setShowB2bEnquiryDetete(true);
  const [id, setId] = useState("");
  const [showReply, setShowReply] = useState({ show: false, id: "" });

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  // function for get B2B Enquiry list api calling
  async function getBtoBListEnquiry() {
    setIsLoading(true);
    const details = await SwiftVanApi.getB2BEnquiry(
      userToken,
      pageNumber,
      search
    );
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setBTOBList(details && details.data && details.data.b2bEnquiryList);
      setTotalRecords(details && details.data && details.data.b2bEnquiryCount);
      setIsLoading(false);
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getBtoBListEnquiry();
  }, [pageNumber, search]);

  const handleReplyToMail = (email, id) => {
    setShowReply({ show: true, email, id });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="BenquiryMainSection">
                {/*---search-section---*/}
                <div className={Styles.b2bEnquiryTopTitle}>
                  <h5>{t("B2B_ENQUIRY")}</h5>
                </div>

                {/* table */}
                <div className="tableMainSection">
                  <div className="tableMainInner">
                    <div className="tableBootomScroll">
                      <div className="UserTableInner">
                        <Table striped responsive="lg">
                          <thead>
                            <tr className="align-middle">
                              <th
                                className="tableThBg leftsidepadding"
                                style={{ minWidth: "150px" }}
                              >
                                {t("CUSTOMER_NAME")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "50px" }}
                              >
                                {t("EMAIL")}
                              </th>

                              <th
                                className="tableThBg"
                                style={{ minWidth: "150px" }}
                              >
                                {t("CONTACT_NUMBER")}
                              </th>

                              <th
                                className="tableThBg"
                                style={{ minWidth: "150px" }}
                              >
                                {t("COMPONY_NAME")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "150px" }}
                              >
                                {t("ESTIMATED_STOPS_PER_WEEK")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "180px" }}
                              >
                                {t("CATEGORY")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "210px" }}
                              >
                                {t("DESCRIPTION")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "150px" }}
                              >
                                {t("DATE")}
                              </th>
                              <th className="tableThBg tablethRight text-center rightsidepadding">
                                {t("ACTION")}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {bToBList && bToBList.length > 0 ? (
                              bToBList.map((item, index) => {
                                return (
                                  <tr key={index} className="align-middle">
                                    <td className="tableTdText textUpperLatter leftsidepadding">
                                      {item && item.full_name
                                        ? item.full_name
                                        : "NA"}
                                    </td>
                                    <td className="tableTdTextEmail">
                                      {item && item.email ? item.email : "NA"}
                                    </td>
                                    <td className="tableTdText">
                                      {item && item.phone_number ? (
                                        <>
                                          {item &&
                                          item.dial_code &&
                                          !item.dial_code.includes("+")
                                            ? `+${item && item.dial_code}`
                                            : `${item && item.dial_code}`}{" "}
                                          {formatPhoneNumber(
                                            item && item.phone_number
                                          )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>

                                    <td className="tableTdText">
                                      <div className="tableTextThreeLine">
                                        {item && item.company_name ? (
                                          <>
                                            {capitalizeFirstLetter(
                                              item && item.company_name
                                            )}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td className="tableTdText">
                                      <div className="tableTextThreeLine">
                                        {item && item.estimated_stops ? (
                                          <>{item.estimated_stops}</>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td className="tableTdText">
                                      <div className="tableTextThreeLine">
                                        {item && item.category ? (
                                          <>
                                            {capitalizeFirstLetter(
                                              item && item.category
                                            )}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td className="tableTdText">
                                      <div
                                        className="tableTextThreeLine"
                                        title={
                                          item.description.length > 50 &&
                                          item.description
                                        }
                                        style={{
                                          cursor:
                                            item.description.length > 50 &&
                                            "pointer",
                                        }}
                                      >
                                        {item && item.description ? (
                                          <>
                                            {capitalizeFirstLetter(
                                              item && item.description
                                            )}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td className="tableTdText">
                                      <div className="tableTextThreeLine">
                                        {item && item.created_at ? (
                                          <>
                                            {moment(
                                              item && item.created_at
                                            ).format("DD/MM/YYYY")}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td className="text-center rightsidepadding">
                                      <div className="TableDropdown">
                                        <Dropdown>
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <div className="optionList">
                                              <SlOptions />
                                            </div>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                            <Dropdown.Item
                                              onClick={() => {
                                                handleReplyToMail(
                                                  item.email,
                                                  item.id
                                                );
                                              }}
                                              disabled={item.is_reply}
                                            >
                                              {!item.is_reply
                                                ? t("REPLY")
                                                : t("REPLIED")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                b2bEnquiryDeleteShowPopup();
                                                setId(item && item.id);
                                              }}
                                            >
                                              {t("DELETE_DROPDOWN")}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="centeredCell">
                                  <div className="dataRecord">
                                    {t("NO_REC_FOUND")}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    {/* pagination */}
                    <div className="tablePaginationDash">
                      {totalRecords > itemsPerPage && (
                        <ReactPaginate
                          previousLabel={<MdKeyboardArrowLeft />}
                          nextLabel={<MdKeyboardArrowRight />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          pageCount={Math.ceil(totalRecords / itemsPerPage)}
                          breakLabel={<span className="page-link">...</span>}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={window.innerWidth > 768 ? 10 : 2}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <B2BenquiryDeletePopup
        b2bEnquiryDeteteClosePopup={b2bEnquiryDeteteClosePopup}
        b2bEnquiryDelete={b2bEnquiryDelete}
        getBtoBListEnquiry={getBtoBListEnquiry}
        id={id}
      />
      <ReplyMail
        showReply={showReply}
        setShowReply={setShowReply}
        type={"b2b"}
        onSubmitSuceess={getBtoBListEnquiry}
      />
    </>
  );
};

export default Benquiry;
