import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";

const initialState = {
  currentUser: {},
  isLoading: false,
  userToken: null,
  success: false,
  errorCode: null,
  error: null,
  user_type: null,
};

// Thunk for user login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get admin notification list
export const getAdminNotificationList = createAsyncThunk(
  "user/getAdminNotificationList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SwiftVanApi.getAdminNotification(
        data.userToken,
        data.page_no
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.currentUser = {};
      state.isLoading = false;
      state.userToken = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    // user login===============
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      const responseErrorCode = response && response.data && response.data.code;

      state.isLoading = false;

      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response && response.data && response.data.token;
        state.currentUser = response && response.data;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { userLogoutClear } = userSlice.actions;
export default userSlice.reducer;
