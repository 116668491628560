import Modal from "react-bootstrap/Modal";
import styles from "../../Validation/Alert.module.css";
import { useTranslation } from "react-i18next";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";

function CategoryDeletePopup({
  categoryModal,
  setCategoryModal,
  deleteCategoryId,
  level,
  subCategory,
  setSubCategory,
  categoryItems,
  setCategoryItems,
  getCategoryList,
  setName,
  subEventKeyValue,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);

  // ----------- function for remove category----------------
  const removeCategory = async (id) => {
    setLoader(true);
    await SwiftVanApi.deleteCategory(userToken, id).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        Toster(t("CATEGORY_DELET"), "success");
        setCategoryModal(false);
        if (level === 2) {
          const subCategoryArr = [...subCategory];
          setSubCategory(
            subCategory.filter((item) => item.id !== deleteCategoryId)
          );
          if (subEventKeyValue == deleteCategoryId) {
            setCategoryItems("");
            setName("");

            if (subCategoryArr.length > 1) {
              if (subCategoryArr[0].id != deleteCategoryId) {
                getCategoryList(3, subCategoryArr[0].id, "");
                setName(subCategoryArr[0].name);
              } else {
                getCategoryList(3, subCategoryArr[1].id, "");
                setName(subCategoryArr[1].name);
              }
            } else {
              setName("");
            }
          } else {
            setName("");
          }
        } else if (level === 3) {
          setCategoryItems(
            categoryItems.filter((item) => item.id !== deleteCategoryId)
          );
        }
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
        setLoader(false);
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.MASTER_DATA_NOT_DELETE
        ) {
          Toster(t("CATEGORY_DELETE_POPUP_TOSTER_MESSAGE"), "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {loader ? <Loader /> : ""}
      <Modal
        show={categoryModal}
        onHide={() => {
          setCategoryModal(false);
        }}
      >
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("ALERT")}</p>
            <span className={styles.alertCross}>
              <MdCancel
                onClick={() => {
                  setCategoryModal(false);
                }}
              />
            </span>
          </div>

          <div className={styles.devider}></div>
          <div className={styles.alertBodyInner}>
            <div className={styles.alertLabel}>
              <p>{t("REMOVE_CATEGORY")}</p>
            </div>

            <div className={styles.buttonContainer}>
              <button
                className={styles.buttonNo}
                type="button"
                onClick={() => {
                  setCategoryModal(false);
                }}
              >
                {t("NO")}
              </button>
              <button
                className={styles.buttonYes}
                type="submit"
                onClick={() => removeCategory(deleteCategoryId)}
              >
                {t("YES")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CategoryDeletePopup;
