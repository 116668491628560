import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Form, Row } from "react-bootstrap";
import Styles from "../Commission/Commission.module.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useForm } from "react-hook-form";
import { COMMISSION_TYPE } from "../../Utils/Constant";
import Loader from "../../Validation/LoadingSpinner";
import { AiOutlinePercentage } from "react-icons/ai";

const Commission = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [commissionInput, setCommissionInput] = useState("");

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue == "" ||
      (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 99)
    ) {
      setValue("CommissionRate", inputValue, { shouldValidate: true });
      setCommissionInput(inputValue);
    }
  };

  const SettingOptionData = () => {
    setIsLoading(true);
    let res = SwiftVanApi.settingOptionList(
      authToken,
      COMMISSION_TYPE.COMMISSION
    );
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setValue("CommissionRate", data.data[0].option_value);
        setCommissionInput(data.data[0].option_value);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    SettingOptionData();
  }, []);

  const onSubmit = (data) => {
    updateApi(data);
  };

  const updateApi = (data) => {
    var PostData = new FormData();
    PostData.append("option_name", COMMISSION_TYPE.COMMISSION);
    PostData.append("option_value", Number(commissionInput));
    PostData.append("option_type", COMMISSION_TYPE.COMMISSION);

    setIsLoading(true);
    let res = SwiftVanApi.postSettingOptionList(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_COMMISSION_SUCCESSFULLY"), "success");
        SettingOptionData();
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="commissionSection">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className={Styles.commissionMainSection}>
                    <h5>{t("COMMISSION")}</h5>

                    <Row>
                      <Col lg={12}>
                        <Form.Group className="popupInput commissionPersentSign">
                          <Form.Label>{t("COMMISSION_RATE")}</Form.Label>
                          <div
                            style={{ display: "flex", position: "relative" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Form.Control
                                type="text"
                                name="CommissionRate"
                                {...register("CommissionRate", {
                                  required: {
                                    value: true,
                                    message: `${t("COMMISSION_RATE_INPUT")}`,
                                  },
                                })}
                                value={commissionInput}
                                onChange={handleInputChange}
                                placeholder={`${t("ENTER_COMMISSION")}`}
                                min={1}
                                max={99}
                              />

                              {formState.errors.CommissionRate && (
                                <p
                                  style={{
                                    color: "red",
                                    mb: "1",
                                  }}
                                >
                                  {t(formState.errors.CommissionRate.message)}
                                </p>
                              )}
                            </div>

                            <div className={Styles.minTimeInput}>
                              <div className={Styles.CategoryDevider}></div>
                              <div className={Styles.SelectDuration}>
                                <div className={Styles.SelectDurationInner}>
                                  <AiOutlinePercentage />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className={Styles.AddVanBtn}>
                      <button type="submit">{t("UPDATE")}</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Commission;
