import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";
import InputSearch from "../../Assests/Images/uil_search.png";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import Styles from "../Jobs/jobs.module.css";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { SlOptions } from "react-icons/sl";
import SwiftVanApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { jobStatus, jobTrackingStatus } from "../../mockData";
import { PAGE } from "../../Utils/Constant";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import Loader from "../../Validation/LoadingSpinner";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import dayjs from "dayjs";
import { calculateTotalAmountWithDiscount } from "../../Utils/CalculateAmountJob";
import { Space } from "antd";
import { RangePicker, rangePresets } from "../../Utils/DateRange";
import { STATUS_MSG } from "../../Utils/StatusMsg";

const Jobs = () => {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobList, setJobList] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [search, setSearch] = useState("");
  const [filterByStartDate, setFilterByStartDate] = useState(null);
  const [filterByEndDate, setFilterByEndDate] = useState(null);
  const [filterByTrackJob, setFilterByTrackJob] = useState("");
  const [filterByJobStatus, setFilterByJobStatus] = useState("");
  const [filterByCategory, setFilterByCategory] = useState("");
  const [categegory, setCategegory] = useState("");
  const itemsPerPage = PAGE.PAGE_COUNT;
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const handleDateRangeChange = (dates) => {
    if (!dates) {
      resetJobList();
      return;
    }

    const [start, end] = dates;
    setFilterByStartDate(start);
    setFilterByEndDate(end);
  };

  // function for change category
  const ChangeCategory = (e) => {
    setFilterByCategory(e.target.value);
  };

  // function for change tracking status
  const trackingStatus = (e) => {
    setFilterByTrackJob(e.target.value);
  };

  // function for change job status
  const JobStatus = (e) => {
    setFilterByJobStatus(e.target.value);
  };

  // function for handle search
  const hadleSearch = (e) => {
    setSearch(e.target.value);
  };

  // function for get category list
  useEffect(() => {
    async function getCategoryList(id = "", level = "") {
      const details = await SwiftVanApi.getCategoryList(userToken, id, level);
      if (details && details.code === STATUS_CODES.SUCCESS) {
        setCategegory(details && details.data);
      } else if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      }
    }
    getCategoryList();
  }, []);

  // function for get job list
  async function getJobLists(reset = false) {
    setIsLoading(true);

    let formattedStartDate = "";
    let formattedEndDate = "";

    if (!reset && filterByStartDate && filterByEndDate) {
      formattedStartDate = dayjs(filterByStartDate).format("YYYY-MM-DD");
      formattedEndDate = dayjs(filterByEndDate).format("YYYY-MM-DD");
    }
    const data = await SwiftVanApi.getJobList(
      userToken,
      pageNumber,
      search,
      "",
      "",
      "",
      formattedStartDate,
      formattedEndDate,
      reset ? "" : filterByCategory,
      reset ? "" : filterByJobStatus,
      reset ? "" : filterByTrackJob
    );
    if (data && data.code === STATUS_CODES.SUCCESS) {
      setJobList(data && data.data && data.data.jobList);
      setTotalRecords(data && data.data && data.data.jobListCount);
      setIsLoading(false);
    } else if (
      data &&
      data.data &&
      data.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getJobLists();
  }, [pageNumber, search]);

  function resetJobList() {
    setSearch("");
    setFilterByEndDate("");
    setFilterByStartDate("");
    setFilterByTrackJob("");
    setFilterByJobStatus("");
    setFilterByCategory("");
    setPageNumber(1);
    getJobLists(true);
  }

  function applyFilterCall() {
    setPageNumber(1);
    getJobLists();
  }

  const CancelJobList = (id) => {
    setIsLoading(true);
    let res = SwiftVanApi.cancelJob(userToken, id);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("CANCEL_JOB_SUCCESSFULLY"), "success");
        getJobLists();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="jobsMainSection">
                {/*---search-section---*/}
                <div className="topSearchSection">
                  <div className="topSearchSectionInner">
                    <h1>{t("JOBS")}</h1>
                    <div className="topSearchMain">
                      <img src={InputSearch} />
                      <Form.Control
                        type="search"
                        placeholder={`${t("SEARCH_FOR_JOBS")}`}
                        value={search}
                        onChange={(e) => hadleSearch(e)}
                      />
                    </div>
                  </div>
                </div>

                {/* ---userDetail---dropdown-dateall */}
                <div className="UserDetailDropMenu">
                  <Row xs={1} sm={1} md={2} lg={2} xl={4}>
                    <Col className="mb-3" xl={3}>
                      <Form.Label>{t("DATE")}</Form.Label>
                      <Form.Group className={Styles.selectDateRange}>
                        <Space
                          direction="vertical"
                          size={12}
                          style={{ width: "100%" }}
                        >
                          <RangePicker
                            inputReadOnly={true}
                            className="ant-picker-outlined"
                            presets={rangePresets}
                            value={[filterByStartDate, filterByEndDate]}
                            onChange={handleDateRangeChange}
                            format="DD/MM/YYYY"
                          />
                        </Space>
                      </Form.Group>
                    </Col>

                    <Col className="mb-3" xl={3}>
                      <Form.Label>{t("JOB_TRACK")}</Form.Label>
                      <Form.Select
                        className="inputDrop"
                        aria-label="Default select example"
                        type="date"
                        value={filterByTrackJob}
                        onChange={(value) => trackingStatus(value)}
                      >
                        <option key={0} value="">
                          {t("SELECT_STATUS")}{" "}
                        </option>
                        {jobTrackingStatus.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.value}>
                              {item.text}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>

                    <Col className="mb-3" xl={3}>
                      <Form.Label>{t("PAYMENT_STATUS")}</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="inputDrop"
                        value={filterByJobStatus}
                        onChange={(value) => JobStatus(value)}
                      >
                        <option key={0} value="">
                          {t("SELECT_PAYMENT_STATUS")}
                        </option>
                        {jobStatus.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.value}>
                              {" "}
                              {item.text}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>

                    <Col className="mb-3" xl={3}>
                      <Form.Label>{t("CATEGORY")}</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="inputDrop"
                        value={filterByCategory}
                        onChange={(value) => ChangeCategory(value)}
                      >
                        <option key={0} value="">
                          {t("SELECT_CATEGORY")}
                        </option>
                        {categegory &&
                          categegory.length > 0 &&
                          categegory.map((item, index) => {
                            return (
                              <option key={index + 1} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Col>

                    <Col className="mb-3" xs={12} sm={12} md={6} lg={6} xl={4}>
                      <div
                        className="userDetailsBtn"
                        style={{ marginTop: "5px" }}
                      >
                        <button
                          className="filterBtn"
                          onClick={() => applyFilterCall()}
                        >
                          {t("APPLY_FILTER")}
                        </button>
                        <button
                          className="resetBtn"
                          onClick={() => resetJobList()}
                        >
                          {t("RESET")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* table */}
                <div className="tableMainSection">
                  <div className="tableMainInner">
                    <div className="tableBootomScroll">
                      <div className="DashboardTableInner">
                        <Table
                          className="tabledashboard"
                          striped
                          responsive="lg"
                        >
                          <thead>
                            <tr className="align-middle">
                              <th
                                className="tableThBg tablethleft  leftsidepadding"
                                style={{ minWidth: "300px" }}
                              >
                                {t("SOURCE_LOCATION")}
                              </th>
                              <th
                                className="tableThBg "
                                style={{ minWidth: "300px" }}
                              >
                                {t("DESTINATION_LOCATION")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "150px" }}
                              >
                                {t("CUSTOMER")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "150px" }}
                              >
                                {t("DRIVER")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "100px" }}
                              >
                                {t("DATE")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "100px" }}
                              >
                                {t("REQUEST_STATUS_TEXT")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "100px" }}
                              >
                                {t("PAYMENT_STATUS")}
                              </th>
                              <th
                                className="tableThBg text-center"
                                style={{ minWidth: "80px" }}
                              >
                                {t("STOPS")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "120px" }}
                              >
                                {t("JOB_TRACK")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "200px" }}
                              >
                                {t("CATEGORYTABLE")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "120px" }}
                              >
                                {t("COMMISSION")}
                              </th>
                              <th
                                className="tableThBg"
                                style={{ minWidth: "120px" }}
                              >
                                {t("AMOUNT")}
                              </th>
                              <th className="tableThBg tablethRight rightsidepadding">
                                {t("ACTION")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {jobList && jobList.length > 0 ? (
                              jobList.map((item, index) => {
                                return (
                                  <tr
                                    className="tableDashtd align-middle"
                                    key={index}
                                  >
                                    <td className="dashboardTdSource leftsidepadding">
                                      <div className="tableTextThreeLine">
                                        {item && item.source_location ? (
                                          <>
                                            {capitalizeFirstLetter(
                                              item && item.source_location
                                            )}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td className="dashboardTdSource">
                                      <div className="tableTextThreeLine">
                                        {item && item.destination_location ? (
                                          <>
                                            {capitalizeFirstLetter(
                                              item && item.destination_location
                                            )}
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </div>
                                    </td>
                                    <td className="textUpperLatter tableTextStartTop">
                                      {item && item.customer_first_name ? (
                                        <>
                                          {item && item.customer_first_name}
                                          &nbsp;
                                          {item && item.customer_last_name}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td className="textUpperLatter tableTextStartTop">
                                      {item && item.driver_first_name ? (
                                        <>
                                          {item && item.driver_first_name}&nbsp;
                                          {item && item.driver_last_name}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td
                                      className={Styles.tableDate}
                                      style={{ Color: "#000000" }}
                                    >
                                      {item && item.date ? (
                                        <>
                                          {moment(item && item.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td
                                      className={`${Styles.tableStatus} textUpperLatter`}
                                    >
                                      {item && item.request_status ? (
                                        <>
                                          {item.request_status == "pending" && (
                                            <div className="PendingClass">
                                              {item.request_status}
                                            </div>
                                          )}
                                          {item.request_status == "accept" && (
                                            <div className="activeClass">
                                              {t("Accept")}
                                            </div>
                                          )}
                                          {item.request_status ==
                                            "reject_driver" && (
                                            <div className="InactiveClass">
                                              {t("REJECT_DRIVER")}
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td
                                      className={`${Styles.tableStatus} textUpperLatter`}
                                    >
                                      {item && item.job_status ? (
                                        <>
                                          {item &&
                                            item.job_status == "pending" && (
                                              <div className="PendingClass">
                                                {t("PENDING")}
                                              </div>
                                            )}
                                          {item &&
                                            item.job_status == "inprogress" && (
                                              <div>{t("INPROGRESS")}</div>
                                            )}
                                          {item &&
                                            item.job_status == "complete" && (
                                              <div className="activeClass">
                                                {t("COMPLETE")}
                                              </div>
                                            )}
                                          {item &&
                                            item.job_status ==
                                              "cancel_by_admin" && (
                                              <div className="InactiveClass">
                                                {t("CANCEL_ADMIN")}
                                              </div>
                                            )}
                                          {item &&
                                            item.job_status ==
                                              "cancel_customer" && (
                                              <div className="InactiveClass">
                                                {t("CANCEL_CUSTOMER")}
                                              </div>
                                            )}
                                          {item &&
                                            item.job_status ==
                                              "cancel_driver" && (
                                              <div className="InactiveClass">
                                                {t("CANCEL_DRIVER")}
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td className="tableTdStops text-center">
                                      {item && item.stops}
                                    </td>
                                    <td
                                      className={`${Styles.tableStatus} textUpperLatter`}
                                    >
                                      {item && item.track_status ? (
                                        <>
                                          {item &&
                                            item.track_status ==
                                              "on-the-way" && (
                                              <div className="PendingClass">
                                                {t("ON_THE_WAY")}
                                              </div>
                                            )}
                                          {item &&
                                            item.track_status == "pickup" && (
                                              <div>{t("PICKUP")}</div>
                                            )}
                                          {item &&
                                            item.track_status ==
                                              "delivered" && (
                                              <div className="activeClass">
                                                {t("DELIVERED")}
                                              </div>
                                            )}
                                          {item &&
                                            item.track_status == "start" && (
                                              <div className="PendingClass">
                                                {t("START")}
                                              </div>
                                            )}
                                          {item &&
                                            item.track_status == "reached" && (
                                              <div className="activeClass">
                                                {t("REACHED")}
                                              </div>
                                            )}
                                          {item &&
                                            item.track_status == "stop" && (
                                              <div className="PendingClass">
                                                {t("ON_THE_STOP")}
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td className={Styles.tableCategory}>
                                      {item && item.name ? item.name : "NA"}
                                    </td>
                                    <td className={Styles.tableAmount}>
                                      {item &&
                                        item.payment_summary.map(
                                          (com, index) => {
                                            return (
                                              <>
                                                {com && com.admin_commission ? (
                                                  <span key={index}>
                                                    <span
                                                      className={
                                                        Styles.currancySymbolTable
                                                      }
                                                    >
                                                      {item &&
                                                        item.payment_summary[0]
                                                          .currency_symbol}
                                                    </span>

                                                    {/* {parseFloat(
                                                      calculateTotalAmountWithDiscount(
                                                        item,
                                                        "commission"
                                                      )
                                                    ).toFixed(2)} */}
                                                    {item &&
                                                      Number(
                                                        item.payment_summary[0]
                                                          .admin_commission_amount
                                                      )?.toFixed(2)}
                                                  </span>
                                                ) : (
                                                  "NA "
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                    </td>
                                    <td className={Styles.tableAmount}>
                                      {item &&
                                      item.payment_summary[0].amount ? (
                                        <span>
                                          <span
                                            className={
                                              Styles.currancySymbolTable
                                            }
                                          >
                                            {item &&
                                              item.payment_summary[0]
                                                .currency_symbol}
                                          </span>
                                          {item &&
                                          item.payment_summary[0].admin_amount
                                            ? parseFloat(
                                                item &&
                                                  item.payment_summary[0]
                                                    .admin_amount
                                              ).toFixed(2)
                                            : parseFloat(
                                                item &&
                                                  item.payment_summary[0].amount
                                              ).toFixed(2)}
                                        </span>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                    <td className="text-center tableTextStartTop rightsidepadding">
                                      <div className="TableDropdown ">
                                        <Dropdown>
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <div className="optionListNew">
                                              <SlOptions />
                                            </div>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                            <Dropdown.Item
                                              onClick={() => {
                                                navigate(
                                                  `/jobs/job-details/${item.id}`
                                                );
                                              }}
                                            >
                                              {t("VIEW_DROPDOWN")}
                                            </Dropdown.Item>
                                            {item &&
                                            item.job_status === "pending" ? (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  CancelJobList(item.id)
                                                }
                                              >
                                                {t("CANCEL_JOB_BTN")}
                                              </Dropdown.Item>
                                            ) : (
                                              ""
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="centeredCell">
                                  <div className="dataRecord">
                                    {t("NO_REC_FOUND")}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    {/* pagination */}
                    <div className="tablePaginationDash">
                      {totalRecords > itemsPerPage && (
                        <ReactPaginate
                          previousLabel={<MdKeyboardArrowLeft />}
                          nextLabel={<MdKeyboardArrowRight />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          pageCount={Math.ceil(totalRecords / itemsPerPage)}
                          breakLabel={<span className="page-link">...</span>}
                          marginPagesDisplayed={0}
                          forcePage={pageNumber - 1}
                          pageRangeDisplayed={window.innerWidth > 768 ? 10 : 2}
                          onPageChange={handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Jobs;
