export const PAGE = {
  PAGE_COUNT: 20,
};

export const LIST_FOR = {
  DRIVER: 1,
  CUSTOMER: 2,
};

export const COUPON = {
  ADD_COUPON: 1,
  EDIT_COUPON: 2,
};

// term and AboutUs or privacy validation message const
export const messageValidation = {
  Min: 3,
  Medium: 10000,
  Max: 20000,
};

export const messageLengthValidtionValueMin = 3;
export const messageLengthValidationValueMax = 20000;
export const messageLengthValidationValueMedium = 10000;

export const COMMISSION_TYPE = {
  COMMISSION: "commission",
  PAGE: "page",
  LIFT_TIME: "lift_time",
};

export const OPTION_NAME = {
  ABOUT_US: "about_us",
  TERM_AND_CONDITION: "term_and_condition",
  PRIVACY_POLICY: "privacy_policy",
  LIFT_TIME: "lift_time",
};

export const VEHICLE_STATUS = {
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const SUPPORT_TYPE = {
  CUSTOMER: "customer",
  DRIVER: "driver",
};

export const TUTORIAL_TYPE = {
  ADD: "add",
  UPDATE: "update",
  DELETE: "delete",
};
