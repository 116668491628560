import styles from "../../authComponent/ForgotPassword/ForgotPassword.module.css";
import { useTranslation } from "react-i18next";
// import backgroundImage from "../../assets/Images/forgotBg.png";
import lock from "../../Assests/Images/lock.png";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Toster from "../../Utils/Toster";
import { useForm } from "react-hook-form";
import SwiftVanApi from "../../helpers/Api";
import Loader from "../../Validation/LoadingSpinner";
import { Validation } from "../../Validation/Validation";
import { STATUS_CODES } from "../../Utils/StatusCode";
import {STATUS_MSG} from "../../Utils/StatusMsg"

//---------function for forgot password----------
function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState,
  } = useForm();


  //-----------function for forgot password api call-----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("email", formdata.email.trim());
    setIsLoading(true);
    await SwiftVanApi.forgotPassword(requestData).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
      STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("RESET_SUCCESFULLY"), "success");
        navigate("/reset-password", {
          state: {
            email: formdata.email,
          },
        });
      } else {
        setIsLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      <div className="main">
        <div className={styles.loginMain}>
          <Container>
            <div className={styles.formBlock}>
              <div className={styles.logoImage}>
                <img src={lock} alt="" />
              </div>
              <div className={styles.forgotText}>
                <h3>{t("FORGOT_PASSWORD_PAGE")}</h3>
                <p>{t("FORGOT_TEXT")}</p>
              </div>
              <Form className=" formTopSpace" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="formSection ">
                  <Form.Label>{t("EMAIL")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    {...register("email", Validation.email)}
                    isInvalid={!!formState.errors.email}
                    placeholder={`${t("Enter_Email")}`}
                    autoComplete="off"
                    onChange={(e) => {
                      setValue("email", e.target.value.replace(/\s/g, ""), {
                        shouldValidate: true,
                      });
                    }}
                  />

                  {formState.errors.email && (
                    <p style={{ color: "red" }}>
                      {t(formState.errors.email.message)}
                    </p>
                  )}
                </Form.Group>

                <div className={styles.buttonControls}>
                  <button
                    type="button"
                    onClick={() => navigate("/")}
                    className={styles.leftButton}
                  >
                    {t("CANCEL")}
                  </button>
                  <button type="submit" className={styles.rightButton}>
                    {t("RESET")}
                  </button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
}
export default ForgotPassword;
