import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "../../Admin/Metadata/metadata.module.css";
import FileImg from "../../Assests/Images/FileInput.png";
import CameraImg from "../../Assests/Images/uploadCamera.png";
import { useTranslation } from "react-i18next";
import { Validation } from "../../Validation/Validation";
import { Controller, useForm } from "react-hook-form";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../../Validation/LoadingSpinner";
import { MdCancel } from "react-icons/md";
import { TUTORIAL_TYPE } from "../../Utils/Constant";

function TutorialPopup({
  tutorialPopupShow,
  tutorialPopupHandleClose,
  seletedTutorialData,
  TutorialList,
  eventKeyValue,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, setValue, formState, reset, control } =
    useForm({
      mode: "onChange",
    });

  const [previewVideos, setPreviewVideos] = useState([null, null, null]);

  useEffect(() => {
    if (seletedTutorialData.length == 0) {
      setPreviewVideos([null, null, null]);
      setValue("description", "");
      return;
    }
    const getVideoCount = seletedTutorialData?.[0]?.videos?.length;
    let array = [];
    if (getVideoCount) {
      [...Array(3 - getVideoCount)].map((val) => array.push(null));
    }
    setPreviewVideos(array);
    setValue("description", seletedTutorialData?.[0]?.description);
  }, [seletedTutorialData, tutorialPopupShow]);

  const handleFileSelection = (index) => (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const updatedPreviews = [...previewVideos];
      updatedPreviews[index] = selectedFile;
      setPreviewVideos(updatedPreviews);
    }
  };

  const onSubmit = (data) => {
    updateApi(data);
  };

  const updateApi = (data) => {
    const trimmedDescription =
      data && data.description && data.description.trim();
    var PostData = new FormData();
    const videosItems = previewVideos.filter((item) => {
      return item != null;
    });

    PostData.append(
      "type",
      seletedTutorialData.length == 0 ? TUTORIAL_TYPE.ADD : TUTORIAL_TYPE.UPDATE
    );
    {
      seletedTutorialData.length !== 0 &&
        PostData.append("tutorial_id", seletedTutorialData[0]?.id);
    }
    PostData.append("description", trimmedDescription);
    {
      seletedTutorialData.length == 0 &&
        PostData.append("category_id", eventKeyValue);
    }
    videosItems &&
      videosItems.length > 0 &&
      videosItems.map((item) => {
        PostData.append("videos", item);
      });

    setIsLoading(true);
    let res = SwiftVanApi.postTutorialData(authToken, PostData);
    res.then((data) => {
      setIsLoading(false);
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        {
          seletedTutorialData.length == 0
            ? Toster(t("ADD_TUTORIAL_SUCCESSFULLY"), "success")
            : Toster(t("UPDATE_TUTORIAL_SUCCESSFULLY"), "success");
        }
        tutorialPopupHandleClose();
        TutorialList();
        reset();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const onClose = () => {
    tutorialPopupHandleClose();
    reset();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal show={tutorialPopupShow.show} onHide={() => onClose()}>
        <div className="addVanPopupMain">
          <Modal.Header>
            {seletedTutorialData?.[0]?.videos?.length > 0 ? (
              <Modal.Title>{t("UPDATE_TUTORIAL")}</Modal.Title>
            ) : (
              <Modal.Title>{t("ADD_TUTORIAL")}</Modal.Title>
            )}
            <MdCancel onClick={() => onClose()} />
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              {previewVideos?.length > 0 && (
                <div className={Styles.tutorialPopupMain}>
                  <div className={Styles.addVanUploadBtn}>
                    {previewVideos.length > 0 && <h1>{t("TUTORIAL_VIDEO")}</h1>}

                    {previewVideos.map((previewVideo, index) => (
                      <>
                        <div className={Styles.vanUploadBtnInner} key={index}>
                          {previewVideo && (
                            <div className={Styles.addVanPreviewImg}>
                              <span
                                className={Styles.previewImageCrossIcon}
                                onClick={() => {
                                  const updatedPreviews = [...previewVideos];
                                  updatedPreviews[index] = null;
                                  setPreviewVideos(updatedPreviews);
                                }}
                              >
                                <MdCancel />
                              </span>
                            </div>
                          )}

                          <div className={Styles.vanUploadInnerText}>
                            {previewVideo ? (
                              <div className={Styles.vanUploadImg}>
                                <video
                                  src={URL.createObjectURL(previewVideo)}
                                  controls
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                            ) : (
                              <>
                                <div className={Styles.vanUploadImgIcon}>
                                  <img
                                    src={FileImg}
                                    width="50px"
                                    height="50px"
                                    alt="File Icon"
                                  />
                                </div>

                                <p>{t("UPLOAD_TUTORIAL_VIDEO")}</p>

                                <div>
                                  <Controller
                                    control={control}
                                    name={`tutorialVideo${index + 1}`}
                                    render={({ field }) => (
                                      <input
                                        id={`file${index}`}
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="video/*"
                                        onChange={handleFileSelection(index)}
                                      />
                                    )}
                                  />
                                  <div className={Styles.uploadBtnMain}>
                                    <button type="button">
                                      <label
                                        style={{ cursor: "pointer" }}
                                        htmlFor={`file${index}`}
                                      >
                                        <img src={CameraImg} alt="Camera" />
                                        {t("UPLOAD")}
                                      </label>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {!previewVideo &&
                          formState.errors[`tutorialVideo${index + 1}`] && (
                            <p style={{ color: "red" }}>
                              {t(
                                formState.errors[`tutorialVideo${index + 1}`]
                                  .message
                              )}
                            </p>
                          )}
                      </>
                    ))}
                  </div>
                </div>
              )}

              <Row>
                <Col lg={12}>
                  <Form.Group className="popupInput">
                    <Form.Label>{t("DESCRIPTION")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="description"
                      placeholder={`${t("ENTER_DESCRIPTION")}`}
                      autoComplete="off"
                      {...register(
                        "description",
                        Validation.vehicleDescription
                      )}
                      onChange={(e) => {
                        setValue("description", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {formState.errors.description && (
                      <p style={{ color: "red" }}>
                        {t(formState.errors.description.message)}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className={Styles.AddVanBtn}>
                <button type="submit">{t("SAVE")}</button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default TutorialPopup;
